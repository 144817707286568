import { useDeleteDepotMutation } from "@evr/apis/depot";
import { useGetDepotById } from "@evr/apis/depot/hooks";
import { DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDialog } from "@evr/store/slices/dialog";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Typography } from "@evr/ui/Typography";

export const DeleteDepotDialog = () => {
  const { id } = useAppSelector(selectDialog);
  const depot = useGetDepotById(id || -1);

  const [deleteDepot, { isLoading }] = useDeleteDepotMutation();

  const handleAction = () => {
    if (!id || !depot) {
      return;
    }
    deleteDepot(id);
  };

  return (
    <>
      <DialogHeader title={DialogTitle.DELETE_DEPOT} />
      <DialogBody>
        <Typography>
          Are you sure to delete this Depot?
          <br />
          <small>{depot ? `Name: ${depot?.name}` : "Error finding the depot."}</small>
        </Typography>
        <DialogActionButtons loading={isLoading} buttonTitle={"Delete"} onClickAction={handleAction} />
      </DialogBody>
    </>
  );
};
