import { useGetVehiclesQuery } from "@evr/apis/vehicle/hooks";
import { TableSearch } from "@evr/components/Table";
import { TablePagination } from "@evr/components/Table/TablePagination";
import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import {
  clearVehicleSearchColumn,
  selectVehiclesPagination,
  setVehiclePageIndex,
  setVehicleSearchColumn,
} from "@evr/store/slices/vehicle";
import { SearchColumn } from "@evr/types";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { TableWrapper } from "@evr/ui/Table";
import { Typography } from "@evr/ui/Typography";

import { VehicleTable } from "./VehicleTable";
import { vehicleTableColumns } from "./vehicleTableColumns";

export const VehicleWindow = () => {
  const dispatch = useAppDispatch();
  const { search, pageIndex } = useAppSelector(selectVehiclesPagination);
  const { data, isFetching, isError, refetch } = useGetVehiclesQuery();

  const handleRefetchData = () => refetch();
  const handleClearSearchValue = (column: string) => dispatch(clearVehicleSearchColumn(column));
  const handleChangeSearchValue = (item: SearchColumn) => dispatch(setVehicleSearchColumn(item));
  const handleChangePageIndex = (page: number) => dispatch(setVehiclePageIndex(page));
  const handleOpenVehicleDialog = () => dispatch(openDialog({ type: Dialogs.ADD_VEHICLE }));

  const Actions = (
    <Flex>
      <IconButton icon="plus" color="white" size={1.2} title="Add Vehicle" onClick={handleOpenVehicleDialog} />
      <DialogSyncHeaderButton onClick={handleRefetchData} loading={isFetching} />
    </Flex>
  );

  return (
    <>
      <TableSearch
        search={search}
        Actions={Actions}
        columns={vehicleTableColumns}
        changeSearchValue={handleChangeSearchValue}
        clearSearchValue={handleClearSearchValue}
      />
      <TableWrapper>
        {isFetching && <CircleLoading color="primary" size={5} blurBackground />}
        {data ? (
          <VehicleTable vehicles={data.resources} />
        ) : (
          <Flex height="100%" width="100%">
            {isError && <Typography>Something went wrong. Please try again.</Typography>}
          </Flex>
        )}

        {data && data.resources.length === 0 && (
          <Typography textAlign="center" as="div">
            You have no Vehicle
          </Typography>
        )}
      </TableWrapper>
      <TablePagination totalPages={data?.totalPages} pageIndex={pageIndex} action={handleChangePageIndex} />
    </>
  );
};
