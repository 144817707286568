import { ReactNode } from "react";

import { Wrapper, WrapperProps } from "../FlexBox";

interface PropsType extends WrapperProps {
  children: ReactNode;
}

export const DialogBody = ({ children, ...rest }: PropsType) => (
  <Wrapper padding="1rem 1.5rem" overflow="auto" width="100%" {...rest}>
    {children}
  </Wrapper>
);
