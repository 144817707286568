import { useCancelScheduledJourneyMutation } from "@evr/apis/scheduledJourney";
import { useGetScheduledJourneysQuery } from "@evr/apis/scheduledJourney/hooks";
import { DateTimePicker } from "@evr/components/DateTimePicker";
import { ActiveLayerTypes, ScheduledJourneyStatus, TableNameKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { setActiveLayer } from "@evr/store/slices/map";
import { toggleShowingJourney } from "@evr/store/slices/result";
import { clearScheduledJourneyResult } from "@evr/store/slices/scheduled-journey-results";
import { getScheduledJourneyAsync } from "@evr/store/slices/scheduled-journey-results/asyncActions";
import {
  selectScheduledJourneyPagination,
  setScheduledJourneyStatus,
  setScheduledJourneyTimeRange,
} from "@evr/store/slices/scheduled-journeys";
import { openSidebar } from "@evr/store/slices/sidebar";
import { ScheduledJourneyTableRow } from "@evr/types";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { StyledTable, TD, TH, TR } from "@evr/ui/Table";
import { Typography } from "@evr/ui/Typography";

import { ScheduledJourneyTableActions } from "./ScheduledJourneyTableActions";
import { scheduledJourneyTableColumns } from "./ScheduledJourneyTableColumns";

export const CompletedWindow = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading, isError } = useGetScheduledJourneysQuery();
  const pagination = useAppSelector(selectScheduledJourneyPagination);

  const status = pagination.status;
  const [cancelScheduledJourney, { isLoading: addLoading }] = useCancelScheduledJourneyMutation();
  type StatusMap = {
    [key: string]: ScheduledJourneyStatus;
  };
  const api = useApi(getScheduledJourneyAsync);
  const statusMap: StatusMap = {
    Scheduled: ScheduledJourneyStatus.SCHEDULED,
    Rejected: ScheduledJourneyStatus.REJECTED,
    InProgress: ScheduledJourneyStatus.INPROGRESS,
    Completed: ScheduledJourneyStatus.COMPLETED,
    Cancelled: ScheduledJourneyStatus.CANCELLED,
  };
  const journeyList: ScheduledJourneyTableRow[] = data
    ? data.resources.map(journey => {
        const date = new Date(journey.startTime);
        const reason =
          statusMap[journey.status] === ScheduledJourneyStatus.CANCELLED
            ? "Fleet manager"
            : statusMap[journey.status] === ScheduledJourneyStatus.REJECTED
            ? "Driver"
            : "-";
        return {
          id: journey.id,
          depotName: journey.depot.name,
          startTime: date.toLocaleString(),
          driverName: journey.driver.firstName + " " + journey.driver.lastName,
          vehicleName: journey.vehicle.make + " " + journey.vehicle.model,
          vehicleReg: journey.vehicle.licence,
          status: journey.status,
          reason,
        };
      })
    : [];

  const onChange = (date: Date) => {
    let end = data?.endDate;
    if (end === undefined || new Date(end) < date) {
      end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    }
    dispatch(setScheduledJourneyTimeRange({ start: date.toISOString(), end: new Date(end).toISOString() }));
  };

  const endDateChange = (endDate: Date) => {
    if (data === undefined) {
      return null;
    }
    const actualEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
    if (data.startDate !== undefined && new Date(data?.startDate) > endDate) {
      dispatch(setScheduledJourneyTimeRange({ start: endDate.toISOString(), end: actualEnd.toISOString() }));
    } else {
      dispatch(
        setScheduledJourneyTimeRange({ start: new Date(data.startDate).toISOString(), end: actualEnd.toISOString() }),
      );
    }
  };

  const viewJourney = async (journeyId: number) => {
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.SCHEDULED));
    dispatch(clearScheduledJourneyResult());
    dispatch(setActiveLayer(ActiveLayerTypes.SCHEDULED_JOURNEY));
    dispatch(toggleShowingJourney(journeyId));
    await api.sendRequest(journeyId);
    dispatch(openSidebar());
  };

  const cancelJourney = async (journeyId: number) => {
    await cancelScheduledJourney(journeyId).unwrap();
  };

  const start = data ? new Date(data.startDate) : new Date();
  start.setHours(0, 0, 0, 0);
  const end = data ? new Date(data.endDate) : new Date();
  end.setHours(23, 59, 59, 0);

  const columns = scheduledJourneyTableColumns(status);

  return (
    <>
      <ColumnContainer gradient width="100%" padding="0 0 0.5rem">
        {
          <Flex justify="flex-start" position="relative" width="100%" height="45px" style={{ paddingLeft: 15 }}>
            <Typography>From </Typography>
            <DateTimePicker title="Filter start date" label="" value={start} onChange={onChange} dateOnly={true} />
            <Typography style={{ paddingLeft: 15 }}>To </Typography>
            <DateTimePicker title="Filter end date" label="" value={end} onChange={endDateChange} dateOnly={true} />
          </Flex>
        }
      </ColumnContainer>
      <StyledTable tableType={TableNameKeys.SCHEDULED_JOURNEY}>
        <thead>
          <tr>
            {columns.map(col => (
              <TH key={col.field} padding="0" tableType={TableNameKeys.DRIVER}>
                {col.name}
              </TH>
            ))}
            <TH tableType={TableNameKeys.DRIVER} textAlign="center">
              Actions
            </TH>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <TR>
              <TD colSpan={columns.length + 1}>
                <Typography as="h2">Loading Data</Typography>
              </TD>
            </TR>
          )}
          {isError && (
            <TR>
              <TD colSpan={columns.length + 1}>
                <Typography as="h2">Error Loading Data</Typography>
              </TD>
            </TR>
          )}
          {journeyList.length === 0 && !isLoading && !isError && (
            <TR>
              <TD colSpan={columns.length + 1}>
                <Typography as="h2">No Journeys to Display</Typography>
              </TD>
            </TR>
          )}

          {journeyList.map((journeyRow, index) => (
            <TR key={journeyRow.id}>
              {columns.map(col => (
                <TD key={col.field}>{(journeyRow as unknown as Record<string, string>)[col.field] || "_"}</TD>
              ))}
              <TD textAlign="center">
                <ScheduledJourneyTableActions
                  handleViewClick={viewJourney}
                  handleCancelClick={cancelJourney}
                  scheduledJourneyId={journeyRow.id}
                  status={statusMap[journeyRow.status]}
                ></ScheduledJourneyTableActions>
              </TD>
            </TR>
          ))}
        </tbody>
      </StyledTable>
    </>
  );
};
