import { DownloadResultMenu } from "@evr/components/DownloadResultMenu";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { getJourneyCsvAsync, getJourneyGpxAsync } from "@evr/store/slices/result/asyncActions";
import { selectedScheduledJourney } from "@evr/store/slices/scheduled-journey-results";
import { ColumnContainer } from "@evr/ui/FlexBox";

interface PropsType {
  color: string;
}

export const ScheduledResultDownload = ({ color }: PropsType) => {
  const scheduledJourney = useAppSelector(selectedScheduledJourney);
  const downloadGpxApi = useApi(getJourneyGpxAsync);
  const downloadCsvApi = useApi(getJourneyCsvAsync);
  const loading = downloadGpxApi.loading || downloadCsvApi.loading;
  const handleDownloadCSV = async () => {};

  return (
    <>
      <ColumnContainer padding="0.25rem 0" width="45px">
        {false && (scheduledJourney?.status as unknown) !== "Completed" && (
          <DownloadResultMenu loading={loading} menuPosition={{ left: "0" }} handleDownloadCSV={handleDownloadCSV} />
        )}
      </ColumnContainer>
    </>
  );
};
