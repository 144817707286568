import { useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { config } from "@evr/config";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";
import { Typography } from "@evr/ui/Typography";
import { batteryBufferToDecimal, decimalToPercent } from "@evr/utils";

export const SettingBatteryBuffer = () => {
  const { percent: batteryPercent } = config.batteryBuffer;
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [batteryBuffer, setBatterBuffer] = useState(decimalToPercent(orgSettings.batteryBuffer));

  const isFreePlan = planType === PlanKeys.STARTER;

  const [editBatterBuffer, { isLoading }] = useEditSettingsMutation();

  const handleSetBatteryBuffer = () => {
    editBatterBuffer({ ...orgSettings, batteryBuffer: batteryBufferToDecimal(batteryBuffer) });
  };

  const handleChangebatteryBuffer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > batteryPercent.max) {
      setBatterBuffer(batteryPercent.max);
    } else if (value < batteryPercent.min) {
      setBatterBuffer(batteryPercent.min);
    } else {
      setBatterBuffer(value);
    }
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Battery Buffer (0-50)%</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto" title={isFreePlan ? "This item is not active on your plan" : ""}>
        <Input
          type="number"
          width="80px"
          padding="0.3rem 0.4rem"
          disabled={isFreePlan}
          value={batteryBuffer}
          min={batteryPercent.min}
          max={batteryPercent.max}
          onChange={handleChangebatteryBuffer}
        />
        <Wrapper position="relative">
          {isLoading && <CircleLoading size={4} />}
          <Button onClick={handleSetBatteryBuffer} disabled={isFreePlan || isLoading}>
            Set
          </Button>
        </Wrapper>
      </Flex>
    </RowContainer>
  );
};
