import { ParcelProcessedStatusKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectProcessedParcelStatus, selectValidJobLocations } from "@evr/store/slices/parcel";
import { JobLocation } from "@evr/types";
import { Typography } from "@evr/ui/Typography";

import { ParcelSidebarItem } from "../ParcelSidebar/ParcelSidebarItem";

export const ParcelSidebar = () => {
  const validJobLocations = useAppSelector(selectValidJobLocations);
  const parcelStatus = useAppSelector(selectProcessedParcelStatus);
  const validJobLocationsArray = Object.entries(validJobLocations);
  if (validJobLocationsArray.length <= 0) {
    return <Typography>Please select the CSV file first.</Typography>;
  }
  if (parcelStatus !== ParcelProcessedStatusKeys.DONE) {
    return <Typography>Waiting to process all parcels.</Typography>;
  }
  return (
    <>
      {validJobLocationsArray.map(([description, location], index) => (
        <ParcelSidebarItem key={description} description={description} jobLocation={location as JobLocation} index={index} />
      ))}
    </>
  );
};
