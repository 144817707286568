import { useCallback, useEffect, useState } from "react";

import {
  DialogTitle,
  FetchDataKeys,
  SavingsEnergyChartsKeys,
  SavingsEnergyIntervalsKeys,
  SavingsEnergyUnitsKeys,
} from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { selectCalculationSavings } from "@evr/store/slices/result";
import { getCalculationSavingsAsync } from "@evr/store/slices/result/asyncActions";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogBody, DialogHeader, DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { Divider } from "@evr/ui/Divider";
import { ColumnContainer, RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

import { SavingsChartsView } from "./SavingsChartsView";
import { SavingsCustomButtons } from "./SavingsCustomButtons";
import { SavingsEnergyDialogContainer } from "./styles";

const nextChart = (value: SavingsEnergyChartsKeys): SavingsEnergyChartsKeys => {
  switch (value) {
    case SavingsEnergyChartsKeys.COST_VS_SAVING:
      return SavingsEnergyChartsKeys.CO2;
    case SavingsEnergyChartsKeys.CO2:
      return SavingsEnergyChartsKeys.NOx;
    case SavingsEnergyChartsKeys.NOx:
      return SavingsEnergyChartsKeys.COST_VS_SAVING;
    default:
      return SavingsEnergyChartsKeys.COST_VS_SAVING;
  }
};
const previousChart = (value: SavingsEnergyChartsKeys): SavingsEnergyChartsKeys => {
  switch (value) {
    case SavingsEnergyChartsKeys.COST_VS_SAVING:
      return SavingsEnergyChartsKeys.NOx;
    case SavingsEnergyChartsKeys.NOx:
      return SavingsEnergyChartsKeys.CO2;
    case SavingsEnergyChartsKeys.CO2:
      return SavingsEnergyChartsKeys.COST_VS_SAVING;
    default:
      return SavingsEnergyChartsKeys.COST_VS_SAVING;
  }
};

export const SavingsEnergyDialog = () => {
  const [time, setTime] = useState<SavingsEnergyIntervalsKeys>(SavingsEnergyIntervalsKeys.ONE_MONTH);
  const [chart, setChart] = useState<SavingsEnergyChartsKeys>(SavingsEnergyChartsKeys.COST_VS_SAVING);

  const { loading, data: preparedSavingsData } = useAppSelector(selectCalculationSavings);

  const api = useApi(getCalculationSavingsAsync);

  const getSavings = useCallback(
    async () => await api.sendRequest(),
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (loading === FetchDataKeys.LOADED) {
      return;
    }
    getSavings();
  }, [getSavings, loading]);

  const handleLeftArrow = () => setChart(chart => previousChart(chart));
  const handleRightArrow = () => setChart(chart => nextChart(chart));

  const data = preparedSavingsData[time][chart];
  const title = `${chart} ${SavingsEnergyUnitsKeys[chart]} / ${time}`;

  return (
    <>
      <DialogHeader
        title={DialogTitle.SAVING_ENERGY}
        actionButtons={<DialogSyncHeaderButton onClick={getSavings} loading={api.loading} />}
      />
      <DialogBody>
        <SavingsEnergyDialogContainer>
          {api.loading && <CircleLoading size={5} blurBackground />}
          <SavingsCustomButtons time={time} setTime={setTime} />
          <Divider direction="v" />

          <ColumnContainer id="chartContainer">
            <Typography margin="0 0 0.75rem 0">{title}</Typography>
            <SavingsChartsView data={data} chart={chart} />
            <Divider direction="h" />

            <RowContainer width="100%" justify="space-between" padding="0 1.5rem">
              <IconButton icon="arrow-left" title="Previous" onClick={handleLeftArrow} />
              <IconButton icon="arrow-right" title="Next" onClick={handleRightArrow} />
            </RowContainer>
          </ColumnContainer>
        </SavingsEnergyDialogContainer>
      </DialogBody>
    </>
  );
};
