import { useFormikContext } from "formik";

import { MinutesStep, TimeRange } from "@evr/types";

import { TimeRangePicker } from "../TimeRangePicker";

interface FormikTimeRangeProps {
  name: string;
  label?: string;
  minutesStep?: MinutesStep;
  disabled?: boolean;
  clearable?: boolean;
}

export const FormikTimeRange = ({ name, label, minutesStep, disabled, clearable }: FormikTimeRangeProps) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleOnChange = (timeRange: TimeRange | null) => {
    setFieldValue(name, timeRange);
  };

  const currentValues = (values as Record<string, TimeRange>)[name];
  const errorText = (errors as Record<string, TimeRange>)[name];

  return (
    <TimeRangePicker
      values={currentValues}
      error={errorText ? errorText?.end || errorText?.start || String(errorText) : undefined}
      label={label}
      minutesStep={minutesStep}
      onChange={handleOnChange}
      disabled={disabled}
      clearable={clearable}
    />
  );
};
