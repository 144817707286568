import { useContext, useEffect, useMemo, useState } from "react";

import { inAndOut } from "ol/easing";
import { extend } from "ol/extent";
import olMap from "ol/Map";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";

import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectResultRoutesStatus, selectResultState } from "@evr/store/slices/result";
import { selectJourneysColorArray } from "@evr/store/slices/setting";
import { Journey } from "@evr/types";

import ResultJourneyAddressJobsPopUpContainerProps from "./ResultJourneyAddressJobsPopUpContainer";
import ResultJourneyLayer from "./ResultJourneyLayer";
import ResultJourneyLocationsLayer from "./ResultJourneyLocationsLayer";
import ResultJourneyLocationTimeLayer from "./ResultJourneyLocationTimeLayer.tsx";
import ResultsDepotLayer from "./ResultsDepotLayer";
import ResultUnroutablesLayer from "./ResultUnroutablesLayer";

interface ResultLayerProps {
  fitViewToExtent: (extent: number[]) => void;
}

const ResultLayer = ({ fitViewToExtent }: ResultLayerProps) => {
  const depotSource = useMemo(() => new olVectorSource(), []);

  const resultState = useAppSelector(selectResultState);
  const journeySources = useMemo(
    () => resultState.result?.journeys.map(() => new olVectorSource()) ?? [],
    [resultState.result?.journeys],
  );

  const locationSources = useMemo(
    () => resultState.result?.journeys.map(() => new olVectorSource()) ?? [],
    [resultState.result?.journeys],
  );

  const locationTimesSources = useMemo(
    () => resultState.result?.journeys.map(() => new olVectorSource()) ?? [],
    [resultState.result?.journeys],
  );
  const unrouteableSource = useMemo(() => new olVectorSource(), []);

  const journeyColors = useAppSelector(selectJourneysColorArray);
  const { pointToZoom, showRoutes } = useAppSelector(selectResultRoutesStatus);

  const map = useContext(MapContext) as olMap;

  useEffect(() => {
    if (!resultState.calculationId || !map! || !showRoutes) {
      return;
    }

    fitLayersToView();
  }, [resultState.calculationId, resultState.showUnrouteables, showRoutes, map]);

  useEffect(() => {
    if (!map || !pointToZoom || !pointToZoom.point || !resultState) {
      return;
    }
    const convertedCenter = olProj.fromLonLat(pointToZoom.point.coordinates);

    map.getView().animate({
      center: convertedCenter,
      duration: 1000,
      easing: inAndOut,
      zoom: 15,
    });

    setTimeout(() => fitLayersToView(), 2000);
  }, [pointToZoom]);

  const fitLayersToView = () => {
    const visibleJourneySources = resultState!.result!.journeys.reduce(
      (acc: olVectorSource[], val: Journey, index: number) => {
        if (showRoutes[val.id]) {
          acc = [...acc, journeySources[index], locationSources[index]];
        }
        return acc;
      },
      [],
    );

    const sourcesToFit = [depotSource, ...visibleJourneySources, ...locationTimesSources, unrouteableSource];

    if (sourcesToFit.every(source => source.isEmpty())) {
      return;
    }

    const combinedExtent = sourcesToFit
      .map(source => source.getExtent())
      .reduce((acc, extent) => extend(acc ?? [], extent ?? []));

    fitViewToExtent(combinedExtent);
  };

  return (
    <>
      {resultState.result && (
        <>
          {resultState.result.journeys.map((journey, index) => (
            <>
              <ResultJourneyLayer
                key={`journey-${index}`}
                journeySource={journeySources[index]}
                journey={journey}
                journeyColor={journeyColors[index % journeyColors.length]}
                isVisible={showRoutes[journey.id]}
              />
              <ResultJourneyLocationsLayer
                key={`location-${index}`}
                locationsSource={locationSources[index]}
                journey={journey}
                journeyColor={journeyColors[index % journeyColors.length]}
                layerName={MapLayerNames.LOCATIONS + journey.id}
                isVisible={showRoutes[journey.id]}
              />
              <ResultJourneyLocationTimeLayer
                key={`time-${index}`}
                locationsSource={locationTimesSources[index]}
                journey={journey}
                layerName={MapLayerNames.LOCATION_TIMES + journey.id}
                isVisible={showRoutes[journey.id]}
              />
            </>
          ))}
          <ResultUnroutablesLayer unroutablesSource={unrouteableSource} />
          <ResultsDepotLayer
            name={MapLayerNames.RESULTS_DEPOT}
            depotSource={depotSource}
            depot={resultState.result.depot}
          />
          <ResultJourneyAddressJobsPopUpContainerProps
            result={resultState.result}
            layerNames={resultState.result.journeys.map(j => MapLayerNames.LOCATIONS + j.id)}
            snapshots={[]}
          />
        </>
      )}
    </>
  );
};

export default ResultLayer;
