import { DialogTitle, TermsConditions } from "@evr/constant";
import { DialogBody, DialogHeader } from "@evr/ui/Dialog";

export const TermsConditionsDialog = () => {
  return (
    <>
      <DialogHeader title={DialogTitle.TERMS_CONDITIONS} />
      <DialogBody align="flex-start" maxHeight="60%">
        {TermsConditions}
      </DialogBody>
    </>
  );
};
