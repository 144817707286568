import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { SnackbarKeys } from "@evr/constant";
import { apiInstance } from "@evr/services/baseHttp";
import { logOutUser } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { apiCallPayload, apiErrors, apiResponseData, Action } from "@evr/types";
import { errorHandling } from "@evr/utils";

export const apiCallThunk = (name: string) =>
  createAsyncThunk(`apiCall${name}`, async (payload: apiCallPayload, { dispatch, rejectWithValue }) => {
    const {
      apiRequest,
      onStart,
      onSuccess,
      onSuccessEnd,
      onEnd,
      onError,
      onSuccessMsg,
      showDefaultError = true,
    } = payload;

    onStart && dispatch({ ...onStart });

    try {
      const response = await apiInstance.request<apiResponseData>(apiRequest);

      if (onSuccess && !Array.isArray(onSuccess)) {
        dispatch({
          type: (onSuccess as Action).type,
          payload: (onSuccess as Action).payload || response.data.item || response.data.items || response.data,
        });
      } else {
        onSuccess?.forEach(action => {
          dispatch({
            type: (action as Action).type,
            payload: (action as Action).payload || response.data.item || response.data.items || response.data,
          });
        });
      }

      onSuccessEnd && dispatch({ ...onSuccessEnd });
      onSuccessMsg && dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: onSuccessMsg }));
      onEnd && dispatch({ ...onEnd });

      if (apiRequest.responseType === "blob") {
        return URL.createObjectURL(response.data as unknown as Blob);
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosError<apiErrors>;
      const { msg, unAuthorized } = errorHandling(error, apiRequest.url);

      if (unAuthorized) {
        dispatch(logOutUser());
        dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: msg }));
      } else {
        onError && dispatch({ ...onError });
        showDefaultError && dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: msg }));
        onEnd && dispatch({ ...onEnd });
      }
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  });
