import { useContext, useEffect } from "react";

import { FeatureLike as olFeatureLike } from "ol/Feature";
import { Geometry as olGeometry } from "ol/geom";
import olVectorLayer from "ol/layer/Vector";
import olMap from "ol/Map";
import olMapBrowserEvent from "ol/MapBrowserEvent";
import olVectorSource from "ol/source/Vector";

import MapContext from "../Map/MapContext";

export interface ClickSelectProps {
  onClick: (features: olFeatureLike[], e: olMapBrowserEvent<any>) => void;
  layerNames?: string[];
  hitTolerance?: number;
  active?: boolean;
}

const MapClick = ({ onClick, layerNames, hitTolerance = 0, active = true }: ClickSelectProps) => {
  const map = useContext(MapContext) as olMap;

  useEffect(() => {
    if (!map || !active) {
      return () => {};
    }
    map.on("click", clickHandler);

    return () => {
      if (!map || !active) {
        return;
      }
      map.un("click", clickHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClick, map, active]);

  const clickHandler = (e: olMapBrowserEvent<any>) => {
    const features = map.getFeaturesAtPixel(e.pixel, {
      hitTolerance,
      layerFilter: layer => {
        if (!layerNames) {
          return true;
        }
        const layerName = (layer as olVectorLayer<olVectorSource<olGeometry>>).get("name");
        if (!layerName) {
          return false;
        }
        return layerNames.some(n => n === layerName);
      },
    });
    onClick(features, e);
  };

  return null;
};

export default MapClick;
