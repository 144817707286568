import { useEffect, useState } from "react";

import { MinutesStep, TimeRange } from "@evr/types";
import { Wrapper } from "@evr/ui/FlexBox";
import { TextInput } from "@evr/ui/TextInput";

import { Portal } from "../Portal";
import { TimeRangeContainer } from "./TimeRangeContainer";

interface TimeRangePickerProps {
  values?: TimeRange;
  disabled?: boolean;
  label?: string;
  error?: string;
  minutesStep?: MinutesStep;
  clearable?: boolean;
  onChange?: (timeRange: TimeRange | null) => void;
}

const emptyTime = "--:--";

export const TimeRangePicker = ({
  label,
  values,
  disabled,
  error,
  minutesStep,
  clearable,
  onChange,
}: TimeRangePickerProps) => {
  const [open, setOpen] = useState(false);
  const [timeRange, setTimeRange] = useState<TimeRange | null>(values || null);

  useEffect(() => {
    if (!values) {
      return;
    }
    setTimeRange(values);
  }, [values]);

  const handleOpenTimeRange = () => setOpen(open => !open);

  const handleSetTimeRange = (timeRange: TimeRange | null) => {
    setTimeRange(timeRange);
    onChange && onChange(timeRange);
  };

  return (
    <Wrapper position="relative">
      <TextInput
        label={label}
        value={`from ${timeRange?.start || emptyTime}   to ${timeRange?.end || emptyTime}`}
        endIcon="clock"
        readOnly
        disabled={disabled}
        onClickEndIcon={handleOpenTimeRange}
        error={!!error}
        errorText={error || null}
      />
      {open && (
        <Portal withOverlay>
          <TimeRangeContainer
            onClose={handleOpenTimeRange}
            minutesStep={minutesStep}
            timeRange={timeRange || { start: "00:00", end: "00:00" }}
            setTimeRange={handleSetTimeRange}
            clearable={clearable}
          />
        </Portal>
      )}
    </Wrapper>
  );
};
