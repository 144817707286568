import { useState } from "react";

import { FeatureLike } from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { PopoverBody } from "react-bootstrap";
import styled from "styled-components";

import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { PopupOverlay } from "@evr/components/ReactOL/overlays";
import { MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectResultState } from "@evr/store/slices/result";
import { UnrouteableJobLocation } from "@evr/types";

import { locationPopup } from "./locationPopUp";

const StyledPopoverBody = styled(PopoverBody)`
  border-radius: 7px;
  min-width: 350px;
  padding: 1rem;
`;

const UnrouteableOverlay = () => {
  const [selectedUnroutable, setSelectedUnroutable] = useState<UnrouteableJobLocation>();

  const result = useAppSelector(selectResultState);
  const unroutables = result?.result?.unrouteableLocations;

  const mapClick = (e: FeatureLike[]) => {
    if (e.length) {
      const id = e[0].getProperties()["id"];
      const unroutable = unroutables?.find(u => u.id === id);
      setSelectedUnroutable(unroutable);
    } else {
      setSelectedUnroutable(undefined);
    }
  };

  return (
    <>
      <MapClick layerNames={[MapLayerNames.RESULT_UNROUTABLES]} onClick={mapClick} />
      <PopupOverlay
        offset={[0, -20]}
        coordinate={selectedUnroutable ? fromLonLat(selectedUnroutable?.point.coordinates ?? [0, 0]) : undefined}
        placement="top"
      >
        <StyledPopoverBody>{selectedUnroutable ? locationPopup(selectedUnroutable) : null}</StyledPopoverBody>
      </PopupOverlay>
    </>
  );
};

export default UnrouteableOverlay;
