import { ReactNode } from "react";

import { useTheme } from "styled-components";

import logo from "@evr/assets/EVRLogo.png";
import { config } from "@evr/config";
import { ColumnContainer, Flex, RowContainer } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { Typography } from "@evr/ui/Typography";

import { ErrorPageLink } from "./ErrorPageLink";

interface ErrorPageProps {
  children: ReactNode;
}

export const ErrorPage = ({ children }: ErrorPageProps) => {
  const { colors } = useTheme();

  return (
    <>
      <ColumnContainer
        as="main"
        position="relative"
        height="80vh"
        minHeight="400px"
        justify="flex-start"
        padding="1.5rem"
        background="linear-gradient(95deg,#18a8f480,#7ee3558f 100%)"
      >
        <Flex width="100%" justify="flex-start">
          <Img src={logo} alt="EVR-Logo" width="100px" height="auto" loading="lazy" />
        </Flex>
        <ColumnContainer margin="auto 0" padding="1rem 2rem">
          {children}
        </ColumnContainer>
      </ColumnContainer>

      <ColumnContainer as="footer" height="20vh" minHeight="100px" justify="flex-start" padding="1rem 0 0 0">
        <RowContainer justify="space-evenly" width="100%" wrap="wrap">
          <ErrorPageLink title="About Us" href={`${config.basemapURL}/about-us`} color={colors.primary.dark} />
          <ErrorPageLink title="Contact Us" href={`${config.basemapURL}/contact-us`} color={colors.info.dark} />
          <ErrorPageLink title="Support Desk" href={config.basemapSupportDeskURL} color={colors.primary.dark} />
        </RowContainer>
        <Typography margin="auto 0 0.75rem 0">
          {"Copyright © "}
          <a href={config.basemapURL}>Basemap</a> {new Date().getFullYear()}
        </Typography>
      </ColumnContainer>
    </>
  );
};
