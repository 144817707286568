  import { TableSortableHeader } from "@evr/components/Table";
import { TableNameKeys, VehicleKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectVehiclesPagination, setVehicleSortColumn } from "@evr/store/slices/vehicle";
import { Vehicle } from "@evr/types";
import { StyledTable, TD, TH, TR, TableVehicleImage } from "@evr/ui/Table";

  import { VehicleTableActions } from "./VehicleTableActions";
import { vehicleTableColumns } from "./vehicleTableColumns";

  interface VehicleTableProps {
    vehicles: Vehicle[];
  }

  export const VehicleTable = ({ vehicles }: VehicleTableProps) => {
    const dispatch = useAppDispatch();
    const { sort } = useAppSelector(selectVehiclesPagination);

    const handleChangeSortColumn = (column: string) => {
      dispatch(setVehicleSortColumn(column));
    };



    const vehicleList: Vehicle[] = vehicles.map(vehicle => ({
      ...vehicle,
      [VehicleKeys.CREATED_DATE]: vehicle.createdDate ? new Date(vehicle.createdDate).toLocaleDateString() : "_",
    }));

    return (
      <StyledTable tableType={TableNameKeys.VEHICLE}>
        <thead>
          <tr>
            {vehicleTableColumns.map(col => (
              <TH key={col.field} padding="0" tableType={TableNameKeys.VEHICLE}>
                <TableSortableHeader sort={sort} column={col} onChange={handleChangeSortColumn} />
              </TH>
            ))}
            <TH tableType={TableNameKeys.VEHICLE} textAlign="center">
              Actions
            </TH>
          </tr> 
        </thead>
        <tbody>
        {vehicleList.map(vehicle => (
  <TR key={vehicle.id}>
    
    {
      vehicleTableColumns.map(col => {
        if (col.field === VehicleKeys.ICON) {
          return <TableVehicleImage key={col.field} size={vehicle.vehicleSize} type={vehicle.engineType} />;
        }
        return <TD key={col.field}>{(vehicle as unknown as Record<string, string>)[col.field] || "_"}</TD>;
      })

    }
          <VehicleTableActions vehicleId={vehicle.id} />
        </TR>
      ))} 
        </tbody>
      </StyledTable>
    );
  };
