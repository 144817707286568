import styled from "styled-components";
interface PropsType {
  direction: "v" | "h";
  height?: string;
  width?: string;
  margin?: string;
}

export const Divider = styled.div.attrs({
  "aria-label": "divider",
})<PropsType>`
  ${props =>
    props.direction === "h"
      ? `
        height: 1px;
        width: ${props.width ? props.width : "100%"};
        margin: ${props.margin ? props.margin : "0.5rem 0"};
      `
      : `
        height: ${props.height ? props.height : "100%"};
        width: 1px;
        margin: ${props.margin ? props.margin : "0 0.5rem"};
      `}
  background-color: ${props => props.theme.colors.divider};
`;
