import { useForgotPasswordMutation } from "@evr/apis/accounts";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser } from "@evr/store/slices/auth";
import { closeDialog } from "@evr/store/slices/dialog";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const SettingResetPassword = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleSendResetLink = async () => {
    if (!currentUser) {
      return;
    }
    forgotPassword({ email: currentUser.email })
      .unwrap()
      .then(() => dispatch(closeDialog()));
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography> Reset Password</Typography>
      <Wrapper margin="0 0 0 auto" position="relative">
        {isLoading && <CircleLoading size={4} />}
        <Button variant="contained" onClick={handleSendResetLink} disabled={isLoading}>
          Email Link
        </Button>
      </Wrapper>
    </RowContainer>
  );
};
