import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { Dialogs, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { VerizonDriver, VerizonDriverImport, VerizonVehicle, VerizonVehicleImport } from "@evr/types";

import { openDialog } from "../dialog";

export const VerizonImportInitialState: VerizonImportState = {
  importing: false,
  drivers: [],
  vehicles: [],
  verizonToken: undefined,
  verizonTokenExpires: undefined,
  driverIndex: undefined,
  vehicleIndex: undefined,
  importPosition: undefined,
};

export const StartImportWithDialog = createAsyncThunk(
  "verizonImport/startImportWithDialog",
  async (_, { dispatch, getState }) => {
    dispatch(openDialog({ type: Dialogs.VERIZON_LOGIN }));
  },
);

export const VerizonImportSlice = createSlice({
  name: SliceKeys.VERIZON,
  initialState: VerizonImportInitialState,
  reducers: {
    startImport: state => {
      state.importing = true;
    },
    cancelImport: state => {
      state.importing = false;
      state.drivers = [];
      state.vehicles = [];
      state.driverIndex = undefined;
      state.vehicleIndex = undefined;
      state.importPosition = undefined;
    },
    storeToken: (state, action) => {
      state.verizonToken = action.payload.token;
      state.verizonTokenExpires = action.payload.expires;
    },
    storeDrivers: (state, action) => {
      state.drivers = action.payload.map((driver: VerizonDriver) => {
        return {
          selected: true,
          exists: false,
          imported: false,
          driver: driver,
        };
      });
    },
    setDriverExists: (state, action) => {
      // Get driver with the payload email
      const driver = state.drivers.find(driver => driver.driver.email === action.payload.email);
      if (driver) {
        driver.exists = true;
      }
    },
    setDriversSelected: (state, action) => {
      action.payload.forEach((d: { email: string; selected: boolean }) => {
        const driver = state.drivers.find(driver => driver.driver.email === d.email);
        if (driver) {
          driver.selected = d.selected;
        }
      });
    },
    storeVehicles: (state, action) => {
      state.vehicles = action.payload.map((vehicle: VerizonVehicle) => {
        return {
          selected: true,
          exists: false,
          imported: false,
          vehicle: vehicle,
        };
      });
    },
    setVehicleExists: (state, action) => {
      const vehicle = state.vehicles.find(vehicle => vehicle.vehicle.registration === action.payload.registration);
      if (vehicle) {
        vehicle.exists = true;
      }
    },
    setDriverIndex: (state, action) => {
      state.driverIndex = action.payload;
    },
    setEvrDriver: (state, action) => {
      const driver = state.drivers.find(driver => driver.driver.email === action.payload.email);
      if (driver) {
        driver.evrDriver = action.payload;
      }
    },
    setVehiclesSelected: (state, action) => {
      action.payload.forEach((v: { registration: string; selected: boolean }) => {
        const vehicle = state.vehicles.find(vehicle => vehicle.vehicle.registration === v.registration);
        if (vehicle) {
          vehicle.selected = v.selected;
        }
      });
    },
    setVehicleIndex: (state, action) => {
      state.vehicleIndex = action.payload;
    },
    setEvrVehicle: (state, action) => {
      const vehicle = state.vehicles.find(vehicle => vehicle.vehicle.registration === action.payload.licence);
      if (vehicle) {
        vehicle.evrVehicle = action.payload;
      }
    },
    setDriverImported: (state, action) => {
      const driver = state.drivers.find(driver => driver.driver.email === action.payload.driver.email);
      if (driver) {
        driver.imported = true;
      }
    },
    setVehicleImported: (state, action) => {
      const vehicle = state.vehicles.find(
        vehicle => vehicle.vehicle.registration === action.payload.vehicle.registration,
      );
      if (vehicle) {
        vehicle.imported = true;
      }
    },
    setDriverError: (state, action) => {
      const driver = state.drivers.find(driver => driver.driver.email === action.payload.email);
      if (driver) {
        driver.error = action.payload.error;
      }
    },
    setVehicleError: (state, action) => {
      const vehicle = state.vehicles.find(vehicle => vehicle.vehicle.registration === action.payload.registration);
      if (vehicle) {
        vehicle.error = action.payload.error;
      }
    },
    setImportPosition: (state, action) => {
      state.importPosition = action.payload;
    },
  },
});

export const {
  startImport,
  storeToken,
  storeDrivers,
  storeVehicles,
  setVehicleExists,
  setDriverExists,
  setDriversSelected,
  setDriverIndex,
  setEvrDriver,
  setVehiclesSelected,
  setVehicleIndex,
  setEvrVehicle,
  cancelImport,
  setDriverImported,
  setVehicleImported,
  setDriverError,
  setVehicleError,
  setImportPosition,
} = VerizonImportSlice.actions;

export default VerizonImportSlice.reducer;

export const selectDrivers = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.drivers,
);

export const selectSelectedDrivers = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.drivers.filter(x => x.selected && !x.exists),
);

export const isVerizonImporting = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.importing,
);

export const getCurrentDriverIndex = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.driverIndex,
);

export const getCurrentDriver = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.drivers.filter(x => x.selected && !x.exists)[verizon.driverIndex || 0],
);

export const getTotalDrivers = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.drivers.filter(x => x.selected && !x.exists).length,
);

export const selectVehicles = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.vehicles,
);

export const selectSelectedVehicles = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.vehicles.filter(x => x.selected && !x.exists),
);

export const getCurrentVehicleIndex = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.vehicleIndex,
);

export const getTotalVehicles = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.vehicles.filter(x => x.selected && !x.exists).length,
);

export const getCurrentVehicle = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.vehicles.filter(x => x.selected && !x.exists)[verizon.vehicleIndex || 0],
);

export const selectImportPosition = createSelector(
  (state: RootState) => state.verizon,
  verizon => verizon.importPosition,
);

export const getVerizonToken = createSelector(
  (state: RootState) => state.verizon,
  verizon => ({ token: verizon.verizonToken, expires: verizon.verizonTokenExpires }),
);

export interface VerizonImportState {
  verizonToken?: string; // token to authenticate with Verizon
  verizonTokenExpires?: number; // timestamp when the token expires
  importing: boolean; // has the user started the import process
  drivers: VerizonDriverImport[]; // list of drivers to import
  vehicles: VerizonVehicleImport[]; // list of vehicles to import
  driverIndex?: number; // index of the driver being imported
  vehicleIndex?: number; // index of the vehicle being imported
  importPosition?: VerizonImportPosition; // current import position
}

export enum VerizonImportPosition {
  DRIVER_SELECT = 0,
  DRIVER_IMPORT = 1,
  VEHICLE_SELECT = 2,
  VEHICLE_IMPORT = 3,
  DATA_IMPORT = 4,
}
