import styled from "styled-components";

import { VehicleSize } from "@evr/constant";
import { ColumnContainer } from "@evr/ui/FlexBox";

export const DriverVehicleMatchContainer = styled(ColumnContainer)`
  position: absolute;
  justify-content: flex-start;
  top: 90%;
  width: 600px;
  max-height: 70vh;
  overflow: hidden;
  background-color: ${props => props.theme.colors.common.white};
  box-shadow: ${props => props.theme.shadows[6]};
  border-radius: ${props => props.theme.borderRadius};
  z-index: ${props => props.theme.zIndex.window};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    right: calc(80vw - 600px - 5%);
  }
  @media (max-width: 850px) {
    right: 5%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 90%;
  }
`;

export const DriverVehicleRowContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.action.border};
  &:last-child {
    border: none;
  }
`;
interface VehicleIndicatorProps {
  vehicleSize: VehicleSize;
}
export const VehicleIndicator = styled.div<VehicleIndicatorProps>`
  position: absolute;
  height: 1px;
  width: 25%;
  background-color: ${props => props.theme.colors.info.main};
  top: 85%;
  transition: all 0.3s ease-out;
  right: ${p => (p.vehicleSize === VehicleSize.SMALL ? "50%" : p.vehicleSize === VehicleSize.MEDIUM ? "25%" : "0")};
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 55px;
  }
`;
