import { apiVerizon } from "./baseHttp";

export const Verizonlogin = (token: string) =>
  apiVerizon.get("/token/", {
    headers: {
      Authorization: "Basic " + token,
    },
  });

export const VerizonGetDrivers = (token: string) =>
  apiVerizon.get("/cmd/v1/drivers", {
    headers: {
      Authorization: 'Atmosphere atmosphere_app_id="' + process.env.REACT_APP_VERIZON_APP_ID + '", Bearer ' + token,
    },
  });

export const VerizonGetVehicles = (token: string) =>
  apiVerizon.get("/cmd/v1/vehicles", {
    headers: {
      Authorization: 'Atmosphere atmosphere_app_id="' + process.env.REACT_APP_VERIZON_APP_ID + '", Bearer ' + token,
    },
  });
