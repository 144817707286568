const storeItem = <T>(key: string, value: T) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(error);
  }
};

const getItem = <T>(key: string, initialValue?: T) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};

export const localStorageHelper = {
  storeItem,
  getItem,
  removeItem,
};
