import { ScheduledJourneysEndpoint } from "@evr/apis/endpoints";
import { scheduledJourneyApi } from "@evr/services/scheduledJourney";
import { apiCallThunk } from "@evr/store/actions/apiCall";

import { openSidebar } from "../sidebar";
import { closeWindow } from "../window";

import { ScheduledJourneyResultSlice } from ".";

const { setScheduledJourneyResult } = ScheduledJourneyResultSlice.actions;

export const getScheduledJourneyAsync = (id: number) =>
  apiCallThunk(`${ScheduledJourneysEndpoint}/${id}`)({
    apiRequest: scheduledJourneyApi.getScheduledJourney(id),
    onSuccess: [{ type: openSidebar.type }, { type: setScheduledJourneyResult.type }],
    onSuccessEnd: { type: closeWindow.type },
  });

export const getScheduledJourneyMissedParcelsCsvAsync = (journeyId: number) =>
  apiCallThunk(`${ScheduledJourneysEndpoint}/${journeyId}/uncompleted`)({
    apiRequest: scheduledJourneyApi.getScheduledJourneyMissedParcelsCsv(journeyId),
  });
