import { useCallback, useEffect, useState } from "react";

import { IconX } from "@tabler/icons";
import styled from "styled-components";

import { SearchColumn, SearchParameterKeys } from "@evr/types";
import { Flex } from "@evr/ui/FlexBox";
import { StyledIconButton } from "@evr/ui/IconButton/styles";
import { SelectDataType } from "@evr/ui/Select";
import { StyledSelect } from "@evr/ui/Select/styles";

import { inputCss, TableSearchContainer } from "./shared";

export const SelectInput = styled(StyledSelect)`
  ${inputCss}
  background-size: 15px 15px;
`;

interface TableSearchSelectProps {
  name: string;
  column: string;
  search: SearchColumn;
  items?: SelectDataType[];
  onChange: (search: SearchColumn) => void;
  onClear: (column: string) => void;
}

export const TableSearchSelect = ({ items = [], name, search, column, onChange, onClear }: TableSearchSelectProps) => {
  const [selectValue, setSelectValue] = useState("");

  const findItem = useCallback((value: string) => items.find(item => item.value === value), [items]);

  useEffect(() => {
    if (!search || !search.value || !items) {
      setSelectValue("");
      return;
    }
    const selectedItem = findItem(search.value);
    setSelectValue(selectedItem && search.column === column ? (selectedItem.key as string) : "");
  }, [column, findItem, items, search]);

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const selectedItemValue = findItem(value);
    const title = `${name}: ${selectedItemValue?.value}`;
    onChange({ column, value, title, parameter: SearchParameterKeys.EQUAL });
  };

  const handleClearSearch = () => {
    onClear(column);
    setSelectValue("");
  };

  return (
    <TableSearchContainer as={"div"}>
      <SelectInput onChange={handleChangeSearchValue} value={selectValue}>
        <option value="" disabled hidden>{`Select ${name}...`}</option>
        {items.map(item => (
          <option key={item.key} value={item.value}>
            {item.value}
          </option>
        ))}
      </SelectInput>
      {selectValue && (
        <Flex position="absolute" top="50%" right="35px" transform="translateY(-50%)">
          <StyledIconButton noWH margin="0 0.2rem" color="greyDefault" hoverColor="error" onClick={handleClearSearch}>
            <IconX size={20} />
          </StyledIconButton>
        </Flex>
      )}
    </TableSearchContainer>
  );
};
