import React, { useEffect, useRef, useState } from "react";

import olMap from "ol/Map";
import olView from "ol/View";

import styles from "./Map.module.css";
import MapContext from "./MapContext";


export interface MapProps {
  view?: olView;
  children: React.ReactNode;
}

/**
 * Component to render the map wrapper and instantiate an OL Map within it.
 * Allows child components to be rendered inside, allowing further customisation
 * and to specify other map configuration such as layers or control in a markup fashion.
 * @component
 * @param {React.ReactNode} children - the children components to render
 * @example
 * <Map center={center} zoom={zoom} projection={Epsg27700} minZoom={1} maxZoom={20}>
 *   <Layers>
 *     <Tile source={osmSource} />
 *   </Layers>
 *   <Controls>
 *     <FullScreen />
 *   </Controls>
 * </Map>
 * @remarks
 * Note, uses the MapContext to make the OL Map object available to child components.
 * Use useContext(MapContext) to obtain this OL Map object.
 */
const Map = ({ view, children }: MapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<olMap | null>(null);

  useEffect(
    () => {
      const mapObject = new olMap({
        view: view,
        layers: [],
        controls: [],
        overlays: [],
      });

      mapObject.setTarget(mapRef.current as HTMLDivElement);
      setMap(mapObject);

      return () => map?.setTarget(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!map || !view) {return;}
    map.setView(view);
  }, [view]);

  return (
    <MapContext.Provider value={map}>
      <div ref={mapRef} className={styles.map}>
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
