export enum AuthKeys {
  ID = "id",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  USER_NAME = "userName",
  EMAIL = "email",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
  ORGANISATION_NAME = "organisationName",
  BILLING_ADDRESS = "organisationBillingAddress",
  BILLING_EMAIL = "organisationBillingEmail",
  PHONE_NUMBER = "organisationPhone",
  VAT_NUMBER = "organisationVatNumber",
  TERMS_CONDITION = "termsCondition",
  PHOTO = "photo",
  ORG_ADDITIONAL = "organisationAdditionalInformation",
  ORGANISATION_ADDRESS = "organisationAddress",
  ORGANISATION_TRANSACTION_CURRENCY_CODE = "organisationTransactionCurrencyCode",
}
