import { ActiveLayerTypes } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { clearNewCalculationIds } from "@evr/store/slices/calculation";
import { setActiveLayer } from "@evr/store/slices/map";
import { getCalculationResultAsync } from "@evr/store/slices/result/asyncActions";
import { clearScheduledJourneyResult } from "@evr/store/slices/scheduled-journey-results";
import { Calculation } from "@evr/types";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Typography } from "@evr/ui/Typography";

import { NotificationItem } from "./NotificationItem";

interface PropsType {
  calculations?: Calculation[];
  loading?: boolean;
}
export const NotificationList = ({ calculations, loading }: PropsType) => {
  const dispatch = useAppDispatch();
  const api = useApi(getCalculationResultAsync);

  const getCalculationResult = async (calculationHeaderId: number) => {
    await api.sendRequest(calculationHeaderId);
    dispatch(setActiveLayer(ActiveLayerTypes.RESULT));
    dispatch(clearNewCalculationIds());
    dispatch(clearScheduledJourneyResult());
  };

  return (
    <>
      {(api.loading || loading) && <CircleLoading size={6} blurBackground />}
      {calculations && calculations.length > 0
        ? calculations.map(calc => (
            <NotificationItem key={calc.id} data={calc} getCalculationResult={getCalculationResult} />
          ))
        : !loading && <Typography margin="auto">No Calculations to show!</Typography>}
    </>
  );
};
