import { toLonLat } from "ol/proj";
import { useTheme } from "styled-components";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  cancelEditJobLocationCoordinates,
  editJobLocationCoordinates,
  selectEditLocationCoordinates,
  selectParcelMapState,
} from "@evr/store/slices/parcel";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { Button } from "@evr/ui/Button";
import { Flex } from "@evr/ui/FlexBox";

export const JobLocationsActionButtons = () => {
  const { zIndex } = useTheme();
  const dispatch = useAppDispatch();

  const { description, editLocation } = useAppSelector(selectParcelMapState);
  const editLocationCoordinates = useAppSelector(selectEditLocationCoordinates);
  const open = useAppSelector(selectSidebarOpenStatus);

  const handleCancelEditLocation = () => dispatch(cancelEditJobLocationCoordinates());

  const handleEditLocation = () => {
    if (!editLocationCoordinates) {
      return;
    }

    const [lng, lat] = toLonLat(editLocationCoordinates);
    dispatch(editJobLocationCoordinates({ key: description, coordinates: [lng, lat] }));
  };

  return (
    <>
      {editLocation && (
        <Flex zIndex={zIndex.drawer} position="absolute" top="10px" right={open ? "400px" : "10px"} rounded>
          <Button margin="0" color="errorLight" borderRadius="4px 0 0 4px" onClick={handleCancelEditLocation}>
            Cancel
          </Button>
          <Button margin="0" borderRadius="0 4px 4px 0" onClick={handleEditLocation}>
            Confirm
          </Button>
        </Flex>
      )}
    </>
  );
};
