import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SliceKeys } from "@evr/constant";
import { tablePaginationInitialValue } from "@evr/constant";
import { RootState } from "@evr/store";
import { SearchColumn } from "@evr/types";
import { getTableSortColumn, queryBuilder } from "@evr/utils";

export const DriverSlice = createSlice({
  name: SliceKeys.DRIVERS,
  initialState: { pagination: tablePaginationInitialValue },
  reducers: {
    setDriverPageIndex: (state, action: PayloadAction<number>) => {
      state.pagination.pageIndex = action.payload;
    },
    setDriverSearchColumn: (state, action: PayloadAction<SearchColumn>) => {
      state.pagination.pageIndex = tablePaginationInitialValue.pageIndex;
      state.pagination.search[action.payload.column] = action.payload;
    },
    clearDriverSearchColumn: (state, action: PayloadAction<string>) => {
      const { [action.payload]: deleteItem, ...otherSearchItems } = state.pagination.search;
      state.pagination.search = otherSearchItems;
    },
    setDriverSortColumn: (state, action: PayloadAction<string>) => {
      const { column, type } = state.pagination.sort;
      state.pagination.sort = getTableSortColumn(column, action.payload, type);
    },
  },
});

export const { setDriverPageIndex, setDriverSearchColumn, setDriverSortColumn, clearDriverSearchColumn } =
  DriverSlice.actions;

export const selectDriversQuery = createSelector(
  (state: RootState) => state.drivers.pagination,
  ({ pageSize, pageIndex, search, sort }) => queryBuilder(pageSize, pageIndex, search, sort),
);

export const selectDriversPagination = createSelector(
  (state: RootState) => state.drivers.pagination,
  ({ totalPages, pageIndex, sort, search }) => ({ totalPages, pageIndex, sort, search }),
);
export default DriverSlice.reducer;
