import { useState } from "react";

import { ResultCardBox, ResultCardItem } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyColor } from "@evr/store/slices/scheduled-journey-results";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

import RouteDropdownItems from "./RouteDropdownItems";

interface RoutesDropdownProps {
  scheduledJourney: ScheduledJourney;
}

const RoutesDropdown = ({ scheduledJourney }: RoutesDropdownProps) => {
  const maximize = useAppSelector(selectSidebarOpenStatus);
  const [open, setOpen] = useState(false);
  const journeyColor = useAppSelector(selectScheduledJourneyColor);

  const onOpenDetails = () => {
    setOpen(!open);
  };

  return (
    <RowContainer margin="0.5rem 0 1rem" align="flex-start">
      <ResultCardBox open={true}>
        <RowContainer
          background={"lightGrey"}
          color={"black"}
          padding="0.5rem 0"
          height="70px"
          width="100%"
          justify="space-between"
        >
          <ResultCardItem width="20%">
            <Icon className="fas fa-route" />
          </ResultCardItem>
          <ResultCardItem width="80%">
            <Typography style={{ color: "black" }} size={1}>
              View Routes
            </Typography>
          </ResultCardItem>
          <ResultCardItem>
            <IconButton icon="caret-down" WH="25px" title="Click to view Journey details" onClick={onOpenDetails} />
          </ResultCardItem>
        </RowContainer>
        {maximize && (
          <Collapsible open={open}>
            <RouteDropdownItems scheduledJourney={scheduledJourney} />
          </Collapsible>
        )}
      </ResultCardBox>
    </RowContainer>
  );
};

export default RoutesDropdown;
