import { ScheduledJourneyKeys, ScheduledJourneyStatus } from "@evr/constant";
import { TableColumn } from "@evr/types";

export const scheduledJourneyTableColumns = (status: ScheduledJourneyStatus): TableColumn<ScheduledJourneyKeys>[] => {
    const baseColumns: TableColumn<ScheduledJourneyKeys>[] = [
    {
            name: "Start Time",
            field: ScheduledJourneyKeys.START_TIME
        },
        {
            name: "Depot",
            field: ScheduledJourneyKeys.DEPOT_NAME
        },
        {
            name: "Driver",
            field: ScheduledJourneyKeys.DRIVER_NAME,
        },
        {
            name: "Vehicle",
            field: ScheduledJourneyKeys.VEHICLE_NAME
        },
        {
            name: "Registration",
            field: ScheduledJourneyKeys.VEHICLE_REG
        },
    ];

    if (status === ScheduledJourneyStatus.CANCELLED) {
        baseColumns.push({
          name: "Failed by",
          field: ScheduledJourneyKeys.REASON,
        });
      }
  return baseColumns;
};