import { createSelector, createSlice } from "@reduxjs/toolkit";

import { depotApi } from "@evr/apis/depot";
import { DepotStatusKeys, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { queryBuilder } from "@evr/utils";

import { resetCalculationParams } from "../result";
import { depotInitialState } from "./initialState";
import { depotReducers } from "./reducers";

export const DepotSlice = createSlice({
  name: SliceKeys.DEPOTS,
  initialState: depotInitialState,
  reducers: { ...depotReducers },
  extraReducers: builder => {
    builder
      .addCase(resetCalculationParams, state => {
        state.currentDepot = null;
      })
      .addMatcher(depotApi.endpoints.addDepot.matchFulfilled, (state, action) => {
        state.currentDepot = action.payload;
        state.status = DepotStatusKeys.CLOSE;
      })
      .addMatcher(depotApi.endpoints.editDepot.matchFulfilled, (state, action) => {
        if (state.currentDepot && state.currentDepot.id === action.payload.id) {
          state.currentDepot = action.payload;
        }
        state.status = DepotStatusKeys.CLOSE;
      })
      .addMatcher(depotApi.endpoints.deleteDepot.matchFulfilled, (state, action) => {
        if (state.currentDepot && state.currentDepot.id === action.meta.arg.originalArgs) {
          state.currentDepot = null;
        }
      });
  },
});

export const {
  setDepotStatus,
  setCurrentDepot,
  setDepotPageIndex,
  setDepotSearchColumn,
  setDepotSortColumn,
  clearDepotSearchColumn,
} = DepotSlice.actions;

export const selectDepotsStatus = createSelector(
  (state: RootState) => state.depots,
  depotState => depotState.status,
);
export const selectCurrentDepot = createSelector(
  (state: RootState) => state.depots,
  depotState => depotState.currentDepot,
);
export const selectDepotsQuery = createSelector(
  (state: RootState) => state.depots.pagination,
  ({ pageSize, pageIndex, search, sort }) => queryBuilder(pageSize, pageIndex, search, sort),
);
export const selectDepotsPagination = createSelector(
  (state: RootState) => state.depots.pagination,
  ({ totalPages, pageIndex, sort, search }) => ({ totalPages, pageIndex, sort, search }),
);
export default DepotSlice.reducer;
