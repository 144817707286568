import { Coordinate } from "ol/coordinate";
import { PopoverBody } from "react-bootstrap";

import { PopupOverlay } from "@evr/components/ReactOL/overlays";
import { DepotStatusKeys } from "@evr/constant";

interface DepotCommandOverlayProps {
  coordinate?: Coordinate;
  depotStatusKey: DepotStatusKeys;
}

const DepotCommandOverlay = ({ coordinate, depotStatusKey }: DepotCommandOverlayProps) => {
  return (
    <PopupOverlay offset={[0, -40]} coordinate={coordinate} placement="top">
      <PopoverBody className="text-center p-0" style={{ borderRadius: 7 }}>
        <div className="py-0 px-2 text-nowrap">
          <p className="my-1">Click on the map for choosing your Depot location. </p>
          <p className="my-1">Then click on { depotStatusKey  === DepotStatusKeys.ADD ? "Add": "Edit" } button</p>
        </div>
      </PopoverBody>
    </PopupOverlay>
  );
};
export default DepotCommandOverlay;
