import { useTheme } from "styled-components";

import { ReactComponent as DriverVehicleIcon } from "@evr/assets/driverVehicle.svg";
import { WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";
import { selectDVMatchState } from "@evr/store/slices/dvMatch";
import { openWindow, selectWindowType } from "@evr/store/slices/window";
import { Flex } from "@evr/ui/FlexBox";
import { NavItem } from "@evr/ui/NavItem";
import { Typography } from "@evr/ui/Typography";

export const DriverVehicleItem = () => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const window = useAppSelector(selectWindowType);
  const { list: dvm } = useAppSelector(selectDVMatchState);
  const currentDepot = useAppSelector(selectCurrentDepot);
  const isDepotSelected = currentDepot === null;

  const handleOpenDriverVehicleMatch = () => {
    dispatch(openWindow(window === WindowKeys.DRIVER_VEHICLE ? WindowKeys.CLOSE : WindowKeys.DRIVER_VEHICLE));
  };

  return (
    <NavItem
      className="driverVehicelItem-tour"
      onClick={handleOpenDriverVehicleMatch}
      cursor={isDepotSelected ? "initial" : "pointer"}
    >
      <Flex padding="0 0 0 0.5rem" justify="space-around">
        <DriverVehicleIcon
          width={35}
          height={35}
          fill={colors.primary.main}
          opacity={isDepotSelected ? colors.action.disabledOpacity : 1}
        />
        <Typography as="span" size={1.1} width="50px" textAlign="center" disabled={isDepotSelected}>
          {dvm.length}
        </Typography>
      </Flex>
    </NavItem>
  );
};
