import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CurrencySettingsKeys,
  DistanceUnitKeys,
  JourneysInitialColor,
  LocalStorageKeys,
  MapColorKeys,
  SliceKeys,
} from "@evr/constant";
import { RootState } from "@evr/store";
import { JournyColorObject, SettingState } from "@evr/types";
import { localStorageHelper } from "@evr/utils";

import { logOutUser } from "../auth";

const initialState: SettingState = {
  mapColor: MapColorKeys.GREYSCALE,
  currency: CurrencySettingsKeys.POUND,
  distanceUnit: DistanceUnitKeys.MILES,
  journeysColor: JourneysInitialColor,
  startTour: false,
  version: 1,
  PetrolCodeOverride: undefined,
  DieselCodeOverride: undefined,
  ElectricityCodeOverride: undefined,
  proofOfDeliveryRequirement: 0,
};

const getSettings = (): SettingState => {
  let evrSetting: SettingState = localStorageHelper.getItem(LocalStorageKeys.SETTING, initialState);

  if (JSON.stringify(evrSetting) === JSON.stringify(initialState)) {
    localStorageHelper.storeItem(LocalStorageKeys.SETTING, initialState);
  }

  if (!evrSetting.version || evrSetting.version !== initialState.version) {
    evrSetting = initialState;
  }
  const isFirstLogin: boolean = localStorageHelper.getItem(LocalStorageKeys.IS_FIRST_LOGIN, false);
  return { ...evrSetting, startTour: isFirstLogin };
};

const evrSettings = getSettings();

export const SettingSlice = createSlice({
  name: SliceKeys.SETTINGS,
  initialState: evrSettings,
  reducers: {
    changeMapColor: (settings, action: PayloadAction<MapColorKeys>) => {
      settings.mapColor = action.payload;
      localStorageHelper.storeItem(LocalStorageKeys.SETTING, settings);
    },
    changeJourneysColor: (settings, action: PayloadAction<JournyColorObject>) => {
      settings.journeysColor = action.payload;
      localStorageHelper.storeItem(LocalStorageKeys.SETTING, settings);
    },
    changeCurrency: (settings, action: PayloadAction<CurrencySettingsKeys>) => {
      settings.currency = action.payload;
    },
    changeMeasurement: (settings, action: PayloadAction<DistanceUnitKeys>) => {
      settings.distanceUnit = action.payload;
      localStorageHelper.storeItem(LocalStorageKeys.SETTING, settings);
    },
    setTour: (settings, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        localStorageHelper.removeItem(LocalStorageKeys.IS_FIRST_LOGIN);
      }
      settings.startTour = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logOutUser, settings => {
      return getSettings();
    });
  },
});

export const { changeMapColor, changeJourneysColor, changeCurrency, changeMeasurement, setTour } = SettingSlice.actions;
export const selectSettingState = (state: RootState) => state.settings;
export const selectJourneysColor = createSelector(
  (state: RootState) => state.settings.journeysColor,
  journeysColor => journeysColor,
);
export const selectMeasurement = createSelector(
  (state: RootState) => state.settings.distanceUnit,
  distanceUnit => distanceUnit,
);
export const selectJourneysColorArray = createSelector(
  (state: RootState) => state.settings.journeysColor,
  journeysColor => Object.values(journeysColor).slice(0, -1),
);
export default SettingSlice.reducer;
