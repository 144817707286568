import { ScheduledJourneysEndpoint } from "@evr/apis/endpoints";
import { ScheduledJourneyStatus } from "@evr/constant";
import { scheduledJourneyApi } from "@evr/services/scheduledJourney";
import { apiCallThunk } from "@evr/store/actions/apiCall";

import { openSidebar } from "../sidebar";

import { setAllLiveJourneyResult } from ".";

const start = new Date();
start.setHours(0, 0, 0, 0);
const end = new Date();
end.setHours(23, 59, 59, 0);
export const getAllLiveJourneyAsync = () =>
  apiCallThunk(
    `${ScheduledJourneysEndpoint}?startDate=${start}&endDate=${end}&journeyStatus=1&includeJourneySummary=true`,
  )({
    apiRequest: scheduledJourneyApi.getAllLiveJourneys(start, end, ScheduledJourneyStatus.INPROGRESS, true),
    onSuccess: [{ type: openSidebar.type }, { type: setAllLiveJourneyResult.type }],
  });
