import { FetchDataKeys, initialSavingsEnergyValues } from "@evr/constant";
import { ResultState } from "@evr/types";

export const resultInitialState: ResultState = {
  invalidateMapSize: true,
  isReady: false,
  header: { totalCO2: 0, totalCosts: 0, totalNOx: 0, totalTripLength: 0, totalSavings: 0, currency: "" },
  bbox: { id: 0 },
  showRoutes: {},
  showUnrouteables: false,
  pointToZoom: { point: null },
  savingsEnergyData: { loading: FetchDataKeys.INITIAL, data: initialSavingsEnergyValues },
  result: null,
  calculationId: 0,
  hasNonElectricVehicle: false,
  currencySymbol: "",
};
