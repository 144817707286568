import { keyframes } from "styled-components";

export const rotateAnimation = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

export const increaseWidthAnimation = keyframes`
  from { left: 0; width: 5%; }
  to { left: 120%; width: 100%;}
`;

export const moveGradientAnimation = keyframes`
  50% {background-position: 100% 50%;}
`;

export const bellRingAnimation = keyframes`
  0%, 43%, 100% { transform: rotate(0); }
  1%, 9% { transform: rotate(30deg); }
  3%, 11% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
`;

export const danceAnimation = keyframes`
  0%,80% {transform: scaleX(1);}
  82%,84% {transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);}
  86%,90%,94%,98% {transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);}
  88%,92%,96% {transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);}
  to {transform: scaleX(1);}
`;
