import { FormGroup, Label } from "@evr/ui/TextInput/styles";

import { StyledSelect } from "./styles";

export type SelectDataType = { key: string | number; value: string | number };
export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  label?: string;
  items: SelectDataType[] | string[];
  error?: boolean;
  name?: string;
  disabled?: boolean;
  isChangedByKey?: boolean;
}
export const Select = ({ id, label, items, error, disabled, isChangedByKey, ...rest }: SelectProps) => {
  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} disabled={disabled}>
          {label}
        </Label>
      )}
      <StyledSelect id={id} error={error} disabled={disabled} {...rest}>
        {items.map((item, index) => {
          const isString = typeof item === "string";
          let value;
          if (isString) {
            value = item;
          } else {
            value = isChangedByKey ? item.key : item.value;
          }
          return (
            <option key={isString ? index : item.key} value={value}>
              {isString ? item : item.value}
            </option>
          );
        })}
      </StyledSelect>
    </FormGroup>
  );
};
