import styled from "styled-components";

import { RowContainer } from "./FlexBox";

interface PropsType {
  disabled?: boolean;
}

export const NavItem = styled(RowContainer)<PropsType>`
  height: 40px;
  justify-content: space-between;
  position: relative;
  box-shadow: ${props => props.theme.shadows[1]};
  border-radius: ${props => props.theme.borderRadius};
  ${props => props.disabled && `opacity:${props.theme.colors.action.disabledOpacity};`}
`;
