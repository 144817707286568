import { theme } from "@evr/theme";

import { Wrapper } from "../FlexBox";
import { CircleLoadingProps, RotateLoading } from "./styles";

export const CircleLoading = ({ blurBackground, ...props }: CircleLoadingProps) => {
  return (
    <>
      {blurBackground && (
        <Wrapper zIndex={theme.zIndex.modal} inset="0" position="absolute" background="white" opacity={0.7} />
      )}
      <RotateLoading {...props}>
        {Array.from({ length: 4 }, (_, i) => (
          <div key={i} />
        ))}
      </RotateLoading>
    </>
  );
};
