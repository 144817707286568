import { useMemo, useState } from "react";

import { SearchColumn, SearchFieldKeys, SearchObject, TableColumn } from "@evr/types";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

import { TableSearchMenu } from "./TableSearchMenu";
import { TableSearchSelect } from "./TableSearchSelect";
import { TableSearchText } from "./TableSearchText";
import { TableSearchChip } from "./TableSerachChip";

interface TableSearchProps {
  Actions?: JSX.Element;
  columns: TableColumn[];
  search: SearchObject;
  changeSearchValue: (searchColumn: SearchColumn) => void;
  clearSearchValue: (column: string) => void;
}

export const TableSearch = ({ Actions, columns, search, changeSearchValue, clearSearchValue }: TableSearchProps) => {
  const searchItems = useMemo(() => (columns ? columns.filter(col => col.search) : []), [columns]);
  const [searchField, setSearchField] = useState<TableColumn>(searchItems[0]);

  const handleChangeSearchField = (item: TableColumn) => setSearchField(item);

  const handleChangeSearchValue = (searchColumn: SearchColumn) => changeSearchValue(searchColumn);
  const handleClearSearchValue = (column: string) => clearSearchValue(column);

  const selectedColumn = searchField.actionField || (searchField.field as string);

  return (
    <ColumnContainer gradient width="100%" padding="0 1rem 0.5rem">
      <Flex position="relative" width="100%" height="45px">
        {searchField.search?.type === SearchFieldKeys.TEXT ? (
          <TableSearchText
            search={search[selectedColumn]}
            name={searchField.name}
            column={selectedColumn}
            onChange={handleChangeSearchValue}
            onClear={handleClearSearchValue}
          />
        ) : (
          <TableSearchSelect
            search={search[selectedColumn]}
            name={searchField.name}
            items={searchField.search?.options}
            column={selectedColumn}
            onChange={handleChangeSearchValue}
            onClear={handleClearSearchValue}
          />
        )}

        {searchItems.length > 1 && (
          <TableSearchMenu searchItems={searchItems} onChangeSearchMenu={handleChangeSearchField} />
        )}
        <Flex flexGrow={1} />
        {Actions}
      </Flex>
      <Flex wrap="wrap" width="100%" justify="flex-start" padding="0.5rem 0 0 0">
        {Object.values(search).map(item => (
          <TableSearchChip
            key={item.column}
            title={item.title}
            onCloseClick={() => handleClearSearchValue(item.column)}
          />
        ))}
      </Flex>
    </ColumnContainer>
  );
};
