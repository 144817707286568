import { AuthKeys, PhotoStatusKeys } from "@evr/constant";
import { SignUpType } from "@evr/types";

export const getSignUpFormData = (data: SignUpType, photo: File | PhotoStatusKeys.EMPTY): FormData => {
  const form = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === AuthKeys.ID) {
      return;
    }
    if (key === AuthKeys.PHOTO) {
      form.append(key, photo);
    } else if (key === AuthKeys.ORG_ADDITIONAL) {
      // Handle the ORG_ADDITIONAL dictionary
      Object.entries(value as { [key: string]: string }).forEach(([additionalKey, additionalValue]) => {
        form.append(`${AuthKeys.ORG_ADDITIONAL}[${additionalKey}]`, additionalValue);
      });
    } else if (key === AuthKeys.ORGANISATION_ADDRESS) {
      const address = data[AuthKeys.ORGANISATION_ADDRESS]!;
      Object.keys(address).forEach(addressKey => {
        const addressValue = address[addressKey as keyof typeof address];
        form.append(`address[${addressKey}]`, addressValue ? String(addressValue) : "");
      });
    } else {
      form.append(key, value ? String(value) : "");
    }
  });

  return form;
};
