import styled, { css } from "styled-components";

import { rotateAnimation } from "@evr/theme/animations";

interface SwitchContainerProps {
  size?: "xs" | "sm" | "lg";
}

export const SwitchContainer = styled.div<SwitchContainerProps>`
  position: relative;
  display: inline-block;
  font-size: ${props => {
    switch (props.size) {
      case "xs":
        return "5px";
      case "sm":
        return "8px";
      case "lg":
        return "12px";
      default:
        return "10px";
    }
  }};
  width: 6em;
  height: 3.4em;
`;

interface SwitchSliderProps {
  active: boolean;
  disabled?: boolean;
  fetching?: boolean;
}
export const SwitchSlider = styled.span<SwitchSliderProps>`
  position: absolute;
  inset: 0;
  transition: 0.4s;
  border-radius: 3.4em;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${props => props.theme.colors.grey.main};

  &::before {
    position: absolute;
    content: "";
    height: 2.6em;
    width: 2.6em;
    left: 0.4em;
    bottom: 0.4em;
    background-color: ${props => props.theme.colors.common.white};
    transition: 0.4s;
    border-radius: 50%;
  }
  &::after {
    position: absolute;
    content: "";
    height: 2em;
    width: 2em;
    left: ${props => (props.active ? "3.4em" : "0.7em")};
    bottom: 0.7em;
    transition: 0.4s;
    border-radius: 50%;
    border: 2px solid ${props => props.theme.colors.common.white};
    border-color: transparent;
  }
  ${props =>
    props.active &&
    `
    background-color: ${props.theme.colors.primary.dark};
    &::before {
        transform: translateX(2.6em);
    }
  `}
  ${props =>
    props.fetching &&
    css`
      &::after {
        border-color: ${props => props.theme.colors.primary.main} transparent;
        animation: ${rotateAnimation} 1.2s linear infinite;
      }
    `}
`;
