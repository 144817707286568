import { useEffect, useState } from "react";

import { MinutesStep } from "@evr/types";
import { Wrapper } from "@evr/ui/FlexBox";
import { TextInput } from "@evr/ui/TextInput";

import { Portal } from "../Portal";
import { DateTimePickerContainer } from "./DateTimePickerContainer";

interface DateTimePickerProps {
  title: string;
  label: string;
  value: Date | null;
  onChange: (value: Date) => void;
  minutesStep?: MinutesStep;
  minDateTime?: Date | null;
  maxDateTime?: Date | null;
  disabled?: boolean;
  error?: string;
  dateOnly?: boolean;
}

export const DateTimePicker = ({
  title,
  label,
  value,
  onChange,
  minutesStep,
  minDateTime,
  maxDateTime,
  disabled,
  error,
  dateOnly = false,
}: DateTimePickerProps) => {
  const [open, setOpen] = useState(false);
  const [dateTime, setDateTime] = useState<Date | null>(value || null);

  useEffect(() => {
    if (!value) {
      return;
    }
    setDateTime(value);
  }, [value]);

  const handleOpenPicker = () => setOpen(open => !open);

  const handleSetValue = (dateTime: Date) => {
    setDateTime(dateTime);
    onChange && onChange(dateTime);
  };

  const getDateStringForInput = (dateTime: Date | null) => {
    if (dateTime == null) {
      return "";
    }

    if (dateOnly) {
      return dateTime.toLocaleString("en-UK", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    } else {
      return dateTime.toLocaleString("en-UK", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  return (
    <Wrapper position="relative">
      <TextInput
        label={label}
        value={`${getDateStringForInput(dateTime)}`}
        endIcon="clock"
        readOnly
        disabled={disabled}
        onClickEndIcon={handleOpenPicker}
        error={!!error}
        errorText={error || null}
      />
      {open && (
        <Portal withOverlay>
          <DateTimePickerContainer
            title={title}
            onClose={handleOpenPicker}
            minutesStep={minutesStep}
            value={dateTime}
            onChange={handleSetValue}
            minDateTime={minDateTime}
            maxDateTime={maxDateTime}
            dateOnly={dateOnly}
          />
        </Portal>
      )}
    </Wrapper>
  );
};
