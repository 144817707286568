import styled, { css, Keyframes } from "styled-components";

import { colorMixinFunc, ColorsType } from "@evr/theme/colors";
export interface StyledIconButtonProps {
  gradient?: boolean;
  size?: number;
  bgColor?: ColorsType;
  hoverColor?: ColorsType | string;
  noWH?: boolean;
  WH?: string;
  color?: ColorsType | string;

  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  margin?: string;
  borderRadius?: string;
  transition?: string;
  transform?: string;
  enableAnimation?: boolean;
  animation?: Keyframes;
  animationStyle?: string;
}
export const StyledIconButton = styled.button.attrs(props => ({
  type: props.type ? props.type : "button",
}))<StyledIconButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: ${props => (props.size ? `${props.size}rem` : "1.5rem")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "50%")};
  color: ${props => (props.color ? colorMixinFunc(props.color) : props.theme.colors.grey.default)};
  background-color: ${props => (props.bgColor ? colorMixinFunc(props.bgColor) : "transparent")};

  ${props => props.position && `position: ${props.position}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.transition && `transition: ${props.transition}`};
  ${props => props.transform && `transform: ${props.transform}`};

  &:hover:not(:disabled) {
    svg {
      height: 26px;
      width: 26px;
    }
    font-size: ${props => (props.size ? `${props.size + 0.15}rem` : "1.65rem")};
    ${props => props.hoverColor && `color: ${colorMixinFunc(props.hoverColor)}`};
  }
  &:disabled {
    opacity: ${props => props.theme.colors.action.disabledOpacity};
    cursor: default;
  }

  ${props =>
    props.gradient &&
    css`
      background-image: ${props.theme.colors.gradient.main};
      color: ${props.theme.colors.gradient.contrastText};
    `}
  ${props =>
    !props.noWH &&
    css`
      height: ${props.WH ? props.WH : "45px"};
      width: ${props.WH ? props.WH : "45px"};
    `}
  ${props =>
    props.enableAnimation &&
    css`
      animation: ${props.animation} ${props.animationStyle};
    `}
`;
