import { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import { Dialogs, LocalStorageKeys } from "./constant";
import { useAppDispatch } from "./hooks/reduxHooks";
import { Routes } from "./routes";
import { openDialog } from "./store/slices/dialog";
import { GlobalStyles } from "./theme/GlobalStyles";
import { localStorageHelper } from "./utils";

export const App = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (localStorageHelper.getItem(LocalStorageKeys.IS_FIRST_VERIZON_IMPORT)) {
      dispatch(openDialog({ type: Dialogs.VERIZON_NEW_USER }));
    }
  }, []);

  return (
    <BrowserRouter>
      <GlobalStyles />
      <Routes />
    </BrowserRouter>
  );
};
