import { SortColumn, SortKeys } from "@evr/types";

export const getTableSortColumn = (prevColumn: string, column: string, sortType: SortKeys): SortColumn => {
  let type = SortKeys.NONE;
  if (prevColumn === column) {
    switch (sortType) {
      case SortKeys.ASC:
        type = SortKeys.DESC;
        break;
      case SortKeys.DESC:
        type = SortKeys.NONE;
        break;
      case SortKeys.NONE:
        type = SortKeys.ASC;
        break;
      default:
        type = SortKeys.NONE;
        break;
    }
  } else {
    type = SortKeys.ASC;
  }
  return { column, type };
};
