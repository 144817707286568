import { useCallback, useState } from "react";

import { AsyncThunkAction } from "@reduxjs/toolkit";

import { apiCallPayload, apiErrors } from "@evr/types";

import { useAppDispatch } from "./reduxHooks";

type UseApiResponse<T> = { response: T | null; apiErrors: apiErrors | null };

type UseApi<T, A extends unknown[]> = { loading: boolean; sendRequest: (...args: A) => Promise<UseApiResponse<T>> };

export const useApi = <T, A extends unknown[]>(
  asyncAction: (...args: A) => AsyncThunkAction<T, apiCallPayload, { rejectValue: apiErrors }>,
): UseApi<T, A> => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback(
    async (...args: A) => {
      const result: UseApiResponse<T> = { response: null, apiErrors: null };
      setLoading(true);
      try {
        result.response = await dispatch(asyncAction(...args)).unwrap();
      } catch (rejectedValue) {
        result.apiErrors = rejectedValue as apiErrors;
      }
      setLoading(false);
      return result;
    },
    [dispatch, asyncAction],
  );

  return { loading, sendRequest };
};
