import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { App } from "./App";
import { store } from "./store";
import VerizonStateHandlerMiddleware from "./store/middleware/verizonStateHandlerMiddleware";
import { theme } from "./theme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <VerizonStateHandlerMiddleware />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
