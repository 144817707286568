import { engineTypeOptions } from "@evr/constant";
import { SearchFieldKeys, TableColumn } from "@evr/types";

export const notificationFilters: TableColumn<string>[] = [
  {
    name: "Depot Name",
    field: "",
    actionField: "Depot.Name",
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Driver Name",
    field: "",
    actionField: "DriverVehiclePairs.Driver.Name",
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Engine Type",
    field: "",
    actionField: "DriverVehiclePairs.Vehicle.EngineType",
    search: { type: SearchFieldKeys.SELECT, options: engineTypeOptions },
  },
];
