import { ScheduledJourneyStatus } from "@evr/constant";
import { Button } from "@evr/ui/Button";

interface ScheduledJourneyTableActionsProps {
    scheduledJourneyId: number;
    handleViewClick: (scheduledJourneyId:number) => void;
    handleCancelClick: (scheduledJourneyId:number) => void;
    status: ScheduledJourneyStatus;
  }




export const ScheduledJourneyTableActions = ({ scheduledJourneyId: id, handleViewClick, handleCancelClick, status, }: ScheduledJourneyTableActionsProps) => {   
  
  const returnId = () => {
    handleViewClick(id);
  };
  const returnIdAndCancel = () => {
    handleCancelClick(id);
  };
  return    ( <div style={{ display: "flex", gap: "0.5rem" }}>
  <Button  display="inline" padding="0.5rem" fontSize="15px"  margin="0" disabled={false} onClick={returnId} >View</Button>
        {status === ScheduledJourneyStatus.SCHEDULED && (
        <Button
        display="inline"
        padding="0.5rem"
        fontSize="15px"
        margin="0"
        disabled={false}
        onClick={returnIdAndCancel}
      >
        Cancel
      </Button>
        )}
      </div> );
};