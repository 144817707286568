import { ReactNode } from "react";

import { createPortal } from "react-dom";
import { useTheme } from "styled-components";

import { Wrapper } from "@evr/ui/FlexBox";

interface Portalprops {
  children: ReactNode;
  withOverlay?: boolean;
  cliCkOnOutSide?: () => void;
}

export const Portal = ({ withOverlay, cliCkOnOutSide, children }: Portalprops) => {
  const theme = useTheme();
  const Container = (
    <>
      <Wrapper
        inset="0"
        position="absolute"
        background="#00000099"
        zIndex={theme.zIndex.modal}
        onClick={cliCkOnOutSide}
      />
      {children}
    </>
  );
  return createPortal(withOverlay ? Container : children, document.body);
};
