import { useState } from "react";

import { DialogTitle, SnackbarKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, selectDialog } from "@evr/store/slices/dialog";
import { deleteJobLocation, selectValidJobLocations } from "@evr/store/slices/parcel";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { JobLocation, Parcel } from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Select } from "@evr/ui/Select";
import { Typography } from "@evr/ui/Typography";

export const DeleteJobLocationDialog = () => {
  const dispatch = useAppDispatch();
  const { data: description } = useAppSelector(selectDialog);
  const [selectedJob, setSelectedJob] = useState(0);

  const jobLocations = useAppSelector(selectValidJobLocations);
  const jobLocation = description ? (jobLocations[description as string] as JobLocation) : undefined;

  const handleSelectedJob = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedJob(Number(e.target.value));
  };

  const handleAction = () => {
    if (!description || !jobLocation) {
      return;
    }
    dispatch(deleteJobLocation({ key: description as string, jobIndex: selectedJob }));
    dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: "Selected job deleted successfully." }));
    dispatch(closeDialog());
  };

  if (!jobLocation) {
    return null;
  }

  const jobItems = jobLocation.jobs.map(job => job) as Parcel[];
  const jobSelectItems = jobItems.map((task, index) => {
    const value = `Job ${index + 1}${task?.customerName ? `, Name: ${task?.customerName}` : ""}`;
    return { key: index, value };
  });

  return (
    <>
      <DialogHeader title={DialogTitle.DELETE_JOB_LOCATION} />
      <DialogBody>
        <Select
          label="Jobs"
          value={selectedJob}
          items={jobSelectItems}
          disabled={jobSelectItems.length <= 1}
          onChange={handleSelectedJob}
          isChangedByKey
        />
        <Typography>
          Are you sure to delete this Job?
          <br />
          <small>{description ? `Address: ${description}` : "Error finding the Parcel."}</small>
        </Typography>
        {description && <DialogActionButtons buttonTitle={"Delete"} onClickAction={handleAction} />}
      </DialogBody>
    </>
  );
};
