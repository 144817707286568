import styled from "styled-components";

import { Flex } from "@evr/ui/FlexBox";

export const StepLineContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 70%;
  width: auto;
  margin-right: 2rem;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 0;
    flex-direction: row;
    height: auto;
    width: 80%;
    margin-top: 4rem;
  }
`;
interface StepLineNumberProps {
  active?: boolean;
}
export const StepLineNumber = styled(Flex)<StepLineNumberProps>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => (props.active ? props.theme.colors.primary.main : props.theme.colors.grey.default)};
  color: ${props => props.theme.colors.common.white};
  font-size: 1.1rem;
  font-weight: 500;
`;
export const StepLineText = styled.div`
  font-size: 0.9rem;
  margin: auto 1rem;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 1rem auto;
  }
`;
export const StepLineItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;
export const StepLineDivider = styled.div`
  position: absolute;
  z-index: ${props => props.theme.zIndex.negativeOne};
  background-color: ${props => props.theme.colors.divider};
  width: 2px;
  height: 100%;
  left: 15px;
  top: 0;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    height: 2px;
    top: 15px;
    left: 0;
  }
`;
