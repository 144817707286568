import { useDeleteDriverMutation } from "@evr/apis/driver";
import { useGetDriverById } from "@evr/apis/driver/hooks";
import { DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDialog } from "@evr/store/slices/dialog";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Typography } from "@evr/ui/Typography";

export const DeleteDriverDialog = () => {
  const { id } = useAppSelector(selectDialog);
  const driver = useGetDriverById(id || -1);

  const [deleteDriver, { isLoading }] = useDeleteDriverMutation();

  const handleAction = () => {
    if (!id || !driver) {
      return;
    }
    deleteDriver(id);
  };

  return (
    <>
      <DialogHeader title={DialogTitle.DELETE_DRIVER} />
      <DialogBody>
        <Typography>
          Are you sure to delete this Driver?
          <br />
          <small>{driver ? `Name: ${driver.name}` : "Error finding the driver."}</small>
        </Typography>
        {driver && <DialogActionButtons loading={isLoading} buttonTitle={"Delete"} onClickAction={handleAction} />}
      </DialogBody>
    </>
  );
};
