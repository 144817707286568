import olVectorSource from "ol/source/Vector";

import { MapLayerNames } from "@evr/constant";
import { Depot } from "@evr/types";

import ResultsDepotLayer from "../../ResultsLayer/ResultsDepotLayer";

interface ScheduledJourneyDepotLayerProps {
  depotSource: olVectorSource;
  depot: Depot;
}

const ScheduledJourneyDepotLayer = ({ depotSource, depot }: ScheduledJourneyDepotLayerProps) => (
  <ResultsDepotLayer name={MapLayerNames.SCHEDULED_JOURNEY_DEPOT} depotSource={depotSource} depot={depot} />
);

export default ScheduledJourneyDepotLayer;
