import { useState } from "react";

import pounds_circular from "@evr/assets/result/money_circular.png";
import stopwatch from "@evr/assets/result/stopwatch.png";
import { Driver, Journey, Vehicle } from "@evr/types";
import { ColumnContainer, Flex, RowContainer } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { Typography } from "@evr/ui/Typography";
import { getTimeTrip, getTotalWorkingHours } from "@evr/utils";

import { RegistrationNumber, ResultCardItemDetails, ResultCardTab } from "./ResultCardComponents";
interface ResultDetailsProps {
  journey: Journey;
  vehicle: Vehicle;
  driver: Driver;
  currencySymbol: string;
}

interface JourneyDetailsProps {
  journey: Journey;
  vehicleLicence?: string;
  hidden?: boolean;
  currencySymbol: string;
}

interface DriverDetailsProps {
  driver: Driver;
  hidden?: boolean;
}

enum ResultDetailsTab {
  DRIVER = "driver",
  JOURNEY = "journey",
}

const JourneyDetails = ({ journey, vehicleLicence, hidden, currencySymbol }: JourneyDetailsProps) => (
  <RowContainer padding="1rem" width="100%" justify="space-between" hidden={hidden}>
    <ResultCardItemDetails width="32%">
      <Img src={stopwatch} alt="stopwatch" height="30px" width="30px" />
      <Typography fontWeight="bold" overflowEllipsis width="100%">
        {getTimeTrip(journey.tripTime)}
      </Typography>
      <Typography fontWeight={400}>Total Time Elapsed</Typography>
    </ResultCardItemDetails>
    <ResultCardItemDetails width="30%">
      <RegistrationNumber>{vehicleLicence || "-"}</RegistrationNumber>
    </ResultCardItemDetails>
    <ResultCardItemDetails width="32%">
      <Img src={pounds_circular} alt="pounds_circular" height="30px" width="30px" />
      <Typography fontWeight="bold">{`${currencySymbol != undefined ? currencySymbol : ""}${journey.costs.toFixed(
        2,
      )}`}</Typography>
      <Typography fontWeight={400}>
        {`${journey.cO2.toFixed(2)} kg-CO`}
        <sub>2</sub>
      </Typography>
      <Typography fontWeight={400}>
        {`${(journey.nOx * 1000).toFixed(2)} g-NO`}
        <sub>X</sub>
      </Typography>
    </ResultCardItemDetails>
  </RowContainer>
);

export const DriverDetails = ({ driver, hidden }: DriverDetailsProps) => {
  return (
    <ColumnContainer padding="1rem" width="100%" align="flex-start" hidden={hidden}>
      <Typography textAlign="left" size={0.9}>
        <strong>Name:</strong> {driver.name}
        <br />
        <strong>Start Time:</strong> {driver.workingHours.start}
        <br />
        <strong>Total working hours:</strong> {getTotalWorkingHours(driver.workingHours, driver.lunchBreak)}
      </Typography>
    </ColumnContainer>
  );
};

export const ResultDetails = ({ journey, vehicle, driver, currencySymbol }: ResultDetailsProps) => {
  const [detailsTab, setDetailsTab] = useState<ResultDetailsTab>(ResultDetailsTab.JOURNEY);

  return (
    <ColumnContainer width="100%" minHeight="145px" justify="flex-start">
      <Flex width="100%">
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.DRIVER}
          onClick={() => setDetailsTab(ResultDetailsTab.DRIVER)}
        >
          Driver
        </ResultCardTab>
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.JOURNEY}
          onClick={() => setDetailsTab(ResultDetailsTab.JOURNEY)}
        >
          Journey
        </ResultCardTab>
      </Flex>

      <JourneyDetails
        journey={journey}
        vehicleLicence={vehicle.licence}
        hidden={detailsTab !== ResultDetailsTab.JOURNEY}
        currencySymbol={currencySymbol}
      />
      <DriverDetails driver={driver} hidden={detailsTab !== ResultDetailsTab.DRIVER} />
    </ColumnContainer>
  );
};
