import { ActiveLayerTypes, DepotStatusKeys, Dialogs } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { setCurrentDepot, setDepotStatus } from "@evr/store/slices/depot";
import { openDialog } from "@evr/store/slices/dialog";
import { setActiveLayer } from "@evr/store/slices/map";
import { closeWindow } from "@evr/store/slices/window";
import { Depot } from "@evr/types";
import { TableAction } from "@evr/ui/Table";

interface DepotTableActionsProps {
  depot: Depot;
}

export const DepotTableActions = ({ depot }: DepotTableActionsProps) => {
  const dispatch = useAppDispatch();
  const { id } = depot;

  const handleDeleteDepot = () => dispatch(openDialog({ type: Dialogs.DELETE_DEPOT, id }));

  const handleEditDepot = () => {
    dispatch(setCurrentDepot(depot));
    dispatch(setDepotStatus(DepotStatusKeys.EDIT));
    dispatch(setActiveLayer(ActiveLayerTypes.DEPOTS));
    dispatch(closeWindow());
  };

  return <TableAction id={id} onRemoveClick={handleDeleteDepot} onEditClick={handleEditDepot} />;
};
