import { FormikCheckbox } from "@evr/components/Form";
import { AuthKeys, TermsConditions } from "@evr/constant";
import { AuthTitle } from "@evr/pages/Auth/AuthTitle";
import { GridItem, Flex } from "@evr/ui/FlexBox";

import { TermsBox } from "./styles";

export const TermsConditionsForm = () => {
  return (
    <>
      <AuthTitle>Please Accept the Terms and Conditions</AuthTitle>
      <Flex wrap="wrap">
        <GridItem xs={12} margin="0 0 1rem">
          <TermsBox>{TermsConditions}</TermsBox>
        </GridItem>
        <GridItem xs={12} display="flex" justify="flex-start" margin="0 0 1rem">
          <FormikCheckbox label="I agree to Basemap's Terms and Conditions." name={AuthKeys.TERMS_CONDITION} />
        </GridItem>
        <GridItem>
          By using the Service, You agree to the collection and use of information in accordance with this{" "}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </GridItem>
      </Flex>
    </>
  );
};
