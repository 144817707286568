import { Anchor, CustomLinkProps } from "@evr/ui/CustomLink";

interface ErrorPageLinkProps extends CustomLinkProps {
  title: string;
  href?: string;
  color: string;
}

export const ErrorPageLink = ({ title, href, color, ...props }: ErrorPageLinkProps) => (
  <Anchor
    margin="0.25rem 1rem"
    fontWeight="bold"
    color={color}
    textAlign="center"
    target="_blank"
    href={href}
    {...props}
  >
    {title}
  </Anchor>
);
