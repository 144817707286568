import { JourneyColor } from "@evr/types";

export const resultLocationSvg = (number: number, locationColors: JourneyColor, time?: string) => {
  const svg = `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="120" height="120">
        <circle cx="60" cy="60" r="60" fill="${locationColors.main}" />
        <text font-family="Helvetica Neue, Arial, Helvetica, sans-serif"  x="50%" y="50%" text-anchor="middle" fill="${locationColors.contrast}"  font-size="4.2rem" dy=".3em" >
              ${number}
          </text>
      </svg>`;
  return svg;
};
