import { PayloadAction } from "@reduxjs/toolkit";

import { DepotStatusKeys, tablePaginationInitialValue } from "@evr/constant";
import { Depot, DepotState, SearchColumn } from "@evr/types";
import { getTableSortColumn } from "@evr/utils";

export const depotReducers = {
  setDepotPageIndex: (state: DepotState, action: PayloadAction<number>) => {
    state.pagination.pageIndex = action.payload;
  },
  setDepotSearchColumn: (state: DepotState, action: PayloadAction<SearchColumn>) => {
    state.pagination.pageIndex = tablePaginationInitialValue.pageIndex;
    state.pagination.search[action.payload.column] = action.payload;
  },
  clearDepotSearchColumn: (state: DepotState, action: PayloadAction<string>) => {
    const { [action.payload]: deleteItem, ...otherSearchItems } = state.pagination.search;
    state.pagination.search = otherSearchItems;
  },
  setDepotSortColumn: (state: DepotState, action: PayloadAction<string>) => {
    const { column, type } = state.pagination.sort;
    state.pagination.sort = getTableSortColumn(column, action.payload, type);
  },
  setCurrentDepot: (state: DepotState, action: PayloadAction<Depot>) => {
    state.currentDepot = action.payload;
  },
  setDepotStatus: (state: DepotState, action: PayloadAction<DepotStatusKeys>) => {
    state.status = action.payload;
  },
  closeDepot: (state: DepotState) => {
    state.status = DepotStatusKeys.CLOSE;
  },
};
