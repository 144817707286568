import { useMemo } from "react";

import { useVehiclesQuery } from "@evr/apis/vehicle";
import largeV from "@evr/assets/vehicles/large.png";
import mediumV from "@evr/assets/vehicles/medium.png";
import smallV from "@evr/assets/vehicles/small.png";
import { VehicleSize } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDriverMatched, selectDVMatchState } from "@evr/store/slices/dvMatch";
import { maxPageSizeQuery, getVehicleImage } from "@evr/utils";

import { DVMatchVehicle } from "./DVMatchVehicle";

const defaultVehicleImage = {
  [VehicleSize.SMALL]: { icon: smallV, height: "60%" },
  [VehicleSize.MEDIUM]: { icon: mediumV, height: "75%" },
  [VehicleSize.LARGE]: { icon: largeV, height: "85%" },
};

interface PropsType {
  driverId: number;
  driverSelected: boolean;
}

export const DVMatchSelectVehicle = ({ driverId, driverSelected: isDriverActive }: PropsType) => {
  const { list: allDVMatch } = useAppSelector(selectDVMatchState);
  const DVMatch = useAppSelector(selectDriverMatched(driverId));
  const { data: vehicles } = useVehiclesQuery(maxPageSizeQuery);

  const numVehicles = useMemo(
    () =>
      (vehicles?.resources || []).reduce(
        (obj: Record<VehicleSize, number>, vehicle) => {
          if (allDVMatch.findIndex(dvm => vehicle.id === dvm.vehicleId) === -1) {
            obj[vehicle.vehicleSize] += 1;
          }
          return obj;
        },
        {
          [VehicleSize.SMALL]: 0,
          [VehicleSize.MEDIUM]: 0,
          [VehicleSize.LARGE]: 0,
        },
      ),
    [allDVMatch, vehicles?.resources],
  );

  const vehicle = vehicles?.resources.find(vehicle => vehicle.id === DVMatch?.vehicleId);

  const vehicleIcon = (defaultIcon: string, size: VehicleSize) => {
    return vehicle && vehicle.vehicleSize === size
      ? getVehicleImage(vehicle.engineType, vehicle.vehicleSize)
      : defaultIcon;
  };

  const vehiclesData = Object.values(VehicleSize).map((size: VehicleSize, index) => ({
    index,
    size,
    icon: vehicleIcon(defaultVehicleImage[size].icon, size),
    iconHeight: defaultVehicleImage[size].height,
    badgeNumber: numVehicles[size],
  }));
  return (
    <>
      {vehiclesData.map(v => (
        <DVMatchVehicle
          key={v.index}
          icon={v.icon}
          size={v.size}
          iconHeight={v.iconHeight}
          badgeNumber={v.badgeNumber}
          isDriverActive={isDriverActive}
          driverId={driverId}
          vehicle={vehicle}
        />
      ))}
    </>
  );
};
