import styled, { css } from "styled-components";

import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

export const RightSidebarContainer = styled(ColumnContainer)`
  height: 100%;
  transition: width 0.3s ease-out;
  position: relative;
  justify-content: flex-start;
  z-index: ${props => props.theme.zIndex.drawer};
  background: white;
`;

export const RightSidebarScrollable = styled(ColumnContainer)`
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
  scroll-behavior: smooth;
`;

export const RightSidebarTab = styled(Flex)<{ active?: boolean }>`
  padding: 0.75rem 2rem;
  border-top: 1px solid ${props => props.theme.colors.action.border};
  border-bottom: 1px solid ${props => props.theme.colors.action.border};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  flex-basis: 50%;
  position: relative;
  ${p =>
    p.active &&
    css`
      background-color: ${p => p.theme.colors.primary.dark};
      color: ${p => p.theme.colors.primary.contrastText};
    `}
`;
