import styled, { css } from "styled-components";

import { colorMixinFunc, ColorsType } from "@evr/theme/colors";

export interface IconPropsType {
  color?: ColorsType;
  size?: number;
  margin?: string;
  padding?: string;
  cursorPointer?: boolean;
  hoverEffect?: boolean;
  hoverColor?: ColorsType;
  disabled?: boolean;
}

export const Icon = styled.i<IconPropsType>`
  font-size: ${props => (props.size ? `${props.size}rem` : "1.5rem")};
  color: ${props => (props.color ? colorMixinFunc(props.color) : props.theme.colors.grey.default)};
  ${props => props.cursorPointer && "cursor:pointer;"};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.disabled && `opacity:${props.theme.colors.action.disabledOpacity}`}
  ${props =>
    props.hoverEffect &&
    !props.disabled &&
    css`
      &:hover {
        font-size: ${props.size ? `${props.size + 0.15}rem` : "1.65rem"};
        ${props.hoverColor && `color: ${colorMixinFunc(props.hoverColor)}`};
      }
    `}
`;
