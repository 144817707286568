import { useContext, useEffect, useMemo } from "react";

import olMap from "ol/Map";

import MapContext from "../Map/MapContext";

interface ResolutionChangeProps {
  handleZoomChange: (zoom?: number) => void;
}

const ResolutionChange = ({ handleZoomChange }: ResolutionChangeProps) => {
  const map = useContext(MapContext) as olMap;

  useEffect(() => {
    const onChange = () => {
      const zoom = map.getView().getZoom();
      handleZoomChange(zoom);
    };

    map.getView().on("change:resolution", onChange);

    return () => {
      map.getView().un("change:resolution", onChange);
    };
  }, []);

  return null;
};

export default ResolutionChange;
