import { useEffect, useState } from "react";

import { GetCountries } from "@evr/services/sso";
import { Country } from "@evr/types";
import { Input } from "@evr/ui/TextInput/styles";

import FormikTypeahead from "./FormikTypeahead";
import { AutoCompleteOption } from "./Typeahead";

interface CountryTypeaheadProps {
  name: string;
  clear: () => void;
  handleChange?: (country: Country) => void;
}

const CountryTypeahead = ({ name, clear, handleChange }: CountryTypeaheadProps) => {
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    GetCountries().then(res => {
      setCountries(res.data);
    });
  }, []);

  const [countrySearchTerm, setCountrySearchTerm] = useState<string>("");

  const countryOptions =
    (countries?.map(x => ({
      key: x.id,
      value: x.id,
      label: x.name,
      elementLabel: (
        <div style={{ display: "flex", gap: "1rem" }}>
          <img alt={`${x.countryCode}`} src={`https://flagcdn.com/24x18/${x.countryCode?.toLowerCase()}.png`} />
          <div>{x.name}</div>
        </div>
      ),
    })) as AutoCompleteOption[]) || [];

  const onClear = () => {
    setCountrySearchTerm("");
    clear();
  };

  const onChange = (value: string | number) => {
    const selectedCountry = countries?.find(x => x.id === value);
    if (!selectedCountry) {
      return;
    }
    handleChange && handleChange(selectedCountry);
  };

  return countries ? (
    <FormikTypeahead
      items={countryOptions.filter(x => x.label.toLowerCase().includes(countrySearchTerm.toLowerCase()))}
      label="Country"
      onSearch={(term: string) => setCountrySearchTerm(term)}
      clear={() => onClear()}
      name={name}
      handleChange={onChange}
    />
  ) : (
    <Input placeholder="Country" />
  );
};

export default CountryTypeahead;
