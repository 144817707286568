import { evrApi } from "@evr/apis";
import { setSubscription } from "@evr/store/slices/auth";
import { Subscription } from "@evr/types";

import { SubscriptionsEndpoint } from "../endpoints";

export const subscriptionsApi = evrApi.injectEndpoints({
  endpoints: builder => ({
    getSubscriptions: builder.query<Subscription, void>({
      query: () => ({ url: SubscriptionsEndpoint }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(setSubscription(data));
        });
      },
    }),
  }),
});

export const { useLazyGetSubscriptionsQuery } = subscriptionsApi;
