import { Action, AnyAction, combineReducers, configureStore, Reducer, ThunkAction } from "@reduxjs/toolkit";

import { evrApi } from "@evr/apis";

import { licensing } from "./middleware/licensing";
import { miscellaneous } from "./middleware/miscellaneous";
import { appReducers } from "./slices";
import { logOutUser } from "./slices/auth";
import { VerizonImportInitialState } from "./slices/verizon/index";

const loadVerizonState = () => {
  try {
    const serializedState = localStorage.getItem("verizonImportState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Error loading verizon state:", err);
    return undefined;
  }
};

const rootReducers = combineReducers(appReducers);

const rootReducersEnhancer: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === logOutUser.type) {
    state = {} as RootState;
  }
  return rootReducers(state, action);
};

const verizonStatePersistenceMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action);
  const state = store.getState();
  try {
    localStorage.setItem("verizonImportState", JSON.stringify(state.verizon));
  } catch (err) {
    console.error("Error saving verizon state:", err);
  }
  return result;
};

export const store = configureStore({
  reducer: rootReducersEnhancer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend([evrApi.middleware, licensing, miscellaneous, verizonStatePersistenceMiddleware]),
  preloadedState: {
    verizon: loadVerizonState() || VerizonImportInitialState,
  },
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
