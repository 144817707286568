export const validationMsg = {
  required: "This field is required.",
  email: "Please enter a valid email address.",
  equalTo: "Please enter the same value again.",
  termsConditions: "You must accept the terms and conditions.",
  minZero: "Please enter a number greater than zero.",
  onlyNumber: "please enter only number.",
  complexPassword: "Must be complex, One Uppercase, One Lowercase, One Number and one special case Character",
  endAfterStartTime: "End time must be after start time.",
  lunchPeriod: "Luch time must be between working hours.",
  minNumber: (number: number) => `Please enter a number greater than or equal to ${number}.`,
  maxNumber: (number: number) => `Please do not enter more than ${number}.`,
  maxlength: (number: number) => `Please do not enter more than ${number} characters.`,
  minlength: (number: number) => `Please enter at least ${number} characters.`,
};
