import { useState, useEffect } from "react";

import { useTheme } from "styled-components";

import { TimePicker } from "@evr/components/TimeRangePicker/TimePicker";
import { MinutesStep } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

import { DatePicker } from "../DatePicker";

interface DateTimePickerContainerProps {
  title: string;
  value: Date | null;
  onChange: (value: Date) => void;
  onClose: () => void;
  minutesStep?: MinutesStep;
  minDateTime?: Date | null;
  maxDateTime?: Date | null;
  setButtonLabel?: string;
  dateOnly?: boolean;
}

export const DateTimePickerContainer = ({
  title,
  value,
  onChange,
  onClose,
  minutesStep = 15,
  minDateTime = null,
  maxDateTime = null,
  setButtonLabel = "Set",
  dateOnly = false,
}: DateTimePickerContainerProps) => {
  const theme = useTheme();

  const [date, setDate] = useState<Date | null>(value);
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const maxMinutes = Math.floor(59 / minutesStep) * minutesStep;

  useEffect(() => {
    let initialValue = value;
    if (minDateTime && initialValue && initialValue < minDateTime) {
      initialValue = minDateTime;
    }
    if (maxDateTime && initialValue && initialValue > maxDateTime) {
      initialValue = maxDateTime;
    }

    if (initialValue) {
      setDate(new Date(initialValue));
      setHour(initialValue.getHours());
      setMinutes(initialValue.getMinutes());
    }
  }, [value]);

  useEffect(() => {
    if (!minDateTime) {
      setMinDate(null);
      return;
    }

    const minDate = new Date(minDateTime);
    minDate?.setHours(0, 0, 0, 0);
    setMinDate(minDate);
    if (date && date < minDateTime) {
      setDate(new Date(minDateTime));
      setHour(minDateTime.getHours());
      setMinutes(minDateTime.getMinutes());
    }
  }, [minDateTime]);

  useEffect(() => {
    if (!maxDateTime) {
      setMaxDate(null);
      return;
    }

    const maxDate = new Date(maxDateTime);
    maxDate?.setHours(0, 0, 0, 0);
    setMaxDate(minDate);
    if (date && date > maxDateTime) {
      setDate(new Date(maxDateTime));
      setHour(maxDateTime.getHours());
      setMinutes(maxDateTime.getMinutes());
    }
  }, [maxDateTime]);

  const handleSetDate = (date: Date) => {
    if (!date) {
      return;
    }
    handleDateChange(date, hour, minutes);
  };

  const handleSetHour = (hour: number) => {
    if (!date) {
      return;
    }
    handleDateChange(date, hour, minutes);
  };

  const handleSetMinutes = (minutes: number) => {
    if (!date) {
      return;
    }
    handleDateChange(date, hour, minutes);
  };

  const handleDateChange = (date: Date, hour: number, minutes: number) => {
    // Minutes wrap to hours
    if (minutes < 0) {
      minutes = maxMinutes;
      hour--;
    } else if (minutes > maxMinutes) {
      minutes = 0;
      hour++;
    }

    // Hours wrap to days
    if (hour < 0) {
      hour = 23;
      date.setDate(date.getDate() - 1);
    } else if (hour > 23) {
      hour = 0;
      date.setDate(date.getDate() + 1);
    }

    // Apply time to the date
    date.setHours(hour, minutes, 0, 0);

    // Cap to min/max
    if (minDateTime && date < minDateTime) {
      setDate(new Date(minDateTime));
      setHour(minDateTime.getHours());
      setMinutes(minDateTime.getMinutes());
    } else if (maxDateTime && date > maxDateTime) {
      setDate(new Date(maxDateTime));
      setHour(maxDateTime.getHours());
      setMinutes(maxDateTime.getMinutes());
    } else {
      setDate(new Date(date));
      setMinutes(minutes);
      setHour(hour);
    }
  };

  const handleIncreaseHour = () => handleSetHour(hour + 1);
  const handleDecreaseHour = () => handleSetHour(hour - 1);
  const handleIncreaseMinutes = () => handleSetMinutes(minutes + minutesStep);
  const handleDecreaseMinutes = () => handleSetMinutes(minutes - minutesStep);

  const handleSubmit = () => {
    if (!date) {
      return;
    }
    onChange(date);
    onClose();
  };

  return (
    <ColumnContainer
      rounded
      shadow={4}
      top="50%"
      left="50%"
      position="absolute"
      transform="translate3d(-50%, -50%, 0)"
      overflow="hidden"
      minWidth="300px"
      background={theme.colors.common.white}
      zIndex={theme.zIndex.modal}
    >
      <Flex padding="0.5rem 1rem" gradient width="100%" justify="space-between">
        <Typography as="h2" size={1.25} fontWeight={500} lineHeight={1.6} margin="0">
          {title}
        </Typography>
      </Flex>
      <Flex>
        <DatePicker value={date} onChange={handleSetDate} minDate={minDate} maxDate={maxDate} />
        {!dateOnly && (
          <Flex>
            <TimePicker time={hour} handleDecrease={handleDecreaseHour} handleIncrease={handleIncreaseHour} />
            <Typography size={1.5} fontWeight="bold">
              :
            </Typography>
            <TimePicker time={minutes} handleDecrease={handleDecreaseMinutes} handleIncrease={handleIncreaseMinutes} />
          </Flex>
        )}
      </Flex>
      <Flex width="100%" justify="flex-end" margin="0.5rem 0 0">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit} disabled={!date}>
          {setButtonLabel}
        </Button>
      </Flex>
    </ColumnContainer>
  );
};
