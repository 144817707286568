import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SliceKeys, WindowKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { WindowState, WindowType } from "@evr/types";

import { resetCalculationParams } from "../result";

const initialState: WindowState = { type: WindowKeys.CLOSE };

export const WindowSlice = createSlice({
  name: SliceKeys.WINDOW,
  initialState,
  reducers: {
    openWindow: (window, action: PayloadAction<WindowType>) => {
      window.type = action.payload;
    },
    closeWindow: window => {
      window.type = WindowKeys.CLOSE;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetCalculationParams, window => {
      window.type = WindowKeys.CLOSE;
    });
  },
});

export const { openWindow, closeWindow } = WindowSlice.actions;
export const selectWindowType = (state: RootState) => state.window.type;
export default WindowSlice.reducer;
