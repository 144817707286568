import { DistanceUnitKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { changeMeasurement, selectMeasurement } from "@evr/store/slices/setting";
import { Button } from "@evr/ui/Button";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";

export const SettingUnitOfMeasurement = () => {
  const dispatch = useAppDispatch();
  const unitOfMeasurement = useAppSelector(selectMeasurement);

  const handleChangeMeasurementClick = (unit: DistanceUnitKeys) => {
    dispatch(dispatch(changeMeasurement(unit)));
  };

  return (
    <>
      <RowContainer width="100%" justify="flex-start">
        Measurements
        <Wrapper flexGrow={1} />
        <Button
          variant={unitOfMeasurement === DistanceUnitKeys.MILES ? "contained" : "outlined"}
          onClick={() => handleChangeMeasurementClick(DistanceUnitKeys.MILES)}
        >
          Miles
        </Button>
        <Button
          variant={unitOfMeasurement === DistanceUnitKeys.KM ? "contained" : "outlined"}
          onClick={() => handleChangeMeasurementClick(DistanceUnitKeys.KM)}
        >
          KM
        </Button>
      </RowContainer>
    </>
  );
};
