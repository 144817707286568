import { PayloadAction } from "@reduxjs/toolkit";

import { LocalStorageKeys } from "@evr/constant";
import { AuthState, LoginResponse, LoginSSOResponse, OrgSettings, Subscription, User } from "@evr/types";
import { localStorageHelper } from "@evr/utils";

export const AuthReducer = {
  logOutUser: () => {
    localStorageHelper.removeItem(LocalStorageKeys.TOKEN);
  },
  logInUser: (auth: AuthState, action: PayloadAction<LoginResponse>) => {
    const { user, token } = action.payload;
    if (user) {
      auth.currentUser = user;
    }
    if (token) {
      localStorageHelper.storeItem(LocalStorageKeys.TOKEN, token);
    }
  },
  logInSSOUser: (auth: AuthState, action: PayloadAction<LoginSSOResponse>) => {
    const { user, issuer } = action.payload;
    auth.currentUser = user;
    localStorageHelper.storeItem(LocalStorageKeys.SSO_URL, issuer);
  },
  getUserFromSession: (auth: AuthState, action: PayloadAction<User>) => {
    auth.currentUser = action.payload;
  },
  setProfilePhoto: (auth: AuthState, action: PayloadAction<string>) => {
    if (auth.currentUser) {
      auth.currentUser.photo = action.payload;
    }
  },
  setUserHasCalculation: (auth: AuthState, action: PayloadAction<boolean>) => {
    if (!auth.currentUser) {
      return;
    }
    auth.currentUser.hasInProgressCalculation = action.payload;
  },
  setSubscription: (auth: AuthState, action: PayloadAction<Subscription>) => {
    auth.subscription = action.payload;
  },
  setOrgSettings: (auth: AuthState, action: PayloadAction<OrgSettings>) => {
    const {
      defaultJobDuration,
      defaultInitialStateOfCharge,
      driverMaxWaitingTime,
      batteryBuffer,
      prioritiseEVs,
      spreadWork,
      enableRecharge,
      batteryBufferDistance,
      batteryBufferType,
      petrolCostOverride,
      dieselCostOverride,
      electricityCostOverride,
      proofOfDeliveryRequirement,
      monitorDriverInactivity,
      activityTimeout,
      verizonLastImportDate
    } = action.payload;
    auth.orgSettings = {
      defaultJobDuration,
      defaultInitialStateOfCharge,
      driverMaxWaitingTime,
      batteryBuffer,
      prioritiseEVs,
      spreadWork,
      enableRecharge,
      batteryBufferDistance,
      batteryBufferType,
      petrolCostOverride,
      dieselCostOverride,
      electricityCostOverride,
      proofOfDeliveryRequirement,
      monitorDriverInactivity,
      activityTimeout,
      verizonLastImportDate
    };
  },
};
