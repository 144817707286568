export enum UserKeys {
  ID = "id",
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE_NUMBER = "phoneNumber",
  PHOTO = "photo",
  HAS_PHOTO = "hasPhoto",
  HAS_CALCULATION = "hasInProgressCalculation",
  IS_FIRST_LOGIN = "isFirstLogin",
  ORGANISATION = "organisation",
}
