import * as Yup from "yup";

import { AuthKeys, validationMsg } from "@evr/constant";

export const signInValidationSchema = Yup.object({
  [AuthKeys.EMAIL]: Yup.string()
    .email(validationMsg.email)
    .required(validationMsg.required)
    .max(100, validationMsg.maxlength(100)),
  [AuthKeys.PASSWORD]: Yup.string().max(50, validationMsg.maxlength(50)).required(validationMsg.required),
});
