import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser, selectUserOrgSettings } from "@evr/store/slices/auth";
import { openDialog } from "@evr/store/slices/dialog";
import { Button } from "@evr/ui/Button";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const SettingsVerizonImport = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const onImportBegin = () => {
    dispatch(openDialog({ type: Dialogs.VERIZON_LOGIN }));
  };
  const getLastImported = () => {
    if (!orgSettings.verizonLastImportDate) {
      return;
    }

    const date = new Date(orgSettings.verizonLastImportDate);
    return date.toLocaleDateString();
  };
  return currentUser?.organisation.fromVerizon ? (
    <RowContainer width="100%" justify="flex-start">
      <Typography>
        Verizon Import
        {orgSettings?.verizonLastImportDate && (
          <>
            <br />
            <span>Last Import: {getLastImported()}</span>
          </>
        )}
      </Typography>
      <Wrapper margin="0 0 0 auto" position="relative">
        <Button variant="contained" onClick={onImportBegin}>
          Begin Import Process
        </Button>
      </Wrapper>
    </RowContainer>
  ) : (
    <></>
  );
};
