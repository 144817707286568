import styled, { css } from "styled-components";

import { TableNameKeys } from "@evr/constant";
import { colorMixinFunc, ColorsType } from "@evr/theme/colors";
import { TableType } from "@evr/types";

type TextAlign = "inherit" | "initial" | "unset" | "center" | "start" | "end";

interface StyledTableProps {
  tableType?: TableType;
  stickyHeader?: boolean;
}

interface THProps {
  clickable?: boolean;
  color?: ColorsType;
  tableType?: TableType;
  textAlign?: TextAlign;
  padding?: string;
  stickyHeader?: boolean;
}

interface TDProps {
  textAlign?: TextAlign;
  padding?: string;
}

interface TRProps {
  clickable?: boolean;
}

export const TableWrapper = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  flex-grow: 1;
`;

export const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  ${props =>
    props.tableType === TableNameKeys.DRIVER_VEHICLE_MATCH &&
    css`
      margin: 0;
    `}
  background-color: transparent;
  border-collapse: collapse;
  th,
  td {
    vertical-align: middle;
    font-size: 0.85rem;
    user-select: none;
    &:first-child {
      padding-left: 1rem;
    }
  }
`;

export const TH = styled.th<THProps>`
  vertical-align: bottom;
  text-align: ${props => props.textAlign || "start"};
  z-index: ${props => props.theme.zIndex.positiveOne};
  border-bottom: 2px solid ${props => props.theme.colors.action.border};
  padding: ${props => props.padding || "0.5rem 0.4rem"};
  ${props => props.clickable && "cursor:pointer;"}
  ${props => props.color && `color:${colorMixinFunc(props.color)};`}
  background-color: ${props => props.theme.colors.common.white};

  ${props =>
    props.stickyHeader !== false &&
    css`
      position: sticky;
      top: 0;
    `}

  ${props =>
    props.tableType === TableNameKeys.DRIVER_VEHICLE_MATCH &&
    css`
      background-color: ${props.theme.colors.info.dark};
      color: ${props.theme.colors.info.contrastText};
    `}
  ${props =>
    (props.tableType === TableNameKeys.DRIVER || props.tableType === TableNameKeys.VEHICLE) &&
    css`
      background-color: ${props.theme.colors.grey.light};
      color: ${props.theme.colors.common.black};
    `}
`;

export const TD = styled.td<TDProps>`
  padding: ${props => props.padding || "0.5rem 0.4rem"};
  text-align: ${props => props.textAlign || "start"};
`;

export const TR = styled.tr<TRProps>`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
  ${props => props.clickable && "cursor:pointer"};
`;
