import { useState } from "react";

import styled from "styled-components";

import { useDriversQuery } from "@evr/apis/driver";
import { WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openWindow, selectWindowType } from "@evr/store/slices/window";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Input } from "@evr/ui/TextInput/styles";
import { Typography } from "@evr/ui/Typography";
import { maxPageSizeQuery } from "@evr/utils";

import { DVMatchRow } from "./DVMatchRow";
import { DriverVehicleMatchContainer } from "./styles";

const DriverSearchInput = styled(Input)`
  margin-right: auto;
  line-height: 1.2;
  width: 50%;
`;

export const DriverVehicleMatch = () => {
  const dispatch = useAppDispatch();
  const [driverName, setDriverName] = useState("");
  const isHidden = useAppSelector(selectWindowType) !== WindowKeys.DRIVER_VEHICLE;
  const { data: drivers } = useDriversQuery(maxPageSizeQuery);
  const handleOpenDriverTable = () => dispatch(openWindow(WindowKeys.DRIVER));
  const handleCloseWindow = () => dispatch(openWindow(WindowKeys.CLOSE));
  const handleDriverNameSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDriverName(e.target.value);
  };

  const filteredDrivers = drivers?.resources.filter(driver =>
    driver.name.toLowerCase().includes(driverName.toLowerCase()),
  );

  return (
    <DriverVehicleMatchContainer hidden={isHidden}>
      <RowContainer justify="flex-start" padding="0.5rem 0 0.5rem 1rem" width="100%">
        <DriverSearchInput type="search" placeholder="Search in driver's name" onChange={handleDriverNameSearch} />
        <IconButton icon="plus-circle" title="Add Driver/Vehicle" color="success" onClick={handleOpenDriverTable} />
        <IconButton icon="times" title="Close" onClick={handleCloseWindow} />
      </RowContainer>
      <Wrapper width="100%" overflow="auto" padding="0 0.25rem">
        {filteredDrivers && filteredDrivers.length > 0 ? (
          filteredDrivers.map(driver => <DVMatchRow key={driver.id} driver={driver} />)
        ) : (
          <Typography as="div" textAlign="center" padding="1rem" color="error">
            Drivers Not Available
          </Typography>
        )}
      </Wrapper>
    </DriverVehicleMatchContainer>
  );
};
