import { useEffect } from "react";

import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { calculateArrivalTime } from "@evr/services/location";
import { selectResultRoutesStatus } from "@evr/store/slices/result";
import { Journey, JourneyColor } from "@evr/types";

import { resultLocationSvg } from "../ResultLocation/resultLocationSvg";

interface ResultJourneyLocationsLayerProps {
  locationsSource: olVectorSource;
  journey: Journey;
  journeyColor: JourneyColor;
  layerName: string;
  isVisible: boolean;
}

const ResultJourneyLocationsLayer = ({
  locationsSource,
  journey,
  journeyColor,
  layerName,
  isVisible,
}: ResultJourneyLocationsLayerProps) => {
  const { showRoutes } = useAppSelector(selectResultRoutesStatus);

  useEffect(() => {
    const locationFeatures = journey.locations.map(location => {
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(location.point.coordinates)),
      });

      var svg2 = resultLocationSvg(location.stopNumber + 1, journeyColor);
      const style = new Style({
        image: new Icon({
          src: "data:image/svg+xml;utf8," + encodeURIComponent(svg2),
          scale: 0.22,
          anchor: [0.5, 1],
        }),
      });

      feature.setStyle(style);
      feature.setProperties({
        id: location.id,
        arrivalTime: calculateArrivalTime(location, journey),
      });

      return feature;
    });

    locationsSource.addFeatures(locationFeatures ?? []);
  }, [showRoutes]);

  return (
    <Vector visible={isVisible} name={layerName} source={locationsSource} zIndex={MapLayerZIndices.LOCATIONS}></Vector>
  );
};

export default ResultJourneyLocationsLayer;
