export enum ScheduledJourneyKeys {
  ID = "id",
  DEPOT = "depot",
  DEPOT_NAME = "depotName",
  VEHICLE = "vehicle",
  VEHICLE_REG = "vehicleReg",
  VEHICLE_NAME = "vehicleName",
  DRIVER = "driver",
  DRIVER_NAME = "driverName",
  FINAL_STATE_OF_CHARGE = "finalStateOfCharge",
  TRIP_TIME = "tripTime",
  TRIP_LENGTH = "tripLength",
  START_TIME = "startTime",
  STATUS = "status",
  ROUTE = "route",
  LOCATIONS = "locations",
  LATESTRECALCULATION = "latestRecalculation",
  DRIVER_PROGRESS = "driverProgress",
  LATEST_STATE_OF_CHARGE = "latestStateOfCharge",
  STATE_OF_CHARGE_DELTA = "stateOfChargeDelta",
  ETA_DEVIATION_DELTA = "etaDeviationDelta",
  RECALCULATION_COUNT = "recalculationCount",
  JOBS_REMAINING = "jobsRemaining",
  JOBS_COMPLETED = "jobsCompleted",
  LATEST_DRIVER_PROGRESS = "latestDriverProgress",
  RECALCULATIONS = "recalculations",
  IN_PROGRESS_SUMMARY = "inProgressSummary",
  REASON = "reason"
}

export enum ScheduledJourneyStatus {
  SCHEDULED,
  INPROGRESS,
  COMPLETED,
  REJECTED,
  CANCELLED
}
