import { Navigate } from "react-router-dom";

import { config } from "@evr/config";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { logOutUser, selectCurrentUser } from "@evr/store/slices/auth";
import { Button } from "@evr/ui/Button";
import { ColumnContainer, RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

export const Unauthorized = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const handleRedirectToSupportUrl = () => {
    global.window.open(config.basemapSupportDeskURL, "_blank")?.focus();
  };
  const handleLogOut = () => dispatch(logOutUser());

  if (!user) {
    return <Navigate to="/signin" replace />;
  }
  return (
    <ColumnContainer height="100%" position="relative">
      <IconButton icon="exclamation-triangle" size={6} color="warning" />
      <Typography fontWeight="bold" size={1.3} margin="4rem 0 2rem 0">
        The EVR Portal is for the Fleet Manager to plan and schedule journeys.
        <br />
        Go to the App to view your scheduled journeys.
      </Typography>
      <RowContainer position="absolute" bottom="0" left="0">
        <Button variant="text" onClick={handleLogOut}>
          Log Out
        </Button>
        <Button variant="text" onClick={handleRedirectToSupportUrl}>
          Support Desk
        </Button>
      </RowContainer>
    </ColumnContainer>
  );
};
