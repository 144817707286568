import { ReactNode } from "react";

import { useTheme } from "styled-components";

import { Flex, FlexProps } from "./FlexBox";
import { Typography } from "./Typography";

interface PropsType extends FlexProps {
  children: ReactNode;
  badgeContent: number;
  size?: number;
  showZero?: boolean;
  hidden?: boolean;
  warning?: boolean;
  containerProps?: Omit<FlexProps, "position">;
}
export const Badge = ({
  children,
  badgeContent,
  size,
  showZero,
  hidden,
  containerProps,
  warning,
  ...rest
}: PropsType) => {
  const { colors } = useTheme();
  return (
    <Flex position="relative" {...containerProps}>
      {!hidden && badgeContent > (showZero ? -1 : 0) && (
        <Flex
          background={warning ? "transparent" : colors.info.dark}
          position="absolute"
          top="-30%"
          right="-30%"
          borderRadius="10px"
          minWidth="20px"
          height="20px"
          padding="0 2px"
          {...rest}
        >
          {warning ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke={colors.error.main}
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                fill={colors.warning.light}
                d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
              ></path>
              <path d="M12 9v2m0 4v.01" stroke={colors.error.main}></path>
            </svg>
          ) : (
            <Typography fontWeight="bold" size={size || 0.75}>
              {badgeContent}
            </Typography>
          )}
        </Flex>
      )}
      {children}
    </Flex>
  );
};
