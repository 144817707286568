import { TableSortableHeader } from "@evr/components/Table";
import { TableNameKeys, DriverKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDriversPagination, setDriverSortColumn } from "@evr/store/slices/driver";
import { ExtendedDriver, Driver } from "@evr/types";
import { TR, StyledTable, TH, TD } from "@evr/ui/Table";
import { getTotalWorkingHours } from "@evr/utils";

import { DriverTableActions } from "./DriverTableActions";
import { driverTableColumns } from "./driverTableColumns";

interface DriverTableProps {
  drivers: Driver[];
}

export const DriverTable = ({ drivers }: DriverTableProps) => {
  const dispatch = useAppDispatch();
  const { sort } = useAppSelector(selectDriversPagination);

  const handleChangeSortColumn = (column: string) => {
    dispatch(setDriverSortColumn(column));
  };

  const driverList: ExtendedDriver[] = drivers.map(driver => ({
    ...driver,

    [DriverKeys.START_TIME]: driver.workingHours.start,
    [DriverKeys.END_TIME]: driver.workingHours.end,
    [DriverKeys.TOTAL_WORKING_HOURS]: getTotalWorkingHours(driver.workingHours, driver.lunchBreak),
    [DriverKeys.LUNCH_END_TIME]: driver.lunchBreak?.end || "_",
    [DriverKeys.LUNCH_START_TIME]: driver.lunchBreak?.start || "_",
    [DriverKeys.CREATED_DATE]: driver.createdDate ? new Date(driver.createdDate).toLocaleDateString() : "_",
  }));

  return (
    <StyledTable tableType={TableNameKeys.DRIVER}>
      <thead>
        <tr>
          {driverTableColumns.map(col => (
            <TH key={col.field} padding="0" tableType={TableNameKeys.DRIVER}>
              <TableSortableHeader sort={sort} column={col} onChange={handleChangeSortColumn} />
            </TH>
          ))}
          <TH tableType={TableNameKeys.DRIVER} textAlign="center">
            Actions
          </TH>
        </tr>
      </thead>
      <tbody>
        {driverList.map(driver => (
          <TR key={driver.id}>
            {driverTableColumns.map(col => (
              <TD key={col.field}>{(driver as unknown as Record<string, string>)[col.field] || "_"}</TD>
            ))}
            <DriverTableActions driverId={driver.id} />
          </TR>
        ))}
      </tbody>
    </StyledTable>
  );
};
