import { StyledIconButton, StyledIconButtonProps } from "./styles";
interface PropsType extends StyledIconButtonProps, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  icon: string;
  iconClass?: string;
}

export const IconButton = ({ icon, iconClass, ...rest }: PropsType) => {
  return (
    <StyledIconButton {...rest}>
      <i className={`${iconClass ? iconClass : "fas"} fa-${icon}`}></i>
    </StyledIconButton>
  );
};
