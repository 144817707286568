import olVectorSource from "ol/source/Vector";

import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyShowOriginalCalculation } from "@evr/store/slices/scheduled-journey-results";
import { Journey, JourneyColor, ScheduledJourney } from "@evr/types";

import ResultJourneyLayer from "../../ResultsLayer/ResultJourneyLayer";

interface ScheduledJourneyRouteLayerProps {
  journeySource: olVectorSource;
  scheduledJourney: ScheduledJourney;
  journeyColor: JourneyColor;
}

const ScheduledJourneyRouteLayer = ({
  journeySource,
  scheduledJourney,
  journeyColor,
}: ScheduledJourneyRouteLayerProps) => {
  const journey = {
    id: scheduledJourney.id,
    route: {
      coordinates: scheduledJourney.route.coordinates,
    },
  } as Journey;

  const showRoute = useAppSelector(selectScheduledJourneyShowOriginalCalculation);

  return (
    <ResultJourneyLayer
      isVisible={showRoute}
      journeySource={journeySource}
      journey={journey}
      journeyColor={journeyColor}
    />
  );
};

export default ScheduledJourneyRouteLayer;
