import styled from "styled-components";
interface AuthTitleProps {
  size?: number;
}

export const AuthTitle = styled.h3<AuthTitleProps>`
  margin: 1.3rem 0;
  text-align: center;
  font-size: ${props => (props.size ? `${props.size}rem` : "1.4rem")};
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.size ? `${props.size - 0.3}rem` : "1.1rem")};
  }
`;
