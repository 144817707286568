import styled from "styled-components";

import { moveGradientAnimation } from "@evr/theme/animations";

import { RowContainer } from "./FlexBox";

interface PropsType {
  isLoading?: boolean;
}

export const LoadingBox = styled(RowContainer)<PropsType>`
  --border-width: 1px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius};

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: ${props => props.theme.zIndex.negativeOne};
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    ${props =>
      props.isLoading &&
      `
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    `}
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: ${moveGradientAnimation} 4s alternate infinite;
  }
`;
