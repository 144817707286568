import { createSelector, createSlice } from "@reduxjs/toolkit";

import { geolocationApi } from "@evr/apis/geolocation";
import { ParcelProcessedStatusKeys, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { JobLocation, ParcelState } from "@evr/types";

import { resetCalculationParams } from "../result";
import { parcelInitialState } from "./initialState";
import { ParcelMapReducer } from "./parcelMapReducer";
import { ParcelReducer } from "./parcelReducer";

const getParcelData = (description: string, state: ParcelState) => {
  const [key, index] = description.split("&&");
  const parcelIndex = parseInt(index);
  const parcel = isNaN(parcelIndex) ? null : state.data[parcelIndex];
  const parcelsPerLocation = (state.processedState.jobLocations.temp[key] as JobLocation).jobs.length;
  return { key, index, parcel, parcelsPerLocation };
};

export const ParcelSlice = createSlice({
  name: SliceKeys.PARCEL,
  initialState: parcelInitialState,
  reducers: { ...ParcelReducer, ...ParcelMapReducer },
  extraReducers: builder => {
    builder
      .addCase(resetCalculationParams, parcels => {
        return parcelInitialState;
      })
      .addMatcher(geolocationApi.endpoints.getLocations.matchFulfilled, (state, action) => {
        action.payload.forEach(location => {
          const { key, parcel, parcelsPerLocation } = getParcelData(location.description, state);

          if (location.isFound) {
            state.processedState.jobLocations.valids[key] = {
              ...state.processedState.jobLocations.temp[key],
              point: location.point,
              description: "",
            };
            state.processedState.totalProcessedParcels += parcelsPerLocation;
          } else {
            parcel && state.processedState.invalidParcels.push({ ...parcel, Reason: location.message });
            state.processedState.jobLocations.invalids[key] = {
              ...state.processedState.jobLocations.temp[key],
              description: "",
            };
          }
          state.numberOfProcessedParcel += parcelsPerLocation;
          if (state.numberOfProcessedParcel >= state.numberOfParcel) {
            state.status = ParcelProcessedStatusKeys.DONE;
          }
        });
      })
      .addMatcher(geolocationApi.endpoints.getLocations.matchRejected, (state, action) => {
        action.meta.arg.originalArgs.forEach(location => {
          const { key, parcel, parcelsPerLocation } = getParcelData(location.description, state);
          parcel && state.processedState.invalidParcels.push({ ...parcel, Reason: "Unsuccessful API call" });

          state.processedState.jobLocations.invalids[key] = {
            ...state.processedState.jobLocations.temp[key],
            description: "",
          };
          state.numberOfProcessedParcel += parcelsPerLocation;
          if (state.numberOfProcessedParcel >= state.numberOfParcel) {
            state.status = ParcelProcessedStatusKeys.DONE;
          }
        });
      });
  },
});

export const {
  setNewParcelheader,
  nextParcelItem,
  backParcelItem,
  addParcelCSV,
  removeParcelsData,
  setParcelStatus,
  preProcessParcels,
  addAdditionalParcels,
  removeSelectedCsvFile,
  //parcel on map actions
  setJobLocationPointIndex,
  setJobLocationCoordinates,
  editJobLocationCoordinates,
  cancelEditJobLocationCoordinates,
  deleteJobLocation,
  editJobLocation,
  addJobLocation,
  setParcelToEditCoordinates,
} = ParcelSlice.actions;

export const selectParcelDataTable = createSelector(
  (state: RootState) => state.parcel.data,
  (state: RootState) => state.parcel.header,
  (data, header) => ({ data, header }),
);
export const selectParcelHeader = createSelector(
  (state: RootState) => state.parcel.newHeader,
  (state: RootState) => state.parcel.present,
  (newHeader, present) => ({ newHeader, present }),
);
export const selectParcelActions = createSelector(
  (state: RootState) => state.parcel.future,
  (state: RootState) => state.parcel.past,
  (state: RootState) => state.parcel.enableNextBt,
  (future, past, enableNextBt) => ({ future, past, enableNextBt }),
);
export const selectParcelRowsNumber = createSelector(
  (state: RootState) => state.parcel.numberOfParcel,
  numberOfParcel => numberOfParcel,
);
export const selectProcessedParcelStatus = createSelector(
  (state: RootState) => state.parcel.status,
  status => status,
);
export const selectTotalProcessedParcels = createSelector(
  (state: RootState) => state.parcel.processedState.totalProcessedParcels,
  totalProcessedParcels => totalProcessedParcels,
);
export const selectInvalidParcels = createSelector(
  (state: RootState) => state.parcel.processedState.invalidParcels,
  invalidParcels => invalidParcels,
);
export const selectProcessedParcelsNumber = createSelector(
  selectParcelRowsNumber,
  (state: RootState) => state.parcel.numberOfProcessedParcel,
  (numberOfParcel, numberOfProcessedParcel) => ({
    numberOfParcel,
    numberOfProcessedParcel,
  }),
);
export const selectValidJobLocations = createSelector(
  (state: RootState) => state.parcel.processedState.jobLocations.valids,
  valids => valids,
);
export const selectInvalidJobLocations = createSelector(
  (state: RootState) => state.parcel.processedState.jobLocations.invalids,
  invalids => invalids,
);
export const selectIsAppendingParcels = createSelector(
  (state: RootState) => state.parcel.processedState.isAppendingParcels,
  isAppendingParcels => isAppendingParcels,
);
export const selectTempJobLocations = createSelector(
  (state: RootState) => state.parcel.processedState.jobLocations.temp,
  temp => temp,
);
export const selectParcelMapState = createSelector(
  (state: RootState) => state.parcel.mapState.itemToPoint,
  (state: RootState) => state.parcel.mapState.description,
  (state: RootState) => state.parcel.mapState.editLocation,
  (state: RootState) => state.parcel.mapState.jobLocation,
  (itemToPoint, description, editLocation, jobLocation) => ({ itemToPoint, description, editLocation, jobLocation }),
);
export const selectParcelPointToItem = createSelector(
  (state: RootState) => state.parcel.mapState.pointToItem,
  pointToItem => pointToItem,
);

export const selectEditLocationCoordinates = createSelector(
  (state: RootState) => state.parcel.mapState.editLocationCoordinate,
  editLocationCoordinate => editLocationCoordinate,
);

export default ParcelSlice.reducer;
