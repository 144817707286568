import { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { useTheme } from "styled-components";
import * as Yup from "yup";

import { useDriversQuery } from "@evr/apis/driver";
import { useVehiclesQuery } from "@evr/apis/vehicle";
import verizonLogo from "@evr/assets/external/Verizon-Connect-Logo.png";
import { FormikPasswordInput, FormikTextInput } from "@evr/components/Form";
import { Dialogs, DialogTitle, EngineType, VehicleSize } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { VerizonGetDrivers, VerizonGetVehicles, Verizonlogin } from "@evr/services/verizon";
import { openDialog } from "@evr/store/slices/dialog";
import {
  getVerizonToken,
  setDriverExists,
  setDriverIndex,
  setImportPosition,
  setVehicleExists,
  setVehicleIndex,
  startImport,
  storeDrivers,
  storeToken,
  storeVehicles,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { maxPageSizeQuery } from "@evr/utils";

import { verizonLoginValidationSchema } from "./schema";

const emailSchema = Yup.string().email("Invalid email address");

export const VerizonLoginDialog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [credentialError, setCredentialError] = useState<boolean>(false);
  const { data: existingDrivers } = useDriversQuery(maxPageSizeQuery);
  const { data: existingVehicles } = useVehiclesQuery(maxPageSizeQuery);
  const existingTokenData = useAppSelector(getVerizonToken);

  useEffect(() => {
    if (existingTokenData?.token && existingTokenData?.expires && existingTokenData.expires > new Date().getTime()) {
      setLoading(true);
      setLoggedIn(true);
      importVerizonData(existingTokenData.token);
    }
  }, [existingTokenData?.token, existingTokenData?.expires]);

  const handleSubmit = (values: any) => {
    setLoading(true);
    setCredentialError(false);

    var basicToken = btoa(values.username + ":" + values.password);

    Verizonlogin(basicToken)
      .then(d => {
        var verizonToken = d.data;
        dispatch(storeToken({ token: verizonToken, expires: calculateExpiryTime(18) }));
      })
      .catch(error => {
        setCredentialError(true);
        setLoading(false);
      });
  };

  const importVerizonData = (verizonToken: string) => {
    // Now they have logged in, time to get the drivers and vehicles
    VerizonGetDrivers(verizonToken).then(d => {
      var mappedDrivers = d.data
        .filter(
          (v: any) =>
            v.Driver.EmailAddress && v.Driver.EmailAddress.trim() !== "" && isValidEmail(v.Driver.EmailAddress.trim()),
        )
        .map((v: any) => {
          return {
            firstName: v.Driver.FirstName,
            lastName: v.Driver.LastName,
            email: v.Driver.EmailAddress.toLowerCase().trim(),
          };
        });

      VerizonGetVehicles(verizonToken).then(d => {
        var mappedVehicles = d.data
          .filter((v: any) => v.RegistrationNumber && v.RegistrationNumber.trim() !== "")
          .map((v: any) => {
            return {
              registration: v.RegistrationNumber.toLowerCase().trim().slice(0, 10),
              make: v.Make,
              model: v.Model,
              engineType:
                v.FuelType === 0 ? EngineType.PETROL : v.FuelType === 1 ? EngineType.DIESEL : EngineType.ELECTRIC,
              size:
                v.VehicleSize === 0 ? VehicleSize.SMALL : v.VehicleSize === 1 ? VehicleSize.MEDIUM : VehicleSize.LARGE,
            };
          });

        dispatch(storeDrivers(mappedDrivers));
        dispatch(storeVehicles(mappedVehicles));

        existingDrivers?.resources.forEach((driver: any) => {
          var exists = mappedDrivers.find(
            (v: any) =>
              driver.email !== undefined &&
              v.email === driver.email.toLowerCase().trim() &&
              isValidEmail(driver.email.toLowerCase().trim()),
          );
          if (exists) {
            dispatch(setDriverExists({ email: driver.email.toLowerCase().trim() }));
          }
        });

        existingVehicles?.resources.forEach((vehicle: any) => {
          var exists = mappedVehicles.find((v: any) => v.registration === vehicle.licence.toLowerCase().trim());
          if (exists) {
            dispatch(setVehicleExists({ registration: vehicle.licence.toLowerCase().trim() }));
          }
        });

        dispatch(startImport());
        dispatch(setDriverIndex(0));
        dispatch(setVehicleIndex(0));
        dispatch(setImportPosition(VerizonImportPosition.DRIVER_SELECT));
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_SELECT }));
        setLoading(false);
      });
    });
  };

  const isValidEmail = (email: string): boolean => {
    try {
      emailSchema.validateSync(email);
      return true;
    } catch (error) {
      return false;
    }
  };

  const calculateExpiryTime = (minutes: number): number => {
    const currentTime = new Date().getTime();
    const expiryTime = currentTime + minutes * 60 * 1000; // Convert minutes to milliseconds
    return expiryTime;
  };

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_LOGIN} />
      <DialogBody>
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={verizonLoginValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Flex wrap="wrap" align="center" justify="center">
              <img src={verizonLogo} alt="Verizon Connect Logo" style={{ maxHeight: "160px" }} />
            </Flex>
            <Flex wrap="wrap" align="baseline" justify="flex-start">
              <GridItem>
                <FormikTextInput label="Username" name="username" disabled={loggedIn} />
              </GridItem>
              <GridItem>
                <FormikPasswordInput label="Password" name="password" disabled={loggedIn} />
              </GridItem>
              {credentialError && (
                <GridItem>
                  <div style={{ color: theme.colors.error.main, padding: "10px", paddingBottom: 0 }}>
                    Please check your credentials and try again.
                  </div>
                </GridItem>
              )}
            </Flex>
            <DialogActionButtons loading={loading} buttonTitle={"Login"} variant="contained" />
          </Form>
        </Formik>
      </DialogBody>
    </>
  );
};
