import { useMemo } from "react";

import { TableSortableHeader } from "@evr/components/Table";
import { TableNameKeys, DepotKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDepotsPagination, setDepotSortColumn } from "@evr/store/slices/depot";
import { Depot, ExtendedDepot } from "@evr/types";
import { TR, StyledTable, TH, TD } from "@evr/ui/Table";

import { DepotTableActions } from "./DepotTableActions";
import { depotTableColumns } from "./depotTableColumns";

interface DepotTableProps {
  depots: Depot[];
}

export const DepotTable = ({ depots }: DepotTableProps) => {
  const dispatch = useAppDispatch();
  const { sort } = useAppSelector(selectDepotsPagination);

  const handleChangeSortColumn = (column: string) => {
    dispatch(setDepotSortColumn(column));
  };

  const depotList: ExtendedDepot[] = useMemo(
    () =>
      depots.map(depot => ({
        ...depot,
        [DepotKeys.LATITUDE]: depot.point.coordinates[1].toFixed(4),
        [DepotKeys.LONGITUDE]: depot.point.coordinates[0].toFixed(4),
      })),
    [depots],
  );

  return (
    <StyledTable tableType={TableNameKeys.DRIVER}>
      <thead>
        <tr>
          {depotTableColumns.map(col => (
            <TH key={col.field} padding="0" tableType={TableNameKeys.DRIVER}>
              <TableSortableHeader sort={sort} column={col} onChange={handleChangeSortColumn} />
            </TH>
          ))}
          <TH tableType={TableNameKeys.DRIVER} textAlign="center">
            Actions
          </TH>
        </tr>
      </thead>
      <tbody>
        {depotList.map(depot => (
          <TR key={depot.id}>
            {depotTableColumns.map(col => (
              <TD key={col.field}>{(depot as unknown as Record<string, string>)[col.field] || "_"}</TD>
            ))}
            <DepotTableActions depot={depot} />
          </TR>
        ))}
      </tbody>
    </StyledTable>
  );
};
