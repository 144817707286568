import { driverBehaviorOptions, DriverKeys } from "@evr/constant";
import { SearchFieldKeys, TableColumn } from "@evr/types";

export const driverTableColumns: TableColumn<DriverKeys>[] = [
  {
    name: "First Name",
    field: DriverKeys.FIRST_NAME,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Last Name",
    field: DriverKeys.LAST_NAME,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Email",
    field: DriverKeys.EMAIL,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Behavior",
    field: DriverKeys.ASSERTIVENESS,
    sortable: true,
    search: { type: SearchFieldKeys.SELECT, options: driverBehaviorOptions },
  },
  {
    name: "Start Time",
    field: DriverKeys.START_TIME,
  },
  {
    name: "End Time",
    field: DriverKeys.END_TIME,
  },
  {
    name: "Lunch period start",
    field: DriverKeys.LUNCH_START_TIME,
  },
  {
    name: "Lunch period end",
    field: DriverKeys.LUNCH_END_TIME,
  },
  {
    name: "Working Hours",
    field: DriverKeys.TOTAL_WORKING_HOURS,
  },
  {
    name: "Created Date",
    field: DriverKeys.CREATED_DATE,
  },
];
