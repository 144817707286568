import { useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { BatteryBufferType } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const SettingBatteryBufferType = () => {
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [batteryBufferType, setBatteryBufferType] = useState(orgSettings.batteryBufferType);

  const isFreePlan = planType === PlanKeys.STARTER;
  const [editBatterBufferType, { isLoading }] = useEditSettingsMutation();

  const handlePercentage = () => {
    editBatterBufferType({ ...orgSettings, batteryBufferType: BatteryBufferType.PERCENTAGE });
    setBatteryBufferType(BatteryBufferType.PERCENTAGE);
  };
  const handleKm = () => {
    editBatterBufferType({ ...orgSettings, batteryBufferType: BatteryBufferType.KILOMETERS });
    setBatteryBufferType(BatteryBufferType.KILOMETERS);
  };
  const handleMiles = () => {
    editBatterBufferType({ ...orgSettings, batteryBufferType: BatteryBufferType.MILES });
    setBatteryBufferType(BatteryBufferType.MILES);
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography> Battery Buffer Type</Typography>
      <Wrapper flexGrow={1} />
      <Button
        variant={batteryBufferType === BatteryBufferType.PERCENTAGE ? "contained" : "outlined"}
        onClick={handlePercentage}
      >
        Percent
      </Button>
      <Button variant={batteryBufferType === BatteryBufferType.MILES ? "contained" : "outlined"} onClick={handleMiles}>
        Miles
      </Button>
      <Button
        variant={batteryBufferType === BatteryBufferType.KILOMETERS ? "contained" : "outlined"}
        onClick={handleKm}
      >
        KM
      </Button>
    </RowContainer>
  );
};
