import { useState } from "react";

import { FeatureLike } from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { PopoverBody } from "react-bootstrap";

import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { PopupOverlay } from "@evr/components/ReactOL/overlays";
import { MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";

const ParcelDepotOverlay = () => {
  const [show, setShow] = useState(false);

  const depot = useAppSelector(selectCurrentDepot);

  const onClick = (features: FeatureLike[]) => {
    if (features.length) {
      setShow(!show);
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <MapClick layerNames={[MapLayerNames.PARCEL_DEPOT]} onClick={onClick} />
      <PopupOverlay
        offset={[0, -40]}
        coordinate={show ? fromLonLat(depot?.point.coordinates ?? [0, 0]) : undefined}
        placement="top"
      >
        <PopoverBody className="p-0" style={{ borderRadius: 7 }}>
          <div className="p-2 text-nowrap text-left">
            <p className="my-0">
              <strong>Name: </strong> {depot?.name}{" "}
            </p>
            <p className="my-0">
              <strong>Latitude:</strong> {depot?.point.coordinates[1].toFixed(5)}{" "}
            </p>
            <p className="my-0">
              <strong>Longitude: </strong> {depot?.point.coordinates[0].toFixed(5)}{" "}
            </p>
          </div>
        </PopoverBody>
      </PopupOverlay>
    </>
  );
};

export default ParcelDepotOverlay;
