import { Extent as olExtent } from "ol/extent";
import { ObjectEvent } from "ol/Object";

import View, { ViewProps } from "./View";

export interface ConstrainedViewProps extends ViewProps {
  constrainToExtent?: olExtent;
}

class ConstrainedView extends View {
  private _constrainToExtent?: olExtent;

  constructor({
    zoom,
    center,
    projection,
    minZoom,
    maxZoom,
    extent,
    onZoomChange,
    constrainToExtent,
  }: ConstrainedViewProps) {
    super({
      zoom,
      center,
      projection,
      minZoom,
      maxZoom,
      extent,
      onZoomChange,
    });
    this.ConstrainToExtent = constrainToExtent;
    this.on("change:center", this.handleViewContstraint);
  }

  get ConstrainToExtent(): olExtent | undefined {
    return this._constrainToExtent;
  }

  set ConstrainToExtent(constrainToExtent: olExtent | undefined) {
    this._constrainToExtent = constrainToExtent;
  }

  handleViewContstraint = (event: ObjectEvent) => {
    const center = super.getCenter();
    if (center && this.ConstrainToExtent) {
      let newX = center[0];
      let newY = center[1];

      if (center[0] < this.ConstrainToExtent[0]) {
        newX = this.ConstrainToExtent[0];
      } else if (center[0] > this.ConstrainToExtent[2]) {
        newX = this.ConstrainToExtent[2];
      }

      if (center[1] < this.ConstrainToExtent[1]) {
        newY = this.ConstrainToExtent[1];
      } else if (center[1] > this.ConstrainToExtent[3]) {
        newY = this.ConstrainToExtent[3];
      }
      if (newX !== center[0] || newY !== center[1]) {
        super.setCenter([newX, newY]);
        event.defaultPrevented = true;
        event.stopPropagation();
      }
    }
  };
}

export default ConstrainedView;
