import { useEffect, useRef, useState } from "react";

import { IconChevronDown, IconCurrentLocation, IconMap2, IconPencil, IconPlus, IconTrash } from "@tabler/icons";
import styled from "styled-components";

import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import { selectParcelPointToItem, setJobLocationCoordinates } from "@evr/store/slices/parcel";
import { JobLocation, Location } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { StyledIconButton } from "@evr/ui/IconButton/styles";
import { Typography } from "@evr/ui/Typography";

const ItemContainer = styled(ColumnContainer)`
  width: 100%;
  justify-content: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.divider};
  margin-top: 0.25rem;
  &:last-child {
    border-bottom: none;
  }
`;

interface ParcelSidebarItemProps {
  jobLocation: JobLocation;
  description: string;
  index: number;
}

export const ParcelSidebarItem = ({ jobLocation, description, index }: ParcelSidebarItemProps) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const pointToItem = useAppSelector(selectParcelPointToItem);

  const jobItemRef = useRef<HTMLDivElement>(null!);
  const prevParcelIndex = useRef<number>(pointToItem.index);

  useEffect(() => {
    const isSelected = pointToItem.index === index;
    setOpenActions(isSelected);
    setSelected(isSelected);

    const parent = document.getElementById("rightSidebar-scroller");
    if (isSelected && parent) {
      // setting 45 for parent.offsetTop and 45 more for Collapsible item
      const offset = prevParcelIndex.current < pointToItem.index ? 90 : 45;
      parent.scrollTop = jobItemRef.current.offsetTop - offset;
    }
    prevParcelIndex.current = pointToItem.index;
  }, [index, pointToItem]);

  const handleOpenActions = () => {
    if (!openActions) {
      handleShowParcel();
    } else {
      setSelected(false);
    }
    setOpenActions(!openActions);
  };

  const handleShowParcel = () => {
    dispatch(setJobLocationCoordinates({ jobLocation, description, index }));
  };

  const handleEditLocationParcel = () => {
    dispatch(setJobLocationCoordinates({ jobLocation, description, index, editable: true }));
  };

  const handleOpenDeleteLocationDialog = () => {
    dispatch(openDialog({ type: Dialogs.DELETE_JOB_LOCATION, data: description }));
  };

  const handleAddnewJob = () => {
    dispatch(openDialog({ type: Dialogs.ADD_JOB_LOCATION, data: { description, isEditForm: false } }));
  };

  const handleOpenEditJobLocationDialog = () => {
    dispatch(openDialog({ type: Dialogs.EDIT_JOB_LOCATION, data: { description, isEditForm: true } }));
  };

  return (
    <ItemContainer
      ref={jobItemRef}
      background={selected ? "#18a8f342" : undefined}
      borderRadius={selected ? "15px" : undefined}
    >
      <Flex width="100%" justify="space-between" cursor="pointer" onClick={handleOpenActions}>
        <Typography title={description} width="85%" overflowEllipsis textAlign="left">
          {index + 1} - {description}
        </Typography>
        <StyledIconButton
          color="greyDefault"
          hoverColor="infoDark"
          transform={`rotate(${openActions ? 180 : 0}deg)`}
          transition="transform 0.3s"
        >
          <IconChevronDown />
        </StyledIconButton>
      </Flex>
      <Collapsible open={openActions} width="100%" justify="space-around">
        <StyledIconButton title="Add a new job" hoverColor="successDark" onClick={handleAddnewJob}>
          <IconPlus />
        </StyledIconButton>
        <StyledIconButton title="Edit details" hoverColor="info" onClick={handleOpenEditJobLocationDialog}>
          <IconPencil />
        </StyledIconButton>
        <StyledIconButton title="Edit location" hoverColor="success" onClick={handleEditLocationParcel}>
          <IconMap2 />
        </StyledIconButton>
        <StyledIconButton title="Delete location" hoverColor="error" onClick={handleOpenDeleteLocationDialog}>
          <IconTrash />
        </StyledIconButton>
        <StyledIconButton title="Show on map" hoverColor="warning" onClick={handleShowParcel}>
          <IconCurrentLocation />
        </StyledIconButton>
      </Collapsible>
    </ItemContainer>
  );
};
