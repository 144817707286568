import styled, { css } from "styled-components";
interface PropsType {
  width?: string;
  maxWidth?: string;
  minWidth?: string;

  height?: string;
  maxHeight?: string;
  minHeight?: string;

  top?: string;
  right?: string;
  bottom?: string;
  left?: string;

  zIndex?: number;
  shadow?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}
export const MenuContainer = styled.ul.attrs({
  "aria-label": "menuContainer",
})<PropsType>`
  position: absolute;
  overflow-y: auto;
  margin: 0;
  padding: 0.5rem 0;

  background-color: ${props => props.theme.colors.common.white};
  border-radius: ${props => props.theme.borderRadius};

  width: ${props => (props.width ? props.width : "auto")};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${props => props.minWidth && `min-width: ${props.minWidth}`};

  ${props => props.height && `height: ${props.height}`};
  ${props => props.maxHeight && `max-height: ${props.maxHeight}`};
  ${props => props.minHeight && `min-height: ${props.minHeight}`};

  top: ${props => (props.top ? props.top : "100%")};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};

  z-index: ${props => (props.zIndex ? props.zIndex : props.theme.zIndex.menu)};
  box-shadow: ${props => (props.shadow ? props.theme.shadows[props.shadow] : props.theme.shadows[4])};
`;

const ItemCSS = css<{ padding?: string }>`
  display: block;
  padding: ${props => props.padding || "0.5rem"};
  font-size: 1rem;
  color: ${props => props.theme.colors.common.black};
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background: ${props => props.theme.colors.gradient.main};
    color: ${props => props.theme.colors.gradient.contrastText};
  }
`;
export const MenuItem = styled.li`
  ${ItemCSS}
`;

export const MenuItemAnchor = styled.a.attrs(props => ({
  target: props.target || "_blank",
}))`
  text-decoration: none;
  ${ItemCSS}
`;
