import { ActiveLayerTypes } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { getAllLiveJourneyAsync } from "@evr/store/slices/all-live-journeys-result/asyncActions";
import { setActiveLayer } from "@evr/store/slices/map";
import { selectedScheduledJourney } from "@evr/store/slices/scheduled-journey-results";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { Button } from "@evr/ui/Button";
import { Flex } from "@evr/ui/FlexBox";

import InProgressResultCard from "./InProgressResultCard";
import RoutesDropdown from "./RoutesDropdown";

const InProgressSidebar = () => {
  const selectedJourney = useAppSelector(selectedScheduledJourney);
  const open = useAppSelector(selectSidebarOpenStatus);
  const dispatch = useAppDispatch();
  const api = useApi(getAllLiveJourneyAsync);

  const handleBackToAllJourneys = async () => {
    await api.sendRequest().then(() => {
      dispatch(setActiveLayer(ActiveLayerTypes.ALL_JOURNEYS));
    });
  };

  return (
    <>
      {selectedJourney && open && (
        <>
          <InProgressResultCard journey={selectedJourney} />
          <RoutesDropdown scheduledJourney={selectedJourney} />
          <Flex padding=".2rem" direction="column" justify="flex-end" align="flex-end" flexGrow={1} width="100%">
            <Button onClick={handleBackToAllJourneys}>Back to all journeys</Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default InProgressSidebar;
