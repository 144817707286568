import { MapColorKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { changeMapColor, selectSettingState } from "@evr/store/slices/setting";
import { Button } from "@evr/ui/Button";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const SettingMapColor = () => {
  const dispatch = useAppDispatch();
  const { mapColor } = useAppSelector(selectSettingState);

  const handleSetGreyscaleMap = () => dispatch(changeMapColor(MapColorKeys.GREYSCALE));
  const handleSetColouredMap = () => dispatch(changeMapColor(MapColorKeys.COLOURED));

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography> Background maps</Typography>
      <Wrapper flexGrow={1} />
      <Button variant={mapColor === MapColorKeys.GREYSCALE ? "contained" : "outlined"} onClick={handleSetGreyscaleMap}>
        Greyscale
      </Button>
      <Button variant={mapColor === MapColorKeys.COLOURED ? "contained" : "outlined"} onClick={handleSetColouredMap}>
        Coloured
      </Button>
    </RowContainer>
  );
};
