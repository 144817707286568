import { DownloadResultMenu } from "@evr/components/DownloadResultMenu";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { selectCalculationId } from "@evr/store/slices/result";
import {
  getCalculationCsvAsync,
  getCalculationDataAsync,
  getCalculationGpxAsync,
} from "@evr/store/slices/result/asyncActions";
import { downloadFile } from "@evr/utils";

export const ResultHeaderDownload = () => {
  const calculationId = useAppSelector(selectCalculationId);

  const downloadBothApi = useApi(getCalculationDataAsync);
  const downloadGPXsApi = useApi(getCalculationGpxAsync);
  const downloadCSVsApi = useApi(getCalculationCsvAsync);

  const loading = downloadCSVsApi.loading || downloadGPXsApi.loading || downloadBothApi.loading;

  const downloadCSVs = async () => {
    const res = await downloadCSVsApi.sendRequest(calculationId);
    if (!res.apiErrors && res.response) {
      downloadFile(res.response as string, `Calculation${calculationId}_CSV.zip`, true);
    }
  };
  const downloadGPXs = async () => {
    const res = await downloadGPXsApi.sendRequest(calculationId);
    if (!res.apiErrors && res.response) {
      downloadFile(res.response as string, `Calculation${calculationId}_GPX.zip`, true);
    }
  };
  const downloadBoth = async () => {
    const res = await downloadBothApi.sendRequest(calculationId);
    if (!res.apiErrors && res.response) {
      downloadFile(res.response as string, `Calculation${calculationId}_GPX_CSV.zip`, true);
    }
  };

  return (
    <DownloadResultMenu
      color="white"
      loading={loading}
      menuPosition={{ right: "0" }}
      handleDownloadCSV={downloadCSVs}
      handleDownloadGPX={downloadGPXs}
      handleDownloadBoth={downloadBoth}
    />
  );
};
