import { memo, useMemo, useState } from "react";

import { FeatureLike } from "ol/Feature";
import styled from "styled-components";

import AddressJobsPopUp from "@evr/components/OlMap/MapModals/AddressJobs/AddressJobsPopUp";
import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { DriverProgressSnapshot, RoutedJobLocations } from "@evr/types";
import { DialogContainer } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";

interface ScheduledJourneyAddressJobsPopUpContainerProps {
  locations: RoutedJobLocations[];
  layerNames: string[];
  snapshots: DriverProgressSnapshot[];
}

const Layout = styled(Flex)`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: ${props => props.theme.zIndex.modal};
  transition: opacity 0.1s ease-out;
  ${props => props.opacity && `opacity: ${props.opacity};`}
`;

const ScheduledJourneyAddressJobsPopUpContainer = ({ locations, layerNames, snapshots }: ScheduledJourneyAddressJobsPopUpContainerProps) => {
  const [selectedLocation, setSelectedLocation] = useState<{
    arrivalTime: string;
    location: RoutedJobLocations;
    latestSnapshot?: DriverProgressSnapshot;
  }>();

  const clickListener = useMemo(() => (e: FeatureLike[]) => onLocationClick(e, locations), [locations]);

  const onLocationClick = (e: FeatureLike[], locations: RoutedJobLocations[]) => {
    if (!e.length) {
      return;
    }
    const feature = e[0];
    const locationId = feature.get("id");
    const arrivalTimeString = feature.get("arrivalTime");
    let arrivalTime = new Date();

    let [hours, minutes] = arrivalTimeString.split(":").map(Number);
    arrivalTime.setHours(hours);
    arrivalTime.setMinutes(minutes);

    const location = locations.find(l => l.id === locationId);
    const snapshot = snapshots.find(snap => snap.locationId === locationId);
    if (!location) {
      return;
    }

    for (let i = 0; i < location.stopNumber; i++) {
      const prevLocation = locations[i];
      arrivalTime.setMinutes(arrivalTime.getMinutes() + (prevLocation.waitingTime ?? 0)); // Add the waiting time of the previous location to the arrival time
    }
    hours = arrivalTime.getHours().toString().padStart(2, "0");
    minutes = arrivalTime.getMinutes().toString().padStart(2, "0");
    const arrivalTimeStringUpdated = `${hours}:${minutes}`;

    setSelectedLocation({ location: location, arrivalTime: arrivalTimeStringUpdated, latestSnapshot: snapshot });
  };

  return (
    <>
      <MapClick layerNames={layerNames} onClick={clickListener} />
      {selectedLocation && (
        <Layout opacity={1}>
          <DialogContainer>
            <AddressJobsPopUp
              onClose={() => setSelectedLocation(undefined)}
              start={selectedLocation.arrivalTime}
              location={selectedLocation.location}
              snapshot={selectedLocation.latestSnapshot}
            />
          </DialogContainer>
        </Layout>
      )}
    </>
  );
};

export default ScheduledJourneyAddressJobsPopUpContainer;
