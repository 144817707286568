import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

import { theme } from "@evr/theme";

interface PropsType {
  data: Record<string, unknown>[];
  color?: string;
  dataKey?: string;
  xAxisDataKey?: string;
}
export const SavingsLineGraph = ({ data, color = "#8884d8", dataKey = "value", xAxisDataKey = "name" }: PropsType) => (
  <ResponsiveContainer width="99%" height={300}>
    <LineChart data={data} margin={{ top: 5, right: 30, left: -10, bottom: 5 }}>
      <CartesianGrid stroke={theme.colors.grey.default} strokeDasharray="5 5" />
      <XAxis dataKey={xAxisDataKey} />
      <YAxis />
      <Tooltip isAnimationActive={false} />
      <Legend />
      <Line type="monotone" dataKey={dataKey} stroke={color} activeDot={{ r: 8 }} />
    </LineChart>
  </ResponsiveContainer>
);
