import { WindowKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectWindowType } from "@evr/store/slices/window";
import { theme } from "@evr/theme";
import { Flex } from "@evr/ui/FlexBox";

import { CompletedWindow } from "./Completed";
import { ScheduledJourneysWindowHeader } from "./ScheduledJourneysWindowHeader";
import { ScheduledJourneyWindowContainer } from "./ScheduledJourneyWindowCountainer";


export const ScheduledJourneysWindow = () => {
    const windowType = useAppSelector(selectWindowType);
    if(windowType !== WindowKeys.JOURNEY_SCHEDULED && windowType !== WindowKeys.JOURNEY_INPROGRESS && windowType !== WindowKeys.JOURNEY_COMPLETED && windowType !== WindowKeys.JOURNEY_FAILED) {
        return null;
    }

    return(
        <Flex position="absolute" align="flex-start" inset="0" zIndex={theme.zIndex.window}>         
            <ScheduledJourneyWindowContainer>
            <ScheduledJourneysWindowHeader />  
                    <CompletedWindow />
            </ScheduledJourneyWindowContainer>
        </Flex>
    );
};