import styled from "styled-components";

export const SavingsEnergyDialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  height: 400px;
  #chartContainer {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    height: auto;
    #chartContainer {
      width: 100%;
    }
  }
`;

export const SavingsEnergyButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-start;
  height: 300px;
  width: 150px;
  > button {
    width: 130px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-flow: row wrap;
    height: auto;
    width: 100%;
    margin-bottom: 1.5rem;
    > button {
      flex: 0 1 130px;
    }
  }
`;
