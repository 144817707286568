import { JournyColorObject } from "@evr/types";

export const JourneysInitialColor: JournyColorObject = {
  "1": { main: "#ff0000", contrast: "#fff" },
  "2": { main: "#00ff00", contrast: "#000" },
  "3": { main: "#0000ff", contrast: "#fff" },
  "4": { main: "#5ea1e8", contrast: "#000" },
  "5": { main: "#23bc3a", contrast: "#fff" },
  "6": { main: "#d9e435", contrast: "#000" },
  "7": { main: "#ae0af4", contrast: "#fff" },
  "8": { main: "#e89a5e", contrast: "#000" },
  "9": { main: "#e85e9e", contrast: "#000" },
  "10": { main: "#95e85e", contrast: "#000" },
  unroutables: { main: "#808080", contrast: "#fff" },
};
