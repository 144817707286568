import { memo, useMemo, useState } from "react";

import { FeatureLike } from "ol/Feature";
import styled from "styled-components";

import AddressJobsPopUp from "@evr/components/OlMap/MapModals/AddressJobs/AddressJobsPopUp";
import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { DriverProgressSnapshot, RoutedJobLocations, Result } from "@evr/types";
import { DialogContainer } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";

interface ResultJourneyAddressJobsPopUpContainerProps {
  result: Result;
  layerNames: string[];
  snapshots: DriverProgressSnapshot[];
}

const Layout = styled(Flex)`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: ${props => props.theme.zIndex.modal};
  transition: opacity 0.1s ease-out;
  ${props => props.opacity && `opacity: ${props.opacity};`}
`;

const ResultJourneyAddressJobsPopUpContainer = ({ result, layerNames, snapshots }: ResultJourneyAddressJobsPopUpContainerProps) => {
  const [selectedLocation, setSelectedLocation] = useState<{
    arrivalTime: string;
    location: RoutedJobLocations;
    latestSnapshot?: DriverProgressSnapshot;
  }>();

  const clickListener = useMemo(() => (e: FeatureLike[]) => onLocationClick(e, result), [result]);

  const onLocationClick = (e: FeatureLike[], result: Result) => {
    if (!e.length) {
      return;
    }

    const feature = e[0];
    const locationId = feature.get("id");
    const arrivalTimeString = feature.get("arrivalTime");
    let arrivalTime = new Date();

    let [hours, minutes] = arrivalTimeString.split(":").map(Number);
    arrivalTime.setHours(hours);
    arrivalTime.setMinutes(minutes);
    const journey = result.journeys.find(j => j.locations.some(l => l.id === locationId));

    if (!journey) {
      console.warn("Journey not found for location:", locationId);
      return;
    }

    const location = journey.locations.find(l => l.id === locationId);
    const snapshot = snapshots.find(snap => snap.locationId === locationId);

    if (!location) {
      return;
    }

    for (let i = 0; i < location.stopNumber; i++) {
      const prevLocation = journey.locations[i];
      arrivalTime.setMinutes(arrivalTime.getMinutes() + (prevLocation.waitingTime ?? 0)); // Add the waiting time of the previous location to the arrival time
    }

    hours = arrivalTime.getHours().toString().padStart(2, "0");
    minutes = arrivalTime.getMinutes().toString().padStart(2, "0");
    const arrivalTimeStringUpdated = `${hours}:${minutes}`;

    setSelectedLocation({ location: location, arrivalTime: arrivalTimeStringUpdated, latestSnapshot: snapshot });
  };

  return (
    <>
      <MapClick layerNames={layerNames} onClick={clickListener} />
      {selectedLocation && (
        <Layout opacity={1}>
          <DialogContainer>
            <AddressJobsPopUp
              onClose={() => setSelectedLocation(undefined)}
              start={selectedLocation.arrivalTime}
              location={selectedLocation.location}
              snapshot={selectedLocation.latestSnapshot}
            />
          </DialogContainer>
        </Layout>
      )}
    </>
  );
};

export default ResultJourneyAddressJobsPopUpContainer;
