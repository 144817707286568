import styled from "styled-components";

import { ColumnContainer } from "@evr/ui/FlexBox";

export const DialogContainer = styled(ColumnContainer)`
  margin: 1rem;
  padding: ${props => (props.padding ? props.padding : "0 0 0 0")};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "600px")};
  min-width: ${props => (props.minWidth ? props.minWidth : "500px")};
  max-height: calc(80% - 1rem);
  overflow: hidden;
  background-color: ${props => props.theme.colors.common.white};
  box-shadow: ${props => props.theme.shadows[5]};
  border-radius: ${props => props.theme.borderRadius};
  @media (max-width: ${props => (props.maxWidth ? props.maxWidth : props.theme.breakpoints.sm)}) {
    min-width: 95%;
    max-height: calc(100% - 1rem);
  }
`;
