import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";

import { SavingsEnergyCostVsSavingsKeys } from "@evr/constant";
import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface PropsType {
  data: Record<string, unknown>[];
  dataKey?: string;
  colors: string[];
}

export const SavingsPieGraph = ({ data, colors, dataKey = "value" }: PropsType) => {
  const cost = data[0].value as number;
  const savings = data[1].value as number;
  const savingsPercent = Math.round(((savings * 100) / cost) * 100) / 100;
  const endAngel = Math.round(((savings * 360) / cost) * 100) / 100;

  const CustomTooltip = ({ ...props }) => {
    const { active, payload } = props as { active: boolean; payload: Record<string, unknown>[] };
    if (active && payload.length) {
      const { name, value } = payload[0];
      const percent = name === SavingsEnergyCostVsSavingsKeys.SAVINGS ? `(${savingsPercent}%)` : "";
      return (
        <Flex rounded padding="0.5rem 1rem" background="white" shadow={5} maxWidth="180px">
          <Typography>{`${name} : ${value} ${percent}`}</Typography>
        </Flex>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="99.9%" height={300}>
      <PieChart>
        <Pie
          data={data.slice(0, 1)}
          cx="50%"
          cy="50%"
          animationDuration={800}
          label
          outerRadius={80}
          dataKey={dataKey}
          fill={colors[0]}
          stroke={colors[0]}
        />
        <Pie
          data={data.slice(1)}
          cx="50%"
          cy="50%"
          animationDuration={800}
          label
          outerRadius={70}
          startAngle={-90}
          endAngle={endAngel - 90}
          dataKey={dataKey}
          fill={colors[1]}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
