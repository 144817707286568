import { Divider } from "@evr/ui/Divider";
import { ColumnContainer } from "@evr/ui/FlexBox";

export const TermsConditions = (
  <>
    <div>
      <br />
    </div>
    <ColumnContainer width="100%">
      <strong>EVROUTING</strong>
    </ColumnContainer>
    <div>
      <br />
    </div>
    <ColumnContainer width="100%">
      <strong> Terms and Conditions of Supply</strong>
    </ColumnContainer>
    <div>
      <br />
    </div>
    <div>Supplier</div>
    <Divider direction="h" />
    <div>
      <br />
    </div>
    <div>Basemap Ltd</div>
    <div>
      <br />
    </div>
    <div>Registered Office Address:</div>
    <div>Surrey Technology Centre</div>
    <div>40 Occam Road</div>
    <div>GUILDFORD</div>
    <div>Surey GU2 7YG</div>
    <div>
      <br />
    </div>
    <div>Company No: 04121166</div>
    <Divider direction="h" />
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>BACKGROUND</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      (A) The Supplier has developed certain software applications which recommends routes for delivery of packages and
      makes the resulting reports available to customers as a service, either accessible on-line, or through permissive
      downloading of certain selected data.
    </div>
    <div>
      <br />
    </div>
    <div>
      (B) The Customer wishes to use the Supplier's services and be permitted to download certain selected data.
    </div>
    <div>
      <br />
    </div>
    <div>
      (C) The Supplier has agreed to provide and the Customer has agreed to take and pay for the Supplier's services
      subject to the terms and conditions of this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>BY USING THIS PRODUCT YOU AGREE TO THESE TERMS AND CONDITIONS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>1. INTERPRETATION</strong>
    </div>
    <div>
      <br />
    </div>
    <div>1.1 The definitions and rules of interpretation in this clause apply in this agreement.</div>
    <div>
      Authorised Users: those employees, agents and independent contractors of the Customer who are authorised by the
      Customer to use the Services and the Data, as further described in clause 2.3(d).
    </div>
    <div>
      Business Day: a day other than a Saturday, Sunday or public holiday in England when banks in London are open for
      business.
    </div>
    <div></div>
    <div>
      <strong>Change of Control:</strong>
      shall be as defined in section 1124 of the Corporation Tax Act 2010, and the expression change of control shall be
      construed accordingly and controls, controlled and the expression change of control shall be construed
      accordingly.
    </div>
    <div>
      <strong>Confidential Information:</strong>
      information that is proprietary or confidential and is either clearly labelled as such or identified as
      Confidential Information in clause 11.5.
    </div>
    <div>
      <strong>Customer Data:</strong>
      any data inputted by the Customer, Authorised Users, or the Supplier on the Customer's behalf for the purpose of
      using the Services or facilitating the Customer's use of the Services.
    </div>
    <div>
      <strong>Data:</strong>
      the certain selected data sets made available to the Customer by the Supplier as a result of the Services.
    </div>
    <div>
      <strong>Effective Date: </strong>
      the date of this agreement.
    </div>
    <div>
      <strong>Initial Subscription Term:</strong>
      the initial term of this agreement as set out in the supplierâ€™s quotation.
    </div>
    <div>
      <strong>Normal Business Hours:</strong>
      9.00 am to 5.30 pm local UK time, each Business Day.
    </div>
    <div>
      <strong>Proposal:</strong>
      the information and content of documents provided to the Customer by the Supplier that set out a description of
      the Services and the user instructions for the Services.
    </div>
    <div>
      <strong>Renewal Period:</strong>
      the period described in clause 14.1.
    </div>
    <div>
      <strong>Services:</strong>
      the services provided by the Supplier to the Customer under this agreement as more particularly described in the
      Proposal.
    </div>
    <div>
      <strong>Software:</strong>
      the online software applications provided by the Supplier as part of the Services.
    </div>
    <div>
      <strong>Subscription Fees:</strong>
      the subscription fees payable by the Customer to the Supplier for the User Subscriptions, as set out in the
      Supplierâ€™s quotations and invoices
    </div>
    <div>
      <strong>Subscription Term:</strong>
      has the meaning given in clause 14.1 (being the Initial Subscription Term together with any subsequent Renewal
      Periods).
    </div>
    <div>
      <strong>Support Services Policy:</strong>
      the Supplier's policy for providing support in relation to the Services details of which can be made available on
      request.
    </div>
    <div>
      <strong>Third Party Data:</strong>
      any data inputted by the Supplier or any third party for the Customer's benefit to enable the Customer to use the
      Services in accordance with the Proposal.
    </div>
    <div>
      <strong>User Subscriptions: </strong>
      the user subscriptions purchased by the Customer pursuant to clause 9.1 which entitle Authorised Users to access
      and use the Services and the Data in accordance with this agreement.
    </div>
    <div>
      <strong>Virus:</strong>
      any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise
      adversely affect the operation of any computer software, hardware or network, any telecommunications service,
      equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or
      the operation of any programme or data, including the reliability of any programme or data (whether by
      re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the
      user experience, including worms, trojan horses, viruses and other similar things or devices.
    </div>
    <div>
      <br />
    </div>
    <div>
      1.2 A reference to a company shall include any company, corporation or other body corporate, wherever and however
      incorporated or established.
    </div>
    <div>
      <br />
    </div>
    <div>
      1.3 A reference to a statute or statutory provision is a reference to it as it is in force as at the date of this
      agreement and shall include all subordinate legislation made as at the date of this agreement under that statute
      or statutory provision.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>2. USER SUBSCRIPTIONS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>2.1 Subject to the Customer purchasing the User Subscriptions in accordance with clause</div>
    <div>
      3.2 and clause 9.1, the restrictions set out in this clause 2 and the other terms and conditions of this
      agreement, the Supplier hereby grants to the Customer a non- exclusive, non-transferable right to permit the
      Authorised Users to use the Services during the Subscription Term in accordance with the Proposal.
    </div>
    <div>
      <br />
    </div>
    <div>2.2 Under certain circumstances the Customer may download Data, as provided for in the Proposal.</div>
    <div>
      <br />
    </div>
    <div>2.3 In relation to the Authorised Users, the Customer undertakes that:</div>
    <div>
      (a) the maximum number of Authorised Users that it authorises to access and use the Services and the Data shall
      not exceed the number of User Subscriptions it has purchased from time to time;
    </div>
    <div>
      (b) it will not allow or suffer any User Subscription to be used by more than one individual Authorised User
      unless it has been reassigned in its entirety to another individual Authorised User, in which case the prior
      Authorised User shall no longer have any right to access or use the Services and/or Data;
    </div>
    <div>
      (c) each Authorised User shall keep a secure password for his use of the Services, that such password shall be
      changed frequently and that each Authorised User shall keep his password confidential;
    </div>
    <div>
      (d) it shall maintain a written, up to date list of current Authorised Users and provide such list to the Supplier
      within 5 Business Days of the Supplier's written request at any time or times;
    </div>
    <div>
      (e) it shall permit the Supplier to audit the Services in order to establish the name and password of each
      Authorised User. Such audit may be conducted no more than once per calendar year, at the Supplier's expense, and
      this right shall be exercised with reasonable prior notice, in such a manner as not to substantially interfere
      with the Customer's normal conduct of business;
    </div>
    <div></div>
    <div>
      (f) if any of the audits referred to in clause 2.3(e) reveal that any password has been provided to any individual
      who is not an Authorised User, then without prejudice to the Supplier's other rights, the Customer shall promptly
      disable such passwords and the Supplier shall not issue any new passwords to any such individual; and
    </div>
    <div>
      (g) if any of the audits referred to in clause 2.3(e) reveal that the Customer has underpaid Subscription Fees to
      the Supplier, then without prejudice to the Supplier's other rights, the Customer shall pay to the Supplier an
      amount equal to such underpayment as calculated in accordance with the Supplierâ€™s standard prices within 10
      Business Days of the date of the relevant audit.
    </div>
    <div>
      <br />
    </div>
    <div>
      2.4 The Customer shall not access, store, distribute or transmit any Viruses, or any material during the course of
      its use of the Services that:
    </div>
    <div>
      (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically
      offensive;
    </div>
    <div>(b) facilitates illegal activity;</div>
    <div>(c) depicts sexually explicit images;</div>
    <div>(d) promotes unlawful violence;</div>
    <div>(e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or</div>
    <div>
      (f) in a manner that is otherwise illegal or causes damage or injury to any person or property; and the Supplier
      reserves the right, without liability or prejudice to its other rights to the Customer, to disable the Customer's
      access to any material that breaches the provisions of this clause.
    </div>
    <div>
      <br />
    </div>
    <div>2.5 The Customer shall not:</div>
    <div>
      (a) except as may be allowed by any applicable law which is incapable of exclusion by agreement between the
      parties:
    </div>
    <div>
      (i) and except to the extent expressly permitted under this agreement, attempt to copy, modify, duplicate, create
      derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of
      the Software and/or Data (as applicable) in any form or media or by any means; or
    </div>
    <div>
      (ii) attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all
      or any part of the Software; or
    </div>
    <div>
      (b) access all or any part of the Services and Data in order to build a product or service which competes with the
      Services and/or the Data; or
    </div>
    <div>(c) use the Services and/or Data to provide services to third parties; or</div>
    <div>
      (d) subject to clause 22.1, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or
      otherwise commercially exploit, or otherwise make the Services available to any third party except the Authorised
      Users, or
    </div>
    <div>
      (e) attempt to obtain, or assist third parties in obtaining, access to the Services and/or Data, other than as
      provided under this clause 2; and
    </div>
    <div>
      <br />
    </div>
    <div>
      2.6 The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the
      Services and, in the event of any such unauthorised access or use, promptly notify the Supplier.
    </div>
    <div>
      <br />
    </div>
    <div>
      2.7 The rights provided under this clause 2 are granted to the Customer only, and shall not be considered granted
      to any subsidiary or holding company of the Customer.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>3. ADDITIONAL USER SUBSCRIPTIONS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      3.1 Subject to clause 3.2, the Customer may, from time to time during any Subscription Term, purchase additional
      User Subscriptions in excess of the number set out in the Supplierâ€™s quotation and the Supplier shall grant
      access to the Services and the Data to such additional Authorised Users in accordance with the provisions of this
      agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      3.2 The Customer shall pay to the Supplier the relevant fees for such additional User Subscriptions as set out in
      the Supplierâ€™s quotation and invoice and, if such additional User Subscriptions are purchased by the Customer
      part way through the Initial Subscription Term or any Renewal Period (as applicable), such fees shall be pro-rated
      for the remainder of the Initial Subscription Term or then current Renewal Period (as applicable).
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>4. SERVICES</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      4.1 The Supplier shall, during the Subscription Term, provide the Services to the Customer on and subject to the
      terms of this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      4.2 The Supplier shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven
      days a week, except for:
    </div>
    <div>(a) planned maintenance; and</div>
    <div>(b) unscheduled maintenance performed outside Normal Business Hours,</div>
    <div>
      the Supplier will use reasonable endeavours to give the Customer at least 6 Normal Business Hours' notice in
      advance of any maintenance.
    </div>
    <div></div>
    <div>
      4.3 The Supplier will, as part of the Services and at no additional cost to the Customer, provide the Customer
      with the Supplier's standard customer support services during Normal Business Hours in accordance with the
      Supplier's Support Services Policy in effect at the time that the Services are provided. The Supplier may amend
      the Support Services Policy in its sole and absolute discretion from time to time. The Customer may purchase
      enhanced support services separately at the Supplier's then current rates.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>5. CUSTOMER DATA</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      5.1 The Customer shall own all right, title and interest in and to any Customer Data and shall have sole
      responsibility for the legality, reliability, integrity, accuracy and quality of Customer Data.
    </div>
    <div>
      <br />
    </div>
    <div>
      5.2 The Supplier shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data
      however caused.
    </div>
    <div>
      <br />
    </div>
    <div>
      5.3 The Supplier shall, in providing the Services, comply with its published Security Policy relating to the
      security of Customer Data.
    </div>
    <div>
      <br />
    </div>
    <div>
      5.4 If the Supplier processes any personal data on the Customer's behalf when performing its obligations under
      this agreement, the parties record their intention that the Customer shall be the data controller and the Supplier
      shall be a data processor and in any such case:
    </div>
    <div>
      (a) the Customer acknowledges and agrees that the personal data may be transferred or stored outside the EEA or
      the country where the Customer and the Authorised Users are located in order to carry out the Services and the
      Supplier's other obligations under this agreement;
    </div>
    <div>
      (b) the Customer shall ensure that the Customer is entitled to transfer the relevant personal data to the Supplier
      so that the Supplier may lawfully use, process and transfer the personal data in accordance with this agreement on
      the Customer's behalf;
    </div>
    <div>
      (c) the Customer shall ensure that the relevant third parties have been informed of, and have given their consent
      to, such use, processing, and transfer as required by all applicable data protection legislation; and
    </div>
    <div>
      (d) each party shall take appropriate technical and organisational measures against unauthorised or unlawful
      processing of the personal data or its accidental loss, destruction or damage.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>6. THIRD PARTY DATA PROVIDERS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      6.1 The Supplier makes no representation or commitment and shall have no liability or obligation whatsoever in
      relation to the Third Party Data nor shall it have any liability
    </div>
    <div></div>
    <div>or obligation whatsoever in relation to any uses to which the Customer may put any such Third Party Data.</div>
    <div>
      <br />
    </div>
    <div>
      <strong>7. SUPPLIER'S OBLIGATIONS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      7.1 The Supplier undertakes that the Services will be performed substantially in accordance with the Proposal and
      with reasonable skill and care.
    </div>
    <div>
      <br />
    </div>
    <div>
      7.2 The undertaking at clause 7.1 shall not apply to the extent of any non-conformance which is caused by use of
      the Services contrary to the Supplier's instructions, or modification or alteration of the Services by any party
      other than the Supplier or the Supplier's duly authorised contractors or agents. If the Services do not conform
      with the foregoing undertaking, Supplier will, at its expense, use all reasonable commercial endeavours to correct
      any such non-conformance promptly, or provide the Customer with an alternative means of accomplishing the desired
      performance. Such correction or substitution constitutes the Customer's sole and exclusive remedy for any breach
      of the undertaking set out in clause 7.1. Notwithstanding the foregoing, the Supplier:
    </div>
    <div>
      (a) does not warrant that the Customer's use of the Services will be uninterrupted or error-free; or that the
      Services, and/or the information obtained by the Customer through the Services will meet the Customer's
      requirements; and
    </div>
    <div>
      (b) is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer
      of data over communications networks and facilities, including the internet, and the Customer acknowledges that
      the Services may be subject to limitations, delays and other problems inherent in the use of such communications
      facilities.
    </div>
    <div>
      <br />
    </div>
    <div>
      7.3 This agreement shall not prevent the Supplier from entering into similar agreements with third parties, or
      from independently developing, using, selling or licensing documentation, products and/or services which are
      similar to those provided under this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      7.4 The Supplier warrants that it has and will maintain all necessary licences, consents, and permissions
      necessary for the performance of its obligations under this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>8. CUSTOMER'S OBLIGATIONS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>The Customer shall:</div>
    <div>(a) provide the Supplier with:</div>
    <div>(i) all necessary co-operation in relation to this agreement; and</div>
    <div></div>
    <div>(ii) all necessary access to such information as may be required by the Supplier;</div>
    <div>to enable the Supplier to provide the Services;</div>
    <div>
      <br />
    </div>
    <div>(b) comply with all applicable laws and regulations with respect to its activities under this agreement;</div>
    <div>
      (c) carry out all other Customer responsibilities set out in this agreement in a timely and efficient manner. In
      the event of any delays in the Customer's provision of such assistance as agreed by the parties, the Supplier may
      adjust any agreed timetable or delivery schedule as reasonably necessary;
    </div>
    <div>
      (d) ensure that the Authorised Users use the Services in accordance with the terms and conditions of this
      agreement and shall be responsible for any Authorised User's breach of this agreement;
    </div>
    <div>
      (e) obtain and shall maintain all necessary licences, consents, and permissions necessary for the Supplier, its
      contractors and agents to perform their obligations under this agreement, including without limitation the
      Services;
    </div>
    <div>
      (f) ensure that its network and systems comply with the relevant specifications provided by the Supplier from time
      to time; and
    </div>
    <div>
      (g) be solely responsible for procuring and maintaining its network connections and telecommunications links from
      its systems to the Supplier's data centres, and all problems, conditions, delays, delivery failures and all other
      loss or damage arising from or relating to the Customer's network connections or telecommunications links or
      caused by the internet.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>9. CHARGES AND PAYMENT</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      9.1 The Customer shall pay any Subscription Fees to the Supplier for the User Subscriptions in accordance with
      this clause 9.
    </div>
    <div>
      <br />
    </div>
    <div>
      9.2 The Customer shall on the Effective Date provide to the Supplier valid, up-to-date and complete credit card
      details or approved purchase order information acceptable to the Supplier and any other relevant valid, up-to-date
      and complete contact and billing details and, if the Customer provides:
    </div>
    <div>
      (a) its credit card details to the Supplier, the Customer hereby authorises the Supplier to bill such credit card:
    </div>
    <div>
      (i) on the Effective Date for the Subscription Fees payable in respect of the Initial Subscription Term; and
    </div>
    <div>
      (ii) subject to clause 14.1, on each anniversary of the Effective Date for the Subscription Fees payable in
      respect of the next Renewal Period;
    </div>
    <div>(b) its approved purchase order information to the Supplier, the Supplier shall invoice the Customer:</div>
    <div></div>
    <div>
      (i) on the Effective Date for the Subscription Fees payable in respect of the Initial Subscription Term; and
    </div>
    <div>
      (ii) subject to clause 14.1, at least 30 days prior to each anniversary of the Effective Date for the Subscription
      Fees payable in respect of the next Renewal Period,
    </div>
    <div>and the Customer shall pay each invoice as provided for therein.</div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      9.3 If the Supplier has not received payment within 30 days after the due date, and without prejudice to any other
      rights and remedies of the Supplier:
    </div>
    <div>
      (a) the Supplier may, without liability to the Customer, disable the Customer's password, account and access to
      all or part of the Services and the Supplier shall be under no obligation to provide any or all of the Services
      while the invoice(s) concerned remain unpaid; and
    </div>
    <div>
      (b) interest shall accrue on a daily basis on such due amounts at an annual rate equal to 2% over the then current
      base lending rate of the Supplier's bankers in the UK from time to time, commencing on the due date and continuing
      until fully paid, whether before or after judgment.
    </div>
    <div>
      <br />
    </div>
    <div>9.4 All amounts and fees stated or referred to in this agreement:</div>
    <div>(a) shall be payable in pounds sterling;</div>
    <div>(b) are, subject to clause 13.4(b), non-cancellable and non-refundable;</div>
    <div>
      (c) are exclusive of value added tax, which shall be added to the Supplier's invoice(s) at the appropriate rate.
    </div>
    <div>
      <br />
    </div>
    <div>
      9.5 If, at any time whilst using the Services, the Customer exceeds the amount of disk storage space which may be
      specified in the Proposal, the Supplier shall charge the Customer, and the Customer shall pay, the Supplier's then
      current excess data storage fees..
    </div>
    <div>
      <br />
    </div>
    <div>
      9.6 The Supplier shall be entitled to increase the Subscription Fees, the fees payable in respect of the
      additional User Subscriptions purchased pursuant to clause 3.2 and/or the excess storage fees payable pursuant to
      clause 9.5 at the start of each Renewal Period upon 90 days' prior notice to the Customer.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>10. PROPRIETARY RIGHTS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      The Customer acknowledges and agrees that the Supplier and/or its licensors, and Third Party Data providers (as
      the case may be), own all intellectual property rights in the Data and that the Supplier owns all intellectual
      property rights in the Services. Except as expressly stated herein, this agreement does not grant the Customer any
      rights to, or in, patents, copyright, database right, trade secrets, trade names, trade
    </div>
    <div></div>
    <div>
      marks (whether registered or unregistered), or any other rights or licences in respect of the Services, the Data,
      or Third Party Data.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>11. CONFIDENTIALITY</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      11.1 Each party may be given access to Confidential Information from the other party in order to perform its
      obligations under this agreement. A party's Confidential Information shall not be deemed to include information
      that:
    </div>
    <div>(a) is or becomes publicly known other than through any act or omission of the receiving party;</div>
    <div>(b) was in the other party's lawful possession before the disclosure;</div>
    <div>(c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</div>
    <div>
      (d) is independently developed by the receiving party, which independent development can be shown by written
      evidence; or
    </div>
    <div>
      (e) is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or
      administrative body.
    </div>
    <div>
      <br />
    </div>
    <div>
      11.2 Each party shall hold the other's Confidential Information in confidence and, unless required by law, not
      make the other's Confidential Information available to any third party, or use the other's Confidential
      Information for any purpose other than the implementation of this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      11.3 Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it
      has access is not disclosed or distributed by its employees or agents in violation of the terms of this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      11.4 Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential
      Information caused by any third party.
    </div>
    <div>
      <br />
    </div>
    <div>
      11.5 The Customer acknowledges that details of the Services, and the results of any performance tests of the
      Services, constitute the Supplier's Confidential Information.
    </div>
    <div>
      <br />
    </div>
    <div>11.6 The Supplier acknowledges that the Customer Data is the Confidential Information of the Customer.</div>
    <div>
      <br />
    </div>
    <div>11.7 This clause 11 shall survive termination of this agreement, however arising.</div>
    <div>
      <br />
    </div>
    <div>
      11.8 No party shall make, or permit any person to make, any public announcement concerning this agreement without
      the prior written consent of the other parties (such consent not to be unreasonably withheld or delayed), except
      as required by law, any
    </div>
    <div></div>
    <div>
      governmental or regulatory authority (including, without limitation, any relevant securities exchange), any court
      or other authority of competent jurisdiction.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>12. INDEMNITY</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      12.1 The Customer shall defend, indemnify and hold harmless the Supplier against claims, actions, proceedings,
      losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees) arising
      out of or in connection with the Customer's use of the Services, provided that:
    </div>
    <div>(a) the Customer is given prompt notice of any such claim;</div>
    <div>
      (b) the Supplier provides reasonable co-operation to the Customer in the defence and settlement of such claim, at
      the Customer's expense; and
    </div>
    <div>(c) the Customer is given sole authority to defend or settle the claim.</div>
    <div>
      <br />
    </div>
    <div>
      12.2 The Supplier shall defend the Customer, its officers, directors and employees against any claim that the
      Services infringe any United Kingdom patent effective as of the Effective Date, copyright, trade mark, database
      right or right of confidentiality, and shall indemnify the Customer for any amounts awarded against the Customer
      in judgment or settlement of such claims, provided that:
    </div>
    <div>(a) the Supplier is given prompt notice of any such claim;</div>
    <div>
      (b) the Customer provides reasonable co-operation to the Supplier in the defence and settlement of such claim, at
      the Supplier's expense; and
    </div>
    <div>(c) the Supplier is given sole authority to defend or settle the claim.</div>
    <div>
      <br />
    </div>
    <div>
      12.3 In the defence or settlement of any claim, the Supplier may procure the right for the Customer to continue
      using the Services, replace or modify the Services so that they become non-infringing or, if such remedies are not
      reasonably available, terminate this agreement on 2 Business Days' notice to the Customer without any additional
      liability or obligation to pay liquidated damages or other additional costs to the Customer.
    </div>
    <div>
      <br />
    </div>
    <div>
      12.4 In no event shall the Supplier, its employees, agents and sub-contractors be liable to the Customer to the
      extent that the alleged infringement is based on:
    </div>
    <div>(a) a modification of the Services by anyone other than the Supplier; or</div>
    <div>(b) the Customer's use of the Services or Data in a manner contrary to the Proposal; or</div>
    <div>
      (c) the Customer's use of the Services or Data after notice of the alleged or actual infringement from the
      Supplier or any appropriate authority.
    </div>
    <div>
      <br />
    </div>
    <div>
      12.5 The foregoing and clause 13.4(b) state the Customer's sole and exclusive rights and remedies, and the
      Supplier's (including the Supplier's employees', agents' and sub-
    </div>
    <div></div>
    <div>
      contractors') entire obligations and liability, for infringement of any patent, copyright, trade mark, database
      right or right of confidentiality.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>13. LIMITATION OF LIABILITY</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      13.1 This clause 13 sets out the entire financial liability of the Supplier (including any liability for the acts
      or omissions of its employees, agents and sub-contractors) to the Customer:
    </div>
    <div>(a) arising under or in connection with this agreement;</div>
    <div>(b) in respect of any use made by the Customer of the Services and Data or any part of them; and</div>
    <div>
      (c) in respect of any representation, statement or tortious act or omission (including negligence) arising under
      or in connection with this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>13.2 Except as expressly and specifically provided in this agreement:</div>
    <div>
      (a) the Customer assumes sole responsibility for results obtained from the use of the Services and the Data by the
      Customer, and for conclusions drawn from such use. The Supplier shall have no liability for any damage caused by
      errors or omissions in any information, instructions or scripts provided to the Supplier by the Customer in
      connection with the Services, or any actions taken by the Supplier at the Customer's direction;
    </div>
    <div>
      (b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or
      common law are, to the fullest extent permitted by applicable law, excluded from this agreement; and
    </div>
    <div>(c) the Services and the Data are provided to the Customer on an "as is" basis.</div>
    <div>
      <br />
    </div>
    <div>13.3 Nothing in this agreement excludes the liability of the Supplier:</div>
    <div>(a) for death or personal injury caused by the Supplier's negligence; or</div>
    <div>(b) for fraud or fraudulent misrepresentation.</div>
    <div>
      <br />
    </div>
    <div>13.4 Subject to clause 13.2 and clause 13.3:</div>
    <div>
      (a) the Supplier shall not be liable whether in tort (including for negligence or breach of statutory duty),
      contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of
      goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any
      special, indirect or consequential loss, costs, damages, charges or expenses however arising under this agreement;
      and
    </div>
    <div>
      (b) the Supplier's total aggregate liability in contract (including in respect of the indemnity at clause 12.2),
      tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in
      connection with the performance or contemplated performance of this agreement shall be
    </div>
    <div></div>
    <div>
      limited to 50% of the total Subscription Fees paid for the User Subscriptions during the 12 months immediately
      preceding the date on which the claim arose.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>14. TERM AND TERMINATION</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      14.1 This agreement shall, unless otherwise terminated as provided in this clause 14, commence on the Effective
      Date and shall continue for the Initial Subscription Term and, thereafter, this agreement shall be automatically
      renewed for successive periods of one month (each a Renewal Period), unless:
    </div>
    <div>
      (a) either party notifies the other party of termination, in writing, at least 30 days before the end of the
      Initial Subscription Term or any Renewal Period, in which case this agreement shall terminate upon the expiry of
      the applicable Initial Subscription Term or Renewal Period; or
    </div>
    <div>(b) otherwise terminated in accordance with the provisions of this agreement;</div>
    <div>
      <br />
    </div>
    <div>
      and the Initial Subscription Term together with any subsequent Renewal Periods shall constitute the Subscription
      Term.
    </div>
    <div>
      <br />
    </div>
    <div>
      14.2 Without affecting any other right or remedy available to it, either party may terminate this agreement with
      immediate effect by giving written notice to the other party if:
    </div>
    <div>
      (a) the other party fails to pay any amount due under this agreement on the due date for payment and remains in
      default not less than 60 days after being notified in writing to make such payment;
    </div>
    <div>
      (b) the other party commits a material breach of any other term of this agreement which breach is irremediable or
      (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in
      writing to do so;
    </div>
    <div>
      (c) the other party repeatedly breaches any of the terms of this agreement in such a manner as to reasonably
      justify the opinion that its conduct is inconsistent with it having the intention or ability to give effect to the
      terms of this agreement;
    </div>
    <div>
      (d) the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they
      fall due or admits inability to pay its debts or is deemed unable to pay its debts within the meaning of section
      123 of the Insolvency Act 1986 ;
    </div>
    <div>
      (e) the other party commences negotiations with all or any class of its creditors with a view to rescheduling any
      of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors other than
      for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies
      or the solvent reconstruction of that other party;
    </div>
    <div></div>
    <div>
      (f) a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with
      the winding up of that other party other than for the sole purpose of a scheme for a solvent amalgamation of that
      other party with one or more other companies or the solvent reconstruction of that other party;
    </div>
    <div>
      (g) an application is made to court, or an order is made, for the appointment of an administrator, or if a notice
      of intention to appoint an administrator is given or if an administrator is appointed, over the other party;
    </div>
    <div>
      (h) the holder of a qualifying floating charge over the assets of that other party has become entitled to appoint
      or has appointed an administrative receiver;
    </div>
    <div>
      (i) a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed
      over the assets of the other party;
    </div>
    <div>
      (j) a creditor or encumbrancer of the other party attaches or takes possession of, or a distress, execution,
      sequestration or other such process is levied or enforced on or sued against, the whole or any part of the other
      party's assets and such attachment or process is not discharged within 14 days;
    </div>
    <div>
      (k) any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is
      subject that has an effect equivalent or similar to any of the events mentioned in clause 14.2(d) to clause
      14.2(j) (inclusive); or
    </div>
    <div>
      (l) the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of
      its business.
    </div>
    <div>
      <br />
    </div>
    <div>14.3 On termination of this agreement for any reason:</div>
    <div>(a) all licences granted under this agreement shall immediately terminate;</div>
    <div>
      (b) each party shall return and make no further use of any data or other items (and all copies of them) belonging
      to the other party;
    </div>
    <div>
      (c) the Supplier may destroy or otherwise dispose of any of the Customer Data in its possession and the Customer
      shall pay all reasonable expenses incurred by the Supplier in disposing of Customer Data; and
    </div>
    <div>
      (d) any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of
      termination, including the right to claim damages in respect of any breach of the agreement which existed at or
      before the date of termination shall not be affected or prejudiced.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>15. FORCE MAJEURE</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      The Supplier shall have no liability to the Customer under this agreement if it is prevented from or delayed in
      performing its obligations under this agreement, or from carrying on its business, by acts, events, omissions or
      accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial
      disputes
    </div>
    <div></div>
    <div>
      (whether involving the workforce of the Supplier or any other party), failure of a utility service or transport or
      telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or
      governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm
      or default of suppliers or sub-contractors, provided that the Customer is notified of such an event and its
      expected duration.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>16. CONFLICT</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      If there is an inconsistency between any of the provisions in the main body of this agreement and the Supplierâ€™s
      quotations and invoices, the provisions in the main body of this agreement shall prevail.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>17. VARIATION</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      No variation of this agreement shall be effective unless it is in writing and signed by the parties (or their
      authorised representatives).
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>18. WAIVER</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      No failure or delay by a party to exercise any right or remedy provided under this agreement or by law shall
      constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of
      that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict
      the further exercise of that or any other right or remedy.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>19. RIGHTS AND REMEDIES</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      Except as expressly provided in this agreement, the rights and remedies provided under this agreement are in
      addition to, and not exclusive of, any rights or remedies provided by law.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>20. SEVERANCE</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      20.1 If any provision (or part of a provision) of this agreement is found by any court or administrative body of
      competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.
    </div>
    <div>
      <br />
    </div>
    <div>
      20.2 If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it
      were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial
      intention of the parties.
    </div>
    <div></div>
    <div>
      <strong>21. ENTIRE AGREEMENT</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      21.1 This agreement, and any documents referred to in it, constitute the whole agreement between the parties and
      supersede any previous arrangement, understanding or agreement between them relating to the subject matter they
      cover.
    </div>
    <div>
      <br />
    </div>
    <div>
      21.2 Each of the parties acknowledges and agrees that in entering into this agreement it does not rely on any
      undertaking, promise, assurance, statement, representation, warranty or understanding (whether in writing or not)
      of any person (whether party to this agreement or not) relating to the subject matter of this agreement, other
      than as expressly set out in this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>22. ASSIGNMENT</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      22.1 The Customer shall not, without the prior written consent of the Supplier, assign, transfer, charge,
      sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      22.2 The Supplier may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or
      any of its rights or obligations under this agreement.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>23. NO PARTNERSHIP OR AGENCY</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      Nothing in this agreement is intended to or shall operate to create a partnership between the parties, or
      authorise either party to act as agent for the other, and neither party shall have the authority to act in the
      name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any
      representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>24. THIRD PARTY RIGHTS</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      This agreement does not confer any rights on any person or party (other than the parties to this agreement and,
      where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act
      1999.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>25. NOTICES</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      25.1 Any notice required to be given under this agreement shall be in writing and shall be delivered by hand or
      sent by pre-paid first-class post or recorded delivery post to the other party at such address as may have been
      notified by that party for such purposes.
    </div>
    <div>
      <br />
    </div>
    <div>
      25.2 A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in
      business hours, at 9 am on the first business day following
    </div>
    <div></div>
    <div>
      delivery). A correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall be
      deemed to have been received at the time at which it would have been delivered in the normal course of post.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>26. GOVERNING LAW</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation
      (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of
      England and Wales.
    </div>
    <div>
      <br />
    </div>
    <div>
      <strong>27. JURISDICTION</strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any
      dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including
      non-contractual disputes or claims).
    </div>
    <div>
      <br />
    </div>
  </>
);
