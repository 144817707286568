import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Dialogs } from "@evr/constant";

import { RootState } from "..";
import { openDialog } from "../slices/dialog";

const VerizonImportStateHandler: React.FC = () => {
  const dispatch = useDispatch();
  const verizonState = useSelector((state: RootState) => state.verizon);

  useEffect(() => {
    var currentPosition = verizonState.importPosition;
    if (verizonState.importing && currentPosition !== undefined) {
      dispatch(openDialog({ type: Dialogs.VERIZON_INTERRUPTED }));
    }
  }, []);

  return null;
};

export default VerizonImportStateHandler;
