import { useContext, useEffect, useRef, useState } from "react";

import { Coordinate as olCoordinate } from "ol/coordinate";
import olMap from "ol/Map";
import olOverlay from "ol/Overlay";
import { Popover } from "react-bootstrap";
import styled from "styled-components";

import MapContext from "../Map/MapContext";

// React Bootstrap would position its Popover within a bootstrap overlay using popperJS.
// In this context, OpenLayers maintains an overlay and positions it, clashing with popper.
// Instead will just position the Popover around the coordinate.  As the anchor is a point,
// we only care about left, right, top, bottom
const PositionedPopover = styled(Popover)<{ placement?: "left" | "right" | "top" | "bottom" }>`
  position: absolute;
  display: table;

  &.bs-popover-top {
    bottom: 8px;
    transform: translateX(-50%);
    & > .popover-arrow {
      position: absolute;
      left: calc(50% - 8px);
    }
  }
  &.bs-popover-bottom {
    top: 8px;
    transform: translateX(-50%);
    & > .popover-arrow {
      position: absolute;
      left: calc(50% - 8px);
    }
  }
  &.bs-popover-start {
    /* AKA left */
    transform: translateY(-50%);
    right: 8px;
    & > .popover-arrow {
      position: absolute;
      top: 50%;
      margin-top: -8px;
    }
  }
  &.bs-popover-end {
    /* AKA right */
    transform: translateY(-50%);
    left: 8px;
    & > .popover-arrow {
      position: absolute;
      top: 50%;
      margin-top: -8px;
    }
  }
`;

export interface PopupOverlayProps {
  coordinate?: olCoordinate;
  placement?: "left" | "right" | "top" | "bottom";
  children?: React.ReactNode;
  offset?: [number, number];
}

const PopupOverlay = ({ coordinate, placement, children, offset }: PopupOverlayProps) => {
  const map = useContext(MapContext) as olMap;
  const popupRef = useRef<HTMLDivElement>(null);
  const [overlay, setOverlay] = useState<olOverlay | null>(null);

  useEffect(() => {
    if (!map) {
      return () => {};
    }

    const overlay = new olOverlay({
      element: popupRef.current as HTMLDivElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
      offset: offset ?? [0, -0],
    });

    map.addOverlay(overlay);
    setOverlay(overlay);

    return () => {
      if (!map) {
        return;
      }
      map.removeOverlay(overlay);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (!overlay) {
      return;
    }
    overlay.setPosition(coordinate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinate]);

  return (
    <PositionedPopover ref={popupRef} placement={placement ?? "right"}>
      {children}
    </PositionedPopover>
  );
};

export default PopupOverlay;
