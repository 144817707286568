import { useContext, useEffect, useMemo, useState } from "react";

import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { inAndOut } from "ol/easing";
import { FeatureLike } from "ol/Feature";
import { Point } from "ol/geom";
import olMap from "ol/Map";
import olMapBrowserEvent from "ol/MapBrowserEvent";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import markerGreen from "@evr/assets/markers/markerGreen.png";
import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { Vector } from "@evr/components/ReactOL/Layers";
import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { DepotStatusKeys, MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot, selectDepotsStatus } from "@evr/store/slices/depot";

import { DepotActionButtons } from "./DepotActionButtons";

const DepotLayer = () => {
  const depotSource = useMemo(() => new olVectorSource(), []);
  const depotStatus = useAppSelector(selectDepotsStatus);
  const currentDepot = useAppSelector(selectCurrentDepot);
  const map = useContext(MapContext) as olMap;

  const [pos, setPos] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    if (!map) {
      return;
    }

    if (depotStatus === DepotStatusKeys.ADD && !currentDepot) {
      addMarker(map.getView().getCenter() ?? [0, 0]);
      return;
    }

    if (depotStatus === DepotStatusKeys.CLOSE && !currentDepot) {
      depotSource.clear();
      return;
    }

    if ((depotStatus === DepotStatusKeys.CLOSE || depotStatus === DepotStatusKeys.EDIT) && currentDepot) {
      addMarker(olProj.fromLonLat(currentDepot.point.coordinates));
      map.getView().animate({
        center: olProj.fromLonLat(currentDepot.point.coordinates),
        duration: 2000,
        zoom: map.getView().getZoom(),
        easing: inAndOut,
      });
      return;
    }
  }, [currentDepot, depotStatus, map]);

  const addMarker = (center: Coordinate) => {
    if (!depotSource.isEmpty()) {
      depotSource.clear();
    }

    const markerFeature = new Feature({
      geometry: new Point(center),
    });

    const pinStyle = new Style({
      image: new Icon({
        src: markerGreen,
        scale: 0.5,
        anchor: [0.5, 1],
      }),
    });

    markerFeature.setStyle(pinStyle);

    const convertedCenter = olProj.toLonLat(center);
    setPos({ lng: convertedCenter[0], lat: convertedCenter[1] });
    depotSource.addFeature(markerFeature);
  };

  const onDepotLayerClick = (features: FeatureLike[], e: olMapBrowserEvent<any>) => {
    if (features.length === 0) {
      addMarker(e.coordinate);
      return;
    }
  };

  return (
    <>
      <Vector name={MapLayerNames.DEPOT} zIndex={1} source={depotSource} />
      <MapClick
        layerNames={[MapLayerNames.DEPOT]}
        active={depotStatus === DepotStatusKeys.ADD || depotStatus === DepotStatusKeys.EDIT}
        onClick={onDepotLayerClick}
      />
      <DepotActionButtons
        hidden={!(depotStatus === DepotStatusKeys.ADD || depotStatus === DepotStatusKeys.EDIT)}
        pos={pos ?? { lat: 0, lng: 0 }}
        id={currentDepot ? currentDepot.id : -1}
      />
    </>
  );
};

export default DepotLayer;
