import { PayloadAction } from "@reduxjs/toolkit";
import { Coordinate } from "ol/coordinate";

import {
  AddJobLocationType,
  DeleteJobLocationType,
  EditJobLocationCoordinatesType,
  EditJobLocationType,
  JobLocation,
  Location,
  ParcelState,
  SetJobLocationCoordinatesType,
} from "@evr/types";

import { parcelInitialState } from "./initialState";

export const ParcelMapReducer = {
  setJobLocationPointIndex: (parcels: ParcelState, action: PayloadAction<number>) => {
    parcels.mapState.pointToItem.index = action.payload;
    parcels.mapState.pointToItem.generate = Date.now();
  },
  setJobLocationCoordinates: (parcels: ParcelState, action: PayloadAction<SetJobLocationCoordinatesType>) => {
    const { jobLocation, description, index, editable } = action.payload;
    parcels.mapState.jobLocation = jobLocation;
    parcels.mapState.description = description;
    parcels.mapState.itemToPoint.index = index;
    parcels.mapState.itemToPoint.generate = Date.now();
    parcels.mapState.editLocation = editable ?? false;
    parcels.mapState.editLocationCoordinate = null;
  },
  editJobLocationCoordinates: (parcels: ParcelState, action: PayloadAction<EditJobLocationCoordinatesType>) => {
    const { key, coordinates } = action.payload;
    parcels.processedState.jobLocations.valids[key].point.coordinates = coordinates;
    parcels.mapState = parcelInitialState.mapState;
  },
  cancelEditJobLocationCoordinates: (parcels: ParcelState) => {
    parcels.mapState = parcelInitialState.mapState;
  },
  deleteJobLocation: (parcels: ParcelState, action: PayloadAction<DeleteJobLocationType>) => {
    const { key, jobIndex } = action.payload;
    const { [key]: selectedItem, ...restOfJobLocation } = parcels.processedState.jobLocations.valids;
    let jobs = (selectedItem as JobLocation)?.jobs;
    if (jobs.length <= 1) {
      parcels.processedState.jobLocations.valids = restOfJobLocation;
      if (key === parcels.mapState.description) {
        parcels.mapState = parcelInitialState.mapState;
      }
    } else {
      const remainingJobs = jobs.filter((job, index) => index !== jobIndex);
      const location = parcels.processedState.jobLocations.valids[key];
      (parcels.processedState.jobLocations.valids[key] as JobLocation).jobs = remainingJobs;
    }
  },
  addJobLocation: (parcels: ParcelState, action: PayloadAction<AddJobLocationType>) => {
    const { key, openingTime, closingTime, job } = action.payload;
    const jobLocation = parcels.processedState.jobLocations.valids[key] as JobLocation;
    jobLocation.openingTime = openingTime;
    jobLocation.closingTime = closingTime;
    jobLocation.jobs.push(job);
  },
  editJobLocation: (parcels: ParcelState, action: PayloadAction<EditJobLocationType>) => {
    const { key, job, openingTime, closingTime, jobIndex } = action.payload;
    const jobLocation = parcels.processedState.jobLocations.valids[key] as JobLocation;
    jobLocation.openingTime = openingTime;
    jobLocation.closingTime = closingTime;
    jobLocation.jobs[jobIndex] = job;
  },
  setParcelToEditCoordinates: (parcels: ParcelState, action: PayloadAction<Coordinate>) => {
    parcels.mapState.editLocationCoordinate = action.payload;
  },
};
