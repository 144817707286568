export enum Dialogs {
  ADD_DRIVER = "ADD_DRIVER",
  EDIT_DRIVER = "EDIT_DRIVER",
  ADD_VEHICLE = "ADD_VEHICLE",
  EDIT_VEHICLE = "EDIT_VEHICLE",
  ADD_DEPOT = "ADD_DEPOT",
  EDIT_DEPOT = "EDIT_DEPOT",
  SAVING_ENERGY = "SAVING_ENERGY",
  SETTING = "SETTING",
  TERMS_CONDITIONS = "TERMS_CONDITIONS",
  NOTIFICATIONS = "NOTIFICATIONS",
  PLAN = "PLAN",
  DELETE_DRIVER = "DELETE_DRIVER",
  DELETE_VEHICLE = "DELETE_VEHICLE",
  DELETE_DEPOT = "DELETE_DEPOT",
  NO_SUBSCRIPTION = "NO_SUBSCRIPTION",
  DELETE_JOB_LOCATION = "DELETE_JOB_LOCATION",
  ADD_JOB_LOCATION = "ADD_JOB_LOCATION",
  EDIT_JOB_LOCATION = "EDIT_JOB_LOCATION",
  CLOSE = "CLOSE",
  VERIZON_LOGIN = "VERIZON_LOGIN",
  VERIZON_DRIVER_SELECT = "VERIZON_DRIVER_SELECT",
  VERIZON_DRIVER_CREATE = "VERIZON_DRIVER_CREATE",
  VERIZON_VEHICLE_SELECT = "VERIZON_VEHICLE_SELECT",
  VERIZON_IMPORT = "VERIZON_IMPORT",
  VERIZON_INTERRUPTED = "VERIZON_INTERRUPTED",
  VERIZON_NEW_USER = "VERIZON_NEW_USER",
}

export enum DialogTitle {
  ADD_DRIVER = "Add Driver",
  ADD_VEHICLE = "Add Vehicle",
  EDIT_DRIVER = "Edit Driver",
  EDIT_VEHICLE = "Edit Vehicle",
  ADD_DEPOT = "Add Depot",
  EDIT_DEPOT = "Edit Depot",
  DELETE_DRIVER = "Delete Driver",
  DELETE_VEHICLE = "Delete Vehicle",
  DELETE_DEPOT = "Delete Depot",
  SAVING_ENERGY = "Your Saving",
  SETTING = "Settings",
  TERMS_CONDITIONS = "Terms and Conditions",
  NOTIFICATIONS = "Notifications",
  PLAN = "Subscription Information",
  NO_SUBSCRIPTION = "Subscription",
  DELETE_JOB_LOCATION = "Delete Job",
  ADD_JOB_LOCATION = "Add Job",
  EDIT_JOB_LOCATION = "Edit Job",
  VERIZON_LOGIN = "Verizon Connect Import Login",
  VERIZON_DRIVER_SELECT = "Verizon Connect Driver Import",
  VERIZON_DRIVER_CREATE = "Set Driver Details",
  VERIZON_VEHICLE_SELECT = "Verizon Connect Vehicle Import",
  VERIZON_IMPORT = "Verizon Connect Import",
  VERIZON_INTERRUPTED = "Verizon Connect Import Interrupted",
  VERIZON_NEW_USER = "Verizon Connect Import",
  CLOSE = "",
}
