import { useEffect } from "react";

import Feature from "ol/Feature";
import { LineString } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Stroke, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { Journey, JourneyColor } from "@evr/types";

interface ResultJourneyLayerProps {
  journeySource: olVectorSource;
  journey: Journey;
  journeyColor: JourneyColor;
  isVisible: boolean;
}

const ResultJourneyLayer = ({ isVisible, journeySource, journey, journeyColor }: ResultJourneyLayerProps) => {
  useEffect(() => {
    if (!isVisible) {
      journeySource.clear();
    }
  }, [isVisible, journey.id, journeySource]);

  useEffect(() => {
    return () => {
      journeySource.clear();
    };
  }, [journeySource]);

  if (isVisible && journey.route) {
    const feature = new Feature({
      geometry: new LineString(journey.route.coordinates.map(coordinate => olProj.fromLonLat(coordinate))),
    });

    const style = new Style({
      stroke: new Stroke({
        color: journeyColor.main,
        width: 3,
      }),
    });

    feature.setStyle(style);
    journeySource.addFeature(feature);
  }

  return <Vector source={journeySource} zIndex={MapLayerZIndices.ORIGINAL_CALCULATION}></Vector>;
};

export default ResultJourneyLayer;
