import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { closeDialog } from "@evr/store/slices/dialog";
import { Button } from "@evr/ui/Button";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";

import { LinearLoading } from "../LinearLoading";

interface PropsType {
  buttonTitle: string;
  loading?: boolean;
  onClickAction?: () => void;
  variant?: "text" | "contained" | "outlined";
  onCancelAction?: () => void;
  cancelText?: string;
  showCancel?: boolean;
}

export const DialogActionButtons = ({
  buttonTitle,
  loading,
  onClickAction,
  variant = "text",
  onCancelAction,
  cancelText = "Cancel",
  showCancel = true,
}: PropsType) => {
  const dispatch = useAppDispatch();
  const handleCloseDialog = () => {
    if (onCancelAction) {
      onCancelAction();
    } else {
      dispatch(closeDialog());
    }
  };

  return (
    <Wrapper position="relative">
      <RowContainer height="50px" width="100%" padding="1rem 0 0 0" justify="flex-end">
        {showCancel && (
          <Button variant="text" disabled={loading} onClick={handleCloseDialog}>
            {cancelText}
          </Button>
        )}
        <Button variant={variant} disabled={loading} type="submit" onClick={onClickAction}>
          {buttonTitle}
        </Button>
      </RowContainer>
      <Wrapper position="absolute" width="100%">
        {loading && <LinearLoading />}
      </Wrapper>
    </Wrapper>
  );
};
