import { createSlice } from "@reduxjs/toolkit";

import { SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { DriverVehicleMatchState } from "@evr/types";

import { resetCalculationParams } from "../result";
import { DVMatchReducer } from "./dvMatchReducer";

const initialState: DriverVehicleMatchState = { list: [], activeDriverId: null, activeVehicleSize: null };

export const DVMatchSlice = createSlice({
  name: SliceKeys.DV_MATCH,
  initialState,
  reducers: { ...DVMatchReducer },
  extraReducers: builder => {
    builder.addCase(resetCalculationParams, dvm => {
      return initialState;
    });
  },
});

export const { addDVMatch, removeDVMatch, setDVMatchVehicleIdAndCharge, setDVMatchActiveDriverId, setDVMatchActiveVehicleSize } =
  DVMatchSlice.actions;
export const selectDVMatchState = (state: RootState) => state.dvMatch;
export const selectDriverMatched = (driverId: number) => (state: RootState) =>
  state.dvMatch.list.find(dv => dv.driverId === driverId);
export const selectVehicleMatched = (vehicleId: number) => (state: RootState) =>
  state.dvMatch.list.find(dv => dv.vehicleId === vehicleId);
export default DVMatchSlice.reducer;
