import { theme } from "@evr/theme";

import { RowContainer, GridItem, Flex } from "./FlexBox";

interface PropsType {
  value: number;
  min?: number;
  max: number;
  showPercent?: boolean;
  label?: string;
  labelSM?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  labelXS?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
}

export const ProgressBar = ({ label, value, min = 0, max, showPercent, labelXS, labelSM }: PropsType) => {
  const percent = Math.round(((value - min) * 100) / (max - min));
  return (
    <Flex wrap="wrap" margin="0.5rem 0" width="100%">
      {label && (
        <GridItem xs={labelXS ? labelXS : 12} sm={labelSM ? labelSM : labelXS ? labelXS : 12}>
          {label}
        </GridItem>
      )}
      <GridItem xs={labelXS ? 12 - labelXS : 12} sm={labelSM ? 12 - labelSM : labelXS ? 12 - labelXS : 12}>
        <RowContainer
          height="1rem"
          padding="0.7rem 0.5rem"
          width="100%"
          bgColor="greyLight"
          justify="flex-start"
          borderRadius="0.4rem"
          overflow="hidden"
          position="relative"
          flexGrow={1}
        >
          <RowContainer zIndex={theme.zIndex.positiveOne} color="white" height="100%">
            {showPercent ? `${percent}%` : `${value}/${max}`}
          </RowContainer>
          <RowContainer
            transition="right 0.3s linear"
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right={`${100 - percent}%`}
            gradient
          />
        </RowContainer>
      </GridItem>
    </Flex>
  );
};
