import { Extent as olExtent } from "ol/extent";
import { ObjectEvent as olObjectEvent } from "ol/Object";
import olProjection from "ol/proj/Projection";
import olView, { FitOptions as olFitOptions, ViewOptions as olViewOptions } from "ol/View";

export interface ViewProps {
  zoom: number;
  center: number[];
  projection: olProjection;
  minZoom: number;
  maxZoom: number;
  extent?: olExtent;
  onZoomChange?: (zoom: number) => void;
}

class View extends olView {
  private _onZoomChange?: (zoom: number) => void;

  constructor({ zoom, center, projection, minZoom, maxZoom, extent, onZoomChange }: ViewProps) {
    super({
      zoom,
      center,
      projection,
      minZoom,
      maxZoom,
      extent,
    });

    this._onZoomChange = onZoomChange;
    this.on("change:resolution", this.handleZoomChange);
  }

  get zoom(): number {
    return super.getZoom() as number;
  }

  set zoom(zoom: number) {
    super.setZoom(zoom);
  }

  get center(): number[] {
    return super.getCenter() as number[];
  }

  set center(center: number[]) {
    super.setCenter(center);
  }

  get projection(): olProjection {
    return super.getProjection();
  }

  get minZoom(): number {
    return super.getMinZoom();
  }

  set minZoom(minZoom: number) {
    super.setMinZoom(minZoom);
  }

  get maxZoom(): number {
    return super.getMaxZoom();
  }

  set maxZoom(maxZoom: number) {
    super.setMaxZoom(maxZoom);
  }

  get onViewChange(): ((zoom: number) => void) | undefined {
    return this._onZoomChange;
  }

  set onViewChange(onZoomChange: ((zoom: number) => void) | undefined) {
    this._onZoomChange = onZoomChange;
  }

  public fit(extent: olExtent, options?: olViewOptions | olFitOptions) {
    super.fit(extent, options);
  }

  handleZoomChange = (_: olObjectEvent) => {
    if (this._onZoomChange && this.zoom) {
      this._onZoomChange(this.zoom);
    }
  };
}

export default View;
