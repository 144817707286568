import { useEffect } from "react";

import { Navigate, useSearchParams } from "react-router-dom";

import { LocalStorageKeys, QueryKeys } from "@evr/constant";
import { localStorageHelper } from "@evr/utils";

export const Sso = () => {
  const [searchParams] = useSearchParams();

  const ssoToken = searchParams.get(QueryKeys.TOKEN);

  useEffect(() => {
    if (ssoToken) {
      localStorageHelper.storeItem(LocalStorageKeys.TOKEN, ssoToken);
      localStorageHelper.storeItem(LocalStorageKeys.SSO_URL, "sso");
    }
  }, [ssoToken]);

  if (!ssoToken) {
    return <Navigate to="/signin" replace />;
  }
  return <Navigate to="/loadingApp" replace />;
};
