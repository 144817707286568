import { useTheme } from "styled-components";

import { useGetCalculationssQuery } from "@evr/apis/calculation/hooks";
import { TablePagination, TableSearch } from "@evr/components/Table";
import { DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useMediaQuery } from "@evr/hooks/useMediaQuery";
import {
  clearCalculationSearchValue,
  selectCalculationPagination,
  setCalculationPageIndex,
  setCalculationSearchValue,
} from "@evr/store/slices/calculation";
import { SearchColumn } from "@evr/types";
import { DialogBody, DialogHeader, DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { ColumnContainer } from "@evr/ui/FlexBox";

import { notificationFilters } from "./notificationFilters";
import { NotificationList } from "./NotificationList";

export const NotificationsDialog = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { search, pageIndex } = useAppSelector(selectCalculationPagination);
  const isMatchedUpSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

  const { data, isFetching, refetch } = useGetCalculationssQuery();

  const handleChangePageIndex = (page: number) => dispatch(setCalculationPageIndex(page));
  const handleClearSearchValue = (item: string) => dispatch(clearCalculationSearchValue(item));
  const handleChangeSearchValue = (item: SearchColumn) => dispatch(setCalculationSearchValue(item));

  const Actions = <DialogSyncHeaderButton onClick={refetch} loading={isFetching} />;
  return (
    <>
      <DialogHeader title={DialogTitle.NOTIFICATIONS} />
      <DialogBody
        direction="column"
        justify="space-between"
        padding="0"
        overflow="hidden"
        width={isMatchedUpSm ? "600px" : "100%"}
      >
        <TableSearch
          search={search}
          columns={notificationFilters}
          Actions={Actions}
          changeSearchValue={handleChangeSearchValue}
          clearSearchValue={handleClearSearchValue}
        />
        <ColumnContainer position="relative" overflow="hidden">
          <ColumnContainer height="350px" width="100%" padding="1rem" overflow="auto" justify="flex-start">
            <NotificationList calculations={data?.resources} loading={isFetching} />
          </ColumnContainer>
          <TablePagination totalPages={data?.totalPages} pageIndex={pageIndex} action={handleChangePageIndex} />
        </ColumnContainer>
      </DialogBody>
    </>
  );
};
