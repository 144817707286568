import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SliceKeys, SnackbarKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { SnackbarState } from "@evr/types";

const initialState: SnackbarState = { type: SnackbarKeys.INFO, title: "" };

export const SnackbarSlice = createSlice({
  name: SliceKeys.SNACKBAR,
  initialState,
  reducers: {
    pushSnackbar: (snackbar, action: PayloadAction<SnackbarState>) => {
      snackbar.type = action.payload.type;
      snackbar.title = action.payload.title;
      snackbar.generate = Date.now();
    },
  },
});

export const { pushSnackbar } = SnackbarSlice.actions;
export const selectSnackbar = (state: RootState) => state.snackbar;

export default SnackbarSlice.reducer;
