import * as Yup from "yup";

import { ParcelOptionalItems, validationMsg } from "@evr/constant";

//
export const JobLocationValidationSchema = Yup.object({
  [ParcelOptionalItems.WEIGHT]: Yup.number()
    .typeError("please enter a numerical value")
    .min(0, "must be a positive number or 0"),
  [ParcelOptionalItems.QUANTITY]: Yup.number()
    .typeError("please enter a numerical value")
    .integer("must be a whole number")
    .moreThan(0, "must be greater than 0"),
  [ParcelOptionalItems.DURATION]: Yup.number()
    .typeError("please enter a numerical value")
    .integer("must be a whole number")
    .moreThan(0, "must be greater than 0"),
  [ParcelOptionalItems.HEIGHT]: Yup.number()
    .typeError("please enter a numerical value")
    .moreThan(0, "must be greater than 0"),
  [ParcelOptionalItems.WIDTH]: Yup.number()
    .typeError("please enter a numerical value")
    .moreThan(0, "must be greater than 0"),
  [ParcelOptionalItems.DEPTH]: Yup.number()
    .typeError("please enter a numerical value")
    .moreThan(0, "must be greater than 0"),
  [ParcelOptionalItems.NAME]: Yup.string().max(50, validationMsg.maxlength(50)),
  [ParcelOptionalItems.TRACKING_ID]: Yup.string().max(50, validationMsg.maxlength(50)),
  [ParcelOptionalItems.LATTITUDE]: Yup.number().typeError("please enter a numerical value"),
  [ParcelOptionalItems.LONGITUDE]: Yup.number().typeError("please enter a numerical value"),
});
