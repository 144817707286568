import { useEffect, useRef, useState, useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useClickAwayListener } from "@evr/hooks/useClickAwayListener";
import { updateColorMap } from "@evr/store/slices/all-live-journeys-result";
import { changeJourneysColor, selectJourneysColor } from "@evr/store/slices/setting";
import { ColorPicker } from "@evr/ui/ColorPicker";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { MenuContainer, MenuItem } from "@evr/ui/Menu";
import { Input } from "@evr/ui/TextInput/styles";
import { Typography } from "@evr/ui/Typography";
import { getContrastColor } from "@evr/utils";

export const SettingJourneyColors = () => {
  const dispatch = useAppDispatch();
  const journeysColor = useAppSelector(selectJourneysColor);
  const [colors, setColors] = useState(journeysColor);
  const [journeyNumber, setJourneyNumber] = useState("1");
  const [open, setOpen] = useState<boolean>(false);

  const colorsRef = useRef(colors);

  const selectColorMenuRef = useRef(null!);
  const closeMenu = useCallback(() => setOpen(false), []);
  useClickAwayListener(selectColorMenuRef, closeMenu);

  useEffect(() => {
    return () => {
      dispatch(changeJourneysColor(colorsRef.current));
    };
  }, [dispatch]);

  const handleChangeColor = (color: string) => {
    const contrast = getContrastColor(color);
    const oldColor = colors[journeyNumber];
    dispatch(updateColorMap({ oldColor, newColor: { main: color, contrast } }));
    const newColors = { ...colors, [journeyNumber]: { main: color, contrast } };
    setColors(newColors);
    colorsRef.current = newColors;
  };
  const handleMenu = () => setOpen(open => !open);
  const handleChangeJourney = (key: string) => setJourneyNumber(key);

  return (
    <RowContainer margin="0.5rem 0 0" width="100%" justify="flex-start">
      <Typography>Journey's colour</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto">
        <RowContainer ref={selectColorMenuRef} onClick={handleMenu} position="relative" margin="0 0.5rem 0 0">
          <Wrapper position="relative" width="100%">
            <Input
              width="150px"
              padding="0.2rem 0.4rem"
              value={journeyNumber !== "unroutables" ? `Journey ${journeyNumber}` : "Unroutables"}
            />
            <IconButton
              icon="chevron-down"
              position="absolute"
              right="0.5rem"
              top="50%"
              transform="translateY(-50%)"
              WH="24px"
              size={1}
            />
          </Wrapper>

          {open && (
            <MenuContainer height="150px" width="150px">
              {Object.keys(journeysColor).map((key, index) => (
                <MenuItem key={key} padding="0.05rem 0.5rem" onClick={() => handleChangeJourney(key)}>
                  <RowContainer justify="space-between">
                    {index < 10 ? `Journey ${key}` : "Unroutables"}
                    <Wrapper height="15px" width="20px" background={colors[key].main} />
                  </RowContainer>
                </MenuItem>
              ))}
            </MenuContainer>
          )}
        </RowContainer>
        <ColorPicker onDeferredChange={handleChangeColor} value={colors[journeyNumber].main} />
      </Flex>
    </RowContainer>
  );
};
