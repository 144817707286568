import { useAddDepotMutation, useEditDepotMutation } from "@evr/apis/depot";
import { useGetDepotById } from "@evr/apis/depot/hooks";
import { FormikForm, FormikTextInput } from "@evr/components/Form";
import { DepotKeys, DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDialogData } from "@evr/store/slices/dialog";
import { Depot, ExtendedDepot } from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

import { DepotValidationSchema } from "./schema";

export const DepotDialog = () => {
  const newDepot = useAppSelector(selectDialogData) as ExtendedDepot;
  const depot = useGetDepotById(newDepot ? newDepot.id : -1);

  const [addDepot, { isLoading: addLoading }] = useAddDepotMutation();
  const [editDepot, { isLoading: editLoading }] = useEditDepotMutation();

  const handleSubmit = ({ name }: { [DepotKeys.NAME]: string }) => {
    const submitedDepot: Depot = { ...newDepot, name };
    depot ? editDepot(submitedDepot) : addDepot(submitedDepot);
  };
  if (!newDepot) {
    return null;
  }
  return (
    <>
      <DialogHeader title={depot ? DialogTitle.EDIT_DEPOT : DialogTitle.ADD_DEPOT} />
      <DialogBody>
        <FormikForm
          initialValues={{ [DepotKeys.NAME]: depot ? depot.name : "" }}
          validationSchema={DepotValidationSchema}
          onSubmit={handleSubmit}
        >
          <Flex wrap="wrap" align="baseline" justify="flex-start">
            <GridItem xs={12}>
              <FormikTextInput label="Name" name={DepotKeys.NAME} autoFocus={!depot} />
            </GridItem>
            <GridItem xs={12} padding="0.5rem 1rem">
              <Typography>
                <strong>{depot && "New "}Full Address: </strong>
                <Typography size={0.9}>{newDepot?.display_name}</Typography>
              </Typography>
            </GridItem>
          </Flex>
          <DialogActionButtons loading={addLoading || editLoading} buttonTitle={depot ? "Edit" : "Add"} />
        </FormikForm>
      </DialogBody>
    </>
  );
};
