import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { closeDialog } from "@evr/store/slices/dialog";
import { Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

import { Divider } from "../Divider";

interface PropsType {
  title: string;
  actionButtons?: JSX.Element;
  onClose?: () => void;
  showClose?: boolean;
}

export const DialogHeader = ({ title, actionButtons, onClose, showClose = true }: PropsType) => {
  const dispatch = useAppDispatch();
  const handleCloseDialog = () => {
    onClose && onClose();
    dispatch(closeDialog());
  };
  return (
    <Flex padding="0.5rem 1rem" gradient width="100%" justify="space-between">
      <Typography as="h2" size={1.25} fontWeight={500} lineHeight={1.6} margin="0">
        {title}
      </Typography>
      <Flex>
        {actionButtons && actionButtons}
        {actionButtons && <Divider direction="v" height="40px" />}
        {showClose && (
          <IconButton icon="times" title="Close Dialog" size={1.5} color="white" onClick={handleCloseDialog} />
        )}
      </Flex>
    </Flex>
  );
};
