import { DepotKeys } from "@evr/constant";
import { SearchFieldKeys, TableColumn } from "@evr/types";

export const depotTableColumns: TableColumn<DepotKeys>[] = [
  {
    name: "Name",
    field: DepotKeys.NAME,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "City",
    field: DepotKeys.CITY,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "State",
    field: DepotKeys.STATE,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Country",
    field: DepotKeys.COUNTRY,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Postcode",
    field: DepotKeys.POSTCODE,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Latitude",
    field: DepotKeys.LATITUDE,
  },
  {
    name: "Longitude",
    field: DepotKeys.LONGITUDE,
  },
];
