export const getSize = (s: number): string => {
  return s >= 1024 * 1024
    ? `${(s / (1024 * 1024)).toFixed(2)} MB`
    : s >= 1024
    ? `${(s / 1024).toFixed(2)} KB`
    : `${s} B`;
};

export const imageValidator = (file: File, size: number = 80 * 1024, length: number = 50) => {
  const maxLength = length;
  const maxSize = size;
  if (file.name.length > maxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${maxLength} characters`,
    };
  }
  if (file.size > maxSize) {
    return {
      code: "size-too-big",
      message: "Filesize is bigger than 50KB",
    };
  }
  return null;
};
