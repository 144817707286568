import { FormikPasswordInput, FormikTextInput } from "@evr/components/Form";
import { AuthKeys } from "@evr/constant";
import { AuthTitle } from "@evr/pages/Auth/AuthTitle";
import { GridItem, Flex } from "@evr/ui/FlexBox";

export const PersonalForm = () => {
  return (
    <>
      <AuthTitle>Please enter your personal information</AuthTitle>
      <Flex wrap="wrap" align="baseline" justify="flex-start">
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="First Name" name={AuthKeys.FIRST_NAME} autoFocus />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Last Name" name={AuthKeys.LAST_NAME} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="User Name" name={AuthKeys.USER_NAME} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Email Address" name={AuthKeys.EMAIL} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikPasswordInput placeholder="Password" name={AuthKeys.PASSWORD} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Repeat Password" type="password" name={AuthKeys.CONFIRM_PASSWORD} />
        </GridItem>
      </Flex>
    </>
  );
};
