import { useTheme } from "styled-components";

import { vehicleLowerKwBounds, VehicleSize, vehicleUpperKwBounds } from "@evr/constant";

interface PropsType {
  listedRange: number;
  capacity: number;
  vehicleSize: VehicleSize;
}
export const RangeRatioWarning = ({ listedRange, capacity, vehicleSize }: PropsType) => {
  const theme = useTheme();

  const lowerBound = vehicleLowerKwBounds[vehicleSize];
  const upperBound = vehicleUpperKwBounds[vehicleSize];
  const vehicleDescription =
    vehicleSize === VehicleSize.SMALL ? "small" : vehicleSize === VehicleSize.MEDIUM ? "medium" : "large";
  const ratio = listedRange / capacity;

  return (
    <div style={{ color: theme.colors.warning.main, padding: "10px", paddingBottom: 0 }}>
      This vehicle is currently operating at {ratio.toFixed(2)} miles per kWh. We would expect between {lowerBound} and{" "}
      {upperBound} for a {vehicleDescription} vehicle. This may affect estimated battery usage.
    </div>
  );
};
