import { TableNameKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectParcelHeader, setNewParcelheader } from "@evr/store/slices/parcel";
import { TableHeaderType } from "@evr/types";
import { TH } from "@evr/ui/Table";

export const ParcelTableHeader = () => {
  const dispatch = useAppDispatch();
  const { present, newHeader } = useAppSelector(selectParcelHeader);
  const { title: presentParcelItem, header: currentHeader } = present;

  const handleHeaderClick = (field: string) => {
    const changedHeader: TableHeaderType[] = currentHeader.map((h: TableHeaderType) => {
      if (h.field === field) {
        return { ...h, headerName: presentParcelItem, clickable: false, color: "infoLight" };
      }
      return h;
    });
    dispatch(setNewParcelheader(changedHeader));
  };

  return (
    <thead>
      <tr>
        {newHeader.map(col => (
          <TH
            key={col.field}
            color={col.color}
            clickable={col.clickable}
            tableType={TableNameKeys.PARCEL}
            onClick={() => col.clickable && handleHeaderClick(col.field)}
          >
            {col.headerName}
          </TH>
        ))}
      </tr>
    </thead>
  );
};
