import styled, { css } from "styled-components";

import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

interface ResultCardItemProps {
  border?: boolean;
  hoverEffect?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  width?:string;
}

export const ResultCardBox = styled(ColumnContainer)`
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.shadows[5]};
  font-size: 1.2rem;
  overflow: hidden;
  width: ${props => (props.open ? "300px" : "50px")};
`;

export const ResultCardItem = styled.div<ResultCardItemProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${props => props.width ? `width: ${props.width};`: `width: 20%;`}
  align-items: center;
  justify-content: space-around;
  ${props =>
    props.border &&
    css`
      border-right: 1px solid ${props.theme.colors.action.border};
      border-left: 1px solid ${props.theme.colors.action.border};
    `}
    ${props =>
      props.borderRight &&
      css`
        border-right: 1px solid ${props.theme.colors.action.border};
      `}
      ${props =>
        props.borderLeft &&
        css`
          border-left: 1px solid ${props.theme.colors.action.border};
        `}
  i, span, button {
    color: inherit;
  }
  transition: transform 0.3s ease-in-out;
  &:hover {
    ${props => props.hoverEffect && "transform: translateY(-10%);"}
  }
`;

export const ResultCardItemDetails = styled(ColumnContainer)`
  height: 100%;
  font-size: 0.75rem;
  text-align: center;
`;

export const RegistrationNumber = styled.div`
  background: linear-gradient(to bottom, #f8d038 0%, #f5ca2e 100%);
  padding: 0.4rem 0.8rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.shadows[4]};
  word-break: break-word;
`;

export const ResultCircleDownload = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  border: solid 1.5px ${props => props.color};
  color: ${props => props.color};
`;

export const ResultCardTab = styled(Flex)<{ active?: boolean }>`
  cursor: pointer;
  padding: 0.4rem 0.25rem;
  border-bottom: 1px solid ${p => p.theme.colors.grey.main};
  transition: all 0.3s ease-in-out;
  flex-basis: 50%;
  font-size: 1rem;
  ${p =>
    p.active &&
    css`
      background-color: ${p => p.theme.colors.grey.main};
      color: ${p => p.theme.colors.common.white};
    `}
`;
