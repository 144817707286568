import { AxiosRequestConfig } from "axios";

import { ScheduledJourneysEndpoint } from "@evr/apis/endpoints";
import { ScheduledJourneyStatus } from "@evr/constant";

const getScheduledJourney = (scheduledJourneyId: number): AxiosRequestConfig => ({
  url: `${ScheduledJourneysEndpoint}/${scheduledJourneyId}`,
});

const getScheduledJourneyMissedParcelsCsv = (journeyId: number): AxiosRequestConfig => ({
  url: `${ScheduledJourneysEndpoint}/${journeyId}/uncompleted`,
  responseType: "blob",
});

const getAllLiveJourneys = (
  startDate: Date,
  endDate: Date,
  journeyStatus: ScheduledJourneyStatus,
  includeJourneySummary: boolean,
): AxiosRequestConfig => ({
  url: `${ScheduledJourneysEndpoint}`,
  params: {
    startDate,
    endDate,
    journeyStatus,
    includeJourneySummary,
  },
});

export const scheduledJourneyApi = {
  getScheduledJourney,
  getScheduledJourneyMissedParcelsCsv,
  getAllLiveJourneys,
};
