import { useEffect, useState } from "react";

import { parse } from "papaparse";
import { useDropzone, FileWithPath } from "react-dropzone";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  addParcelCSV,
  removeSelectedCsvFile,
  selectIsAppendingParcels,
  selectParcelRowsNumber,
} from "@evr/store/slices/parcel";
import { Button } from "@evr/ui/Button";
import { ColumnContainer, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { Typography } from "@evr/ui/Typography";
import { getSize } from "@evr/utils";

import { ParcelDropArea } from "./styles";

export const ParcelSelectFile = () => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<FileWithPath | null>(null);
  const rowsNumber = useAppSelector(selectParcelRowsNumber);
  const isAppendingParcels = useAppSelector(selectIsAppendingParcels);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);

    parse<string[], FileWithPath>(file, {
      skipEmptyLines: true,
      header: true,
      complete: results => {
        dispatch(addParcelCSV(results));
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "application/vnd.ms-excel, .csv",
    maxFiles: 1,
    onDropAccepted: onDrop,
  });

  const handleDeleteFile = () => {
    setFile(null);
    dispatch(removeSelectedCsvFile());
  };

  // This useEffect delete the CSV file (if any exist) when rowsNumber will be reset
  // to the default value by clicking on the EVR Icon
  useEffect(() => {
    if (!rowsNumber) {
      setFile(null);
    }
  }, [rowsNumber]);

  return (
    <>
      {file ? (
        <RowContainer wrap="wrap" width="100%" padding="1.5rem" justify="flex-start">
          <Icon className="fas fa-file-csv" size={4} />
          <ColumnContainer margin="0 1rem" align="flex-start" flexGrow={1}>
            <Typography overflowEllipsis>Name: {file.path}</Typography>
            <small>Size: {getSize(file.size)}</small>
            {rowsNumber !== 0 && <small>Rows: {rowsNumber}</small>}
          </ColumnContainer>
          <Wrapper alignSelf="flex-end">
            <Button variant="outlined" fontSize="1rem" color="error" onClick={handleDeleteFile}>
              Delete Current File
            </Button>
          </Wrapper>
        </RowContainer>
      ) : (
        <Wrapper width="100%" padding={isAppendingParcels ? "3rem 2.5rem 1rem" : "1rem 2.5rem"}>
          <ParcelDropArea {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragAccept && <p>File will be accepted</p>}
            {isDragReject && <p>File will be rejected</p>}
            {!isDragActive && !file && <p>Click or Drop your file here ...</p>}
          </ParcelDropArea>
        </Wrapper>
      )}
    </>
  );
};
