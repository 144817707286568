import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Point } from "geojson";

import { SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { DriverProgressSnapshot, JourneyColor, ScheduledJourney, UpdateJourneyProgressRequest } from "@evr/types";

interface ScheduledJourneyResult {
  selectedJourney: ScheduledJourney | null;
  pointToZoom: { point: null | Point; generate?: number };
  journeyColor: JourneyColor;
  showRecalculationRoutes: Record<number, boolean>;
  showRecalculationLocations: Record<number, boolean>;
  showActualRoute: boolean;
  showOriginalCalculation: boolean;
  showLocations: boolean;
}

const initialState: ScheduledJourneyResult = {
  selectedJourney: null,
  pointToZoom: { point: null },
  journeyColor: { main: "#ff0000", contrast: "#fff" },
  showRecalculationRoutes: {},
  showRecalculationLocations: {},
  showActualRoute: true,
  showOriginalCalculation: true,
  showLocations: true,
};

export const ScheduledJourneyResultSlice = createSlice({
  name: SliceKeys.SCHEDULED_JOURNET_RESULT,
  initialState: initialState,
  reducers: {
    setScheduledJourneyResult: (state, action: PayloadAction<ScheduledJourney>) => {
      action.payload.recalculations.forEach((_, index) => {
        state.showRecalculationRoutes[index] = true;
        state.showRecalculationLocations[index] = true;
      });
      state.selectedJourney = action.payload;
      state.pointToZoom = initialState.pointToZoom;
    },
    clearScheduledJourneyResult: state => {
      state.selectedJourney = null;
    },
    setScheduledJourneyPointToZoom: (state, action: PayloadAction<Point>) => {
      state.pointToZoom = { point: action.payload, generate: Date.now() };
    },
    setScheduledJourneyColor: (state, action: PayloadAction<JourneyColor>) => {
      state.journeyColor = action.payload;
    },
    updateScheduledJourneyLocation: (state, action: PayloadAction<UpdateJourneyProgressRequest>) => {
      if (state.selectedJourney && state.selectedJourney?.id === action.payload.journeyId) {
        const latestSnapshot = {
          stateOfCharge: action.payload.remainingBatteryCapacity,
          point: action.payload.currentLocation,
          timeStamp: new Date().toISOString(),
          locationId: action.payload.locationId,
        } as DriverProgressSnapshot;
        state.selectedJourney.driverProgress.push(latestSnapshot);
        if (state.selectedJourney.inProgressSummary) {
          state.selectedJourney.inProgressSummary.latestDriverProgress = latestSnapshot;
        }
      }
    },
    toggleShowRecalculationRoute: (state, action: PayloadAction<number>) => {
      state.showRecalculationRoutes[action.payload] = !state.showRecalculationRoutes[action.payload];
    },
    toggleShowRecalculationLocations: (state, action: PayloadAction<number>) => {
      state.showRecalculationLocations[action.payload] = !state.showRecalculationLocations[action.payload];
    },
    toggleShowActualRoute: state => {
      state.showActualRoute = !state.showActualRoute;
    },
    toggleShowOriginalCalculation: state => {
      state.showOriginalCalculation = !state.showOriginalCalculation;
    },
    toggleShowLocations: state => {
      state.showLocations = !state.showLocations;
    },
  },
});

export const {
  setScheduledJourneyResult,
  clearScheduledJourneyResult,
  setScheduledJourneyPointToZoom,
  setScheduledJourneyColor,
  updateScheduledJourneyLocation,
  toggleShowRecalculationRoute,
  toggleShowActualRoute,
  toggleShowOriginalCalculation,
  toggleShowLocations,
  toggleShowRecalculationLocations,
} = ScheduledJourneyResultSlice.actions;

export const selectedScheduledJourney = (state: RootState) => state.scheduledJourneyResult.selectedJourney;

export const selectedScheduledJourneyRecalculation = (state: RootState) =>
  state.scheduledJourneyResult.selectedJourney?.latestRecalculation;

export const selectScheduledPointToZoom = (state: RootState) => state.scheduledJourneyResult.pointToZoom;

export const selectScheduledJourneyColor = (state: RootState) => state.scheduledJourneyResult.journeyColor;

export const selectScheduledJourneyShowRecalculationRoutes = (state: RootState) =>
  state.scheduledJourneyResult.showRecalculationRoutes;

export const selectScheduledJourneyShowRecalculationLocations = (state: RootState) =>
  state.scheduledJourneyResult.showRecalculationLocations;

export const selectScheduledJourneyShowActualRoute = (state: RootState) => state.scheduledJourneyResult.showActualRoute;

export const selectScheduledJourneyShowOriginalCalculation = (state: RootState) =>
  state.scheduledJourneyResult.showOriginalCalculation;

export const selectScheduledJourneyShowLocations = (state: RootState) => state.scheduledJourneyResult.showLocations;

export default ScheduledJourneyResultSlice.reducer;
