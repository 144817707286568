import { MapBrowserEvent } from "ol";
import { FeatureLike } from "ol/Feature";

import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { MapLayerNames } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectParcelMapState, setParcelToEditCoordinates } from "@evr/store/slices/parcel";

const EditJobLocationCoordinates = () => {
  const { jobLocation, editLocation } = useAppSelector(selectParcelMapState);
  const dispatch = useAppDispatch();

  const onClick = (features: FeatureLike[], e: MapBrowserEvent<any>) => {
    if (features.length) {
      return;
    }

    dispatch(setParcelToEditCoordinates(e.coordinate));
  };

  return (
    <MapClick
      onClick={onClick}
      active={jobLocation != null && editLocation}
      layerNames={[MapLayerNames.PARCEL_LOCATIONS]}
    ></MapClick>
  );
};

export default EditJobLocationCoordinates;
