import * as Yup from "yup";

import { AuthKeys, validationMsg } from "@evr/constant";
import { regexPasswordValidation } from "@evr/utils";

export const resetPassValidationSchema = Yup.object({
  [AuthKeys.PASSWORD]: Yup.string()
    .required(validationMsg.required)
    .min(8, validationMsg.minlength(8))
    .matches(regexPasswordValidation, validationMsg.complexPassword)
    .max(50, validationMsg.maxlength(50)),
  [AuthKeys.CONFIRM_PASSWORD]: Yup.string()
    .required(validationMsg.required)
    .oneOf([Yup.ref(AuthKeys.PASSWORD), null], validationMsg.equalTo),
});
