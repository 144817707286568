import { JourneyColor } from "@evr/types";

export const cancelledLocationSvg = (journeyColor: JourneyColor) => {
  const main = journeyColor.main;
  const contrast = journeyColor.contrast;
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
  <!-- Circular background -->
  <circle cx="50" cy="50" r="50" fill="${main}" />

  <!-- Diagonal line from top-left to bottom-right within the circular bounds -->
  <line x1="25" y1="25" x2="75" y2="75" stroke="${contrast}" stroke-width="17" stroke-linecap="round" />

  <!-- Diagonal line from top-right to bottom-left within the circular bounds -->
  <line x1="25" y1="75" x2="75" y2="25" stroke="${contrast}" stroke-width="17" stroke-linecap="round" />
</svg>`;

  return svg;
};

export default cancelledLocationSvg;
