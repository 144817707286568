import ImageTile from "ol/ImageTile";
import olOSMSource from "ol/source/OSM";
import olTileState from "ol/TileState";

/**
 * Service to create an OL OpenStreet Map tile source for use in Tile layers.
 * The tiles will be intercepted and converted to greyscale.
 * @returns {import("ol/source/OSM").default} an OSM source object
 */
const OSMGreyscale = () => {
  const BlobToImageData = (blob: Blob) => {
    let blobUrl = URL.createObjectURL(blob);
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = err => reject(err);
      img.src = blobUrl;
    }).then((img: any) => {
      URL.revokeObjectURL(blobUrl);
      const canvas = document.createElement("canvas");
      canvas.width = 256;
      canvas.height = 256;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      return ctx?.getImageData(0, 0, 256, 256);
    });
  };

  const ToGreyscale = (imageData: ImageData | undefined) => {
    if (!imageData) {
      return;
    }
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      // CIE luminance for the RGB
      let v = 0.2126 * r + 0.7152 * g + 0.0722 * b;

      // Show white color instead of black color while loading new tiles:
      if (v === 0.0) {
        v = 255.0;
      }
      data[i + 0] = v; // Red
      data[i + 1] = v; // Green
      data[i + 2] = v; // Blue
      data[i + 3] = 255; // Alpha
    }

    return imageData;
  };

  const ImageDataToDataUrl = (imageData: ImageData | undefined) => {
    if (!imageData) {
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }
    ctx?.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
  };

  return new olOSMSource({
    tileLoadFunction: (tile, src) => {
      fetch(src)
        .then(async response => {
          if (!response.ok) {
            return Promise.reject(response.status);
          }
          return response.blob();
        })
        .then(async data => {
          return BlobToImageData(data);
        })
        .then(imageData => {
          const greyscale = ToGreyscale(imageData);
          const dataUrl = ImageDataToDataUrl(greyscale);
          if (!dataUrl) {
            return Promise.reject();
          }
          const imageTile = tile as ImageTile;
          const image = imageTile.getImage() as HTMLImageElement;
          image.src = dataUrl;
        })
        .catch(() => {
          tile.setState(olTileState.ERROR);
        });
    },
  });
};

export default OSMGreyscale;
