import styled from "styled-components";

export const TermsBox = styled.div`
  height: 240px;
  overflow-y: scroll;
  padding: 0.5rem;
  outline: ${props => props.theme.colors.divider};
  outline-width: 1px;
  outline-style: solid;
`;
