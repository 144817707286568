import styled from "styled-components";

import { rotateAnimation } from "@evr/theme/animations";
import { colorMixinFunc, ColorsType } from "@evr/theme/colors";

export interface CircleLoadingProps {
  color?: ColorsType;
  size?: number;
  blurBackground?: boolean;
}

export const RotateLoading = styled.div<CircleLoadingProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: ${props => props.theme.zIndex.modal};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => (props.size ? `${props.size * 6}px` : "30px")};
    height: ${props => (props.size ? `${props.size * 6}px` : "30px")};

    border: ${props => (props.size ? `${props.size}px` : "6px")} solid ${props => props.theme.colors.common.white};
    border-radius: 50%;
    animation: ${rotateAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => (props.color ? colorMixinFunc(props.color) : props.theme.colors.primary.dark)} transparent
      transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
