import { useEffect, useState } from "react";

import { useDebounce } from "@evr/hooks/useDebounce";

interface ColorPickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDeferredChange?: (color: string) => void;
}

export const ColorPicker = ({ value, onDeferredChange, ...restProps }: ColorPickerProps) => {
  const [color, setColor] = useState(value);
  const deferedColor = useDebounce(color, 300);

  useEffect(() => {
    onDeferredChange && onDeferredChange(deferedColor as string);
    // eslint-disable-next-line
  }, [deferedColor]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => setColor(e.target.value);

  return <input type="color" value={value} onChange={handleOnChange} {...restProps} />;
};
