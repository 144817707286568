import { CalculationStatusKeys, Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { resetNotificationBadgeNumber, selectNotificationBadge } from "@evr/store/slices/calculation";
import { openDialog } from "@evr/store/slices/dialog";
import { theme } from "@evr/theme";
import { bellRingAnimation } from "@evr/theme/animations";
import { Badge } from "@evr/ui/Badge";
import { IconButton } from "@evr/ui/IconButton";

export const NotificationIcon = () => {
  const dispatch = useAppDispatch();
  const { number, status } = useAppSelector(selectNotificationBadge);
  const handleOpenNotificationsDialog = () => {
    dispatch(openDialog({ type: Dialogs.NOTIFICATIONS }));
    dispatch(resetNotificationBadgeNumber());
  };

  return (
    <Badge badgeContent={number} zIndex={theme.zIndex.positiveOne} warning={status === CalculationStatusKeys.FAILED}>
      <IconButton
        iconClass="far"
        icon="bell"
        WH="25px"
        size={1.9}
        color="white"
        onClick={handleOpenNotificationsDialog}
        animation={bellRingAnimation}
        animationStyle="4s .7s ease-in-out infinite"
        enableAnimation={number > 0}
        style={{ transformOrigin: "50% 10%" }}
      />
    </Badge>
  );
};
