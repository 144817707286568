import { useEffect, useState } from "react";

import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";

import ResolutionChange from "@evr/components/ReactOL/Interactions/ResolutionChange";
import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { calculateArrivalTime } from "@evr/services/location";
import { Journey } from "@evr/types";

interface ResultJourneyLocationsLayerProps {
  locationsSource: olVectorSource;
  journey: Journey;
  layerName: string;
  isVisible: boolean;
}

const ResultJourneyLocationTimeLayer = ({
  locationsSource,
  layerName,
  journey,
  isVisible,
}: ResultJourneyLocationsLayerProps) => {
  const [showTimes, setShowTimes] = useState<boolean>(false);

  useEffect(() => {
    const locationFeatures = journey.locations.map(location => {
      const arrivalTime = calculateArrivalTime(location, journey);
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(location.point.coordinates)),
      });

      const style = new Style({
        text: new Text({
          text: arrivalTime,
          stroke: new Stroke({
            width: 0.2,
          }),
          offsetY: 12,
          font: `13px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif`,
          backgroundFill: new Fill({
            color: "rgba(204, 204, 204, 0.8)",
          }),
        }),
      });

      feature.setStyle(style);
      feature.setProperties({
        id: location.id,
        arrivalTime: arrivalTime,
      });
      return feature;
    });

    locationsSource.addFeatures(locationFeatures ?? []);
  }, []);

  const handleZoomChange = (zoom?: number) => {
    setShowTimes(zoom ? zoom > 12 : false);
  };

  return (
    <>
      {" "}
      <Vector
        visible={isVisible && showTimes}
        name={layerName}
        source={locationsSource}
        zIndex={MapLayerZIndices.LOCATION_TIMES}
        declutter={true}
      ></Vector>
      <ResolutionChange handleZoomChange={handleZoomChange} />
    </>
  );
};

export default ResultJourneyLocationTimeLayer;
