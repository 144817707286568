import axios from "axios";

import { LocalStorageKeys } from "@evr/constant";
import { localStorageHelper } from "@evr/utils";

export const apiInstance = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });

export const apiNominatim = axios.create({ baseURL: "https://nominatim.openstreetmap.org" });

export const apiVerizon = axios.create({ baseURL: "https://fim.api.us.fleetmatics.com:443" });

export const apiSso = axios.create({ baseURL: `${process.env.REACT_APP_SSO_URL}/api` });

apiInstance.interceptors.request.use(
  config => {
    const token = localStorageHelper.getItem(LocalStorageKeys.TOKEN);
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  error => Promise.reject(error),
);
