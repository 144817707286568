import { Flex } from "./FlexBox";
import { Icon, IconPropsType } from "./Icon";

interface PropsType {
  url?: string;
  WH?: string;
  icon?: string;
  title?: string;
  cursorPointer?: boolean;
  onClick?: () => void;
  iconProps?: Omit<IconPropsType, "cursorPointer">;
}
export const CircleImageIcon = ({ iconProps, icon, url, WH, title, cursorPointer, onClick }: PropsType) => {
  return (
    <Flex
      data-testid="circleImage-wrapper"
      bgImage={url}
      bgPosition="center"
      bgSize="cover"
      width={WH || "35px"}
      height={WH || "35px"}
      borderRadius="50%"
      title={title}
      cursor={cursorPointer ? "pointer" : "default"}
      onClick={onClick}
    >
      {!url && <Icon data-testid="circleImage-icon" className={`fas fa-${icon ? icon : "user"}`} {...iconProps} />}
    </Flex>
  );
};
