import { ParcelHeaderItems, ParcelOptionalItems, ParcelRequiredItems } from "@evr/constant";
import { Dimensions, JobTask, JobType, Parcel, ProofOfDeliveryRequirementOption, TableHeaderType } from "@evr/types";

export const isPositiveInteger = (str: string) => {
  if (typeof str !== "string") {
    return false;
  }
  const num = Number(str);
  if (Number.isInteger(num) && num > 0) {
    return true;
  }
  return false;
};

export const isValidNumber = (str: string) => {
  if (typeof str !== "string") {
    return false;
  }
  const num = Number(str);
  if (!isNaN(num)) {
    return true;
  }
  return false;
};

export const getNewCSVHeaderSelectedByUser = (header: TableHeaderType[]) => {
  const newParcelHeader: Record<string, string> = {};
  for (let index = 0; index < header.length; index++) {
    const element = header[index];
    if (element.color !== "infoLight") {
      continue;
    }
    newParcelHeader[ParcelHeaderItems[element.headerName]] = element.field;
  }
  return { newParcelHeader };
};

export const transformParcelTableHeader = (headers: string[]) => {
  return headers.map((h: string) => ({ headerName: h, field: h, clickable: true }));
};

export const createParcelFromItems = (
  parcelOptionalitems: Record<ParcelOptionalItems, string>
) => {
  const {
    weight,
    name,
    quantity,
    task,
    duration,
    openingTime,
    closingTime,
    width,
    height,
    depth,
    trackingId,
    proofOfDeliveryRequirement,
    latitude,
    longitude
  } = parcelOptionalitems;
  const dimensions: Dimensions = { weight };
  const parcel: Parcel = { dimensions, signedName: null, signatureKey: null, photoKey: null, photoURL: null, signatureURL: null, jobType: JobType.PACKAGE, latitude: undefined, longitude: undefined};
  if (!isValidNumber(latitude) && !isValidNumber(longitude))
    {
      parcel.latitude = -999;
      parcel.longitude = -999;
    }else if(!isValidNumber(latitude))
    {
      parcel.latitude = -998;
      parcel.longitude = parseFloat(longitude);
    }else if(!isValidNumber(longitude))
    {
      parcel.latitude = parseFloat(latitude);
      parcel.longitude = -998;
    }else{
      parcel.latitude = parseFloat(latitude);
      parcel.longitude = parseFloat(longitude);
    }
  if (width && isPositiveInteger(width)) {
    dimensions.width = parseInt(width);
  }
  if (height && isPositiveInteger(height)) {
    dimensions.height = parseInt(height);
  }
  if (depth && isPositiveInteger(depth)) {
    dimensions.depth = parseInt(depth);
  }

  if (quantity && isPositiveInteger(quantity)) {
    parcel.quantity = parseInt(quantity);
  }

  if (duration && isPositiveInteger(duration)) {
    parcel.duration = parseInt(duration);
  }
  if (name) {
    parcel.customerName = name;
  }
  if (trackingId) {
    parcel.trackingId = trackingId;
  }

  if(!weight) {
    parcel.dimensions.weight = 0;
  }

  if (proofOfDeliveryRequirement && proofOfDeliveryRequirement !== undefined) {
    if (
      Object.values(ProofOfDeliveryRequirementOption).includes(
        proofOfDeliveryRequirement as ProofOfDeliveryRequirementOption,
      )
    ) {
      parcel.proofOfDeliveryRequirement = proofOfDeliveryRequirement as ProofOfDeliveryRequirementOption;
    } else {
      parcel.proofOfDeliveryRequirement = ProofOfDeliveryRequirementOption.DEFAULT;
    }
  } else {
    parcel.proofOfDeliveryRequirement = ProofOfDeliveryRequirementOption.DEFAULT;
  }

  if (task && task.toLocaleLowerCase() === JobTask.COLLECTION) {
    parcel.jobType = JobType.PICKUP;
  }

  return parcel;
};

export const getParcelItemsSelectedByUser = (
  newParcelItems: Record<string, string>,
  csvRow: Record<string, string>,
) => {
  const requiredItems = Object.values(ParcelRequiredItems).reduce((obj: Record<string, string>, value) => {
    obj[value] = csvRow[newParcelItems[value]];
    return obj;
  }, {});

  const optionalItems = Object.values(ParcelOptionalItems).reduce((obj: Record<string, string>, value) => {
    const selectedValue = csvRow[newParcelItems[value]];
    if (selectedValue) {
      obj[value] = selectedValue;
    }
    return obj;
  }, {});

  const parcel = createParcelFromItems(optionalItems);
  return { requiredItems, optionalItems, parcel };
};
