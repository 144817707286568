import { PayloadAction } from "@reduxjs/toolkit";
import { ParseResult } from "papaparse";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";

import { DepotStatusKeys, notificationsMsg, SnackbarKeys, Dialogs, PlanKeys } from "@evr/constant";
import { RootState, store } from "@evr/store";
import { setSubscription, setUserHasCalculation } from "@evr/store/slices/auth";
import { addNewCalculation } from "@evr/store/slices/calculation";
import { setDepotStatus } from "@evr/store/slices/depot";
import { closeDialog, openDialog } from "@evr/store/slices/dialog";
import { addDVMatch } from "@evr/store/slices/dvMatch";
import { addParcelCSV, setNewParcelheader } from "@evr/store/slices/parcel";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { Subscription } from "@evr/types";

const showMessage = (msg: string) => {
  store.dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: msg }));
};

export const licensing: Middleware =
  (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => next => (action: PayloadAction) => {
    const sub = store.getState().auth.subscription;
    const planName = sub.planName;
    switch (action.type) {
      case setSubscription.type:
        const { isNoPlan, isExpired } = action.payload as unknown as Subscription;
        if (isNoPlan) {
          return;
        }
        if (isExpired) {
          store.dispatch(openDialog({ type: Dialogs.NO_SUBSCRIPTION }));
        }
        return next(action);
      case closeDialog.type:
        if (sub.isExpired) {
          return;
        }
        return next(action);
      case addDVMatch.type:
        const numberOfMatchedDriverVehicle = store.getState().dvMatch.list.length;
        if (numberOfMatchedDriverVehicle < sub.maxDriverVehicleMatchedPerCalculation) {
          return next(action);
        }
        return showMessage(
          notificationsMsg.LICENSE_DRIVER_VEHICLE_MATCH(planName, sub.maxDriverVehicleMatchedPerCalculation),
        );
      case setDepotStatus.type:
        if ((action.payload as unknown as DepotStatusKeys) !== DepotStatusKeys.ADD) {
          return next(action);
        }
        const numberOfDepots = store.getState().depots.pagination.totalItems;
        if (numberOfDepots < sub.maxStoredDepots) {
          return next(action);
        }
        return showMessage(notificationsMsg.LICENSE_DEPOT(planName, sub.maxStoredDepots));
      case addParcelCSV.type:
        const { data } = action.payload as unknown as ParseResult<string[]>;
        if (data.length > sub.maxParcelsPerCalculation) {
          return showMessage(notificationsMsg.LICENSE_PARCEL(planName, sub.maxParcelsPerCalculation));
        }
        if (data.length > sub.maxGeolocationsCallPerMonth - sub.usedGeolocationsCallPerMonth) {
          return showMessage(notificationsMsg.LICENSE_REMAINING_PARCEL);
        }
        return next(action);
      case setUserHasCalculation.type:
        if (sub.planType !== PlanKeys.STARTER) {
          return;
        }
        return next(action);
      case addNewCalculation.type:
        if (store.getState().auth.currentUser?.hasInProgressCalculation) {
          store.dispatch(setUserHasCalculation(false));
        }
        return next(action);
      case setNewParcelheader.type:
        if (
          store.getState().parcel.present.title === "Duration" &&
          sub.planType !== PlanKeys.SILVER &&
          sub.planType !== PlanKeys.GOLD
        ) {
          return showMessage(notificationsMsg.LICENSE_SELECTING_COLUMN_PARCEL);
        }
        return next(action);
      default:
        return next(action);
    }
  };
