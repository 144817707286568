import { createSlice } from "@reduxjs/toolkit";

import { SliceKeys, Dialogs } from "@evr/constant";
import { RootState } from "@evr/store";
import { DialogState } from "@evr/types";

import { DialogReducer } from "./dialogReducer";

const initialState: DialogState = {
  type: Dialogs.CLOSE,
  onClose: false,
};

export const DialogSlice = createSlice({
  name: SliceKeys.DILAOG,
  initialState,
  reducers: { ...DialogReducer },
});

export const { openDialog, closeDialog, detachedDialog } = DialogSlice.actions;
export const selectDialog = (state: RootState) => state.dialog;
export const selectDialogData = (state: RootState) => state.dialog.data;

export default DialogSlice.reducer;
