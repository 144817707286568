import { WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeWindow, openWindow, selectWindowType } from "@evr/store/slices/window";
import { Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

interface HeaderItemProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const HeaderItem = ({ title, selected, onClick }: HeaderItemProps) => (
  <Typography size={1.15} fontWeight={selected ? 700 : "lighter"} onClick={onClick} cursorPointer margin="0 1rem 0 0">
    {title}
  </Typography>
);

export const TablesHeader = () => {
  const dispatch = useAppDispatch();
  const windowType = useAppSelector(selectWindowType);

  const handleOpenDepotWindow = () => dispatch(openWindow(WindowKeys.DEPOT));
  const handleOpenDriverWindow = () => dispatch(openWindow(WindowKeys.DRIVER));
  const handleOpenVehicleWindow = () => dispatch(openWindow(WindowKeys.VEHICLE));
  const handleCloseWindow = () => dispatch(closeWindow());

  return (
    <Flex padding="0.5rem 1rem" width="100%" gradient justify="space-between">
      <Flex width="100%" justify="flex-start">
        <HeaderItem title="Drivers" selected={windowType === WindowKeys.DRIVER} onClick={handleOpenDriverWindow} />
        <HeaderItem title="Vehicles" selected={windowType === WindowKeys.VEHICLE} onClick={handleOpenVehicleWindow} />
        <HeaderItem title="Depots" selected={windowType === WindowKeys.DEPOT} onClick={handleOpenDepotWindow} />
      </Flex>

      <IconButton icon="times" title="Close" color="white" size={1.5} onClick={handleCloseWindow} />
    </Flex>
  );
};
