export enum CommonKeys {
  ID = "id",
  NAME = "name",
  EMAIL = "email",
  ACTIVE = "isActive",
  DRIVER = "Driver",
  VEHICLE = "Vehicle",
  PARCEL = "Parcel",
  DEPOT = "Depot",
  JOURNEY = "Journey"
}

export enum FetchDataKeys {
  INITIAL = "initial",
  LOADED = "loaded",
  FAILED = "failed",
}

export enum PhotoStatusKeys {
  CHANGED = "changed",
  REMOVED = "removed",
  NOT_CHANGED = "notChanged",
  EMPTY = "",
}

export enum CalculationStatusKeys {
  IN_PROGRESS = "InProcess",
  COMPLETED = "Completed",
  FAILED = "Failed",
}
