import { ParcelHeaderItems, ParcelProcessedStatusKeys } from "@evr/constant";
import { ParcelState } from "@evr/types";

const parcelItems = Object.keys(ParcelHeaderItems);
export const parcelInitialState: ParcelState = {
  newHeader: [],
  numberOfParcel: 0,
  numberOfProcessedParcel: 0,
  status: ParcelProcessedStatusKeys.INITIAL,
  processedState: {
    isAppendingParcels: false,
    totalProcessedParcels: 0,
    invalidParcels: [],
    jobLocations: {
      temp: {},
      valids: {},
      invalids: {},
    },
  },
  mapState: {
    editLocation: false,
    jobLocation: null,
    description: "",
    itemToPoint: { index: -1 },
    pointToItem: { index: -1 },
    editLocationCoordinate: null,
  },
  enableNextBt: false,
  past: [],
  present: { title: parcelItems[0], header: [] },
  future: parcelItems.slice(1).map(title => ({ title, header: [] })),
  data: [],
  header: [],
};
