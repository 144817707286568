import { useCallback, useEffect } from "react";

import { SnackbarKeys, notificationsMsg, PlanKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser, selectUserSubscription } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { theme } from "@evr/theme";
import { Wrapper } from "@evr/ui/FlexBox";

export const DisablingHeader = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);
  const subscription = useAppSelector(selectUserSubscription);

  const isDisablingHeader = user?.hasInProgressCalculation && subscription.planType === PlanKeys.STARTER;

  const showCalculationInprogressMsg = useCallback(() => {
    dispatch(pushSnackbar({ type: SnackbarKeys.INFO, title: notificationsMsg.CALCULATION_IN_PROGRESS }));
  }, [dispatch]);

  useEffect(() => {
    if (!isDisablingHeader) {
      return;
    }
    showCalculationInprogressMsg();
    // eslint-disable-next-line
  }, []);

  if (!isDisablingHeader) {
    return null;
  }
  return (
    <Wrapper
      position="absolute"
      width="100%"
      height="100%"
      opacity={0.7}
      bgColor="white"
      zIndex={theme.zIndex.header}
      onClick={showCalculationInprogressMsg}
    />
  );
};
