export enum MapLayerNames {
  ACTUAL_DRIVER_ROUTE = "actualDriverRoute",
  ORIGINAL_CALCULATION = "originalCalculation",
  RECALCULATION_ROUTES = "recalculationRoute",
  LOCATIONS = "locations",
  LOCATION_TIMES = "locationTimes",
  RECALCULATION_LOCATIONS = "recalculationLocations",
  DEPOT = "depot",
  DRIVER_POSITION = "driverPosition",
  RESULTS_DEPOT = "resultsDepot",
  RESULT_UNROUTABLES = "resultUnroutables",
  SCHEDULED_JOURNEY_DEPOT = "scheduledJourneyDepot",
  PARCEL_LOCATIONS = "parcelLocations",
  PARCEL_DEPOT = "parcelDepot",
}
