import { useFormikContext } from "formik";

import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { batteryBufferOptions, BatteryBufferType } from "@evr/types";
import { Select } from "@evr/ui/Select";

interface BatteryBufferFieldProps {
  name: string;
  label: string;
  isAllowedToChange: boolean;
  onSelect: (value: string) => void;
}

export const BatteryBufferTypeSelect = ({ name, label, isAllowedToChange, onSelect }: BatteryBufferFieldProps) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext();

  const selectedValue = (values as Record<string, BatteryBufferType>)[name];

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const bbType = e.target.value;
    setFieldValue(name, bbType);
    onSelect(bbType);
  };

  return (
    <Select
      disabled={!isAllowedToChange}
      items={batteryBufferOptions}
      label={label}
      value={selectedValue}
      onChange={handleOnChange}
    />
  );
};
