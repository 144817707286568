import { Coordinate } from "ol/coordinate";
import { PopoverBody } from "react-bootstrap";

import { PopupOverlay } from "@evr/components/ReactOL/overlays";
import { Depot } from "@evr/types";

interface DepotInfoOverlayProps {
  coordinate?: Coordinate;
  depot?: Depot | null;
}

const DepotInfoOverlay = ({ coordinate, depot }: DepotInfoOverlayProps) => {
  return (
    <PopupOverlay offset={[0, -40]} coordinate={coordinate} placement="top">
      <PopoverBody className="p-0" style={{ borderRadius: 7 }}>
        <div className="p-2 text-nowrap text-left">
          <p className="my-0">
            <strong>Name: </strong> {depot?.name}{" "}
          </p>
          <p className="my-0">
            <strong>Latitude:</strong> {depot?.point.coordinates[1].toFixed(5)}{" "}
          </p>
          <p className="my-0">
            <strong>Longitude: </strong> {depot?.point.coordinates[0].toFixed(5)}{" "}
          </p>
        </div>
      </PopoverBody>
    </PopupOverlay>
  );
};

export default DepotInfoOverlay;
