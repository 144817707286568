import { useState } from "react";

import { ResultCardBox } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { ScheduledResultDownload } from "@evr/components/ScheduledJourneySidebar/ScheduledJourneyResultCard/ScheduledResultDownload";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectJourneysColorArray } from "@evr/store/slices/setting";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";

import { RecalculationResultDetails } from "../RecalculationResultCard/RecalculationResultDetails";
import { RecalculationResultItems } from "../RecalculationResultCard/RecalculationResultItems";
import { UndeliveredParcels } from "../UndeliveredParcels";
interface PropsType {
  scheduledJourney: ScheduledJourney;
}

export const RecalculationResultCard = ({ scheduledJourney }: PropsType) => {
  const maximize = useAppSelector(selectSidebarOpenStatus);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenDetails = () => setOpen(open => !open);
  const journeysColor = useAppSelector(selectJourneysColorArray);

  const visitedLocations = scheduledJourney.locations.filter(l => l.addresses.flatMap(a => a.jobs.filter(j => j.status === "Completed")).length > 0);
  const proposedLocationsByRecalculation = scheduledJourney.latestRecalculation.locations;
  const leftBehindLocations =  scheduledJourney.locations.filter(location => {
    const isVisited = visitedLocations.some(visited => visited.id === location.id);
    const isProposed = proposedLocationsByRecalculation.some(proposed => proposed.id === location.id);
    return !isVisited && !isProposed;
  });
  
  return (
    <div>
      <RowContainer margin="0.5rem 0 1rem" align="flex-start">
        {maximize && <ScheduledResultDownload color={journeysColor[1].main} />}
        <ResultCardBox open={maximize}>
          <RecalculationResultItems
            onOpenDetails={handleOpenDetails}
            maximize={maximize}
            scheduledJourney={scheduledJourney}
            journeyColor={journeysColor[1]}
          />
          {maximize && (
            <Collapsible open={open}>
              <RecalculationResultDetails scheduledJourney={scheduledJourney} />
            </Collapsible>
          )}
        </ResultCardBox>
      </RowContainer>
    {scheduledJourney.status.toString() === "InProgress" && leftBehindLocations.length > 0 && (
      <div>
        <UndeliveredParcels scheduledJourney={scheduledJourney} totalUndelivered={leftBehindLocations.length} />
      </div>
    )}
  </div>
);
};
