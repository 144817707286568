import { Journey, RoutedJobLocations, ScheduledJourney } from "@evr/types";

export const calculateArrivalTime = (location: RoutedJobLocations, journey: Journey) => {
  const previousLocations = journey.locations.filter(l => l.stopNumber < location.stopNumber);
  const totalJobTimeSeconds =
    previousLocations
      .flatMap(l => l.addresses.flatMap(a => a.jobs))
      .reduce((acc, job) => acc + (job.duration ?? 0), 0) * 60;
  const totalTravelTimeSeconds = previousLocations.reduce(
    (acc, l) => acc + (l.legTime as number) + ((l.waitingTime ?? 0) * 60),
    (location.legTime as number) ?? 0,
  );

  const journeyStart = new Date(journey.startDate ?? new Date());
  const arrivalTime = new Date(journeyStart.getTime() + (totalJobTimeSeconds + totalTravelTimeSeconds) * 1000);

  // Round the arrivalTime to the nearest minute
  arrivalTime.setSeconds(0);
  arrivalTime.setMilliseconds(0);
  // If seconds are greater than or equal to 30, round up to the next minute
  if (arrivalTime.getSeconds() >= 30) {
    arrivalTime.setMinutes(arrivalTime.getMinutes() + 1);
  }

  const hours = arrivalTime.getHours().toString().padStart(2, "0");
  const minutes = arrivalTime.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const calculateScheduledJourneyArrivalTime = (
  location: RoutedJobLocations,
  locations: RoutedJobLocations[],
  startDate: Date,
) => {
  const previousLocations = locations.filter(l => l.stopNumber < location.stopNumber);
  const totalJobTimeSeconds =
    previousLocations
      .flatMap(l => l.addresses.flatMap(a => a.jobs))
      .reduce((acc, job) => acc + (job.duration ?? 0), 0) * 60;
  const totalTravelTimeSeconds = previousLocations.reduce(
    (acc, l) => acc + (l.legTime as number) + (l.waitingTime ?? 0),
    (location.legTime as number) ?? 0,
  );

  const journeyStart = new Date(startDate ?? new Date());
  const arrivalTime = new Date(journeyStart.getTime() + (totalJobTimeSeconds + totalTravelTimeSeconds) * 1000);

  arrivalTime.setSeconds(0);
  arrivalTime.setMilliseconds(0);
  if (arrivalTime.getSeconds() >= 30) {
    arrivalTime.setMinutes(arrivalTime.getMinutes() + 1);
  }

  const hours = arrivalTime.getHours().toString().padStart(2, "0");
  const minutes = arrivalTime.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};
