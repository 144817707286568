import { Subscription } from "@evr/types";

export enum PlanKeys {
  STARTER = "Starter",
  BRONZ = "Bronze",
  SILVER = "Silver",
  GOLD = "Gold",
}

export const InitialPlan: Subscription = {
  maxStoredDepots: 0,
  maxDriverVehicleMatchedPerCalculation: 0,
  maxParcelsPerCalculation: 0,
  maxGeolocationsCallPerMonth: 0,
  maxStoredCalculations: 0,
  maxDayStoredCalculations: 0,

  usedStoredDepots: 0,
  usedGeolocationsCallPerMonth: 0,
  usedStoredCalculations: 0,

  canStoreDepot: false,
  canCallGeolocationAPI: false,
  canStoreCalculation: false,
  planName: "No Plan",
  planUrl: "",
  invoiceUrl: "",
  planType: PlanKeys.STARTER,
  expirationDate: "",
  isExpired: true,
  isNoPlan: true,
};
