import { useEffect, useRef } from "react";

import { useGetLocationsMutation } from "@evr/apis/geolocation";
import { config } from "@evr/config";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";
import { selectTempJobLocations } from "@evr/store/slices/parcel";
import { JobLocation, ParcelLocationRequest } from "@evr/types";

const createGroups = (arr: Array<ParcelLocationRequest>, perGroup: number = config.locationPerRequest) => {
  const numGroup = Math.ceil(arr.length / perGroup);
  return new Array(numGroup).fill("").map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
};

export const ParcelProcessing = () => {
  const currentDepot = useAppSelector(selectCurrentDepot);
  const tempLocations = useAppSelector(selectTempJobLocations);
  const [getParcelsLocation] = useGetLocationsMutation();

  const cancelRequest = useRef<boolean>(false);

  const handleProcessParcels = async () => {
    const locationArray = Object.values(tempLocations).map(l => l as JobLocation);
    if (locationArray.length <= 0) {
      return;
    }
    const addresses = locationArray.map(({ postcode, houseNumber, streetName, city, description, jobs }) => ({
      postcode,
      houseNumber,
      streetName,
      city,
      description,
      country: currentDepot?.country,
      latitude: jobs[0].latitude,
      longitude: jobs[0].longitude,
      depotLatitude: currentDepot?.point.coordinates[1],
      depotLongitude: currentDepot?.point.coordinates[0],
    }));
    const addressesArray = createGroups(addresses);

    for (let i = 0; i < addressesArray.length; i++) {
      if (cancelRequest.current) {
        break;
      }
      const data = addressesArray[i];
      await getParcelsLocation(data);
    }
  };

  useEffect(() => {
    handleProcessParcels();
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line
  }, []);

  return null;
};
