import * as Yup from "yup";

import { AuthKeys, validationMsg } from "@evr/constant";

export const forgotPassValidationSchema = Yup.object({
  [AuthKeys.EMAIL]: Yup.string()
    .email(validationMsg.email)
    .required(validationMsg.required)
    .max(100, validationMsg.maxlength(100)),
});
