export const DepotEndpoint = "/Depots";
export const DriverEndpoint = "/Drivers";
export const VehicleEndpoint = "/Vehicles";
export const GeolocationEndpoint = "/GeoLocations";
export const CalculationEndpoint = "/Calculations";
export const SubscriptionsEndpoint = "/Subscriptions";
export const OrgSettingsEndpoint = "/OrganisationSettings";
export const ScheduledJourneysEndpoint = "/ScheduledJourney";

export const AccountsEndpoint = {
  Register: "/Accounts",
  Login: "/Accounts/Login",
  Session: "/Accounts/Session",
  ResetPass: "/Accounts/ResetPassword",
  ForgotPass: "/Accounts/ForgotPassword",
  GetUserPhoto: "/Accounts/getUserPhoto",
  LoginSSO: "/Accounts/LoginSSO",
};
