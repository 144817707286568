import { rotateAnimation } from "@evr/theme/animations";
import { IconButton } from "@evr/ui/IconButton";

interface PropsType {
  onClick: () => void;
  loading: boolean;
}

export const DialogSyncHeaderButton = ({ onClick, loading }: PropsType) => (
  <IconButton
    icon="sync-alt"
    size={1.2}
    title="Reload Data"
    onClick={onClick}
    enableAnimation={loading}
    animation={rotateAnimation}
    animationStyle="1.2s infinite"
    disabled={loading}
    color="white"
  />
);
