import { useState } from "react";

import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectLiveJourneysColorMap } from "@evr/store/slices/all-live-journeys-result";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";

import { ResultCardBox } from "../ResultSidebar/ResultCard/ResultCardComponents";
import AllLiveJourneysResultItems from "./AllLiveJourneysResultItems";
import LiveJourneyResultDetails from "./LiveJourneyResultDetails";

interface LiveJourrneysResultCardProps {
  journey: ScheduledJourney;
  index: number;
}

const LiveJourneyResultCard = ({ journey, index }: LiveJourrneysResultCardProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const colorMap = useAppSelector(selectLiveJourneysColorMap);
  const maximize = useAppSelector(selectSidebarOpenStatus);

  const journeyColor = colorMap.find(color => color.journeyId === journey.id);

  return (
    <>
      {journeyColor != null && (
        <RowContainer margin="0.5rem 0 1rem" align="flex-start">
          <ResultCardBox open={maximize}>
            <AllLiveJourneysResultItems
              maximize={maximize}
              journeyColor={journeyColor.color}
              onOpenDetails={() => setOpen(!open)}
              scheduledJourney={journey}
            />
            {maximize && (
              <Collapsible open={open}>
                <LiveJourneyResultDetails journey={journey} />
              </Collapsible>
            )}
          </ResultCardBox>
        </RowContainer>
      )}
    </>
  );
};

export default LiveJourneyResultCard;
