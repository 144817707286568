import { useNavigate, useLocation } from "react-router-dom";

import { useResetPasswordMutation } from "@evr/apis/accounts";
import { FormikForm, FormikPasswordInput, FormikTextInput } from "@evr/components/Form";
import { LoginBackground } from "@evr/components/LoginBackground";
import { AuthKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { AuthTitle } from "@evr/pages/Auth/AuthTitle";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { ResetPasswordType } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { GridItem } from "@evr/ui/FlexBox";

import { resetPassValidationSchema } from "./schema";

const initialValues = {
  [AuthKeys.PASSWORD]: "",
  [AuthKeys.CONFIRM_PASSWORD]: "",
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const queryString = new URLSearchParams(search);

  const token = queryString.get("token");
  const email = queryString.get("email");

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleSubmit = async ({ password }: ResetPasswordType) => {
    if (!token || !email) {
      return dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: notificationsMsg.RESET_PASSWORD_NO_TOKEN }));
    }
    resetPassword({ password, token, email })
      .unwrap()
      .then(() => navigate("/"));
  };

  return (
    <LoginBackground>
      <AuthTitle>Reset Password</AuthTitle>
      <FormikForm initialValues={initialValues} validationSchema={resetPassValidationSchema} onSubmit={handleSubmit}>
        <FormikPasswordInput placeholder="New Password" name={AuthKeys.PASSWORD} />
        <FormikTextInput placeholder="Cofirm password" type="password" name={AuthKeys.CONFIRM_PASSWORD} />
        <GridItem padding="1rem 0.5rem 0.5rem" width="100%" position="relative">
          <Button fullWidth fontSize="1.3rem" type="submit" padding="0.5rem" disabled={isLoading}>
            {isLoading && <CircleLoading />}
            Reset Password
          </Button>
        </GridItem>
      </FormikForm>
    </LoginBackground>
  );
};
