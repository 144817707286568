import { useContext, useEffect, useState } from "react";

import olLayer from "ol/layer/Layer";
import olTileLayer from "ol/layer/Tile";
import olMap from "ol/Map";
import olTileSource from "ol/source/Tile";

import MapContext from "../Map/MapContext";

type Props = {
  source: olTileSource;
  visible?: boolean;
  zIndex: number;
};

/**
 * Component to register a new OL Tile Layer on the Map
 * using the MapContext.
 * @component
 * @param {import("ol/source/Tile").default} source The OL source to use
 * @example
 * <Tile source={osmSource} />
 */
const Tile = ({ source, visible = true, zIndex }: Props) => {
  const map = useContext(MapContext) as olMap;
  const [layer, setLayer] = useState<olLayer | null>(null);

  useEffect(() => {
    if (!map) {
      return () => {};
    }

    const layer = new olTileLayer({
      source,
      zIndex,
    });

    layer.setVisible(visible);
    map.addLayer(layer);
    setLayer(layer);
    return () => {
      if (!map) {
        return;
      }
      map.removeLayer(layer);
    };
  }, [map, source]);

  useEffect(() => {
    if (!layer) {
      return;
    }
    layer.setVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (!layer || !zIndex) {
      return;
    }
    layer.setZIndex(zIndex);
  }, [zIndex]);

  return null;
};

export default Tile;
