import { useEffect, useState } from "react";

import { IconChevronLeft, IconChevronRight, IconX, TablerIcon } from "@tabler/icons";
import styled, { useTheme } from "styled-components";

import { DriverProgressSnapshot, Job, JobType, Location, Parcel, RoutedJobLocations } from "@evr/types";
import { Button as EvrButton } from "@evr/ui/Button";
import { DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

import DetailedJobView from "./DetailedJobView";



interface StyledTableProps {
    headerColor: string;
}

interface PaginationButtonProps {
    Icon: TablerIcon;
    disabled?: boolean;
    onClick?: () => void;
  }

  const Button = styled.button`
    padding: 0.5rem 0.2rem;
    margin: 0.2rem 0;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  `;

  const PaginationButton = ({ Icon, onClick, disabled }: PaginationButtonProps) => (
    <Button disabled={disabled} onClick={onClick}>
      <Icon size={15} />
    </Button>
  );

const StyledTable = styled.table<StyledTableProps>`
  width: 100%;
  max-width: 100%;
  margin-top: 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0; 
  border-collapse: collapse;
  th,
  td {
    vertical-align: middle;
    font-size: 0.85rem;
    text-align: center;
    padding: 0.2rem;
    user-select: none;
  }

  th {
    background-color: ${({ headerColor }) => headerColor};
    color: black;
  }
`;

const getQuantity = (job: Job) => {
    if(job.jobType === JobType.PICKUP || job.jobType === JobType.PACKAGE) {
        const parcel = job as Parcel;
        return parcel.quantity;
    }
    return "-";
};

const TableIcon = styled.i`
    font-size: 1.2rem; 
`;

    interface AddressJobsPopUpProps {
        location: Location | RoutedJobLocations;
        snapshot?: DriverProgressSnapshot;
        start?: string;
        onClose: () => void;
    }

const AddressJobsPopUp = ({location, snapshot, start, onClose}:AddressJobsPopUpProps) => {
    const [jobToView, setJobToView] = useState<Job | null>();
    const [pageIndex, setPageIndex] = useState(0);
    const loc = location as RoutedJobLocations;
    const snap = snapshot as DriverProgressSnapshot;
    const addressGrouping = (location as Location).addresses;
    const [currentAddress, setCurrentAddress] = useState(addressGrouping[pageIndex]);
    const theme = useTheme();
    const anyCompleted = currentAddress.jobs.some(job => job.status === "Completed");

        useEffect(() => {
            setCurrentAddress(addressGrouping[pageIndex]);
        }, [pageIndex]);
    
        const getStatusIcon = (job: Job) => {
            if(job.status === "Completed") {
                return <TableIcon style={{ color: theme.colors.success.dark}}  className="fas fa-check-circle"></TableIcon>;
            }
            else if(job.status === "Failed") {
                return <TableIcon style={{color: theme.colors.error.dark}} className="fas fa-times-circle"></TableIcon>;
            }
            else {
               return <TableIcon style={{color: theme.colors.warning.dark}} className="fas fa-clock"></TableIcon>;
            }
        };
    
        const getEndTime = (location: Location, waitingTime: number, startTimeString?: string) => {
            if (!startTimeString) {
                return "-";
            }
            const [hours, minutes] = startTimeString.split(':').map(Number);
            const startTime = new Date();
            startTime.setHours(hours);
            startTime.setMinutes(minutes);
            const endTime = location.addresses
            .flatMap(address => address.jobs)
            .reduce((acc, job) => {
                const jobEndTime = new Date(acc);
                jobEndTime.setMinutes(jobEndTime.getMinutes() + (job.duration ?? 0));
                return jobEndTime;
            }, startTime);
        
            endTime.setMinutes(endTime.getMinutes() + Math.ceil(waitingTime));            
            const formattedEndTime = `${endTime.getHours().toString().padStart(2, '0')}:${endTime.getMinutes().toString().padStart(2, '0')}: `;
            const italicizedArrival = <i style={{ fontSize: '12px' }}>(Est. Arrival</i>;
            const italicizedDeparture = <i style={{ fontSize: '12px' }}>Est. Departure)</i>;
        
            return (
                <>
                    {formattedEndTime}
                    {italicizedArrival} - {italicizedDeparture}
                </>
            );
        };
    
        function extractTimeFromString(timeString: String) {
            let [hours, minutes] = timeString.split(':').map(Number);
            hours += (minutes/60);
            return hours;
        }
        
        const GetLatestOpeningAndEarliestClosing = (addresses: Location["addresses"]) => {
            var currentOpening = "";
            var currentClosing = "";
            var openingTime = 0;
            var closingTime = 25;
            
            addresses.forEach(address => {
                var opening = extractTimeFromString(address.openingTime);
                var closing = extractTimeFromString(address.closingTime);
                if (opening > openingTime)
                {
                    currentOpening = address.openingTime;
                }
                if (closing < closingTime)
                {
                    currentClosing = address.closingTime;
                }
                
            });
            const italicisedJobWindow = <i style={{ fontSize: '12px' }}>(Job Window)</i>;
            const openingClosingString = currentOpening + "\u00A0\u00A0- " + currentClosing + ": ";
            return (
                <>
                {openingClosingString}
                {italicisedJobWindow}
                </>
            );
        };
    return (
            <>
            <DialogHeader onClose={onClose} title={"Location"} />
            <DialogBody>
            <Flex marginBottom="0.5rem" direction="row" justify="space-between">
            <Typography size={1} as="h3">    
            <span style={{ color: (loc.waitingTime ?? 0) !== 0 ? '#F47600' : 'inherit' }}>{start}{(loc.waitingTime ?? 0) !== 0 &&  '!'} </span> 
             - {getEndTime(location, loc.waitingTime ?? 0, start)}
            </Typography>
            {
                (
            <Flex direction="column" align="flex-end" marginBottom="1rem" style={{ minWidth: '200px' }}>
              <Flex direction="row" align="center" marginBottom="0.5rem">
                <Typography size={0.9} margin="0 0.5rem 0 0 " as="h3">
                  Predicted: {Math.round(loc.stateOfCharge * 100)}%
                </Typography>
                <TableIcon style={{color: theme.colors.primary.main}} className="fas fa-battery-three-quarters"></TableIcon>
              </Flex>
              {snap?.stateOfCharge && (
                <Flex direction="row" align="center">
                    <Typography
                    size={0.9}
                    margin="0 0.5rem 0 0 "
                    as="h3"
                    style={{
                      color: snap.stateOfCharge < loc.stateOfCharge ? theme.colors.error.dark : 'inherit',
                    }}
                    >                    
                    Actual: {Math.round(snap.stateOfCharge * 100)}%
                  </Typography>
                  <TableIcon style={{color: theme.colors.primary.main}} className="fas fa-battery-three-quarters"></TableIcon>
                </Flex>
              )}
            </Flex>
                )
            }
            </Flex>
            {(loc.waitingTime??0) !== 0 ? 
            <Flex marginBottom="0.5rem" direction="row" justify="space-between">
            <Typography size={1} as="h3">
                {GetLatestOpeningAndEarliestClosing(loc.addresses)}
            </Typography>
            </Flex> : null}
            <Flex flex-direction="row" justify="center">
            <Typography>{currentAddress.houseNumber}, 
            {currentAddress.streetName }, 
            {currentAddress.city}, 
            {currentAddress.postCode}
            </Typography>
            </Flex>
            {
                jobToView && (<DetailedJobView routeBackToTable={() => setJobToView(null)} job={jobToView} />)
            }
            {
                !jobToView && ( 
            
            <StyledTable headerColor={theme.colors.grey.main}>
                <thead>
                <tr>
                <th>Type</th>
                <th>Duration</th>
                <th>Quantity</th>
                {anyCompleted && (<th>Completed</th>)
                }
                <th>Status</th>
                <th></th>
                </tr>
                </thead>
                <tbody>
                { currentAddress.jobs.map((job, index) => (
                <tr key={index}>
                    <td>{job.jobType}</td>
                    <td>{job.duration} mins</td>
                    <td>{getQuantity(job)}</td>
                    {anyCompleted && (<td>{ job.completedTime }</td>)
                    }
                    <td>{ getStatusIcon(job)}</td>
                    <td><EvrButton fontSize="0.8rem" fullWidth onClick={() => setJobToView(job)}>View</EvrButton></td>
                </tr>
                ))}
                </tbody>
            </StyledTable>
            )}
      {
        addressGrouping.length > 1 && !jobToView && (
    <Flex wrap="wrap" width="100%" justify="center" margin="auto 0 0">
      <Flex>
        <PaginationButton Icon={IconChevronLeft} onClick={() => setPageIndex(pageIndex - 1)} disabled={pageIndex - 1 < 0} />
        <Typography overflowEllipsis fontWeight="bold" margin="0 1.5rem">
          {pageIndex  + 1} of {loc.addresses.length}
        </Typography>
        <PaginationButton Icon={IconChevronRight} onClick={() => setPageIndex(pageIndex  + 1)} disabled={pageIndex + 2 > loc.addresses.length} />
      </Flex>
    </Flex>
        )
      }
    </DialogBody>
            </>
);
};


export default AddressJobsPopUp;