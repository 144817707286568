import { EngineType, VehicleSize } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { setDVMatchActiveVehicleSize } from "@evr/store/slices/dvMatch";
import { Vehicle } from "@evr/types";
import { Badge } from "@evr/ui/Badge";
import { ColumnContainer, GridItem } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { Typography } from "@evr/ui/Typography";

import { SettingInitialStateOfCharge } from "./InitialStateOfCharge";

interface PropsType {
  icon: string;
  driverId: number;
  size: VehicleSize;
  iconHeight: string;
  badgeNumber: number;
  isDriverActive: boolean;
  vehicle?: Vehicle;
}

export const DVMatchVehicle = ({
  driverId,
  isDriverActive,
  size,
  badgeNumber,
  icon,
  iconHeight,
  vehicle,
}: PropsType) => {
  const dispatch = useAppDispatch();

  const handleSetVehicleSize = () =>
    isDriverActive && !!badgeNumber && dispatch(setDVMatchActiveVehicleSize({ size, driverId }));

  return (
    <GridItem xs={3} sm={3} textAlign="center">
      <ColumnContainer>
        <Badge
          badgeContent={badgeNumber}
          top="-25%"
          right="-15%"
          bgColor="info"
          showZero
          hidden={!isDriverActive}
          containerProps={{ height: "45px" }}
        >
          <Img
            src={icon}
            height={iconHeight}
            cursorPointer={isDriverActive && !!badgeNumber}
            onClick={handleSetVehicleSize}
          />
        </Badge>

        {vehicle && vehicle.vehicleSize === size && (
          <>
            {vehicle.engineType !== EngineType.ELECTRIC ? (
              <Typography margin="0.2rem 0 0 0" size={0.7} overflowEllipsis width="100%">
                {vehicle.licence}
              </Typography>
            ) : (
              <SettingInitialStateOfCharge vehicle={vehicle} />
            )}
          </>
        )}
      </ColumnContainer>
    </GridItem>
  );
};
