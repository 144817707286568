import { evrApi } from "@evr/apis";
import { ApiMethodKeys } from "@evr/constant";
import { ParcelLocationRequest, ParcelLocationResponse } from "@evr/types";

import { GeolocationEndpoint } from "../endpoints";

export const geolocationApi = evrApi.injectEndpoints({
  endpoints: builder => ({
    getLocations: builder.mutation<ParcelLocationResponse[], ParcelLocationRequest[]>({
      query: data => ({ url: `${GeolocationEndpoint}/getlocations`, method: ApiMethodKeys.POST, data }),
    }),
  }),
});

export const { useGetLocationsMutation } = geolocationApi;
