import * as Yup from "yup";

import { VehicleKeys, validationMsg, EngineType } from "@evr/constant";

export const VehicleValidationSchema = Yup.object({
  [VehicleKeys.LICENCE]: Yup.string().required(validationMsg.required).max(10, validationMsg.maxlength(10)),
  [VehicleKeys.MAX_PAYLOAD_WEIGHT]: Yup.number()
    .required(validationMsg.required)
    .min(1, validationMsg.minZero)
    .max(1_000_000, validationMsg.maxNumber(1_000_000)),
  [VehicleKeys.BATTERY_CAPACITY]: Yup.number().when(VehicleKeys.ENGINE_TYPE, {
    is: EngineType.ELECTRIC,
    then: Yup.number()
      .required(validationMsg.required)
      .min(1, validationMsg.minZero)
      .max(1_000_000, validationMsg.maxNumber(1_000_000)),
  }),
  [VehicleKeys.LISTED_RANGE]: Yup.number().when(VehicleKeys.ENGINE_TYPE, {
    is: EngineType.ELECTRIC,
    then: Yup.number()
      .required(validationMsg.required)
      .min(1, validationMsg.minZero)
      .max(1_000_000, validationMsg.maxNumber(1_000_000)),
  }),
  [VehicleKeys.MODEL]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
  [VehicleKeys.MAKE]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
  [VehicleKeys.STORAGE_SIZE]: Yup.number()
    .min(1, validationMsg.minZero)
    .max(1_000_000, validationMsg.maxNumber(1_000_000)),
});
