import { ReactNode, useEffect, useState } from "react";

import styled from "styled-components";

import logo from "@evr/assets/EVRLogo.png";
import login1 from "@evr/assets/login/login1.jpg";
import login2 from "@evr/assets/login/login2.png";
import login3 from "@evr/assets/login/login3.png";
import loginRoad from "@evr/assets/login/loginRoad.png";
import { CircleCreateAccount } from "@evr/components/CircleCreateAccount";
import { config } from "@evr/config";
import { ColumnContainer, Wrapper } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";

const imageArray = [login1, login2, login3];

interface LoginImagesProps {
  currentImage: number;
}
const LoginImages = styled.div<LoginImagesProps>`
  transition: all 1s linear;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: ${props => props.theme.zIndex.negativeOne};
  opacity: 0.8;
  background: url(${loginRoad}) center, url(${props => imageArray[props.currentImage]}) right;
  background-repeat: no-repeat;
  background-size: contain, 50% 100%;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    opacity: 0.3;
    background: url(${props => imageArray[props.currentImage]});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding-left: 2rem;
  margin: auto;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    justify-content: center;
    padding-left: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3rem;
  }
`;
interface PropsType {
  children: ReactNode;
}
export const LoginBackground = ({ children }: PropsType) => {
  const [currentImage, setCurrentImage] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(currentImage => (currentImage === 2 ? 0 : currentImage + 1));
    }, 8000);
    return () => clearInterval(interval);
  }, []);
  return (
    <ColumnContainer overflow="auto" position="relative" height="100vh" justify="flex-start">
      <LoginImages currentImage={currentImage} />
      <Img src={logo} position="absolute" top="10px" left="10px" height="50px" />
      <LoginContainer>
        <CircleCreateAccount />
        <Wrapper maxWidth="350px" width="100%" margin="1rem">
          {children}
        </Wrapper>
      </LoginContainer>
      <Wrapper margin="2rem 0" width="100%" textAlign="center">
        {"Copyright © "}
        <a href={config.basemapURL}>Basemap</a> {new Date().getFullYear()}
      </Wrapper>
    </ColumnContainer>
  );
};
