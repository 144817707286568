import { useEffect, useState, useRef, ReactNode } from "react";

import styled from "styled-components";

import { Flex, FlexProps } from "@evr/ui/FlexBox";

interface PropsType extends FlexProps {
  open: boolean;
  children?: ReactNode;
}
interface ContainerPropsType {
  height: number | undefined;
}
const Container = styled.div<ContainerPropsType>`
  height: ${props => `${props.height}px`};
  min-height: ${props => `${props.height}px`};
  width: 100%;
  overflow: hidden;
  transition: all 0.2s ease-out;
`;

export const Collapsible = ({ open, children, ...rest }: PropsType) => {
  const [height, setHeight] = useState<number | undefined>(open ? undefined : 0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    setHeight(open ? ref.current.getBoundingClientRect().height : 0);
  }, [open, children]);

  return (
    <Container height={height}>
      <Flex ref={ref} wrap="wrap" {...rest}>
        {children}
      </Flex>
    </Container>
  );
};
