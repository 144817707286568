import { evrApi } from "@evr/apis";
import { ApiMethodKeys, CommonKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { closeDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { ApiPageResponse, Vehicle } from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { VehicleEndpoint } from "../endpoints";

export const vehicleApi = evrApi.enhanceEndpoints({ addTagTypes: [CacheKeys.VEHICLES] }).injectEndpoints({
  endpoints: builder => ({
    vehicles: builder.query<ApiPageResponse<Vehicle>, string>({
      query: query => ({ url: `${VehicleEndpoint}?${query}` }),
      providesTags: [CacheKeys.VEHICLES],
    }),
    addVehicle: builder.mutation<Vehicle, Vehicle & { skipNotification?: boolean }>({
      query: arg => {
        const { skipNotification, ...data } = arg;
        return { url: VehicleEndpoint, method: ApiMethodKeys.POST, data };
      },
      invalidatesTags: [CacheKeys.VEHICLES],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { skipNotification = false } = arg;

        await queryFulfilled;
        if (!skipNotification) {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.ADD_ITEM(CommonKeys.VEHICLE) }));
          dispatch(closeDialog());
        }
      },
    }),
    editVehicle: builder.mutation<Vehicle, Vehicle>({
      query: data => ({ url: VehicleEndpoint, method: ApiMethodKeys.PATCH, data }),
      invalidatesTags: [CacheKeys.VEHICLES],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.EDIT_ITEM(CommonKeys.VEHICLE) }));
          dispatch(closeDialog());
        });
      },
    }),
    deleteVehicle: builder.mutation<void, number>({
      query: id => ({ url: `${VehicleEndpoint}/${id}`, method: ApiMethodKeys.DELETE }),
      invalidatesTags: [CacheKeys.VEHICLES],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.DELETE_ITEM(CommonKeys.VEHICLE) }),
          );
          dispatch(closeDialog());
        });
      },
    }),
  }),
});

export const { useVehiclesQuery, useAddVehicleMutation, useEditVehicleMutation, useDeleteVehicleMutation } = vehicleApi;
