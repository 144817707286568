import { evrApi } from "@evr/apis";
import { ApiMethodKeys, CommonKeys, notificationsMsg, SnackbarKeys, WindowKeys } from "@evr/constant";
import { closeDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { openWindow } from "@evr/store/slices/window";
import { ApiPageResponse, Depot } from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { DepotEndpoint } from "../endpoints";

export const depotApi = evrApi.enhanceEndpoints({ addTagTypes: [CacheKeys.DEPOTS] }).injectEndpoints({
  endpoints: builder => ({
    depots: builder.query<ApiPageResponse<Depot>, string>({
      query: query => ({
        url: `${DepotEndpoint}?${query}`,
      }),
      providesTags: [CacheKeys.DEPOTS],
    }),
    addDepot: builder.mutation<Depot, Depot>({
      query: data => ({ url: DepotEndpoint, method: ApiMethodKeys.POST, data }),
      invalidatesTags: [CacheKeys.DEPOTS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.ADD_ITEM(CommonKeys.DEPOT) }));
          dispatch(closeDialog());
        });
      },
    }),
    editDepot: builder.mutation<Depot, Depot>({
      query: data => ({ url: DepotEndpoint, method: ApiMethodKeys.PATCH, data }),
      invalidatesTags: [CacheKeys.DEPOTS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.EDIT_ITEM(CommonKeys.DEPOT) }));
          dispatch(closeDialog());
          dispatch(openWindow(WindowKeys.DEPOT));
        });
      },
    }),
    deleteDepot: builder.mutation<void, number>({
      query: id => ({ url: `${DepotEndpoint}/${id}`, method: ApiMethodKeys.DELETE }),
      invalidatesTags: [CacheKeys.DEPOTS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.DELETE_ITEM(CommonKeys.DEPOT) }));
          dispatch(closeDialog());
        });
      },
    }),
  }),
});

export const { useDepotsQuery, useAddDepotMutation, useEditDepotMutation, useDeleteDepotMutation } = depotApi;
