/*
matches password to have at least 
one uppercase (?=.*[A-Z]), 
one lower case (?=.*[a-z]), 
one number (?=.*\d), 
and one of these special charachters (?=.*[@$!%*#?&^])

the minimum length is checked by Yup validation
*/
export const regexPasswordValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&^]).*$/;
