import styled, { css } from "styled-components";

interface PropsType {
  primary?: boolean;
  error?: boolean;
  borderShadow?: boolean;
}

export const StyledSelect = styled.select<PropsType>`
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${props => props.theme.colors.grey.dark};
  background-color: ${props => props.theme.colors.common.white};
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid ${props => props.theme.colors.action.border};
  border-radius: ${props => props.theme.borderRadius};
  appearance: none;
  &:focus {
    border-color: ${props => props.theme.colors.primary.main};
    outline: 0;
    ${props => props.borderShadow && "box-shadow: 0 0 0 0.2rem rgba(122, 212, 0, 0.25);"}
  }
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.error.main};
      &:focus {
        border-color: ${props.theme.colors.error.main};
        ${props.borderShadow && "box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);"}
      }
    `}
`;
