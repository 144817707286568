import { createSlice } from "@reduxjs/toolkit";

import { ActiveLayerTypes, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";

interface MapState {
  activeLayer: ActiveLayerTypes;
}

const initialState: MapState = {
  activeLayer: ActiveLayerTypes.NONE,
};

export const MapSlice = createSlice({
  name: SliceKeys.MAP,
  initialState: initialState,
  reducers: {
    setActiveLayer: (state, action) => {
      state.activeLayer = action.payload;
    },
  },
});

export const { setActiveLayer } = MapSlice.actions;

export const selectActiveLayer = (state: RootState) => state.map.activeLayer;

export default MapSlice.reducer;
