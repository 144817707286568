import { ScheduledJourney } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { Typography } from "@evr/ui/Typography";

import { RegistrationNumber, ResultCardItemDetails } from "../ResultSidebar/ResultCard/ResultCardComponents";

interface LiveJourneyDetailsProps {
  journey: ScheduledJourney;
  hidden: boolean;
}

const LiveJourneyDetails = ({ journey, hidden }: LiveJourneyDetailsProps) => {
  return (
    <RowContainer padding="1rem" width="100%" justify="space-between" hidden={hidden}>
      <ResultCardItemDetails width="50%">
        <Icon margin="0 0 .5rem 0" className="fas fa-calculator" />
        <Typography fontWeight="bold" overflowEllipsis width="100%">
          {journey.recalculationCount}
        </Typography>
        <Typography fontWeight={400}>Recalculations</Typography>
      </ResultCardItemDetails>
      <ResultCardItemDetails width="30%">
        <RegistrationNumber>{journey.vehicle.licence || "-"}</RegistrationNumber>
      </ResultCardItemDetails>
    </RowContainer>
  );
};

export default LiveJourneyDetails;
