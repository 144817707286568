import { config } from "@evr/config";
import { Driver } from "@evr/types";

export enum DriverKeys {
  ID = "id",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  NAME = "name",
  EMAIL = "email",
  START_TIME = "startTime",
  END_TIME = "endTime",
  TOTAL_WORKING_HOURS = "totalWorkingHours",
  WORKING_HOURS = "workingHours",
  LUNCH_BREAK = "lunchBreak",
  LUNCH_START_TIME = "lunchPeriodStartTime",
  LUNCH_END_TIME = "lunchPeriodEndTime",
  CREATED_DATE = "createdDate",
  ASSERTIVENESS = "assertiveness",
  BATTERY_BUFFER = "batteryBuffer",
  BATTERY_BUFFER_DISTANCE = "batteryBufferDistance",
  BATTERY_BUFFER_TYPE = "batteryBufferType",
  USER_ID = "userId",
  CREATE_SSO_ACCOUNT = "createSsoAccount",
  USERNAME = "userName"
}

export enum DriverBehavior {
  NORMAL = "Normal",
  MODERATE = "Moderate",
  ASSERTIVE = "Assertive",
}

export const driverBehaviorOptions = Object.entries(DriverBehavior).map(([key, value]) => ({ key, value }));

export const driverInitialValues: Driver = {
  [DriverKeys.ID]: -1,
  [DriverKeys.FIRST_NAME]: "",
  [DriverKeys.LAST_NAME]: "",
  [DriverKeys.NAME]: "",
  [DriverKeys.EMAIL]: "",
  [DriverKeys.ASSERTIVENESS]: DriverBehavior.NORMAL,
  [DriverKeys.WORKING_HOURS]: {
    start: "09:00",
    end: "17:00",
  },
  [DriverKeys.LUNCH_BREAK]: null,
  [DriverKeys.BATTERY_BUFFER]: config.batteryBuffer.percent.default,
  [DriverKeys.BATTERY_BUFFER_DISTANCE]: config.batteryBuffer.distance.default,
  [DriverKeys.BATTERY_BUFFER_TYPE]: config.batteryBuffer.type.default,
};
