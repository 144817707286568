export const downloadFile = (data: string, fileName: string, blob: boolean = false) => {
  const anchor = document.createElement("a");
  anchor.hidden = true;
  anchor.download = fileName;

  anchor.href = blob ? data : `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;

  anchor.click();
  anchor.remove();
};
