export enum DepotKeys {
  ID = "id",
  NAME = "name",
  ROAD = "road",
  CITY = "city",
  STATE = "state",
  POINT = "point",
  COUNTRY = "country",
  POSTCODE = "postcode",
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  DESCRIPTION = "description",
  DISPLAY_NAME = "display_name",
}

export enum DepotStatusKeys {
  ADD = "ADD",
  EDIT = "EDIT",
  CLOSE = "CLOSE",
}
