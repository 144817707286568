import { Form, Formik } from "formik";

import { Dialogs, DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, openDialog } from "@evr/store/slices/dialog";
import { cancelImport, selectImportPosition, VerizonImportPosition } from "@evr/store/slices/verizon";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";

export const VerizonImportInterruptedDialog = () => {
  const dispatch = useAppDispatch();
  const importPosition = useAppSelector(selectImportPosition);

  const handleSubmit = (values: any) => {
    if (importPosition == VerizonImportPosition.DRIVER_SELECT) {
      dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_SELECT }));
    } else if (importPosition == VerizonImportPosition.DRIVER_IMPORT) {
      dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_CREATE }));
    } else if (importPosition == VerizonImportPosition.VEHICLE_SELECT) {
      dispatch(openDialog({ type: Dialogs.VERIZON_VEHICLE_SELECT }));
    } else if (importPosition == VerizonImportPosition.VEHICLE_IMPORT) {
      dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE }));
    } else if (importPosition == VerizonImportPosition.DATA_IMPORT) {
      dispatch(openDialog({ type: Dialogs.VERIZON_IMPORT }));
    }
  };

  const handleCancel = () => {
    dispatch(cancelImport());
    dispatch(closeDialog());
  };

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_INTERRUPTED} showClose={false} />
      <DialogBody>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          <Form>
            <Flex wrap="wrap" align="baseline" justify="flex-start">
              <GridItem>
                <p>You were in the middle of a Verizon Data Import, would you like to continue the import?</p>
              </GridItem>
            </Flex>
            <DialogActionButtons
              buttonTitle={"Yes"}
              variant="contained"
              cancelText="No"
              onCancelAction={handleCancel}
            />
          </Form>
        </Formik>
      </DialogBody>
    </>
  );
};
