import { evrApi } from "@evr/apis";
import { ApiMethodKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { getUserFromSession, logInSSOUser, logInUser } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import {
  ForgotPasswordType,
  LoginResponse,
  LoginSSOResponse,
  ResetPasswordPayloadType,
  SignInType,
  User,
} from "@evr/types";

import { AccountsEndpoint } from "../endpoints";

export const accountsApi = evrApi.injectEndpoints({
  endpoints: builder => ({
    signUp: builder.mutation<void, FormData>({
      query: data => ({ url: AccountsEndpoint.Register, method: ApiMethodKeys.POST, data }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.SIGNUP }));
        });
      },
    }),
    signIn: builder.mutation<LoginResponse, SignInType>({
      query: data => ({ url: AccountsEndpoint.Login, method: ApiMethodKeys.POST, data }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(logInUser(data));
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.LOGIN }));
        });
      },
    }),
    getSession: builder.query<User, void>({
      query: () => ({ url: AccountsEndpoint.Session }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(getUserFromSession(data));
        });
      },
    }),
    loginSSO: builder.query<LoginSSOResponse, string>({
      query: token => ({ url: `${AccountsEndpoint.LoginSSO}?token=${token}` }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(({ data }) => {
          dispatch(logInSSOUser(data));
        });
      },
    }),
    getUserPhoto: builder.query<string, void>({
      query: () => ({ url: AccountsEndpoint.GetUserPhoto, responseType: "blob" }),
      transformResponse: (response: Blob) => URL.createObjectURL(response),
    }),
    resetPassword: builder.mutation<void, ResetPasswordPayloadType>({
      query: data => ({ url: AccountsEndpoint.ResetPass, method: ApiMethodKeys.POST, data }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.RESET_PASSWORD }));
        });
      },
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordType>({
      query: data => ({ url: AccountsEndpoint.ForgotPass, method: ApiMethodKeys.POST, data }),
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.FORGOT_PASSWORD }));
        });
      },
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useGetUserPhotoQuery,
  useLazyLoginSSOQuery,
  useLazyGetSessionQuery,
  useResetPasswordMutation,
  useForgotPasswordMutation,
} = accountsApi;
