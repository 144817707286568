import { SavingsEnergyChartsKeys, SavingsEnergyCostVsSavingsKeys } from "@evr/constant";
import { ResultHeader, SavingsEnergyCharts, SavingsEnergyParams } from "@evr/types";

const roundWith2Decimal = (num: number) => Math.round(num * 100) / 100;
const getDayMonthOfDate = (date: Date) => `${date.getUTCMonth() + 1}/${date.getUTCDate()}`;

export const getThisJourneySavings = ({ totalCO2, totalNOx, totalCosts, totalSavings }: ResultHeader) => {
  return {
    [SavingsEnergyChartsKeys.COST_VS_SAVING]: [
      { name: SavingsEnergyCostVsSavingsKeys.COSTS, value: roundWith2Decimal(totalCosts) },
      { name: SavingsEnergyCostVsSavingsKeys.SAVINGS, value: roundWith2Decimal(totalSavings) },
    ],
    [SavingsEnergyChartsKeys.CO2]: [{ name: "1", CO2: roundWith2Decimal(totalCO2) }],
    [SavingsEnergyChartsKeys.NOx]: [{ name: "1", NOx: roundWith2Decimal(totalNOx) }],
  };
};

export const filterSavingsBasedOnMonths = (numberOfMonths: number, results: SavingsEnergyParams[]) => {
  const monthsAgoFromNow = new Date();
  monthsAgoFromNow.setMonth(monthsAgoFromNow.getMonth() - numberOfMonths);

  const tempData: SavingsEnergyCharts = {
    [SavingsEnergyChartsKeys.COST_VS_SAVING]: [],
    [SavingsEnergyChartsKeys.CO2]: [],
    [SavingsEnergyChartsKeys.NOx]: [],
  };
  const sum = { cO2: 0, nOx: 0, cost: 0, savings: 0 };

  results.forEach((element, index) => {
    const dataTime = new Date(element.createdDate);
    const timeIndex = getDayMonthOfDate(dataTime);
    if (dataTime.getTime() > monthsAgoFromNow.getTime()) {
      sum.cO2 += element.totalCO2;
      sum.nOx += element.totalNOx * 1000;
      sum.cost += element.totalCosts;
      sum.savings += element.totalSavings;

      tempData.CO2.push({ name: timeIndex, CO2: roundWith2Decimal(sum.cO2) });
      tempData.NOx.push({ name: timeIndex, NOx: roundWith2Decimal(sum.nOx) });
    }
  });
  if (sum.cost > 0) {
    tempData[SavingsEnergyChartsKeys.COST_VS_SAVING].push({
      name: SavingsEnergyCostVsSavingsKeys.COSTS,
      value: roundWith2Decimal(sum.cost),
    });
    tempData[SavingsEnergyChartsKeys.COST_VS_SAVING].push({
      name: SavingsEnergyCostVsSavingsKeys.SAVINGS,
      value: roundWith2Decimal(sum.savings),
    });
  }
  return tempData;
};
