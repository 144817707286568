import { useCallback, useEffect, useState } from "react";

import { useAddDriverMutation } from "@evr/apis/driver";
import { useUpdateVerizonImportDateMutation } from "@evr/apis/orgSettings";
import { useAddVehicleMutation } from "@evr/apis/vehicle";
import { Dialogs, DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, openDialog } from "@evr/store/slices/dialog";
import {
  cancelImport,
  getTotalVehicles,
  selectSelectedDrivers,
  selectSelectedVehicles,
  setDriverError,
  setDriverImported,
  setImportPosition,
  setVehicleError,
  setVehicleImported,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { StyledTable, TD, TH, TR } from "@evr/ui/Table";

export const VerizonImportDialog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const reduxDrivers = useAppSelector(selectSelectedDrivers);
  const reduxVehicles = useAppSelector(selectSelectedVehicles);
  const selectedVehicleTotal = useAppSelector(getTotalVehicles);
  const [imported, setImported] = useState<boolean>(false);
  const [addDriver] = useAddDriverMutation();
  const [addVehicle] = useAddVehicleMutation();
  const [updateVerizonImportDate] = useUpdateVerizonImportDateMutation();

  const handleBack = useCallback(() => {
    if (selectedVehicleTotal > 0) {
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_IMPORT));
      dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE }));
    } else {
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_SELECT));
      dispatch(openDialog({ type: Dialogs.VERIZON_VEHICLE_SELECT }));
    }
  }, [dispatch, selectedVehicleTotal]);

  useEffect(() => {
    var toBeImported = reduxDrivers.filter(driver => !driver.imported).length;
    toBeImported += reduxVehicles.filter(vehicle => !vehicle.imported).length;
    if (toBeImported === 0) {
      setImported(true);
    }
  }, [reduxDrivers, reduxVehicles]);

  const handleStartImport = useCallback(() => {
    setLoading(true);
    const importDrivers = async () => {
      for (const driver of reduxDrivers) {
        await addDriver({ ...driver.evrDriver, skipNotification: true })
          .unwrap()
          .then(() => {
            dispatch(setDriverImported(driver));
          })
          .catch(error => {
            dispatch(setDriverError({ email: driver.evrDriver.email, error: error.data }));
          });
      }

      for (const vehicle of reduxVehicles) {
        await addVehicle({ ...vehicle.evrVehicle, skipNotification: true })
          .unwrap()
          .then(() => {
            dispatch(setVehicleImported(vehicle));
          })
          .catch(error => {
            dispatch(
              setVehicleError({
                registration: vehicle.evrVehicle.licence,
                error: error.data?.message || error.message,
              }),
            );
          });
      }

      updateVerizonImportDate();

      setLoading(false);
      setImported(true);
    };
    importDrivers();
  }, [dispatch, reduxDrivers]);

  const handleClose = useCallback(() => {
    dispatch(closeDialog());
    dispatch(cancelImport());
  }, []);

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_DRIVER_SELECT} showClose={false} />
      <DialogBody>
        <p>Drivers to import: {reduxDrivers.length}</p>
        <StyledTable>
          <thead>
            <TR>
              <TH>Email</TH>
              <TH>Name</TH>
              <TH>Status</TH>
            </TR>
          </thead>
          <tbody>
            {reduxDrivers.map((driver, index) => (
              <TR key={index}>
                <TD>{driver.evrDriver.email}</TD>
                <TD>
                  {driver.evrDriver.firstName} {driver.evrDriver.lastName}
                </TD>
                <TD>{driver.imported ? (driver.error ? "Error: " + driver.error : "Success") : "Pending"}</TD>
              </TR>
            ))}
            {reduxDrivers.length === 0 && (
              <TR>
                <TD colSpan={3}>
                  <b>No drivers to import</b>
                </TD>
              </TR>
            )}
          </tbody>
        </StyledTable>
        <br />
        <br />
        <p>Vehicles to import: {reduxVehicles.length}</p>
        <StyledTable>
          <thead>
            <TR>
              <TH>Registration</TH>
              <TH>Make</TH>
              <TH>Model</TH>
              <TH>Status</TH>
            </TR>
          </thead>
          <tbody>
            {reduxVehicles.map((vehicle, index) => (
              <TR key={index}>
                <TD>{vehicle.evrVehicle.licence}</TD>
                <TD>{vehicle.evrVehicle.make}</TD>
                <TD>{vehicle.evrVehicle.model}</TD>
                <TD className="">
                  {vehicle.imported ? (vehicle.error ? "Error: " + vehicle.error : "Success") : "Pending"}
                </TD>
              </TR>
            ))}
            {reduxVehicles.length === 0 && (
              <TR>
                <TD colSpan={4}>
                  <b>No vehicles to import</b>
                </TD>
              </TR>
            )}
          </tbody>
        </StyledTable>
        {!imported && (
          <DialogActionButtons
            loading={loading}
            buttonTitle={"Start Import"}
            variant="contained"
            cancelText="Back"
            onCancelAction={handleBack}
            onClickAction={handleStartImport}
          />
        )}
        {imported && (
          <DialogActionButtons
            buttonTitle={"Close"}
            variant="contained"
            showCancel={false}
            onClickAction={handleClose}
          />
        )}
      </DialogBody>
    </>
  );
};
