import styled from "styled-components";

import { colorMixinFunc, ColorsType } from "@evr/theme/colors";

interface PropsType {
  color?: ColorsType;
  size?: number;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  overflowEllipsis?: boolean;
  width?: string;
  cursorPointer?: boolean;
  textAlign?: "center" | "right" | "left" | "inherit" | "initial";
  fontWeight?: "bold" | "bolder" | "lighter" | "normal" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  lineHeight?: number;
  letterSpacing?: string;
}

export const Typography = styled.span<PropsType>`
  ${props => props.size && `font-size:${props.size}rem;`}
  ${props => props.color && `color: ${colorMixinFunc(props.color)};`}
  margin: ${props => (props.margin ? props.margin : "0")};
  padding: ${props => (props.padding ? props.padding : "0")};
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
  ${props => props.fontWeight && `font-weight:${props.fontWeight};`}
  ${props => props.disabled && `opacity:${props.theme.colors.action.disabledOpacity};`}
  ${props => props.cursorPointer && "cursor: pointer"};
  ${props => props.lineHeight && `line-height:${props.lineHeight};`}
  ${props => props.letterSpacing && `letter-spacing:${props.letterSpacing}rem;`}
  ${props => props.width && `width:${props.width};`}
  ${props =>
    props.overflowEllipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
`;
