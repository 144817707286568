import { FieldHookConfig, useField } from "formik";

import { Checkbox } from "@evr/ui/Checkbox";
interface PropsType {
  label: string;
}
export const FormikCheckbox = (props: FieldHookConfig<string> & PropsType) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  const err = Boolean(meta.touched && meta.error);
  return <Checkbox id={props.name} {...field} {...props} error={err} errorText={err ? meta.error : null} />;
};
