import { useCallback, useRef, useState } from "react";

import { IconAdjustmentsHorizontal } from "@tabler/icons";

import { useClickAwayListener } from "@evr/hooks/useClickAwayListener";
import { TableColumn } from "@evr/types";
import { Wrapper } from "@evr/ui/FlexBox";
import { StyledIconButton } from "@evr/ui/IconButton/styles";
import { MenuContainer, MenuItem } from "@evr/ui/Menu";

interface TableSearchMenuProps {
  searchItems: TableColumn[];
  onChangeSearchMenu?: (item: TableColumn) => void;
}

export const TableSearchMenu = ({ searchItems, onChangeSearchMenu }: TableSearchMenuProps) => {
  const [searchMenu, setSearchMenu] = useState(false);
  const searchMenuRef = useRef<HTMLDivElement>(null!);

  const closeSearchMenu = useCallback(() => setSearchMenu(false), []);
  useClickAwayListener(searchMenuRef, closeSearchMenu);

  const handleOpenSearchMenu = () => setSearchMenu(prev => !prev);

  const handleOnMenuClick = (item: TableColumn) => {
    onChangeSearchMenu && onChangeSearchMenu(item);
    closeSearchMenu();
  };

  return (
    <Wrapper position="relative" ref={searchMenuRef}>
      <StyledIconButton title="Search fields" color="white" hoverColor="infoDark" onClick={handleOpenSearchMenu}>
        <IconAdjustmentsHorizontal />
      </StyledIconButton>
      {searchMenu && (
        <MenuContainer right="0" top="100%">
          {searchItems.map(item => (
            <MenuItem key={item.name} onClick={() => handleOnMenuClick(item)}>
              {item.name}
            </MenuItem>
          ))}
        </MenuContainer>
      )}
    </Wrapper>
  );
};
