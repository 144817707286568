import { ScheduledJourneyTablePagination, SortKeys,  } from "@evr/types";

import { ScheduledJourneyStatus } from "./scheduledJourney";

const now = new Date();
const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

export const scheduledJourneyPaginationInitialValue: ScheduledJourneyTablePagination = {
  pageSize: 5,
  totalItems: 0,
  totalPages: 1,
  pageIndex: 1,
  sort: { column: "", type: SortKeys.NONE },
  search: {},
  timeRange: { start:start.toISOString(), end: end.toISOString() },
  status: ScheduledJourneyStatus.SCHEDULED
};
