export interface LayersProps {
  children: React.ReactNode;
}

/**
 * Component to wrap a collection of map layer components
 * Does not actually render anything, but instead allows the layers to be declared in markup fashion
 * @component
 * @param {React.ReactNode} children - the children components to render
 * @example
 * <Map center={center} zoom={zoom} projection={Epsg27700}>
 *   <Layers>
 *     <Tile source={osmSource} />
 *   </Layers>
 *   ...
 * </Map>
 */
const Layers = ({ children }: LayersProps) => {
  return <>{children}</>;
};

export default Layers;
