import styled from "styled-components";

import { SnackbarType } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";

interface PropsType {
  type: SnackbarType;
}
export const SnackBox = styled(RowContainer)<PropsType>`
  position: absolute;
  bottom: -100px;
  right: 10px;
  justify-content: space-between;
  padding: 0.2rem 0.5rem 0.2rem 1rem;
  transition: all 0.4s ease-in-out;
  box-shadow: ${props => props.theme.shadows[3]};
  border-radius: ${props => props.theme.borderRadius};

  ${props =>
    props.type &&
    `
    background-color: ${props.theme.colors[props.type].light};
    color: ${props.theme.colors[props.type].contrastText};
  `}
  transform: ${props => (props.open ? "translateY(-110px)" : "translateY(0px)")};
  opacity: ${props => (props.open ? 1 : 0)};
  z-index: ${props => props.theme.zIndex.snackbar};
  min-width: 40%;
  max-width: 98%;
  @media (max-width: 600px) {
    left: 10px;
  }
`;
