import { useEffect, useState } from "react";

import { SwitchContainer, SwitchSlider } from "./styles";

export interface ToggleSwitchPropsType {
  disabled?: boolean;
  checked?: boolean;
  loading?: boolean;
  title?: string;
  value?: boolean;
  size?: "xs" | "sm" | "lg";
  onChange?: (status: boolean) => void;
}
export const ToggleSwitch = ({ onChange, value, checked, loading, disabled, title, size }: ToggleSwitchPropsType) => {
  const [active, setActive] = useState(checked || false);
  useEffect(() => {
    if (value != active) {
      handleChangeNoUserInput();
    } else {
      setActive(value);
    }
  }, [value]);
  const handleChangeNoUserInput = () => {
    onChange && onChange(!active);
    setActive(active => !active);
  };
  const handleChangeActive = () => {
    if (!disabled) {
      onChange && onChange(!active);
      setActive(active => !active);
    }
  };
  return (
    <SwitchContainer title={title} size={size}>
      <SwitchSlider disabled={disabled} fetching={loading} active={active} onClick={handleChangeActive} />
    </SwitchContainer>
  );
};
