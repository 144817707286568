import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { scheduledJourneyPaginationInitialValue, ScheduledJourneyStatus } from "@evr/constant";
import { SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { ScheduledJourneyTablePagination, SearchObject, SortColumn, TimeRange } from "@evr/types";
import { getDefaultSearchParams } from "@evr/utils";

interface ScheduledJourneyState {
  pagination: ScheduledJourneyTablePagination;
}

const initialState: ScheduledJourneyState = {
  pagination: scheduledJourneyPaginationInitialValue,
};

export const ScheduledJourneySlice = createSlice({
  name: SliceKeys.SCHEDULED_JOURNEYS,
  initialState: initialState,
  reducers: {
    setScheduledJourneyStatus: (state, action: PayloadAction<ScheduledJourneyStatus>) => {
      state.pagination.status = action.payload;
      state.pagination.pageIndex = scheduledJourneyPaginationInitialValue.pageIndex;
    },
    setScheduledJourneyTimeRange: (state, action: PayloadAction<TimeRange>) => {
      state.pagination.timeRange = action.payload;
      state.pagination.pageIndex = scheduledJourneyPaginationInitialValue.pageIndex;
    },
    setScheduledJourneyPagination: (state, action: PayloadAction<ScheduledJourneyTablePagination>) => {
      state.pagination = action.payload;
      state.pagination.pageIndex = scheduledJourneyPaginationInitialValue.pageIndex;
    },
  },
});

export const { setScheduledJourneyStatus, setScheduledJourneyTimeRange, setScheduledJourneyPagination } =
  ScheduledJourneySlice.actions;

export const selectScheduledJourneysQuery = createSelector(
  (state: RootState) => state.scheduledJourneys.pagination,
  ({ pageSize, pageIndex, search, sort, timeRange, status }) =>
    getSelectQuery(status, pageSize, pageIndex, search, sort, timeRange),
);

const getSelectQuery = (
  status: ScheduledJourneyStatus,
  pageSize: number,
  pageIndex: number,
  searchObject?: SearchObject,
  sort?: SortColumn,
  timeRange?: TimeRange,
) => {
  let params: URLSearchParams;
  if (status === ScheduledJourneyStatus.REJECTED || status === ScheduledJourneyStatus.CANCELLED)
    {
      params = getDefaultSearchParams(pageSize, pageIndex, searchObject, sort, timeRange);
      params.set("journeyStatus", ScheduledJourneyStatus.REJECTED+ "," + ScheduledJourneyStatus.CANCELLED);
    }
  else
    {
      params = getDefaultSearchParams(pageSize, pageIndex, searchObject, sort, timeRange);
      params.set("journeyStatus", status.toString());
    }
  return params.toString();
};

export const selectScheduledJourneyPagination = (state: RootState) => state.scheduledJourneys.pagination;

export default ScheduledJourneySlice.reducer;
