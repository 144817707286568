import { useEffect } from "react";

import { IconX } from "@tabler/icons";
import { useTheme } from "styled-components";

import { ResultSidebar } from "@evr/components/ResultSidebar";
import { ActiveLayerTypes } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectAllLiveJourneys } from "@evr/store/slices/all-live-journeys-result";
import { selectActiveLayer, setActiveLayer } from "@evr/store/slices/map";
import { clearCurrentResult, selectResultIsReady } from "@evr/store/slices/result";
import { clearScheduledJourneyResult, selectedScheduledJourney } from "@evr/store/slices/scheduled-journey-results";
import { closeSidebar, selectSidebarState, setSidebarTab, toggleSidebar } from "@evr/store/slices/sidebar";
import { SidebarTab } from "@evr/types";
import { Flex, Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { StyledIconButton } from "@evr/ui/IconButton/styles";

import AllLiveJourneysSidebar from "../AllLiveJourneysSidebar";
import InProgressSidebar from "../InProgressJourneySidebar.tsx";
import { ParcelSidebar } from "../ParcelSidebar";
import { ScheduledJourneySidebar } from "../ScheduledJourneySidebar";
import { RightSidebarContainer, RightSidebarScrollable, RightSidebarTab } from "./RightSidebarComponents";

export const RightSidebar = () => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const resultIsReady = useAppSelector(selectResultIsReady);
  const { open, tab: sidebarTab } = useAppSelector(selectSidebarState);
  const activeLayer = useAppSelector(selectActiveLayer);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  const handleSetSidebarTab = (name: SidebarTab) => {
    if (name === SidebarTab.RESULT) {
      dispatch(setActiveLayer(ActiveLayerTypes.RESULT));
    } else if (name === SidebarTab.PARCEL) {
      dispatch(setActiveLayer(ActiveLayerTypes.PARCEL));
    }
    dispatch(setSidebarTab(name));
  };

  const handleClearCurrentResult = () => dispatch(clearCurrentResult());

  const handleClearScheduled = () => {
    dispatch(clearScheduledJourneyResult());
    dispatch(setSidebarTab(SidebarTab.PARCEL));
    dispatch(toggleSidebar());
  };

  const selectedJourney = useAppSelector(selectedScheduledJourney);
  const isScheduledJourney = selectedJourney != null;

  const allLiveJourneysResult = useAppSelector(selectAllLiveJourneys);
  const isAllLiveJourneysResult = allLiveJourneysResult != null;

  const notInProgress =
    isScheduledJourney &&
    selectedJourney.status.toString() !== "InProgress" &&
    activeLayer === ActiveLayerTypes.SCHEDULED_JOURNEY;

  const minWidth =
    activeLayer === ActiveLayerTypes.RESULT ||
    notInProgress ||
    (activeLayer === ActiveLayerTypes.ALL_JOURNEYS &&
      isAllLiveJourneysResult &&
      allLiveJourneysResult?.resources.length)
      ? "80px"
      : "0";

  useEffect(() => {
    if (activeLayer === ActiveLayerTypes.DEPOTS) {
      dispatch(closeSidebar());
    }
  }, [activeLayer]);

  const renderCalculationAndParcel = () => {
    return (
      <>
        {open && (
          <Flex width="100%">
            <RightSidebarTab
              active={sidebarTab === SidebarTab.PARCEL}
              onClick={() => handleSetSidebarTab(SidebarTab.PARCEL)}
            >
              Parcels
            </RightSidebarTab>
            <RightSidebarTab
              active={sidebarTab === SidebarTab.RESULT}
              onClick={() => handleSetSidebarTab(SidebarTab.RESULT)}
            >
              Result
              {resultIsReady && (
                <StyledIconButton
                  noWH
                  color="inherit"
                  position="absolute"
                  right="10px"
                  hoverColor={colors.error.main}
                  title="Close current result"
                  onClick={handleClearCurrentResult}
                >
                  <IconX />
                </StyledIconButton>
              )}
            </RightSidebarTab>
          </Flex>
        )}
        <RightSidebarScrollable id="rightSidebar-scroller">
          <Wrapper textAlign="center" hidden={sidebarTab !== "result" || (!resultIsReady && !open)}>
            <ResultSidebar />
          </Wrapper>
          <Wrapper width="100%" textAlign="center" hidden={sidebarTab !== "parcel" || !open}>
            <ParcelSidebar />
          </Wrapper>
        </RightSidebarScrollable>
      </>
    );
  };

  const renderScheduledJourney = () => {
    return (
      <>
        {isScheduledJourney && open && (
          <>
            {" "}
            <Flex width="100%">
              <RightSidebarTab
                style={{ flexBasis: "100%" }}
                active={true}
                onClick={() => handleSetSidebarTab(SidebarTab.PARCEL)}
              >
                {selectedJourney.status} Route {new Date(selectedJourney.startTime).toLocaleString()}{" "}
                <StyledIconButton
                  noWH
                  color="inherit"
                  position="absolute"
                  right="10px"
                  hoverColor={colors.error.main}
                  title="Close current result"
                  onClick={handleClearScheduled}
                >
                  <IconX />
                </StyledIconButton>
              </RightSidebarTab>
            </Flex>
          </>
        )}
        {isScheduledJourney && selectedJourney.status.toString() !== "InProgress" && (
          <Wrapper textAlign="center" hidden={activeLayer !== ActiveLayerTypes.SCHEDULED_JOURNEY && !open}>
            <ScheduledJourneySidebar journey={selectedJourney} />
          </Wrapper>
        )}
        {isScheduledJourney && selectedJourney.status.toString() === "InProgress" && <InProgressSidebar />}
      </>
    );
  };

  const renderAllLiveJourneys = () => {
    return (
      <Wrapper textAlign="center" hidden={!isAllLiveJourneysResult && !open}>
        <RightSidebarScrollable>
          <AllLiveJourneysSidebar journeys={allLiveJourneysResult?.resources} />
        </RightSidebarScrollable>
      </Wrapper>
    );
  };

  return (
    <>
      <RightSidebarContainer width={open ? "380px" : minWidth}>
        {(activeLayer === ActiveLayerTypes.RESULT || activeLayer === ActiveLayerTypes.PARCEL) &&
          renderCalculationAndParcel()}
        {activeLayer === ActiveLayerTypes.SCHEDULED_JOURNEY && renderScheduledJourney()}
        {activeLayer === ActiveLayerTypes.ALL_JOURNEYS && renderAllLiveJourneys()}
        <Flex
          height="45px"
          width="45px"
          borderRadius="50% 0 0 50%"
          position="absolute"
          left="-35px"
          top="50%"
          background="white"
          transform="translateY(-50%)"
          zIndex={-1}
        >
          <IconButton
            onClick={handleToggleSidebar}
            icon="chevron-left"
            margin="0 5px 0 0"
            transition="transform 0.3s ease-out"
            transform={`rotate(${open ? "180" : "0"}deg)`}
          />
        </Flex>
      </RightSidebarContainer>
    </>
  );
};
