import { useTheme } from "styled-components";

import { Driver } from "@evr/types";
import { ColumnContainer, GridItem } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

interface PropsType {
  driver: Driver;
  isDriverActive: boolean;
  handleDriverSelected: () => void;
}

export const DVMatchSelectDriver = ({ driver, isDriverActive, handleDriverSelected }: PropsType) => {
  const { colors } = useTheme();

  return (
    <GridItem xs={3} sm={3} overflow="hidden" onClick={handleDriverSelected}>
      <ColumnContainer position="relative">
        {isDriverActive && (
          <IconButton
            color={colors.error.main}
            size={1}
            icon="times"
            position="absolute"
            top="5px"
            left="30px"
            right="0"
            noWH
          />
        )}
        <IconButton icon="user" title={driver.name} />
        <Typography margin="0.2rem 0 0 0" size={0.7} overflowEllipsis width="100%">
          {driver.name}
        </Typography>
      </ColumnContainer>
    </GridItem>
  );
};
