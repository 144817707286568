import LocationOverlay from "./LocationOverlay";
import ParcelDepotOverlay from "./ParcelDepot";

const ParcelOverlays = () => {
  return (
    <>
      <LocationOverlay />
      <ParcelDepotOverlay />
    </>
  );
};

export default ParcelOverlays;
