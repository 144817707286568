import { useEffect, useState } from "react";

import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  selectScheduledJourneyColor,
  selectScheduledJourneyShowActualRoute,
  selectScheduledJourneyShowOriginalCalculation,
  selectScheduledJourneyShowRecalculationRoutes,
  selectScheduledJourneyShowLocations,
  toggleShowActualRoute,
  toggleShowOriginalCalculation,
  toggleShowRecalculationRoute,
  toggleShowLocations,
  selectScheduledJourneyShowRecalculationLocations,
  toggleShowRecalculationLocations,
} from "@evr/store/slices/scheduled-journey-results";
import { selectJourneysColorArray } from "@evr/store/slices/setting";
import { ScheduledJourney } from "@evr/types";
import { StyledCheckBox } from "@evr/ui/Checkbox/styles";
import { Flex } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { Typography } from "@evr/ui/Typography";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0.5rem;
`;

const IconColumn = styled.div`
  width: 20%;
  text-align: center;
`;

const TextColum = styled.div`
  width: 60%;
  text-align: left;
`;

const ActionsColumn = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const RouteDropDownItem = ({ iconName, routeName, color, onToggle, checked }: RouteDropDownItemProps) => {
  return (
    <Flex width="100%" direction="row" justify="flex-start">
      <IconColumn>
        <Icon style={{ color: color }} size={1} className={iconName}></Icon>
      </IconColumn>
      <TextColum>
        <Typography size={1}>{routeName}</Typography>
      </TextColum>
      <ActionsColumn>{onToggle && <StyledCheckBox checked={checked} onChange={e => onToggle()} />}</ActionsColumn>
    </Flex>
  );
};

const MarginDiv = styled.div`
  margin-bottom: 0.15rem;
`;
interface RouteDropDownItemProps {
  iconName: string;
  routeName: string;
  color: string;
  onToggle?: () => void;
  checked?: boolean;
}
interface RouteDropdownItemsProps {
  scheduledJourney: ScheduledJourney;
}

const RouteDropdownItems = ({ scheduledJourney }: RouteDropdownItemsProps) => {
  const journeyColor = useAppSelector(selectScheduledJourneyColor);
  const journeyColors = useAppSelector(selectJourneysColorArray);
  const recalculationColors = journeyColors.filter(
    (x: any) => x.main !== journeyColor.main || x.contrast !== journeyColor.contrast,
  );

  const showActualRoute = useAppSelector(selectScheduledJourneyShowActualRoute);
  const showOriginalCalculation = useAppSelector(selectScheduledJourneyShowOriginalCalculation);
  const showRecalculationRoutes = useAppSelector(selectScheduledJourneyShowRecalculationRoutes);
  const showRecalculationLocations = useAppSelector(selectScheduledJourneyShowRecalculationLocations);
  const showLocations = useAppSelector(selectScheduledJourneyShowLocations);

  const dispatch = useAppDispatch();

  const toggleActualDriverJourney = () => {
    dispatch(toggleShowActualRoute());
  };

  const toggleOriginalJourney = () => {
    dispatch(toggleShowOriginalCalculation());
  };

  const toggleRecalc = (index: number) => {
    dispatch(toggleShowRecalculationRoute(index));
  };

  const toggleRecalcLocations = (index: number) => {
    dispatch(toggleShowRecalculationLocations(index));
  };

  const toggleLocations = () => {
    dispatch(toggleShowLocations());
  };

  return (
    <Container>
      <RouteDropDownItem
        iconName="fas fa-car"
        routeName="Actual Route"
        color={journeyColor.main}
        onToggle={toggleActualDriverJourney}
        checked={showActualRoute}
      />
      <RouteDropDownItem
        iconName="fas fa-route"
        routeName="Route"
        color={journeyColor.main}
        onToggle={toggleOriginalJourney}
        checked={showOriginalCalculation}
      />
      <RouteDropDownItem
        iconName="fas fa-map-marker-alt"
        routeName="Stops"
        color={journeyColor.main}
        onToggle={toggleLocations}
        checked={showLocations}
      />
      {scheduledJourney.recalculations.map((recalc, index) => {
        if (recalc.route.coordinates.length) {
          return (
            <div key={index}>
              <RouteDropDownItem
                iconName="fas fa-calculator"
                routeName={`Recalculation ${index + 1}`}
                color={recalculationColors[index].main}
              />{" "}
              <RouteDropDownItem
                iconName="fas fa-route"
                routeName={`Route`}
                color={recalculationColors[index].main}
                onToggle={() => toggleRecalc(index)}
                checked={showRecalculationRoutes[index]}
              />
              <MarginDiv />
              <RouteDropDownItem
                iconName="fas fa-map-marker-alt"
                routeName={`Stops`}
                color={recalculationColors[index].main}
                onToggle={() => toggleRecalcLocations(index)}
                checked={showRecalculationLocations[index]}
              />
            </div>
          );
        }
      })}
    </Container>
  );
};

export default RouteDropdownItems;
