import { useState } from "react";

import { Link } from "react-router-dom";

import { useForgotPasswordMutation } from "@evr/apis/accounts";
import { FormikForm, FormikTextInput } from "@evr/components/Form";
import { LoginBackground } from "@evr/components/LoginBackground";
import { AuthKeys } from "@evr/constant";
import { AuthTitle } from "@evr/pages/Auth/AuthTitle";
import { ForgotPasswordType } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { GridItem } from "@evr/ui/FlexBox";

import { forgotPassValidationSchema } from "./schema";
import { SendEmailAgainCounter } from "./SendEmailAgainCounter";

const initialValues: ForgotPasswordType = {
  [AuthKeys.EMAIL]: "",
};

export const ForgotPassword = () => {
  const [disable, setDisable] = useState<boolean>(false);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleSubmit = (values: ForgotPasswordType) => {
    forgotPassword(values)
      .unwrap()
      .then(() => setDisable(true));
  };

  return (
    <LoginBackground>
      <AuthTitle>Forgot Password</AuthTitle>
      <FormikForm initialValues={initialValues} validationSchema={forgotPassValidationSchema} onSubmit={handleSubmit}>
        <FormikTextInput placeholder="Email Address" name={AuthKeys.EMAIL} />
        <GridItem padding="1rem 0.5rem 0.5rem" width="100%" position="relative">
          <Button fullWidth fontSize="1.3rem" type="submit" padding="0.5rem" disabled={disable || isLoading}>
            {isLoading && <CircleLoading />}
            {!isLoading && disable ? <SendEmailAgainCounter count={90} setDisable={setDisable} /> : "Send Email"}
          </Button>
        </GridItem>
      </FormikForm>
      <GridItem padding="0.5rem" width="100%" textAlign="center" position="relative">
        <Link to="/signin">Have an account? Sign In</Link>
      </GridItem>
    </LoginBackground>
  );
};
