import { useState } from "react";

import { useLazyGetSubscriptionsQuery } from "@evr/apis/subscriptions";
import { DialogTitle, PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserSubscription } from "@evr/store/slices/auth";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogBody, DialogHeader, DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { ColumnContainer, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { ProgressBar } from "@evr/ui/ProgressBar";
import { Typography } from "@evr/ui/Typography";

export const PlanDialog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const subscription = useAppSelector(selectUserSubscription);

  const [getSubscription, { isLoading }] = useLazyGetSubscriptionsQuery();

  const handleBuySubscription = () => {
    global.window.open(subscription.planUrl, "_self")?.focus();
    setLoading(true);
  };

  return (
    <>
      <DialogHeader
        title={DialogTitle.PLAN}
        actionButtons={
          <DialogSyncHeaderButton
            onClick={() => {
              getSubscription();
            }}
            loading={isLoading}
          />
        }
      />
      <DialogBody>
        <Wrapper position="relative">
          {(loading || isLoading) && <CircleLoading size={5} blurBackground />}
          <ColumnContainer width="100%" align="flex-start">
            <Typography margin="0.5rem 0">
              <strong>Plan: </strong>
              {subscription.planName}
            </Typography>
            <Typography margin="0.5rem 0">
              <strong>Expiration Date: </strong>
              {new Date(subscription.expirationDate).toLocaleString()}
            </Typography>
            <Typography fontWeight="bold" margin="0.5rem 0">
              Usage:
            </Typography>
            <ProgressBar
              label="Calculation / Mo"
              labelSM={5}
              max={subscription.maxStoredCalculations}
              value={subscription.usedStoredCalculations}
            />
            <ProgressBar
              label="Geolocations call / Mo"
              labelSM={5}
              max={subscription.maxGeolocationsCallPerMonth}
              value={subscription.usedGeolocationsCallPerMonth}
            />
          </ColumnContainer>
          <RowContainer height="50px" width="100%" padding="1rem 0 0 0" justify="flex-end">
            <Button variant="text" onClick={handleBuySubscription} disabled={subscription.planType === PlanKeys.GOLD}>
              Upgrade
            </Button>
          </RowContainer>
        </Wrapper>
      </DialogBody>
    </>
  );
};
