import { CalculationEndpointApi } from "@evr/constant";
import { calculationApi } from "@evr/services/calculation";
import { apiCallThunk } from "@evr/store/actions/apiCall";

import { closeDialog } from "../dialog";

import { ResultSlice } from "./index";

const { setResult, setSavingsEnergyData } = ResultSlice.actions;

export const getCalculationResultAsync = (id: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/${id}`)({
    apiRequest: calculationApi.getCalculationResult(id),
    onSuccess: { type: setResult.type },
    onSuccessEnd: { type: closeDialog.type },
  });

export const getCalculationSavingsAsync = () =>
  apiCallThunk(CalculationEndpointApi.GET_SAVINGS)({
    apiRequest: calculationApi.getCalculationSavings(),
    onSuccess: { type: setSavingsEnergyData.type },
  });

export const getCalculationDataAsync = (calculationId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/calculationData/${calculationId}`)({
    apiRequest: calculationApi.getCalculationData(calculationId),
  });

export const getCalculationGpxAsync = (calculationId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/calculationGPX/${calculationId}`)({
    apiRequest: calculationApi.getCalculationData(calculationId, true, false),
  });

export const getCalculationCsvAsync = (calculationId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/calculationCSV/${calculationId}`)({
    apiRequest: calculationApi.getCalculationData(calculationId, false, true),
  });

export const getJourneyGpxAsync = (calculationId: number, journeyId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/journeyGPX/${journeyId}`)({
    apiRequest: calculationApi.getJourneyGpx(calculationId, journeyId),
  });

export const getJourneyCsvAsync = (calculationId: number, journeyId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/journeyCSV/${journeyId}`)({
    apiRequest: calculationApi.getJourneyCsv(calculationId, journeyId),
  });

export const getCalculationUnrouteableCsvAsync = (calculationId: number) =>
  apiCallThunk(`${CalculationEndpointApi.BASE}/unroutableParcels/${calculationId}}`)({
    apiRequest: calculationApi.getCalculationUnrouteableCsv(calculationId),
  });
