import { useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";

import { PhotoStatusKeys, SnackbarKeys } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { PhotoStatusType } from "@evr/types";
import { FlexProps } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { imageValidator } from "@evr/utils";

import { ImageDropArea, ImagePreviewWrapper, ImagePreviewOverlay } from "./styles";

interface PropsType extends FlexProps {
  WH?: string;
  cameraIconSize?: number;
  deleteIconSize?: number;
  setImg: React.Dispatch<React.SetStateAction<File | PhotoStatusKeys.EMPTY>>;
  setImgStatus?: React.Dispatch<React.SetStateAction<PhotoStatusType>>;
  img?: string;
}

export const ImageSelector = ({
  img,
  setImg,
  setImgStatus,
  WH,
  cameraIconSize,
  deleteIconSize,
  ...rest
}: PropsType) => {
  const dispatch = useAppDispatch();
  const [preview, setPreview] = useState<string>(img ? img : "");

  const handleDeletePreview = () => {
    setPreview("");
    setImg(PhotoStatusKeys.EMPTY);
    setImgStatus && setImgStatus(PhotoStatusKeys.REMOVED);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const err = imageValidator(file);
    if (err) {
      return dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: err.message }));
    }
    setPreview(URL.createObjectURL(file));
    setImg(file);
    setImgStatus && setImgStatus(PhotoStatusKeys.CHANGED);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    multiple: false,
    onDropAccepted: onDrop,
    noKeyboard: true,
  });

  useEffect(
    () => () => {
      if (!img) {
        URL.revokeObjectURL(preview);
      }
    },
    [preview, img],
  );
  return (
    <ImageDropArea {...getRootProps()} WH={WH} {...rest}>
      {preview ? (
        <ImagePreviewWrapper url={preview}>
          <ImagePreviewOverlay>
            <IconButton icon="trash-alt" size={deleteIconSize || 2} onClick={handleDeletePreview} />
          </ImagePreviewOverlay>
        </ImagePreviewWrapper>
      ) : (
        <>
          <input {...getInputProps()} />
          <IconButton icon="camera" size={cameraIconSize || 3.5} />
        </>
      )}
    </ImageDropArea>
  );
};
