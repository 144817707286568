import { Outlet } from "react-router-dom";

import { Snanckbar } from "@evr/components/Snackbar";
import { ColumnContainer } from "@evr/ui/FlexBox";

export const BaseLayout = () => (
  <ColumnContainer position="relative" overflow="hidden" align="initial" height="100vh">
    <Outlet />
    <Snanckbar />
  </ColumnContainer>
);
