import { useEffect, useState } from "react";

import { FeatureLike } from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { PopoverBody } from "react-bootstrap";
import styled from "styled-components";

import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { PopupOverlay } from "@evr/components/ReactOL/overlays";
import { MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import {
  editJobLocationCoordinates,
  selectEditLocationCoordinates,
  selectParcelMapState,
  selectValidJobLocations,
} from "@evr/store/slices/parcel";
import { JobLocation, Location } from "@evr/types";

import { locationPopup } from "../../Result/Unroutable/locationPopUp";

const StyledPopoverBody = styled(PopoverBody)`
  border-radius: 7px;
  min-width: 350px;
  padding: 1rem;
`;

const LocationOverlay = () => {
  const [location, setLocation] = useState<Location | JobLocation>();
  const locations = useAppSelector(selectValidJobLocations);
  const jobLocationsArray = Object.entries(locations);
  const { editLocation, jobLocation } = useAppSelector(selectParcelMapState);
  const editLocationCoordinate = useAppSelector(selectEditLocationCoordinates);

  useEffect(() => {
    if (!locations.length) {
      setLocation(undefined);
    }
    if (editLocation && jobLocation) {
      setLocation(jobLocation);
    }
  }, [locations, jobLocation, editLocation]);

  const mapClick = (e: FeatureLike[]) => {
    if (e.length) {
      const indexProperty = e[0].getProperties()["index"];
      const result = jobLocationsArray.find(([_, location], index) => index === indexProperty);
      if (result) {
        const resultLocation = result[1];
        setLocation(resultLocation as Location | JobLocation);
      }
    } else {
      setLocation(undefined);
    }
  };

  const getPopUpCoordinate = () => {
    if (location && editLocationCoordinate) {
      return editLocationCoordinate;
    } else {
      return location ? fromLonLat(location?.point.coordinates ?? [0, 0]) : undefined;
    }
  };

  return (
    <>
      <MapClick layerNames={[MapLayerNames.PARCEL_LOCATIONS]} onClick={mapClick} />
      <PopupOverlay placement="top" offset={[0, -20]} coordinate={getPopUpCoordinate()}>
        <StyledPopoverBody>
          <strong>
            {editLocation ? "Click on the map to relocate this parcel." : "Selected parcel on the sidebar."}
          </strong>
          <br />
          <br />
          <div>{location ? locationPopup(location) : null}</div>
        </StyledPopoverBody>
      </PopupOverlay>
    </>
  );
};

export default LocationOverlay;
