import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useSignInMutation } from "@evr/apis/accounts";
import { FormikForm, FormikTextInput, FormikPasswordInput } from "@evr/components/Form";
import { LoginBackground } from "@evr/components/LoginBackground";
import { AuthKeys } from "@evr/constant";
import { AuthTitle } from "@evr/pages/Auth/AuthTitle";
import { SignInType } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { GridItem } from "@evr/ui/FlexBox";

import { signInValidationSchema } from "./schema";

interface SignInPageParams {
  userEmail?: string;
}

export const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userEmail }: SignInPageParams = useParams();

  const [signInUser, { isLoading }] = useSignInMutation();

  const calculationId = (location.state as Record<string, string> | null)?.calculationId as string;

  const initialValues: SignInType = { email: userEmail ? userEmail : "", password: "" };

  const handleSignIn = (values: SignInType) => {
    signInUser(values)
      .unwrap()
      .then(() => {
        navigate(calculationId ? `/result/${calculationId}` : "/loadingApp", { replace: true });
      });
  };

  return (
    <LoginBackground>
      <AuthTitle>User Login</AuthTitle>
      <FormikForm initialValues={initialValues} validationSchema={signInValidationSchema} onSubmit={handleSignIn}>
        <FormikTextInput placeholder="Email Address" name={AuthKeys.EMAIL} autoFocus={!userEmail} />
        <FormikPasswordInput placeholder="Password" name={AuthKeys.PASSWORD} autoFocus={!!userEmail} />
        <GridItem padding="1rem 0.5rem 0.5rem" width="100%" position="relative">
          <Button fullWidth fontSize="1.3rem" type="submit" padding="0.5rem" disabled={isLoading}>
            {isLoading && <CircleLoading />}
            Login
          </Button>
        </GridItem>
      </FormikForm>
      <GridItem padding="0.5rem" width="100%" textAlign="center" position="relative">
        <Link to="/forgotpass">Forgot Password?</Link>
      </GridItem>
    </LoginBackground>
  );
};
