import { useGetDriversQuery } from "@evr/apis/driver/hooks";
import { TableSearch } from "@evr/components/Table";
import { TablePagination } from "@evr/components/Table/TablePagination";
import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import {
  clearDriverSearchColumn,
  selectDriversPagination,
  setDriverPageIndex,
  setDriverSearchColumn,
} from "@evr/store/slices/driver";
import { SearchColumn } from "@evr/types";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { TableWrapper } from "@evr/ui/Table";
import { Typography } from "@evr/ui/Typography";

import { DriverTable } from "./DriverTable";
import { driverTableColumns } from "./driverTableColumns";

export const DriverWindow = () => {
  const dispatch = useAppDispatch();
  const { search, pageIndex } = useAppSelector(selectDriversPagination);
  const { data, isFetching, isError, refetch } = useGetDriversQuery();

  const handleRefetchData = () => refetch();
  const handleClearSearchValue = (column: string) => dispatch(clearDriverSearchColumn(column));
  const handleChangeSearchValue = (item: SearchColumn) => dispatch(setDriverSearchColumn(item));
  const handleChangePageIndex = (page: number) => dispatch(setDriverPageIndex(page));
  const handleOpenDriverDialog = () => dispatch(openDialog({ type: Dialogs.ADD_DRIVER }));

  const Actions = (
    <Flex>
      <IconButton icon="plus" color="white" size={1.2} title="Add Driver" onClick={handleOpenDriverDialog} />
      <DialogSyncHeaderButton onClick={handleRefetchData} loading={isFetching} />
    </Flex>
  );

  return (
    <>
      <TableSearch
        search={search}
        Actions={Actions}
        columns={driverTableColumns}
        changeSearchValue={handleChangeSearchValue}
        clearSearchValue={handleClearSearchValue}
      />
      <TableWrapper>
        {isFetching && <CircleLoading color="primary" size={5} blurBackground />}
        {data ? (
          <DriverTable drivers={data.resources} />
        ) : (
          <Flex height="100%" width="100%">
            {isError && <Typography>Something went wrong. Please try again.</Typography>}
          </Flex>
        )}

        {data && data.resources.length === 0 && (
          <Typography textAlign="center" as="div">
            You have no Driver
          </Typography>
        )}
      </TableWrapper>
      <TablePagination totalPages={data?.totalPages} pageIndex={pageIndex} action={handleChangePageIndex} />
    </>
  );
};
