import { useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";
import { Typography } from "@evr/ui/Typography";

export const SettingJobDuration = () => {
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [jobDuration, setJobDuration] = useState(orgSettings.defaultJobDuration);

  const [editJobDuration, { isLoading }] = useEditSettingsMutation();

  const isFreePlan = planType === PlanKeys.STARTER;

  const handleSetJobDuration = () => {
    editJobDuration({ ...orgSettings, defaultJobDuration: jobDuration });
  };

  const handleChangeJobDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setJobDuration(value);
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Job Duration (mins)</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto" title={isFreePlan ? "This item is not active on your plan" : ""}>
        <Input
          type="number"
          width="80px"
          padding="0.35rem 0.4rem"
          disabled={isFreePlan}
          value={jobDuration}
          min={0}
          max={24 * 60}
          onChange={handleChangeJobDuration}
        />
        <Wrapper position="relative">
          {isLoading && <CircleLoading size={4} />}
          <Button onClick={handleSetJobDuration} disabled={isFreePlan || isLoading}>
            Set
          </Button>
        </Wrapper>
      </Flex>
    </RowContainer>
  );
};
