export enum AddressKeys {
  ID = "id",
  FIRST_LINE = "firstLine",
  SECOND_LINE = "secondLine",
  CITY = "city",
  STATE = "state",
  POSTCODE = "postcode",
  COUNTRY_ID = "countryId",
  COUNTRY = "country",
}
