import { useFormikContext } from "formik";

import { Select, SelectDataType, SelectProps } from "@evr/ui/Select";

interface FormikSelectProps extends Omit<SelectProps, "name" | "items" | "onChange"> {
  name: string;
  items: SelectDataType[];
  valueIsNumber?: boolean;
}

export const FormikSelect = ({ name, items, valueIsNumber, ...restProps }: FormikSelectProps) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = valueIsNumber ? Number(e.target.value) : e.target.value;
    setFieldValue(name, value);
  };

  const currentValues = (values as Record<string, number | string>)[name];
  const errorText = (errors as Record<string, string>)[name];

  return <Select error={!!errorText} items={items} value={currentValues} onChange={handleOnChange} {...restProps} />;
};
