import styled from "styled-components";

import { ColumnContainer } from "@evr/ui/FlexBox";

export const ParcelContainer = styled(ColumnContainer)`
  position: absolute;
  justify-content: flex-start;
  align-items: flex-start;
  top: 90%;
  width: 700px;
  max-height: 70vh;
  overflow: hidden;
  background-color: ${props => props.theme.colors.common.white};
  box-shadow: ${props => props.theme.shadows[6]};
  border-radius: ${props => props.theme.borderRadius};
  z-index: ${props => props.theme.zIndex.window};
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    right: calc(50vw - 700px + 5%);
  }
  @media (max-width: 1400px) {
    right: 5%;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 90%;
  }
`;

export const ParcelDropArea = styled.div`
  padding: 2.5rem 1.5rem;
  border: 1px dashed #ccc;
  border-radius: 1rem;
  width: 100%;
  text-align: center;
  background: #f0f0f0;
`;
