import { SortKeys, TablePagination } from "@evr/types";

export const MAX_PAGE_SIZE = 10000;
export const DEFAULT_PAGE_INDEX = 1;

export const tablePaginationInitialValue: TablePagination = {
  pageSize: 5,
  totalItems: 0,
  totalPages: 1,
  pageIndex: 1,
  sort: { column: "", type: SortKeys.NONE },
  search: {},
};
