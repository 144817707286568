export interface InteractionsProps {
  children?: React.ReactNode;
}

/**
 * Component to wrap a collection of overlays components
 * Does not actually render anything, but instead allows the controls to be renders after the map container
 * @component
 * @param {React.ReactNode} children - the children components to render
 */
const Overlays = ({ children }: InteractionsProps) => {
  return <>{children}</>;
};

export default Overlays;
