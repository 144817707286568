import { Point } from "geojson";



export interface Dimensions {
  weight: number | string;
  width?: number | string;
  height?: number | string;
  depth?: number | string;
}

export enum JobTask {
  COLLECTION = "collection",
  DELIVERY = "delivery",
}

export enum JobType {
  GENERIC = "Generic",
  PACKAGE = "Package",
  PICKUP = "Pickup",
  CHARGE = "Charge",
}


export enum ProofOfDeliveryRequirementOption {
  PHOTO_REQUIRED = "Photo",
  SIGNATURE_REQUIRED = "Signature",
  BOTH = "Both",
  NONE = "None",
  DEFAULT = "Organisation Default",
}

export interface GenericJob {
  jobType: JobType;
  jobName?: string;
  customerName?: string;
  trackingId?: string;
  duration?: number;
  status?: string;
  completedTime?: Date;
  driverComments?: string;
  latitude?: number;
  longitude?: number;
}

export interface Parcel extends GenericJob {
  jobType: JobType.PACKAGE | JobType.PICKUP | JobType.CHARGE;
  dimensions: Dimensions;
  quantity?: number | string;
  proofOfDeliveryRequirement?: ProofOfDeliveryRequirementOption | null;
  photoKey: string | null;
  signatureKey: string | null;
  signedName: string | null;
  photoURL: string | null;
  signatureURL: string | null;
}

export type Job = GenericJob | Parcel;

export interface JobLocation {
  id?: number;
  point: Point;
  houseNumber: string;
  streetName: string;
  city: string;
  postcode: string;
  description: string;
  legTime?: number | string;
  jobs: Job[];
  openingTime?:string | null;
  closingTime?:string | null;
  proofOfDeliveryRequirement: string | null;
}
