import { evrApi } from "@evr/apis";
import { SliceKeys } from "@evr/constant";

import AllLiveJourneysReducer from "./all-live-journeys-result";
import AuthReducer from "./auth";
import CalcReducer from "./calculation";
import DepotReducer from "./depot";
import DialogReducer from "./dialog";
import DriverReducer from "./driver";
import DVMatchReducer from "./dvMatch";
import MapSliceReducer from "./map";
import ParcelReducer from "./parcel";
import ResultReducer from "./result";
import ScheduledJourneyResultsReducer from "./scheduled-journey-results";
import ScheduledJourneyReducer from "./scheduled-journeys";
import SettingsReducer from "./setting";
import SidebarReducer from "./sidebar";
import SnackbarReducer from "./snackbar";
import VehicleReducer from "./vehicle";
import VerizonReducer from "./verizon";
import WindowReducer from "./window";

export const appReducers = {
  [SliceKeys.SNACKBAR]: SnackbarReducer,
  [SliceKeys.DEPOTS]: DepotReducer,
  [SliceKeys.DILAOG]: DialogReducer,
  [SliceKeys.WINDOW]: WindowReducer,
  [SliceKeys.DRIVERS]: DriverReducer,
  [SliceKeys.VEHICLES]: VehicleReducer,
  [SliceKeys.DV_MATCH]: DVMatchReducer,
  [SliceKeys.PARCEL]: ParcelReducer,
  [SliceKeys.SETTINGS]: SettingsReducer,
  [SliceKeys.AUTH]: AuthReducer,
  [SliceKeys.RESULT]: ResultReducer,
  [SliceKeys.CALCULATION]: CalcReducer,
  [SliceKeys.SIDEBAR]: SidebarReducer,
  [SliceKeys.SCHEDULED_JOURNEYS]: ScheduledJourneyReducer,
  [SliceKeys.SCHEDULED_JOURNET_RESULT]: ScheduledJourneyResultsReducer,
  [SliceKeys.ALL_LIVE_JOURNEYS_RESULT]: AllLiveJourneysReducer,
  [SliceKeys.MAP]: MapSliceReducer,
  [evrApi.reducerPath]: evrApi.reducer,
  [SliceKeys.VERIZON]: VerizonReducer,
};
