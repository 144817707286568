import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { LocalStorageKeys, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import {
  DateRangeResourceListResult,
  DriverProgressSnapshot,
  JourneyColor,
  ScheduledJourney,
  UpdateJourneyProgressRequest,
} from "@evr/types";
import { localStorageHelper } from "@evr/utils";

interface AllLiveJourneysResult {
  journeys: DateRangeResourceListResult<ScheduledJourney> | null;
  fitToBounds: boolean;
  colorMap: { journeyId: number; color: JourneyColor }[];
}

const initialState: AllLiveJourneysResult = {
  journeys: null,
  fitToBounds: true,
  colorMap: [],
};

export const AllLiveJourneysResultSlice = createSlice({
  name: SliceKeys.ALL_LIVE_JOURNEYS_RESULT,
  initialState: initialState,
  reducers: {
    setAllLiveJourneyResult: (state, action: PayloadAction<DateRangeResourceListResult<ScheduledJourney>>) => {
      const journeyColorsArray = Object.values(localStorageHelper.getItem(LocalStorageKeys.SETTING).journeysColor);
      const journeys = action.payload.resources;
      const colors = state.colorMap.filter(x => journeys.map(x => x.id).includes(x.journeyId));
      journeys.forEach(j => {
        if (!colors.map(x => x.journeyId).includes(j.id) && j.latestDriverProgress) {
          const usedColors = colors.map(colorObj => colorObj.color);
          const allColorsUsed = journeyColorsArray.every(color =>
            usedColors.map(x => JSON.stringify(x)).includes(JSON.stringify(color)),
          );
          if (allColorsUsed) {
            colors.push({ journeyId: j.id, color: colors[colors.length - journeyColorsArray.length].color });
          } else {
            const usedColors = colors.map(x => x.color);
            const unusedColors = journeyColorsArray.filter(
              color => !usedColors.map(x => JSON.stringify(x)).includes(JSON.stringify(color)),
            ) as JourneyColor[];
            colors.push({ journeyId: j.id, color: unusedColors[0] });
          }
        }
      });

      const journeyWithPosition = action.payload.resources.filter(x => x.latestDriverProgress != null);
      const filteredPayload = { ...action.payload, resources: journeyWithPosition };

      state.journeys = filteredPayload;
      state.colorMap = colors;
    },
    clearAllLiveJourneyResult: state => {
      state.journeys = null;
    },
    updateDriverLocation: (state, action: PayloadAction<UpdateJourneyProgressRequest>) => {
      if (state.journeys) {
        const latestSnapshot = {
          stateOfCharge: action.payload.remainingBatteryCapacity,
          point: action.payload.currentLocation,
          timeStamp: new Date().toISOString(),
          locationId: action.payload.locationId,
        } as DriverProgressSnapshot;
        state.fitToBounds = false;
        state.journeys.resources = state.journeys.resources.map(journey => {
          if (journey.id === action.payload.journeyId) {
            return { ...journey, latestDriverProgress: latestSnapshot }; // Update the value property of the matched object
          }
          return journey;
        });
      }
    },
    updateColorMap: (state, action: PayloadAction<{ oldColor: JourneyColor; newColor: JourneyColor }>) => {
      state.colorMap = state.colorMap.map(x => {
        if (JSON.stringify(x.color) === JSON.stringify(action.payload.oldColor)) {
          return { ...x, color: action.payload.newColor };
        }
        return x;
      });
    },
  },
});

export const { setAllLiveJourneyResult, clearAllLiveJourneyResult, updateDriverLocation, updateColorMap } =
  AllLiveJourneysResultSlice.actions;

export const selectAllLiveJourneys = (state: RootState) => state.allLiveJourneysResult.journeys;

export const selectFitAllJourneysToBounds = (state: RootState) => state.allLiveJourneysResult.fitToBounds;

export const selectLiveJourneysColorMap = (state: RootState) => state.allLiveJourneysResult.colorMap;

export default AllLiveJourneysResultSlice.reducer;
