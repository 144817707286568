import flag from "@evr/assets/result/flag.png";
import stopwatch from "@evr/assets/result/stopwatch.png";
import {
  RegistrationNumber,
  ResultCardItemDetails,
} from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { ScheduledJourney } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { Typography } from "@evr/ui/Typography";
import { getTimeTrip } from "@evr/utils";

interface ScheduledJourneyDetailsProps {
  scheduledJourney: ScheduledJourney;
  hidden: boolean;
}

export const ScheduledJourneyDetails = ({ scheduledJourney, hidden }: ScheduledJourneyDetailsProps) => {
  let eta = "N/A";
  if (scheduledJourney.status.toString() == "Completed") {
    if (scheduledJourney.driverProgress.length == 0) {
      eta = "N/A";
    } else {
      eta = scheduledJourney.driverProgress[scheduledJourney.driverProgress.length - 1].timeStamp;
    }
  } else {
    eta = new Date(
      new Date(scheduledJourney.startTime).getTime() + scheduledJourney.tripTime * 1000,
    ).toLocaleTimeString();
  }

  return (
    <RowContainer padding="1rem" width="100%" justify="space-between" hidden={hidden}>
      <ResultCardItemDetails width="32%">
        <Img src={stopwatch} alt="stopwatch" height="30px" width="30px" />
        <Typography fontWeight="bold" overflowEllipsis width="100%">
          {getTimeTrip(scheduledJourney.tripTime)}
        </Typography>
        <Typography fontWeight={400}>Total Time Elapsed</Typography>
      </ResultCardItemDetails>
      <ResultCardItemDetails width="30%">
        <RegistrationNumber>{scheduledJourney.vehicle.licence || "-"}</RegistrationNumber>
      </ResultCardItemDetails>
      <ResultCardItemDetails width="32%">
        <Img src={flag} alt="stopwatch" height="30px" width="30px" />
        <Typography fontWeight="bold" overflowEllipsis width="100%">
          {eta}
        </Typography>

        <Typography fontWeight={400}>
          {scheduledJourney.status.toString() === "Completed" && <>Arrival Time</>}
          {scheduledJourney.status.toString() !== "Completed" && <>Calculated Time of Completion</>}
        </Typography>
      </ResultCardItemDetails>
    </RowContainer>
  );
};
