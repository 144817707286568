import { FieldHookConfig, useField } from "formik";

import { TextInput, TextInputPropsType } from "@evr/ui/TextInput";

export const FormikTextInput = (props: FieldHookConfig<string> & TextInputPropsType) => {
  const [field, meta] = useField(props);

  const err = Boolean(meta.touched && meta.error);
  return (
    <TextInput
      id={props.name}
      {...field}
      {...props}
      error={err}
      errorText={err ? meta.error : null}
      warning={props.warning}
      warningText={props.warningText}
    />
  );
};
