import { DistanceUnitKeys, EngineType, vehicleTypeSymbol } from "@evr/constant";
import { Vehicle } from "@evr/types";

import { batteryBufferToPercent } from "./batteryBuffer";

const MilesPerMeter = 1609.344;

export const getTripLength = (meters: number, unit: DistanceUnitKeys) => {
  if (unit === DistanceUnitKeys.MILES) {
    return Math.round(meters / MilesPerMeter);
  }
  return Math.round(meters / 1000);
};

export const getFuelIconAndRemainingBattery = (FinalStateOfCharge?: number, vehicle?: Vehicle) => {
  let remainingBatteryOrSymbol = "";
  let fuelIcon = "burn";
  if (!vehicle || vehicle.engineType !== EngineType.ELECTRIC) {
    remainingBatteryOrSymbol = !vehicle ? "NA" : vehicleTypeSymbol[vehicle.engineType];
    return { remainingBatteryOrSymbol, fuelIcon };
  }
  if (!FinalStateOfCharge && FinalStateOfCharge !== 0) {
    return { remainingBatteryOrSymbol: "NA", fuelIcon: "battery-empty" };
  }
  const remaining = batteryBufferToPercent(FinalStateOfCharge);
  remainingBatteryOrSymbol = remaining < 0 ? "0%" : `${remaining}%`;
  if (remaining <= 15) {
    fuelIcon = "battery-empty";
  } else if (remaining <= 35 && remaining > 15) {
    fuelIcon = "battery-quarter";
  } else if (remaining <= 60 && remaining > 35) {
    fuelIcon = "battery-half";
  } else if (remaining <= 90 && remaining > 60) {
    fuelIcon = "battery-three-quarters";
  } else {
    fuelIcon = "battery-full";
  }
  return { remainingBatteryOrSymbol, fuelIcon };
};

export const getTimeTrip = (time?: number) => {
  if (!time) {
    return "0";
  }
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

export const convertKgToGrams = (kilograms: number) => (kilograms * 1000).toFixed(2);
