import { useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { ToggleSwitch } from "@evr/ui/ToggleSwitch";
import { Typography } from "@evr/ui/Typography";

export const ToggleSpreadWork = () => {
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [spreadWork] = useState(orgSettings.spreadWork);

  const isFreePlan = planType === PlanKeys.STARTER;

  const [editSpreadWork, { isLoading }] = useEditSettingsMutation();

  const onChange = (status: boolean) => {
    let updatedOrgSettings = { ...orgSettings };
    if (status === true) {
      // We want to disable the prioritise elctric vehicle option if this is set
      updatedOrgSettings.prioritiseEVs = false;
    }

    editSpreadWork({ ...updatedOrgSettings, spreadWork: status });
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Spread Work Between Drivers</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto" title={isFreePlan ? "This item is not active on your plan" : ""}>
        <ToggleSwitch
          onChange={onChange}
          loading={isLoading}
          disabled={isFreePlan}
          value={spreadWork}
          checked={spreadWork}
        ></ToggleSwitch>
        <Wrapper position="relative">{isLoading && <CircleLoading size={4} />}</Wrapper>
      </Flex>
    </RowContainer>
  );
};
