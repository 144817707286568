import { RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";

interface PropsType {
  id: number;
  edit?: boolean;
  remove?: boolean;
  onEditClick?: (id: number) => void;
  onRemoveClick?: (id: number) => void;
}

export const TableAction = ({ id, edit = true, remove = true, onEditClick, onRemoveClick }: PropsType) => {
  return (
    <td>
      <RowContainer>
        {remove && (
          <IconButton
            icon="trash-alt"
            size={1.2}
            hoverColor="errorLight"
            margin="0 0.5rem"
            WH="25px"
            onClick={() => onRemoveClick && onRemoveClick(id)}
          />
        )}
        {edit && (
          <IconButton
            icon="pencil-alt"
            size={1.2}
            hoverColor="infoLight"
            margin="0 0.5rem"
            WH="25px"
            onClick={() => onEditClick && onEditClick(id)}
          />
        )}
      </RowContainer>
    </td>
  );
};
