import { useState } from "react";

import { ResultCardTab } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { DriverDetails } from "@evr/components/ResultSidebar/ResultCard/ResultDetails";
import { ScheduledJourney } from "@evr/types";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

import { RecalculationDetails } from "./RecalculationDetails";

enum ResultDetailsTab {
  DRIVER = "driver",
  JOURNEY = "journey",
}

interface RecalculationResultDetailsProps {
  scheduledJourney: ScheduledJourney;
}

export const RecalculationResultDetails = ({ scheduledJourney }: RecalculationResultDetailsProps) => {
  const [detailsTab, setDetailsTab] = useState<ResultDetailsTab>(ResultDetailsTab.JOURNEY);

  return (
    <ColumnContainer width="100%" minHeight="145px" justify="flex-start">
      <Flex width="100%">
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.DRIVER}
          onClick={() => setDetailsTab(ResultDetailsTab.DRIVER)}
        >
          Driver
        </ResultCardTab>
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.JOURNEY}
          onClick={() => setDetailsTab(ResultDetailsTab.JOURNEY)}
        >
          Journey
        </ResultCardTab>
      </Flex>

      <RecalculationDetails scheduledJourney={scheduledJourney} hidden={detailsTab !== ResultDetailsTab.JOURNEY} />
      <DriverDetails driver={scheduledJourney.driver} hidden={detailsTab !== ResultDetailsTab.DRIVER} />
    </ColumnContainer>
  );
};
