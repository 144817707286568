import { useEffect, useState } from "react";

import styled from "styled-components";

import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { detachedDialog, selectDialog } from "@evr/store/slices/dialog";
import { DialogContainer } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";

import { dialogs } from "./dialogs";

const Layout = styled(Flex)`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: ${props => props.theme.zIndex.modal};
  transition: opacity 0.1s ease-out;
  ${props => props.opacity && `opacity: ${props.opacity};`}
`;

export const Dialog = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const dialog = useAppSelector(selectDialog);

  useEffect(() => {
    if (dialog.onClose) {
      setOpen(false);
    }
    if (!dialog.onClose && dialog.type !== Dialogs.CLOSE) {
      setOpen(true);
    }
  }, [dialog]);

  const handleDetachedDialog = () => {
    if (!dialog.onClose) {
      return;
    }
    dispatch(detachedDialog());
  };

  const dialogObject = dialogs[dialog.type];

  if (dialog.type === Dialogs.CLOSE) {
    return null;
  }
  return (
    <>
      <Layout opacity={open ? 0.6 : 0} bgColor="black" />
      <Layout opacity={open ? 1 : 0} onTransitionEnd={handleDetachedDialog}>
        <DialogContainer maxWidth={dialogObject.maxWidth}>
          <dialogObject.component />
        </DialogContainer>
      </Layout>
    </>
  );
};
