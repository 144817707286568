import styled from "styled-components";

import { useReRunCalculationMutation } from "@evr/apis/calculation";
import { CalculationStatusKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectNewCalculationIds } from "@evr/store/slices/calculation";
import { Calculation } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

const Container = styled(Flex)`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.action.border};
  }
`;
interface PropsType {
  data: Calculation;
  getCalculationResult: (calculationHeaderId: number) => void;
}

export const NotificationItem = ({ data, getCalculationResult }: PropsType) => {
  const newCalculationIds = useAppSelector(selectNewCalculationIds);
  const [reRunCalculation, { isLoading }] = useReRunCalculationMutation();

  const date = new Date(data.created).toLocaleString();

  const isCalculationFailed = data.status === CalculationStatusKeys.FAILED;
  const isCalculationInProgress = data.status === CalculationStatusKeys.IN_PROGRESS;
  const isNewCalculation = newCalculationIds.includes(data.id);

  const handleGetCalculationResult = () => {
    getCalculationResult(data.id);
  };
  const handleRerunCalculation = () => reRunCalculation(data.id);
  return (
    <Container
      width="100%"
      justify="space-between"
      opacity={isCalculationInProgress ? 0.6 : 1}
      color={isNewCalculation ? "success" : "greyDefault"}
    >
      <ColumnContainer align="flex-start">
        {isCalculationFailed && <Typography color="errorDark">Failed</Typography>}
        {isNewCalculation && <Typography color="error">New Calculation</Typography>}
        {isCalculationInProgress && <Typography color="warning">In Progress</Typography>}
        <Typography textAlign="left">
          Depot: {data.depot.name} -- Created: {date} -- Parcels: {data.numberOfParcels || "NA"}
          <br />
          Number of drivers: {data.driverVehiclePairs.length}
        </Typography>
        <Typography textAlign="left" margin={isCalculationFailed ? "0.5rem 0 0" : "0"}>
          {isCalculationFailed ? (
            <>{data.failureReason ?? "Please contact Basemap Support."}</>
          ) : (
            !!data.numberOfUnrouteableParcels && `Unroutable Parcels: ${data.numberOfUnrouteableParcels || "NA"}`
          )}
        </Typography>
      </ColumnContainer>
      {isCalculationFailed ? (
        <Flex position="relative">
          {isLoading && <CircleLoading size={4} blurBackground />}
          <Button variant="outlined" color="primaryDark" disabled={isLoading} onClick={handleRerunCalculation}>
            Try Again
          </Button>
        </Flex>
      ) : (
        <IconButton
          icon="download"
          hoverColor="success"
          title="Download Calculation"
          disabled={isCalculationInProgress}
          onClick={handleGetCalculationResult}
          color={isNewCalculation ? "success" : "greyDefault"}
        />
      )}
    </Container>
  );
};
