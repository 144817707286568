import { PayloadAction } from "@reduxjs/toolkit";
import { Point } from "geojson";
import { ParseResult } from "papaparse";

import { ParcelProcessedStatusKeys } from "@evr/constant";
import { JobLocation, Location, ParcelState, TableHeaderType } from "@evr/types";
import { getNewCSVHeaderSelectedByUser, getParcelItemsSelectedByUser, transformParcelTableHeader } from "@evr/utils";

import { parcelInitialState } from "./initialState";

export const ParcelReducer = {
  setNewParcelheader: (parcels: ParcelState, action: PayloadAction<TableHeaderType[]>) => {
    parcels.newHeader = action.payload;
    parcels.enableNextBt = true;
  },
  nextParcelItem: (parcels: ParcelState, action: PayloadAction<{ skip: boolean }>) => {
    if (parcels.future.length === 0 || parcels.present.header.length === 0) {
      return;
    }
    if (action.payload.skip) {
      parcels.future[0].header = parcels.present.header;
      parcels.newHeader = parcels.present.header;
    } else {
      parcels.future[0].header = parcels.newHeader;
    }
    const newPresent = parcels.future[0];

    parcels.past = [...parcels.past, parcels.present];
    parcels.present = newPresent;
    parcels.future = parcels.future.slice(1);
    parcels.enableNextBt = false;
    if (parcels.future.length === 0) {
      parcels.newHeader = parcels.newHeader.map(header => ({ ...header, clickable: false }));
    }
  },
  backParcelItem: (parcels: ParcelState) => {
    if (parcels.past.length === 0 || parcels.present.header.length === 0) {
      return;
    }

    const newPresent = parcels.past[parcels.past.length - 1];
    parcels.newHeader = newPresent.header;
    parcels.future = [parcels.present, ...parcels.future];
    parcels.present = newPresent;
    parcels.past = parcels.past.slice(0, parcels.past.length - 1);
    parcels.enableNextBt = false;
  },
  addParcelCSV: (parcels: ParcelState, action: PayloadAction<ParseResult<string[]>>) => {
    const header = action.payload.meta.fields as string[];
    const transformedHeader = transformParcelTableHeader(header);
    const data = action.payload.data as unknown as Record<string, string>[];

    parcels.data = data;
    parcels.header = header;
    parcels.newHeader = transformedHeader;
    parcels.present.header = transformedHeader;
    parcels.numberOfParcel = data.length;
    parcels.status = ParcelProcessedStatusKeys.SELECT_FILE;
  },
  setParcelStatus: (parcels: ParcelState, action: PayloadAction<ParcelProcessedStatusKeys>) => {
    parcels.status = action.payload;
  },
  preProcessParcels: (parcels: ParcelState) => {
    const { newParcelHeader } = getNewCSVHeaderSelectedByUser(parcels.present.header);

    for (let i = 0; i < parcels.data.length; i++) {
      const parcel = parcels.data[i];

      const { requiredItems, optionalItems, parcel: modifiedparcel } = getParcelItemsSelectedByUser(newParcelHeader, parcel);
      const { city, postcode, streetName, houseNumber } = requiredItems;
      const { openingTime, closingTime, proofOfDeliveryRequirement } = optionalItems;

      if (!city || !postcode || !streetName || !houseNumber) {
        parcels.processedState.invalidParcels.push({ ...parcel, Reason: "Missing required items" });
        parcels.numberOfProcessedParcel += 1;
        if (parcels.numberOfProcessedParcel >= parcels.numberOfParcel) {
          parcels.status = ParcelProcessedStatusKeys.DONE;
        }
        continue;
      }
      //concatenate address to have a unique key
      const key = [postcode, houseNumber, streetName, city].join(", ");
      const description = `${key}&&${i}`;
      if (parcels.processedState.isAppendingParcels && key in parcels.processedState.jobLocations.valids) {
        const location = parcels.processedState.jobLocations.valids[key];
        const jobs = (location as JobLocation)?.jobs ?? (location as Location)?.addresses?.flatMap(a => a.jobs);
        jobs.push(modifiedparcel);
        parcels.numberOfProcessedParcel += 1;
        if (parcels.numberOfProcessedParcel >= parcels.numberOfParcel) {
          parcels.status = ParcelProcessedStatusKeys.DONE;
        }
      } else if (key in parcels.processedState.jobLocations.temp) {
        const location = parcels.processedState.jobLocations.temp[key];      
        const jobs = (location as JobLocation)?.jobs ?? (location as Location)?.addresses?.flatMap(a => a.jobs);
        jobs.push(modifiedparcel);
      } else {
        const point = { type: "Point", coordinates: [0, 0] } as Point;
        const newParcel: JobLocation = {
          point,
          city,
          postcode,
          streetName,
          houseNumber,
          description,
          openingTime: openingTime,
          closingTime: closingTime,
          jobs: [modifiedparcel],
          proofOfDeliveryRequirement
        };
        parcels.processedState.jobLocations.temp[key] = newParcel;
      }
    }
    parcels.processedState.isAppendingParcels = false;
    parcels.status = ParcelProcessedStatusKeys.IN_PROGRESS;
  },
  addAdditionalParcels: (parcels: ParcelState) => {
    const { processedState } = parcels;
    return {
      ...parcelInitialState,
      processedState: {
        ...processedState,
        isAppendingParcels: true,
        jobLocations: { ...processedState.jobLocations, temp: {} },
      },
    };
  },
  removeSelectedCsvFile: (parcels: ParcelState) => {
    if (parcels.processedState.isAppendingParcels) {
      const { processedState } = parcels;
      return { ...parcelInitialState, processedState };
    }
    return parcelInitialState;
  },
  removeParcelsData: () => parcelInitialState,
};
