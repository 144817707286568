import { useRef } from "react";

import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight, TablerIcon } from "@tabler/icons";

import { Button } from "@evr/ui/Button";
import { Flex } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";
import { Typography } from "@evr/ui/Typography";

interface PaginationButtonProps {
  Icon: TablerIcon;
  disabled?: boolean;
  onClick?: () => void;
}

interface TablePaginationProps {
  totalPages?: number;
  pageIndex: number;
  action: (pageIndex: number) => void;
}

const PaginationButton = ({ Icon, onClick, disabled }: PaginationButtonProps) => (
  <Button padding="0.5rem 0.2rem" variant="text" margin="0.2rem 0" disabled={disabled} onClick={onClick}>
    <Icon size={20} />
  </Button>
);

export const TablePagination = ({ totalPages = 1, pageIndex, action }: TablePaginationProps) => {
  const inputPageIndexRef = useRef<HTMLInputElement>(null!);

  const canPreviousPage = totalPages > 1 && pageIndex > 1;
  const canNextPage = totalPages > pageIndex;

  const gotoPage = (page: number) => action(page);

  const handlePageIndex = () => {
    const page = Number(inputPageIndexRef.current.value);
    if (page > totalPages || page <= 0) {
      return;
    }
    gotoPage(page);
  };

  return (
    <Flex wrap="wrap" width="100%" padding="0.25rem 1rem" bgColor="greyLight" justify="center" margin="auto 0 0">
      <Flex margin="0 1.5rem">
        <PaginationButton Icon={IconChevronsLeft} onClick={() => gotoPage(1)} disabled={!canPreviousPage} />
        <PaginationButton Icon={IconChevronLeft} onClick={() => gotoPage(pageIndex - 1)} disabled={!canPreviousPage} />
        <Typography overflowEllipsis fontWeight="bold" margin="0 1.5rem">
          {pageIndex} of {totalPages}
        </Typography>
        <PaginationButton Icon={IconChevronRight} onClick={() => gotoPage(pageIndex + 1)} disabled={!canNextPage} />
        <PaginationButton Icon={IconChevronsRight} onClick={() => gotoPage(totalPages)} disabled={!canNextPage} />
      </Flex>
      <Flex>
        <Typography margin="0 0.5rem">Go to page: </Typography>
        <Input
          width="60px"
          padding="0.2rem 0.4rem"
          defaultValue={pageIndex}
          ref={inputPageIndexRef}
          type="number"
          min={1}
          max={totalPages}
        />
        <Button padding="0.5rem 0.2rem" variant="text" margin="0 0.5rem" onClick={handlePageIndex}>
          Go
        </Button>
      </Flex>
    </Flex>
  );
};
