import { Wrapper } from "../FlexBox";
import { IconButton } from "../IconButton";
import { EndLabel, ErrorBox, FormGroup, Input, Label, WarningBox } from "./styles";

export interface TextInputPropsType extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  endIcon?: string;
  endIconTitle?: string;
  onClickEndIcon?: () => void;
  endLabel?: string;
  errorText?: string | null;
  warning?: boolean;
  warningText?: string | null;
}

export const TextInput = ({
  id,
  label,
  error,
  disabled,
  errorText,
  endIcon,
  endIconTitle,
  onClickEndIcon,
  endLabel,
  warning,
  warningText,
  ...rest
}: TextInputPropsType) => {
  return (
    <FormGroup>
      {label && (
        <Label htmlFor={id} error={error} warning={warning} disabled={disabled}>
          {label}
        </Label>
      )}
      <Wrapper position="relative" width="100%">
        <Input id={id} error={error} warning={warning} disabled={disabled} {...rest} />
        {endIcon && (
          <IconButton
            icon={endIcon}
            position="absolute"
            right="1rem"
            top="50%"
            transform="translateY(-50%)"
            WH="25px"
            title={endIconTitle}
            size={1.1}
            onClick={onClickEndIcon}
            disabled={disabled}
          />
        )}
        {endLabel && <EndLabel>{endLabel}</EndLabel>}
      </Wrapper>
      {warning && !error && <WarningBox>{warningText}</WarningBox>}
      {error && <ErrorBox>{errorText}</ErrorBox>}
    </FormGroup>
  );
};
