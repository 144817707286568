import { SavingsEnergyChartsKeys, SavingsEnergyColorsKeys } from "@evr/constant";
import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

import { SavingsLineGraph } from "./SavingsLineChart";
import { SavingsPieGraph } from "./SavingsPieChart";

interface PropsType {
  data: Record<string, unknown>[];
  chart: SavingsEnergyChartsKeys;
}
export const SavingsChartsView = ({ data, chart }: PropsType) => {
  if (data.length === 0) {
    return (
      <Flex width="99%" height="300px">
        <Typography>No Data</Typography>
      </Flex>
    );
  }
  return (
    <>
      {chart === SavingsEnergyChartsKeys.COST_VS_SAVING ? (
        <SavingsPieGraph data={data} colors={SavingsEnergyColorsKeys[chart]} />
      ) : (
        <SavingsLineGraph data={data} dataKey={chart} color={SavingsEnergyColorsKeys[chart]} />
      )}
    </>
  );
};
