import { EngineType, VehicleSize } from "@evr/constant";
import { Flex } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { getVehicleImage } from "@evr/utils";

import { TD } from "./styles";

interface PropsType {
  size: VehicleSize;
  type: EngineType;
}

export const TableVehicleImage = ({ size, type }: PropsType) => (
  <TD>
    <Flex width="50px" height="30px">
      <Img
        height={size === VehicleSize.SMALL ? "70%" : size === VehicleSize.MEDIUM ? "90%" : "95%"}
        src={getVehicleImage(type, size)}
      />
    </Flex>
  </TD>
);
