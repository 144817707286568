import styled from "styled-components";

import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";

import RoutesDropdown from "../InProgressJourneySidebar.tsx/RoutesDropdown";
import { RecalculationResultCard } from "./RecalculationResultCard";
import { ScheduledJourneyResultCard } from "./ScheduledJourneyResultCard";
import { UndeliveredParcels } from "./UndeliveredParcels";

interface ScheduledJourneySidebarProps {
  journey: ScheduledJourney;
}

const DropdownWrapper = styled.div`
  margin-left: 45px;
`;

export const ScheduledJourneySidebar = ({ journey }: ScheduledJourneySidebarProps) => {
  const open = useAppSelector(selectSidebarOpenStatus);

  let totalUndeliveredParcels = 0;
  journey.locations.forEach(l =>
    l.addresses.forEach(a =>
      a.jobs.forEach(j => {
        if (j.status !== "Completed") {
          totalUndeliveredParcels++;
        }
      }),
    ),
  );

  return (
    <>
      {journey.status.toString() == "Completed" && totalUndeliveredParcels > 0 && (
        <UndeliveredParcels scheduledJourney={journey} totalUndelivered={totalUndeliveredParcels} />
      )}
      <ScheduledJourneyResultCard scheduledJourney={journey} />
      {journey.latestRecalculation != null && journey.status.toString() === "InProgress" && (
        <>
          <hr /> {"Latest Recalculation"}
          <RecalculationResultCard scheduledJourney={journey} />
        </>
      )}
      {journey.status.toString() === "Completed" && open && (
        <DropdownWrapper>
          <RoutesDropdown scheduledJourney={journey} />
        </DropdownWrapper>
      )}
    </>
  );
};
