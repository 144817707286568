import * as Yup from "yup";

import { AddressKeys, AuthKeys, CountryKeys, validationMsg } from "@evr/constant";
import { Country } from "@evr/types";
import { regexPasswordValidation } from "@evr/utils";

export const personalFormValidationSchema = Yup.object({
  [AuthKeys.FIRST_NAME]: Yup.string()
    .required(validationMsg.required)
    .min(2, validationMsg.minlength(2))
    .max(20, validationMsg.maxlength(20)),
  [AuthKeys.LAST_NAME]: Yup.string()
    .required(validationMsg.required)
    .min(2, validationMsg.minlength(2))
    .max(20, validationMsg.maxlength(20)),
  [AuthKeys.EMAIL]: Yup.string()
    .required(validationMsg.required)
    .email(validationMsg.email)
    .max(100, validationMsg.maxlength(100)),
  [AuthKeys.USER_NAME]: Yup.string()
    .required(validationMsg.required)
    .min(2, validationMsg.minlength(2))
    .max(20, validationMsg.maxlength(20)),
  [AuthKeys.PASSWORD]: Yup.string()
    .required(validationMsg.required)
    .min(8, validationMsg.minlength(8))
    .matches(regexPasswordValidation, validationMsg.complexPassword)
    .max(50, validationMsg.maxlength(50)),
  [AuthKeys.CONFIRM_PASSWORD]: Yup.string()
    .required(validationMsg.required)
    .oneOf([Yup.ref(AuthKeys.PASSWORD), null], validationMsg.equalTo),
});

export const companyFormValidationSchema = Yup.object({
  [AuthKeys.BILLING_EMAIL]: Yup.string()
    .email(validationMsg.email)
    .required(validationMsg.required)
    .max(100, validationMsg.maxlength(100)),
  [AuthKeys.ORGANISATION_NAME]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
  [AuthKeys.ORGANISATION_ADDRESS]: Yup.object({
    [AddressKeys.FIRST_LINE]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
    [AddressKeys.SECOND_LINE]: Yup.string().max(100, validationMsg.maxlength(100)),
    [AddressKeys.CITY]: Yup.string().required(validationMsg.required).max(50, validationMsg.maxlength(50)),
    [AddressKeys.STATE]: Yup.string().max(50, validationMsg.maxlength(50)),
    [AddressKeys.POSTCODE]: Yup.string().max(20, validationMsg.maxlength(20)),
    [AddressKeys.COUNTRY_ID]: Yup.string().required(validationMsg.required),
    [AddressKeys.COUNTRY]: Yup.object(),
  }),
  [AuthKeys.PHONE_NUMBER]: Yup.string().max(20, validationMsg.maxlength(25)),
  [AuthKeys.VAT_NUMBER]: Yup.string()
    .when(`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.COUNTRY_ID}`, {
      is: (countryId: string) => !countryId,
      then: Yup.string().required("Please select a country"),
    })
    .when(`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.COUNTRY}`, {
      is: (country: Country) => country?.[CountryKeys.IS_EU] || country?.[CountryKeys.COUNTRY_CODE] === "GB",
      then: Yup.string().required("VAT number is required for EU countries"),
    }),
  [AuthKeys.ORGANISATION_TRANSACTION_CURRENCY_CODE]: Yup.string().required(validationMsg.required),
});

export const termsConditionsValidationSchema = Yup.object({
  [AuthKeys.TERMS_CONDITION]: Yup.boolean().oneOf([true], validationMsg.termsConditions),
});
