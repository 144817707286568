export interface InteractionsProps {
  children: React.ReactNode;
}

/**
 * Component to wrap a collection of interaction components
 * Does not actually render anything, but instead allows the controls to be renders after the map container
 * @component
 * @param {React.ReactNode} children - the children components to render
 */
const Interactions = ({ children }: InteractionsProps) => {
  return <>{children}</>;
};

export default Interactions;
