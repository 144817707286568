import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectResult } from "@evr/store/slices/result";
import { Typography } from "@evr/ui/Typography";

import { NonRoutedDropOffs } from "./NonRoutedDropOffs";
import { ResultCard } from "./ResultCard";

export const ResultSidebar = () => {
  const resultState = useAppSelector(selectResult);

  if (!resultState) {
    return <Typography>Please select a calculation first.</Typography>;
  }

  return (
    <>
      {resultState.result?.journeys.map((journey, index) => (
        <ResultCard
          key={journey.id}
          index={index}
          calculationId={resultState.calculationId}
          journey={journey}
          currencySymbol={resultState.currencySymbol}
        />
      ))}
      <NonRoutedDropOffs />
    </>
  );
};
