import { theme } from "@evr/theme";

export enum SavingsEnergyIntervalsKeys {
  THIS_JOURNEY = "This journey",
  ONE_MONTH = "1 Month",
  THREE_MONTHS = "3 Months",
  SIX_MONTHS = "6 Months",
}

export enum SavingsEnergyCostVsSavingsKeys {
  COSTS = "£ Cost",
  SAVINGS = "£ Potential Savings",
}

export enum SavingsEnergyChartsKeys {
  COST_VS_SAVING = "Cost vs. Potential Saving",
  CO2 = "CO2",
  NOx = "NOx",
}

export const SavingsEnergyUnitsKeys = {
  [SavingsEnergyChartsKeys.COST_VS_SAVING]: "",
  [SavingsEnergyChartsKeys.CO2]: "Kg",
  [SavingsEnergyChartsKeys.NOx]: "g",
};

export const SavingsEnergyColorsKeys = {
  [SavingsEnergyChartsKeys.COST_VS_SAVING]: [theme.colors.success.main, theme.colors.info.main],
  [SavingsEnergyChartsKeys.CO2]: theme.colors.success.main,
  [SavingsEnergyChartsKeys.NOx]: theme.colors.info.main,
};

const initialSavingsEnegyChartData = {
  [SavingsEnergyChartsKeys.COST_VS_SAVING]: [],
  [SavingsEnergyChartsKeys.CO2]: [],
  [SavingsEnergyChartsKeys.NOx]: [],
};
export const initialSavingsEnergyValues = {
  [SavingsEnergyIntervalsKeys.THIS_JOURNEY]: initialSavingsEnegyChartData,
  [SavingsEnergyIntervalsKeys.ONE_MONTH]: initialSavingsEnegyChartData,
  [SavingsEnergyIntervalsKeys.THREE_MONTHS]: initialSavingsEnegyChartData,
  [SavingsEnergyIntervalsKeys.SIX_MONTHS]: initialSavingsEnegyChartData,
};
