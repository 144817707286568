import { useEffect, useRef } from "react";

import { IconSearch, IconX } from "@tabler/icons";
import styled from "styled-components";

import { SearchColumn } from "@evr/types";
import { Flex } from "@evr/ui/FlexBox";
import { StyledIconButton } from "@evr/ui/IconButton/styles";
import { Input } from "@evr/ui/TextInput/styles";

import { inputCss, TableSearchContainer } from "./shared";

const InputSearch = styled(Input)`
  ${inputCss}
`;

interface TableSearchTextProps {
  name: string;
  column: string;
  search: SearchColumn;
  onChange: (search: SearchColumn) => void;
  onClear: (column: string) => void;
}

export const TableSearchText = ({ column, name, search, onChange, onClear }: TableSearchTextProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (!search || !search.value) {
      searchInputRef.current.value = "";
      return;
    }
    const previousSearchValue = search.value.replaceAll("'", "");
    searchInputRef.current.value = search.column === column ? previousSearchValue : "";
  }, [column, search]);

  const handleSetSearch = () => {
    const inputValue = searchInputRef.current.value;
    if (!inputValue || (inputValue === search?.value && column === search?.column)) {
      return;
    }
    //To handle search with space we have to wrap the search value between single quotation
    let searchValue = inputValue;
    if (searchValue.includes(" ")) {
      searchValue = `'${searchValue}'`;
    }
    onChange({ column, value: searchValue, title: `${name}: ${searchValue}` });
  };

  const handleClearSearch = () => {
    searchInputRef.current.value = "";
    onClear(column);
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSetSearch();
  };

  return (
    <TableSearchContainer onSubmit={handleSubmitSearch}>
      <InputSearch ref={searchInputRef} placeholder={`Search in ${name.toLowerCase()}`} />
      <Flex position="absolute" top="50%" right="10px" transform="translateY(-50%)">
        <StyledIconButton
          noWH
          margin="0 0.2rem"
          type="submit"
          color="greyDefault"
          hoverColor="success"
          onClick={handleSetSearch}
        >
          <IconSearch />
        </StyledIconButton>
        <StyledIconButton noWH margin="0 0.2rem" color="greyDefault" hoverColor="error" onClick={handleClearSearch}>
          <IconX />
        </StyledIconButton>
      </Flex>
    </TableSearchContainer>
  );
};
