import olVectorSource from "ol/source/Vector";

import { DriverProgressSnapshot, JourneyColor } from "@evr/types";

import DriverPositionLayer from "../../AllJourneysLayer/DriverPositionLayer";

interface JourneyDriverPositionLayerProps {
  source: olVectorSource;
  snapshot: DriverProgressSnapshot;
  journeyColor: JourneyColor;
}

const JourneyDriverPositionLayer = ({ source, snapshot, journeyColor }: JourneyDriverPositionLayerProps) => {
  return <DriverPositionLayer source={source} snapshot={snapshot} journeyColor={journeyColor} />;
};

export default JourneyDriverPositionLayer;
