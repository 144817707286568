import { useContext, useEffect, useMemo, useState } from "react";

import { inAndOut } from "ol/easing";
import { extend } from "ol/extent";
import olMap from "ol/Map";
import olVectorSource from "ol/source/Vector";

import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectAllLiveJourneys, selectLiveJourneysColorMap } from "@evr/store/slices/all-live-journeys-result";

import DriverPositionLayer from "./DriverPositionLayer";

interface AllJourneysLayerProps {
  fitViewToExtent: (extent: number[]) => void;
}

const AllJourneysLayer = ({ fitViewToExtent }: AllJourneysLayerProps) => {
  const liveJourneys = useAppSelector(selectAllLiveJourneys);
  const map = useContext(MapContext) as olMap;
  const colorMap = useAppSelector(selectLiveJourneysColorMap);
  const [viewHasBeenSet, setViewHasBeenSet] = useState(false);

  const driverPositionSources = useMemo(
    () => liveJourneys?.resources.map(() => new olVectorSource()) ?? [],
    [liveJourneys],
  );

  useEffect(() => {
    if (!liveJourneys || !map || viewHasBeenSet) {
      return;
    }
    fitLayersToView();
    setViewHasBeenSet(true);
  }, [driverPositionSources]);

  useEffect(() => {
    fitLayersToView();
    setViewHasBeenSet(true);
  }, [liveJourneys?.resources.length, colorMap]);

  const fitLayersToView = () => {
    if (driverPositionSources.every(source => source.isEmpty())) {
      return;
    }

    const combinedExtent = driverPositionSources
      .map(source => source.getExtent())
      .reduce((acc, extent) => extend(acc ?? [], extent ?? []));

    fitViewToExtent(combinedExtent);
  };

  return (
    <>
      {liveJourneys && (
        <>
          {liveJourneys.resources.map((journey, index) => {
            if (journey.latestDriverProgress && colorMap.find(color => color.journeyId === journey.id)) {
              return (
                <DriverPositionLayer
                  key={journey.id}
                  source={driverPositionSources[index]}
                  snapshot={journey.latestDriverProgress}
                  journeyColor={colorMap.find(color => color.journeyId === journey.id)!.color}
                />
              );
            }
          })}
        </>
      )}
    </>
  );
};

export default AllJourneysLayer;
