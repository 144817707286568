import { useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { getScheduledJourneyMissedParcelsCsvAsync } from "@evr/store/slices/scheduled-journey-results/asyncActions";
import { selectJourneysColor } from "@evr/store/slices/setting";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Flex, RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { downloadFile } from "@evr/utils";

interface PropsType {
  scheduledJourney: ScheduledJourney;
  totalUndelivered: number;
}

export const UndeliveredParcels = ({ scheduledJourney, totalUndelivered }: PropsType) => {
  const maximize = useAppSelector(selectSidebarOpenStatus);
  const journeysColor = useAppSelector(selectJourneysColor);
  const downloadCsvApi = useApi(getScheduledJourneyMissedParcelsCsvAsync);

  const handleDownloadNonroutedDropOffs = async () => {
    const res = await downloadCsvApi.sendRequest(scheduledJourney?.id);
    if (!res.apiErrors) {
      downloadFile(res.response as string, `Journey${scheduledJourney.id}_MissedParcels.csv`, true);
    }
  };

  return (
    <RowContainer margin="0.5rem 0 1rem" align="flex-start">
      {maximize && (
        <>
          <Flex width="45px" padding="0.25rem 0" height="100%">
            <IconButton
              WH="25px"
              icon="cloud-download-alt"
              title="Download Non Routable Parcels"
              onClick={handleDownloadNonroutedDropOffs}
            />
          </Flex>

          <Flex
            rounded
            shadow={5}
            height="50px"
            overflow="hidden"
            padding="0.5rem 1rem"
            background={journeysColor.unroutables.main}
            color={journeysColor.unroutables.contrast}
            width={maximize ? "300px" : "50px"}
            justify={maximize ? "space-between" : "center"}
          >
            <Typography fontWeight={500}>Undelivered Parcels : {totalUndelivered}</Typography>
          </Flex>
        </>
      )}
    </RowContainer>
  );
};
