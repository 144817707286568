import { useState } from "react";

import { useLazyGetSubscriptionsQuery } from "@evr/apis/subscriptions";
import { DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { logOutUser, selectUserSubscription } from "@evr/store/slices/auth";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogBody, DialogHeader, DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { ColumnContainer, RowContainer } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const NoSubscriptionDialog = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { planUrl } = useAppSelector(selectUserSubscription);

  const [getSubscription, { isLoading }] = useLazyGetSubscriptionsQuery();

  const handleBuySubscription = () => {
    global.window.open(planUrl, "_self")?.focus();
    setLoading(true);
  };

  const handleLogOut = () => dispatch(logOutUser());

  return (
    <>
      <DialogHeader
        title={DialogTitle.NO_SUBSCRIPTION}
        actionButtons={<DialogSyncHeaderButton onClick={getSubscription} loading={isLoading} />}
      />
      <DialogBody>
        <ColumnContainer position="relative" width="100%">
          {(loading || isLoading) && <CircleLoading size={5} blurBackground />}
          <Typography margin="0.5rem 0" textAlign="left">
            You do not have a subscription, or your subscription is expired. Please select
            <strong> Buy</strong> to obtain a new plan. <br />
            <br />
            If you have recently purchased a new plan, please be aware this may take a few minutes to update on our
            system.
          </Typography>
          <RowContainer height="50px" width="100%" padding="1rem 0 0 0" justify="flex-end">
            <Button variant="text" onClick={handleLogOut}>
              Log Out
            </Button>
            <Button variant="text" onClick={handleBuySubscription}>
              Buy
            </Button>
          </RowContainer>
        </ColumnContainer>
      </DialogBody>
    </>
  );
};
