import { useCallback, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { config } from "@evr/config";
import { Dialogs } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useClickAwayListener } from "@evr/hooks/useClickAwayListener";
import { logOutUser, selectUserSubscription } from "@evr/store/slices/auth";
import { openDialog } from "@evr/store/slices/dialog";
import { Divider } from "@evr/ui/Divider";
import { RowContainer } from "@evr/ui/FlexBox";
import { MenuContainer, MenuItem, MenuItemAnchor } from "@evr/ui/Menu";

import { UserPhotoAndEmail } from "./UserPhotoAndEmail";

export const UserHeaderMenu = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { invoiceUrl } = useAppSelector(selectUserSubscription);
  const navigate = useNavigate();

  let ssoURL = process.env.REACT_APP_SSO_URL;
  const location = window.location.href.toLowerCase();

  if (location.indexOf("localhost") < 0 && location.indexOf("staging") < 0) {
    if (ssoURL != undefined) {
      ssoURL = ssoURL.replace("staging-", "");
    }
  }

  const ref = useRef(null!);
  const closeMenu = useCallback(() => setOpen(false), []);
  useClickAwayListener(ref, closeMenu);

  const handleMenu = () => setOpen(open => !open);

  const handleOpenPlanDialog = () => dispatch(openDialog({ type: Dialogs.PLAN }));
  const handleOpenConditionsDialog = () => dispatch(openDialog({ type: Dialogs.TERMS_CONDITIONS }));
  const handleLogOut = () => {
    dispatch(logOutUser());
    navigate("/signin");
  };

  return (
    <RowContainer ref={ref} position="relative" onClick={handleMenu}>
      <UserPhotoAndEmail />
      {open && (
        <MenuContainer width="250px" right="5px">
          <MenuItemAnchor href={config.basemapSupportDeskURL}>Support Desk</MenuItemAnchor>
          <Divider direction="h" />
          <MenuItem onClick={handleOpenPlanDialog}>My Plans</MenuItem>
          <MenuItemAnchor href={invoiceUrl}>My Invoices</MenuItemAnchor>
          <MenuItemAnchor href={ssoURL} target="_self">
            Return to Basemap Portal
          </MenuItemAnchor>
          <Divider direction="h" />
          <MenuItem onClick={handleOpenConditionsDialog}>Terms and Conditions</MenuItem>
          <Divider direction="h" />
          <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
        </MenuContainer>
      )}
    </RowContainer>
  );
};
