import { theme } from "@evr/theme";

type ColorsPrimaryType = "primaryLight" | "primaryDark" | "primary";
type ColorsWarningType = "warningLight" | "warningDark" | "warning";
type ColorsSuccessType = "successLight" | "successDark" | "success";
type ColorsErrorType = "errorLight" | "errorDark" | "error";
type ColorsInfoType = "infoLight" | "infoDark" | "info";
type ColorsGreyType = "greyLight" | "greyDark" | "grey" | "greyDefault";
type ColorsCommonType = "white" | "black" | "disable";

export type ColorsType =
  | ColorsPrimaryType
  | ColorsWarningType
  | ColorsSuccessType
  | ColorsErrorType
  | ColorsInfoType
  | ColorsGreyType
  | ColorsCommonType;

export const colorMixinFunc = (color: ColorsType | string) => {
  switch (color) {
    case "primaryLight":
      return theme.colors.primary.light;
    case "primaryDark":
      return theme.colors.primary.dark;
    case "primary":
      return theme.colors.primary.main;

    case "infoLight":
      return theme.colors.info.light;
    case "infoDark":
      return theme.colors.info.dark;
    case "info":
      return theme.colors.info.main;

    case "warningLight":
      return theme.colors.warning.light;
    case "warningDark":
      return theme.colors.warning.dark;
    case "warning":
      return theme.colors.warning.main;

    case "errorLight":
      return theme.colors.error.light;
    case "errorDark":
      return theme.colors.error.dark;
    case "error":
      return theme.colors.error.main;

    case "successLight":
      return theme.colors.success.light;
    case "successDark":
      return theme.colors.success.dark;
    case "success":
      return theme.colors.success.main;

    case "greyDefault":
      return theme.colors.grey.default;
    case "greyLight":
      return theme.colors.grey.light;
    case "greyDark":
      return theme.colors.grey.dark;
    case "grey":
      return theme.colors.grey.main;

    case "white":
      return theme.colors.common.white;

    case "black":
      return theme.colors.common.black;

    case "disable":
      return theme.colors.action.disabled;

    default:
      return color;
  }
};

export const contrastColorMixinFunc = (color: ColorsType) => {
  switch (color) {
    case "primaryLight":
    case "primaryDark":
    case "primary":
      return theme.colors.primary.contrastText;

    case "infoLight":
    case "infoDark":
    case "info":
      return theme.colors.info.contrastText;

    case "warningLight":
    case "warningDark":
    case "warning":
      return theme.colors.warning.contrastText;

    case "errorLight":
    case "errorDark":
    case "error":
      return theme.colors.error.contrastText;

    case "successLight":
    case "successDark":
    case "success":
      return theme.colors.success.contrastText;

    case "greyLight":
      return theme.colors.common.black;
    case "greyDefault":
    case "greyDark":
    case "grey":
      return theme.colors.common.white;

    case "white":
      return theme.colors.common.black;

    case "black":
      return theme.colors.common.white;

    case "disable":
      return theme.colors.common.black;

    default:
      return theme.colors.common.black;
  }
};

export const hoverColorMixinFunc = (color: ColorsType) => {
  switch (color) {
    case "primaryLight":
      return theme.colors.primary.main;
    case "primaryDark":
    case "primary":
      return theme.colors.primary.dark;

    case "infoLight":
      return theme.colors.info.main;
    case "infoDark":
    case "info":
      return theme.colors.info.dark;

    case "warningLight":
      return theme.colors.warning.main;
    case "warningDark":
    case "warning":
      return theme.colors.warning.dark;

    case "errorLight":
      return theme.colors.error.main;
    case "errorDark":
    case "error":
      return theme.colors.error.dark;

    case "successLight":
      return theme.colors.success.main;
    case "successDark":
    case "success":
      return theme.colors.success.dark;

    case "greyDefault":
      return theme.colors.grey.main;
    case "greyLight":
      return theme.colors.grey.default;
    case "greyDark":
    case "grey":
      return theme.colors.grey.dark;

    case "white":
      return theme.colors.grey.light;

    case "black":
      return theme.colors.grey.default;

    case "disable":
      return theme.colors.grey.default;

    default:
      return theme.colors.common.black;
  }
};
