import { PayloadAction } from "@reduxjs/toolkit";

import { Dialogs } from "@evr/constant";
import { DialogState } from "@evr/types";

export const DialogReducer = {
  openDialog: (dialogs: DialogState, action: PayloadAction<DialogState>) => {
    dialogs.type = action.payload.type;
    dialogs.data = action.payload.data;
    dialogs.id = action.payload.id;
    dialogs.msg = action.payload.msg;
    dialogs.isOpen = true;
  },
  closeDialog: (dialogs: DialogState) => {
    if (dialogs.isOpen === true)
    {
      dialogs.onClose = true;
      dialogs.isOpen = false;
    }
  },
  detachedDialog: (dialogs: DialogState) => {
    dialogs.type = Dialogs.CLOSE;
    dialogs.onClose = false;
  },
};
