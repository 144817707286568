import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export interface CustomLinkProps {
  color?: string;
  fontSize?: string;
  margin?: string;
  padding?: string;
  fontWeight?: string | number;
  hoverColor?: string;
  hovertextdecoration?: string;
  textAlign?: "center" | "right" | "left" | "inherit" | "initial";
}

const linkStyles = css<CustomLinkProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${p => p.color || p.theme.colors.common.black};
  text-align: ${p => p.textAlign || "center"};
  ${p => p.fontSize && `font-size:${p.fontSize}`};
  ${p => p.margin && `margin:${p.margin}`};
  ${p => p.padding && `padding:${p.padding}`};
  ${p => p.fontWeight && `font-weight:${p.fontWeight}`};
  &:hover {
    ${p => p.hovertextdecoration && `text-decoration:${p.hovertextdecoration}`};
    ${p => p.hoverColor && `color:${p.hoverColor}`};
  }
`;
export const CustomLink = styled(Link)<CustomLinkProps>`
  ${linkStyles}
`;
export const Anchor = styled.a<CustomLinkProps>`
  ${linkStyles}
`;
