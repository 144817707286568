import { TableNameKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectParcelDataTable } from "@evr/store/slices/parcel";
import { StyledTable, TableWrapper, TD, TR } from "@evr/ui/Table";

import { ParcelTableHeader } from "./ParcelTableHeader";

export const ParcelTable = () => {
  const { data, header } = useAppSelector(selectParcelDataTable);

  return (
    <TableWrapper>
      <StyledTable tableType={TableNameKeys.PARCEL}>
        <ParcelTableHeader />
        <tbody>
          {data.slice(0, 20).map((row, index) => (
            <TR key={index}>
              {header.map(col => (
                <TD key={col}>{row[col] || "_"}</TD>
              ))}
            </TR>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};
