export enum ApiMethodKeys {
  GET = "get",
  PUT = "put",
  POST = "post",
  PATCH = "patch",
  DELETE = "delete",
}

export enum CalculationEndpointApi {
  BASE = "/calculations",
  GET_SAVINGS = "/calculations/getSavings",
}
