import { useDeleteVehicleMutation } from "@evr/apis/vehicle";
import { useGetVehicleById } from "@evr/apis/vehicle/hooks";
import { DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDialog } from "@evr/store/slices/dialog";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Typography } from "@evr/ui/Typography";

export const DeleteVehicleDialog = () => {
  const { id } = useAppSelector(selectDialog);
  const vehicle = useGetVehicleById(id || -1);

  const [deleteVehicle, { isLoading }] = useDeleteVehicleMutation();

  const handleAction = () => {
    if (!id || !vehicle) {
      return;
    }
    deleteVehicle(id);
  };

  return (
    <>
      <DialogHeader title={DialogTitle.DELETE_VEHICLE} />
      <DialogBody>
        <Typography>
          Are you sure to delete this Vehicle?
          <br />
          <small>{vehicle ? `License: ${vehicle.licence}` : "Error finding the vehicle."}</small>
        </Typography>
        {vehicle && <DialogActionButtons loading={isLoading} buttonTitle={"Delete"} onClickAction={handleAction} />}
      </DialogBody>
    </>
  );
};
