import { useContext, useEffect, useMemo, useState } from "react";

import { inAndOut } from "ol/easing";
import { extend } from "ol/extent";
import olMap from "ol/Map";
import { fromLonLat } from "ol/proj";
import olVectorSource from "ol/source/Vector";

import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { MapLayerNames, MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import {
  selectedScheduledJourney,
  selectScheduledJourneyColor,
  selectScheduledJourneyShowActualRoute,
  selectScheduledJourneyShowOriginalCalculation,
  selectScheduledJourneyShowRecalculationRoutes,
  selectScheduledJourneyShowLocations,
  selectScheduledPointToZoom,
  selectScheduledJourneyShowRecalculationLocations,
} from "@evr/store/slices/scheduled-journey-results";
import { selectJourneysColorArray } from "@evr/store/slices/setting";

import ScheduledJourneyAddressJobsPopUpContainerProps from "../ScheduledJourneyLayer/ScheduledJourneyAddressJobsPopUpContainer";
import ActualJourneyLayer from "./ActualJourneyLayer";
import JourneyDriverPositionLayer from "./JourneyDriverPositionLayer";
import RecalculationLocations from "./RecalculationLocations";
import RecalculationRouteLayer from "./RecalculationRouteLayer";
import ScheduledJourneyDepotLayer from "./ScheduledJourneyDepotLayer";
import ScheduledJourneyLocationsLayer from "./ScheduledJourneyLocationsLayer";
import ScheduledJourneyLocationTimesLayer from "./ScheduledJourneyLocationTimesLayer";
import ScheduledJourneyRouteLayer from "./ScheduledJourneyRouteLayer";

interface ScheduledJourneyLayerProps {
  fitViewToExtent: (extent: number[]) => void;
}

const ScheduledJourneyLayer = ({ fitViewToExtent }: ScheduledJourneyLayerProps) => {
  const scheduledJourney = useAppSelector(selectedScheduledJourney);

  const depotSource = useMemo(() => new olVectorSource(), [scheduledJourney?.depot]);
  const journeySource = useMemo(() => new olVectorSource(), [scheduledJourney?.route]);
  const locationsSource = useMemo(() => new olVectorSource(), [scheduledJourney?.locations]);
  const locationTimesSource = useMemo(() => new olVectorSource(), [scheduledJourney?.locations]);

  const recalculationRoutesSources = useMemo(
    () => scheduledJourney?.recalculations.map(() => new olVectorSource()) ?? [],
    [scheduledJourney?.recalculations],
  );

  const recalculationLocationSources = useMemo(
    () => scheduledJourney?.recalculations.map(() => new olVectorSource()) ?? [],
    [scheduledJourney?.recalculations],
  );

  const recalculationLocationTimesSources = useMemo(
    () => scheduledJourney?.recalculations.map(() => new olVectorSource()) ?? [],
    [scheduledJourney?.recalculations],
  );
  const actualJourneySource = useMemo(() => new olVectorSource(), [scheduledJourney?.driverProgress]);
  const driverPositionSource = useMemo(() => new olVectorSource(), [scheduledJourney?.latestDriverProgress]);

  const map = useContext(MapContext) as olMap;

  const scheduledJourneysColor = useAppSelector(selectScheduledJourneyColor);
  const allJourneysColors = useAppSelector(selectJourneysColorArray);
  const recalcColors = allJourneysColors.filter(
    (x: any) => x.main !== scheduledJourneysColor.main || x.contrast !== scheduledJourneysColor.contrast,
  );
  const showRecalc = useAppSelector(selectScheduledJourneyShowRecalculationRoutes);
  const showRecalcLocations = useAppSelector(selectScheduledJourneyShowRecalculationLocations);

  const showActualRoute = useAppSelector(selectScheduledJourneyShowActualRoute);
  const showOriginalCalculation = useAppSelector(selectScheduledJourneyShowOriginalCalculation);
  const showLocations = useAppSelector(selectScheduledJourneyShowLocations);
  const pointToZoom = useAppSelector(selectScheduledPointToZoom);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!map || !scheduledJourney) {
      return;
    }

    if (!loaded) {
      fitLayersToView([
        journeySource,
        ...recalculationRoutesSources,
        ...recalculationLocationSources,
        driverPositionSource,
        actualJourneySource,
        locationsSource,
        depotSource,
      ]);
      setLoaded(true);
    } else {
      fitLayersToView([
        ...(showLocations ? [locationsSource] : []),
        ...(showOriginalCalculation ? [journeySource] : []),
        ...getVisibleRecalcSources(recalculationRoutesSources, showRecalc),
        ...getVisibleRecalcSources(recalculationLocationSources, showRecalcLocations),
      ]);
    }
  }, [
    scheduledJourney?.recalculations,
    scheduledJourney?.route,
    showLocations,
    showOriginalCalculation,
    showRecalc,
    showRecalcLocations,
    map,
  ]);

  useEffect(() => {
    if (!map || !pointToZoom || !pointToZoom.point || !scheduledJourney) {
      return;
    }
    const convertedCenter = fromLonLat(pointToZoom.point.coordinates);

    map.getView().animate({
      center: convertedCenter,
      duration: 1000,
      easing: inAndOut,
      zoom: 15,
    });

    setTimeout(
      () =>
        fitLayersToView([
          journeySource,
          ...getVisibleRecalcSources(recalculationRoutesSources, showRecalc),
          ...getVisibleRecalcSources(recalculationLocationSources, showRecalcLocations),
        ]),
      2000,
    );
  }, [pointToZoom]);

  const fitLayersToView = (sourcesToFit: olVectorSource[]) => {
    if (sourcesToFit.every(source => source.isEmpty())) {
      return;
    }

    const combinedExtent = sourcesToFit
      .map(source => source.getExtent())
      .reduce((acc, extent) => extend(acc ?? [], extent ?? []));

    fitViewToExtent(combinedExtent);
  };

  const getVisibleRecalcSources = (sources: olVectorSource[], record: Record<number, boolean>) => {
    return Object.entries(record)
      .filter(([_, value]) => value)
      .map(([key, _]) => Number(key))
      .map(index => sources[index]);
  };

  return (
    <>
      {scheduledJourney && (
        <>
          <ScheduledJourneyDepotLayer depotSource={depotSource} depot={scheduledJourney.depot} />

          <ScheduledJourneyRouteLayer
            journeySource={journeySource}
            scheduledJourney={scheduledJourney}
            journeyColor={scheduledJourneysColor}
          />

          {scheduledJourney.recalculations.map((recalculation, index) => (
            <RecalculationRouteLayer
              key={index}
              recalculationRouteSource={recalculationRoutesSources[index]}
              journeyColor={recalcColors[index % recalcColors.length]}
              recalculation={recalculation}
              recalculationIndex={index}
            />
          ))}

          <ScheduledJourneyLocationsLayer
            locations={scheduledJourney.locations}
            source={locationsSource}
            startDate={new Date(scheduledJourney.startTime ?? scheduledJourney.driver.workingHours.start)}
            journeyColor={scheduledJourneysColor}
            zIndex={MapLayerZIndices.LOCATIONS}
            isVisible={showLocations}
            layerName={MapLayerNames.ORIGINAL_CALCULATION + scheduledJourney.id.toString()}
          />
          <ScheduledJourneyLocationTimesLayer
            locations={scheduledJourney.locations}
            source={locationTimesSource}
            startDate={new Date(scheduledJourney.startTime ?? scheduledJourney.driver.workingHours.start)}
            zIndex={MapLayerZIndices.LOCATION_TIMES}
            isVisible={showLocations}
            layerName={MapLayerNames.LOCATION_TIMES + scheduledJourney.id.toString()}
          />
          <RecalculationLocations
            scheduledJourney={scheduledJourney}
            recalculationLocationSources={recalculationLocationSources}
            layerNames={scheduledJourney.recalculations.map(
              (_, index) => MapLayerNames.RECALCULATION_LOCATIONS + scheduledJourney.id + index,
            )}
            recalculationLocationTimesSources={recalculationLocationTimesSources}
          />
          <ActualJourneyLayer
            source={actualJourneySource}
            driverProgressSnapshots={scheduledJourney.driverProgress}
            isVisible={showActualRoute}
          />
          {scheduledJourney.inProgressSummary && scheduledJourney.inProgressSummary.latestDriverProgress && (
            <JourneyDriverPositionLayer
              source={driverPositionSource}
              snapshot={scheduledJourney.inProgressSummary.latestDriverProgress}
              journeyColor={scheduledJourneysColor}
            />
          )}

          <ScheduledJourneyAddressJobsPopUpContainerProps
            locations={scheduledJourney.locations}
            snapshots={scheduledJourney.driverProgress}
            layerNames={[
              ...scheduledJourney.recalculations.map(
                (_, index) => MapLayerNames.RECALCULATION_LOCATIONS + scheduledJourney.id + index,
              ),
              MapLayerNames.ORIGINAL_CALCULATION + scheduledJourney.id.toString(),
            ]}
          />
        </>
      )}
    </>
  );
};

export default ScheduledJourneyLayer;
