import { useState } from "react";

import { Form, Formik, FormikHelpers } from "formik";

import { useAddDriverMutation, useEditDriverMutation } from "@evr/apis/driver";
import { useGetDriverById } from "@evr/apis/driver/hooks";
import { FormikCheckbox, FormikTextInput, FormikTimeRange } from "@evr/components/Form";
import { Dialogs, DialogTitle, driverInitialValues, DriverKeys, PlanKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { closeDialog, openDialog, selectDialog } from "@evr/store/slices/dialog";
import {
  getCurrentDriver,
  getCurrentDriverIndex,
  getTotalDrivers,
  isVerizonImporting,
  setDriverIndex,
  setEvrDriver,
  setImportPosition,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { apiErrors, BatteryBufferType, Driver } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { batteryBufferToDecimal, decimalToPercent, transformErrorFromApi } from "@evr/utils";

import { BatteryBufferTypeSelect } from "./BatteryBufferTypeSelect";
import { DriverBehaviorField } from "./DriverBehavior";
import { driverValidationSchema } from "./schema";

interface BatteryBufferFieldProps {
  name: string;
  label: string;
  isAllowedToChange: boolean;
}

export const DriverDialog = () => {
  const dispatch = useAppDispatch();
  const { id, type } = useAppSelector(selectDialog);
  const [more, setMore] = useState<boolean>(false);

  const driver = useGetDriverById(id || -1);
  const [batteryBufferType, setBatteryBufferType] = useState(driver?.batteryBufferType);

  const subscription = useAppSelector(selectUserSubscription);
  const settings = useAppSelector(selectUserOrgSettings);

  const isFreePlan = subscription.planType === PlanKeys.STARTER;
  const isBronzPlan = subscription.planType === PlanKeys.BRONZ;

  const [addDriver, { isLoading: addLoading }] = useAddDriverMutation();
  const [editDriver, { isLoading: editLoading }] = useEditDriverMutation();

  const verizonImporting = useAppSelector(isVerizonImporting);
  const currentVerizonDriver = useAppSelector(getCurrentDriver);
  const totalVerizonDrivers = useAppSelector(getTotalDrivers);
  const currentVerizonDriverIndex = useAppSelector(getCurrentDriverIndex) || 0;

  const handleSetMoreOption = () => setMore(more => !more);

  const getButtonTitle = (): string => {
    if (!verizonImporting) {
      return driver ? "Edit" : "Add";
    } else {
      return "Continue";
    }
  };

  const handleSubmit = async (values: Driver, helpers: FormikHelpers<Driver>) => {
    const submitedDriver = {
      ...values,
      email: values.email || null,
      batteryBuffer: batteryBufferToDecimal(values.batteryBuffer),
    };
    if (!verizonImporting) {
      try {
        driver
          ? await editDriver(submitedDriver).unwrap()
          : await addDriver({ ...submitedDriver, skipNotification: false }).unwrap();
      } catch (error) {
        const apiErr = error as { data: apiErrors };
        if (apiErr && apiErr.data.errors) {
          helpers.setErrors(transformErrorFromApi(apiErr.data.errors));
        }
      }
    } else {
      dispatch(setEvrDriver(submitedDriver));
      if (totalVerizonDrivers > currentVerizonDriverIndex + 1) {
        dispatch(closeDialog());
        dispatch(setDriverIndex(currentVerizonDriverIndex + 1));
        dispatch(setImportPosition(VerizonImportPosition.DRIVER_IMPORT));
        setTimeout(() => {
          dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_CREATE }));
        }, 200);
      } else {
        dispatch(closeDialog());
        dispatch(setImportPosition(VerizonImportPosition.VEHICLE_SELECT));
        setTimeout(() => {
          dispatch(openDialog({ type: Dialogs.VERIZON_VEHICLE_SELECT }));
        }, 200);
      }
    }
  };

  const handleVerizonOnCancel = () => {
    if (currentVerizonDriverIndex == 0) {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DRIVER_SELECT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_SELECT }));
      }, 200);
    } else {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DRIVER_IMPORT));
      dispatch(setDriverIndex(currentVerizonDriverIndex - 1));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_CREATE }));
      }, 200);
    }
  };

  let updatedDriverValues = driver
    ? { ...driver, batteryBuffer: decimalToPercent(driver.batteryBuffer) }
    : { ...driverInitialValues, batteryBuffer: decimalToPercent(settings.batteryBuffer) };

  if (verizonImporting) {
    if (currentVerizonDriver.evrDriver) {
      updatedDriverValues = {
        ...currentVerizonDriver.evrDriver,
        batteryBuffer: decimalToPercent(currentVerizonDriver.evrDriver.batteryBuffer),
      };
    } else {
      updatedDriverValues.email = currentVerizonDriver?.driver.email;
      updatedDriverValues.firstName = currentVerizonDriver?.driver.firstName;
      updatedDriverValues.lastName = currentVerizonDriver?.driver.lastName;
    }
  }

  const handleBatteryBufferTypeChange = (value: string) => {
    var bbType = value as BatteryBufferType;
    setBatteryBufferType(bbType);
  };
  return (
    <>
      <DialogHeader
        showClose={!verizonImporting}
        title={
          !verizonImporting
            ? driver
              ? DialogTitle.EDIT_DRIVER
              : DialogTitle.ADD_DRIVER
            : "Import Driver - " + (currentVerizonDriverIndex + 1) + "/" + totalVerizonDrivers
        }
      />
      <DialogBody>
        <Formik initialValues={updatedDriverValues} validationSchema={driverValidationSchema} onSubmit={handleSubmit}>
          <Form>
            <Flex wrap="wrap" align="baseline" justify="flex-start">
              <GridItem marginBottom="1rem" xs={12} sm={12}>
                {(!driver || (driver && !driver.userId)) && (
                  <FormikCheckbox label="Create an account for user" name={DriverKeys.CREATE_SSO_ACCOUNT} />
                )}

                {driver && driver.userId && <Typography>Account linked with SSO</Typography>}
              </GridItem>

              <GridItem xs={12} sm={6}>
                <FormikTextInput label="First Name" name={DriverKeys.FIRST_NAME} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Last Name" name={DriverKeys.LAST_NAME} />
              </GridItem>

              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Email" name={DriverKeys.EMAIL} disabled={verizonImporting} />
              </GridItem>
              <GridItem xs={12} display="flex" wrap="wrap" justify="space-between">
                <GridItem xs={12} sm={6}>
                  <DriverBehaviorField
                    label="Driver Behaviour"
                    name={DriverKeys.ASSERTIVENESS}
                    isAllowedToChange={!isFreePlan}
                  />
                </GridItem>
                <Flex>
                  More Parameters
                  <IconButton icon="sort-down" WH="25px" margin="0 15px 6px 0" onClick={handleSetMoreOption} />
                </Flex>
              </GridItem>
            </Flex>
            <Collapsible open={more} padding="1rem 0 0 0" justify="flex-start" align="baseline">
              <GridItem xs={12} sm={6}>
                <FormikTimeRange name={DriverKeys.WORKING_HOURS} label="Working hours" disabled={isFreePlan} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTimeRange name={DriverKeys.LUNCH_BREAK} label="Lunch break" disabled={isFreePlan} clearable />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <BatteryBufferTypeSelect
                  name={DriverKeys.BATTERY_BUFFER_TYPE}
                  label="Battery buffer type"
                  isAllowedToChange={!isFreePlan && !isBronzPlan}
                  onSelect={handleBatteryBufferTypeChange}
                />
              </GridItem>
              {batteryBufferType === BatteryBufferType.PERCENTAGE && (
                <GridItem xs={12} sm={6}>
                  <FormikTextInput
                    label="Battery buffer (%)"
                    type="number"
                    name={DriverKeys.BATTERY_BUFFER}
                    disabled={isBronzPlan || isFreePlan}
                  />
                </GridItem>
              )}
              {(batteryBufferType === BatteryBufferType.MILES ||
                batteryBufferType === BatteryBufferType.KILOMETERS) && (
                <GridItem xs={12} sm={6}>
                  <FormikTextInput
                    label={"Battery Buffer " + "(" + batteryBufferType + ")"}
                    type="number"
                    name={DriverKeys.BATTERY_BUFFER_DISTANCE}
                    disabled={isBronzPlan || isFreePlan}
                  />
                </GridItem>
              )}
            </Collapsible>
            <DialogActionButtons
              loading={addLoading || editLoading}
              buttonTitle={getButtonTitle()}
              variant={verizonImporting ? "contained" : "text"}
              onCancelAction={verizonImporting ? handleVerizonOnCancel : undefined}
              cancelText={verizonImporting ? "Back" : "Cancel"}
            />
          </Form>
        </Formik>
      </DialogBody>
    </>
  );
};
