import { useState } from "react";

import { FormikHelpers } from "formik";
import { Link, useNavigate } from "react-router-dom";

import { useSignUpMutation } from "@evr/apis/accounts";
import { getSignUpFormData } from "@evr/apis/accounts/getSignUpFormData";
import logo from "@evr/assets/EVRLogo.png";
import verizonLogo from "@evr/assets/external/Verizon-Connect-Logo.png";
import { ImageSelector } from "@evr/components/ImageSelector";
import { PhotoStatusKeys } from "@evr/constant";
import { apiErrors, SignUpType } from "@evr/types";
import { GridItem, Wrapper } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";
import { transformErrorFromApi } from "@evr/utils";

import { AuthTitle } from "../AuthTitle";
import { SignUpForms } from "../SignUp/SignUpForms";
import { StepLine } from "../SignUp/StepLine";
import { SignUpContainer } from "../SignUp/styles";

export const SignUpVerizon = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  const [profileImg, setProfileImg] = useState<File | PhotoStatusKeys.EMPTY>(PhotoStatusKeys.EMPTY);

  const [signUpUser, { isLoading }] = useSignUpMutation();

  const handleSubmit = async (values: SignUpType, helpers: FormikHelpers<SignUpType>) => {
    values.organisationAdditionalInformation["source"] = "verizon";
    const data = getSignUpFormData(values, profileImg);
    try {
      await signUpUser(data).unwrap();
      navigate(`/signin/${values.email}`);
    } catch (error) {
      const apiErr = error as { data: apiErrors };
      if (apiErr && apiErr.data.errors) {
        helpers.setErrors(transformErrorFromApi(apiErr.data.errors));
        setStep(0);
      }
    }
  };

  return (
    <SignUpContainer>
      <Img src={logo} position="absolute" top="10px" left="10px" height="50px" />
      <Img src={verizonLogo} position="absolute" top="10px" right="60px" height="50px" />
      <StepLine step={step} />
      <Wrapper maxWidth="700px" padding="0 1rem">
        <AuthTitle as="h2" size={1.6}>
          Create your EVR account
        </AuthTitle>
        <ImageSelector setImg={setProfileImg} />
        <SignUpForms onSubmit={handleSubmit} setStep={setStep} step={step} loading={isLoading} />
        <GridItem padding="0.5rem" width="100%" textAlign="center">
          <Link to="/signin">Have an account? Sign In</Link>
        </GridItem>
      </Wrapper>
    </SignUpContainer>
  );
};
