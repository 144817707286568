import { useState } from "react";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { invalidateMapSize, selectResultHeader } from "@evr/store/slices/result";
import { selectSettingState } from "@evr/store/slices/setting";
import { theme } from "@evr/theme";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { getTripLength } from "@evr/utils";

import { ResultHeaderDownload } from "./ResultHeaderDownload";

export const ResultHeader = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(true);
  const { isReady: resultIsReady, hasNonElectricVehicle, header } = useAppSelector(selectResultHeader);
  const { totalCO2, totalNOx, totalCosts, totalSavings, totalTripLength, currency } = header;
  const { distanceUnit } = useAppSelector(selectSettingState);

  const handleOpenHeader = () => {
    setOpen(open => !open);
    dispatch(invalidateMapSize());
  };

  if (!resultIsReady) {
    return null;
  }
  return (
    <>
      <Wrapper transition="all 0.3s ease-out" height={open ? "50px" : "0px"} />
      <Wrapper position="absolute" width="100%" transition="all 0.3s ease-out" top="calc(-50px + 100%)">
        <RowContainer gradient justify="space-evenly" width="100%" position="relative" height="50px">
          <div>
            <Icon className="fas fa-map-marker-alt" color="white" size={1.2} margin="0 0.5rem 0 0" />
            <Typography>{`Length: ${getTripLength(totalTripLength, distanceUnit)} ${distanceUnit}`}</Typography>
          </div>
          <div>
            <Icon className="fas fa-coins" color="white" size={1.2} margin="0 0.5rem 0 0" />
            <Typography>{`Costs: ${currency != undefined ? currency : ""}${totalCosts.toFixed(2)}`}</Typography>
          </div>
          {hasNonElectricVehicle && (
            <div>
              <Icon className="fas fa-coins" color="white" size={1.2} margin="0 0.5rem 0 0" />
              <Typography>{`Potential savings: ${currency != undefined ? currency : ""}${totalSavings.toFixed(
                2,
              )}`}</Typography>
            </div>
          )}
          <Typography>
            <Typography fontWeight="bold" size={1.1}>
              CO<sub>2</sub>
            </Typography>
            {` ${totalCO2.toFixed(2)} Kg`}
          </Typography>
          <Typography>
            <Typography fontWeight="bold" size={1.1}>
              NO<sub>X</sub>
            </Typography>
            {` ${totalNOx.toFixed(2)} g`}
          </Typography>
          <Wrapper
            position="absolute"
            top="60%"
            gradient
            borderRadius="50%"
            zIndex={theme.zIndex.negativeOne}
            height="50px"
            width="50px"
          />
          <ResultHeaderDownload />
          <IconButton
            icon="chevron-up"
            color="white"
            onClick={handleOpenHeader}
            position="absolute"
            top="80%"
            size={1.1}
            transition="transform 0.3s ease-out"
            transform={`rotate(${open ? "0" : "180"}deg)`}
          />
        </RowContainer>
      </Wrapper>
    </>
  );
};
