import { useEffect } from "react";

import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { calculateScheduledJourneyArrivalTime } from "@evr/services/location";
import { selectJourneysColor } from "@evr/store/slices/setting";
import { JourneyColor, RoutedJobLocations } from "@evr/types";

import { resultLocationSvg } from "../../ResultsLayer/ResultLocation/resultLocationSvg";
import cancelledLocationSvg from "./cancelledLocationSvg";

interface ScheduledJourneyLocationsLayerProps {
  source: olVectorSource;
  locations: RoutedJobLocations[];
  startDate: Date;
  journeyColor: JourneyColor;
  zIndex: number;
  isVisible: boolean;
  layerName: string;
}

const ScheduledJourneyLocationsLayer = ({
  source,
  locations,
  journeyColor,
  startDate,
  zIndex,
  isVisible,
  layerName,
}: ScheduledJourneyLocationsLayerProps) => {
  const journeysColor = useAppSelector(selectJourneysColor);

  useEffect(() => {
    const locationFeatures = locations.map(location => {
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(location.point.coordinates)),
      });

      const arrivalTime = calculateScheduledJourneyArrivalTime(location, locations, startDate);

      const style = new Style({
        image: new Icon({
          src: getSvgSource(location),
          scale: 0.22,
          anchor: [0.5, 1],
        }),
      });

      feature.setProperties({ id: location.id, arrivalTime: arrivalTime });

      feature.setStyle(style);
      return feature;
    });

    source.addFeatures(locationFeatures ?? []);
  }, []);

  const getLocationColor = (location: RoutedJobLocations) => {
    if (location.addresses.flatMap(x => x.jobs).every(x => x.status === "Completed")) {
      return journeyColor;
    } else {
      return journeysColor.unroutables;
    }
  };

  const getSvgSource = (location: RoutedJobLocations) => {
    if (location.addresses.flatMap(x => x.jobs).every(x => x.status === "Failed")) {
      const svg = cancelledLocationSvg(journeyColor);
      return "data:image/svg+xml;utf8," + encodeURIComponent(svg);
    } else {
      const svg = resultLocationSvg(location.stopNumber + 1, getLocationColor(location));
      return "data:image/svg+xml;utf8," + encodeURIComponent(svg);
    }
  };

  return <Vector visible={isVisible} name={layerName} zIndex={zIndex} source={source}></Vector>;
};

export default ScheduledJourneyLocationsLayer;
