import { WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDVMatchState } from "@evr/store/slices/dvMatch";
import { selectTotalProcessedParcels } from "@evr/store/slices/parcel";
import { openWindow, selectWindowType } from "@evr/store/slices/window";
import { Button } from "@evr/ui/Button";
import { Wrapper } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { NavItem } from "@evr/ui/NavItem";
import { Typography } from "@evr/ui/Typography";

export const ParcelItem = () => {
  const dispatch = useAppDispatch();
  const window = useAppSelector(selectWindowType);
  const { list: dvmList } = useAppSelector(selectDVMatchState);
  const isDVsSelected = dvmList.length === 0;

  const totalValidParcels = useAppSelector(selectTotalProcessedParcels);

  const handleOpenParcel = () => {
    dispatch(openWindow(window === WindowKeys.PARCEL ? WindowKeys.CLOSE : WindowKeys.PARCEL));
  };

  return (
    <NavItem className="parcelItem-tour">
      <Wrapper padding="0.25rem 0 0.25rem 0.75rem" width="40px">
        <Icon className="fas fa-cube" color="primary" disabled={isDVsSelected} hoverEffect />
      </Wrapper>

      <Typography as="span" size={1.1} width="50px" textAlign="center" disabled={isDVsSelected}>
        {totalValidParcels}
      </Typography>
      <Button margin="0" fullHeight disabled={isDVsSelected} borderRadius="0 4px 4px 0" onClick={handleOpenParcel}>
        + Parcel
      </Button>
    </NavItem>
  );
};
