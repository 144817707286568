import { useState } from "react";

import LiveJourneyResultDetails from "@evr/components/AllLiveJourneysSidebar/LiveJourneyResultDetails";
import { ResultCardBox } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyColor, selectedScheduledJourney } from "@evr/store/slices/scheduled-journey-results";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";

import InProgressJourneyResultItems from "./InProgressResultItems";

interface InProgressResultCardProps {
  journey: ScheduledJourney;
}

const InProgressResultCard = ({ journey }: InProgressResultCardProps) => {
  const maximize = useAppSelector(selectSidebarOpenStatus);
  const selectedJourneyColour = useAppSelector(selectScheduledJourneyColor);
  const [open, setOpen] = useState(false);

  const progDetails = journey.inProgressSummary;

  const liveJourney: ScheduledJourney = {
    ...journey,
    latestStateOfCharge: progDetails.latestStateOfCharge,
    stateOfChargeDelta: progDetails.stateOfChargeDelta,
    etaDeviationDelta: progDetails.etaDeviationDelta,
    recalculationCount: progDetails.recalculationCount,
    jobsRemaining: progDetails.jobsRemaining,
    jobsCompleted: progDetails.jobsCompleted,
  };

  return (
    <>
      <RowContainer margin="0.5rem 0 1rem" align="flex-start">
        <ResultCardBox open={maximize}>
          <InProgressJourneyResultItems
            maximize={maximize}
            journeyColor={selectedJourneyColour}
            onOpenDetails={() => setOpen(!open)}
            scheduledJourney={liveJourney}
          />
          {maximize && (
            <Collapsible open={open}>
              <LiveJourneyResultDetails journey={liveJourney} />
            </Collapsible>
          )}
        </ResultCardBox>
      </RowContainer>
    </>
  );
};

export default InProgressResultCard;
