import styled, { css } from "styled-components";

import { ColumnContainer } from "@evr/ui/FlexBox";

interface TimePickerTabProps {
  active?: boolean;
}

export const TimePickerTab = styled(ColumnContainer)<TimePickerTabProps>`
  flex: 1;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid black;
  cursor: pointer;
  row-gap: 0.5rem;
  transition: all 0.3s;
  ${p =>
    p.active &&
    css`
      background-color: ${p => p.theme.colors.primary.dark};
      color: ${p => p.theme.colors.primary.contrastText};
    `}
`;
