import { useEffect, useState } from "react";

import { Feature } from "ol";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";

import ResolutionChange from "@evr/components/ReactOL/Interactions/ResolutionChange";
import { Vector } from "@evr/components/ReactOL/Layers";
import { calculateScheduledJourneyArrivalTime } from "@evr/services/location";
import { RoutedJobLocations } from "@evr/types";

interface ScheduledJourneyLocationsTimesLayerProps {
  source: olVectorSource;
  locations: RoutedJobLocations[];
  startDate: Date;
  zIndex: number;
  isVisible: boolean;
  layerName: string;
}

const ScheduledJourneyLocationTimesLayer = ({
  source,
  locations,
  startDate,
  zIndex,
  isVisible,
  layerName,
}: ScheduledJourneyLocationsTimesLayerProps) => {
  const [showTimes, setShowTimes] = useState<boolean>(false);

  useEffect(() => {
    const locationFeatures = locations.map(location => {
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(location.point.coordinates)),
      });

      const arrivalTime = calculateScheduledJourneyArrivalTime(location, locations, startDate);

      const style = new Style({
        text: new Text({
          text: arrivalTime,
          stroke: new Stroke({
            width: 0.2,
          }),
          offsetY: 12,
          font: `13px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif`,
          backgroundFill: new Fill({
            color: "rgba(204, 204, 204, 0.8)",
          }),
        }),
      });

      feature.setProperties({ id: location.id, arrivalTime: arrivalTime });
      feature.setStyle(style);
      return feature;
    });

    source.addFeatures(locationFeatures);
  }, []);

  const handleZoomChange = (zoom?: number) => {
    setShowTimes(zoom ? zoom > 12 : false);
  };

  return (
    <>
      <ResolutionChange handleZoomChange={handleZoomChange} />;
      <Vector
        declutter={true}
        visible={showTimes && isVisible}
        name={layerName}
        zIndex={zIndex}
        source={source}
      ></Vector>
    </>
  );
};

export default ScheduledJourneyLocationTimesLayer;
