import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { IconX } from "@tabler/icons";
import styled, { useTheme } from "styled-components";

import { RowContainerProps } from "@evr/ui/FlexBox";
import { MenuContainer, MenuItem } from "@evr/ui/Menu";
import { ErrorBox, FormGroup, Input } from "@evr/ui/TextInput/styles";

export type AutoCompleteOption = {
  key: string | number;
  value: string | number;
  label: string;
  elementLabel?: JSX.Element;
};

interface TypeaheadProps {
  label: string;
  items: AutoCompleteOption[];
  onChange: (value: number | string) => void;
  onBlur?: () => void;
  value?: AutoCompleteOption;
  openUpward?: boolean;
  onSearch: (searchItem: string) => void;
  heightOffset?: number;
  maxHeight?: number;
  fieldProps?: RowContainerProps;
  error?: boolean;
  errorText?: string;
  disabled?: boolean;
  onClear: () => void;
  name: string;
}

const TypeaheadContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const ComponentContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Typeahead = ({
  label,
  items,
  onChange,
  onBlur,
  onSearch,
  value,
  openUpward = false,
  heightOffset = 0,
  maxHeight = 250,
  error,
  errorText,
  disabled,
  fieldProps,
  onClear,
  name,
  ...containerProps
}: TypeaheadProps) => {
  const [selectedItem, setSelectedItem] = useState<AutoCompleteOption | undefined>(value);
  const [searchTerm, setSearchTerm] = useState<string>(value ? value.label : "");
  const [showMenu, setShowMenu] = useState(false);
  const [inputFocussed, setInputFocussed] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    setSearchTerm(value ? value.label : "");
  }, [value]);

  const handleBlur = () => {
    onBlur && onBlur();
  };

  const handleClickOutside = useCallback(
    event => {
      if (containerRef.current) {
        const anyC = containerRef.current as any;
        if (!anyC.contains(event.target)) {
          setShowMenu(false);
          if (inputFocussed) {
            handleBlur();
          }
          setInputFocussed(false);
        }
      }
    },
    [inputFocussed, handleBlur], // Dependencies for the callback
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setSearchTerm(selectedItem.label);
    onChange(selectedItem.value);
  }, [selectedItem]);

  const clearSearch = () => {
    setShowMenu(false);
    setSelectedItem(undefined);
    setSearchTerm("");
    onClear();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Backspace") {
      setSelectedItem(undefined);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchTerm(value);
  };

  const handleFocus = () => {
    setInputFocussed(true);
    setShowMenu(true);
  };

  return (
    <ComponentContainer ref={containerRef}>
      <FormGroup>
        <TypeaheadContainer>
          <Input
            onFocus={() => handleFocus()}
            value={searchTerm}
            height={"100%"}
            placeholder={label}
            onChange={onInputChange}
            error={error}
            onKeyDown={handleKeyDown}
          />
          {searchTerm && (
            <IconX onClick={clearSearch} size={20} style={{ position: "absolute", right: "0.5rem", top: "0.8rem" }} />
          )}
        </TypeaheadContainer>

        {items.length > 0 && !selectedItem && showMenu && (
          <MenuContainer maxHeight={`${maxHeight}px`} left="5px" right="5px" top={"100%"}>
            {items.map(item => (
              <MenuItem
                key={item.key}
                title={item.value.toString()}
                onClick={() => {
                  setTimeout(() => setSelectedItem(item), 100);
                }}
              >
                {item.elementLabel ?? item.label}
              </MenuItem>
            ))}
          </MenuContainer>
        )}
        {error && <ErrorBox>{errorText}</ErrorBox>}
      </FormGroup>
    </ComponentContainer>
  );
};

export default Typeahead;
