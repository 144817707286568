import { useGetDepotsQuery } from "@evr/apis/depot/hooks";
import { TableSearch } from "@evr/components/Table";
import { TablePagination } from "@evr/components/Table/TablePagination";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  clearDepotSearchColumn,
  selectDepotsPagination,
  setDepotPageIndex,
  setDepotSearchColumn,
} from "@evr/store/slices/depot";
import { SearchColumn } from "@evr/types";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { DialogSyncHeaderButton } from "@evr/ui/Dialog";
import { Flex } from "@evr/ui/FlexBox";
import { TableWrapper } from "@evr/ui/Table";
import { Typography } from "@evr/ui/Typography";

import { DepotTable } from "./DepotTable";
import { depotTableColumns } from "./depotTableColumns";

export const DepotWindow = () => {
  const dispatch = useAppDispatch();
  const { search, pageIndex } = useAppSelector(selectDepotsPagination);
  const { data, isFetching, isError, refetch } = useGetDepotsQuery();

  const handleRefetchData = () => refetch();
  const handleClearSearchValue = (column: string) => dispatch(clearDepotSearchColumn(column));
  const handleChangeSearchValue = (item: SearchColumn) => dispatch(setDepotSearchColumn(item));
  const handleChangePageIndex = (page: number) => dispatch(setDepotPageIndex(page));

  const Actions = (
    <Flex>
      <DialogSyncHeaderButton onClick={handleRefetchData} loading={isFetching} />
    </Flex>
  );

  return (
    <>
      <TableSearch
        search={search}
        Actions={Actions}
        columns={depotTableColumns}
        changeSearchValue={handleChangeSearchValue}
        clearSearchValue={handleClearSearchValue}
      />
      <TableWrapper>
        {isFetching && <CircleLoading color="primary" size={5} blurBackground />}
        {data ? (
          <DepotTable depots={data.resources} />
        ) : (
          <Flex height="100%" width="100%">
            {isError && <Typography>Something went wrong. Please try again.</Typography>}
          </Flex>
        )}

        {data && data.resources.length === 0 && (
          <Typography textAlign="center" as="div">
            You have no Depot
          </Typography>
        )}
      </TableWrapper>
      <TablePagination totalPages={data?.totalPages} pageIndex={pageIndex} action={handleChangePageIndex} />
    </>
  );
};
