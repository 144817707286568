import { ActiveLayerTypes, ScheduledJourneyStatus } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { setActiveLayer } from "@evr/store/slices/map";
import { setScheduledJourneyColor } from "@evr/store/slices/scheduled-journey-results";
import { getScheduledJourneyAsync } from "@evr/store/slices/scheduled-journey-results/asyncActions";
import { setScheduledJourneyStatus } from "@evr/store/slices/scheduled-journeys";
import { openSidebar } from "@evr/store/slices/sidebar";
import { JourneyColor, ScheduledJourney } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

import { ResultCardItem } from "../ResultSidebar/ResultCard/ResultCardComponents";

interface AllLiveJourneysResultItemsProps {
  scheduledJourney: ScheduledJourney;
  onOpenDetails: () => void;
  journeyColor: JourneyColor;
  maximize?: boolean;
}

const AllLiveJourneysResultItems = ({
  scheduledJourney,
  onOpenDetails,
  journeyColor,
  maximize,
}: AllLiveJourneysResultItemsProps) => {
  const dispatch = useAppDispatch();
  const api = useApi(getScheduledJourneyAsync);

  const formatTime = (value: number): string => {
    if (value == null) {
      return "-";
    }
    const hours = Math.floor(Math.abs(value) / 60);
    const minutes = Math.abs(value) % 60;
    const sign = value < 0 ? "-" : "+";

    if (hours >= 1) {
      const formattedHours = hours + minutes / 60;
      return `${sign}${formattedHours.toFixed(1)}h`;
    } else {
      return `${sign}${minutes}m`;
    }
  };

  const getStateOfChargeDeltaString = (value: number): string => {
    if (value == null) {
      return "-";
    } else if (value > 0) {
      return `+${value}%`;
    } else {
      return `${value}%`;
    }
  };

  const viewJourney = async (journeyId: number) => {
    await api.sendRequest(journeyId);
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.SCHEDULED));
    dispatch(setActiveLayer(ActiveLayerTypes.SCHEDULED_JOURNEY));
    dispatch(setScheduledJourneyColor(journeyColor));
  };

  return (
    <RowContainer
      background={journeyColor.main}
      color={journeyColor.contrast}
      padding="0.5rem 0"
      height="70px"
      width="100%"
      justify="space-evenly"
    >
      <ResultCardItem hoverEffect>
        <IconButton
          onClick={() => viewJourney(scheduledJourney.id)}
          icon="search-location"
          WH="25px"
          title="Click to view more details"
        />
      </ResultCardItem>
      {maximize && (
        <>
          <ResultCardItem title="completed / remaining jobs" border>
            <Icon className="fas fa-cube" />
            <Typography size={1}>
              {scheduledJourney.jobsCompleted} / {scheduledJourney.jobsRemaining}
            </Typography>
          </ResultCardItem>
          <ResultCardItem title="ahead / behind expected time">
            <Icon className="fas fa-stopwatch" />
            <Typography size={1}>{formatTime(scheduledJourney.etaDeviationDelta)}</Typography>
          </ResultCardItem>
          <ResultCardItem title="more / less expected battery" border>
            <Icon className="fas fa-battery-three-quarters" />
            <Typography size={1}>{getStateOfChargeDeltaString(scheduledJourney.stateOfChargeDelta)}</Typography>
          </ResultCardItem>
          <ResultCardItem hoverEffect>
            <IconButton icon="caret-down" WH="25px" title="Click to view Journey details" onClick={onOpenDetails} />
          </ResultCardItem>
        </>
      )}
    </RowContainer>
  );
};

export default AllLiveJourneysResultItems;
