import { IconCaretDown, IconCaretUp } from "@tabler/icons";

import { Button } from "@evr/ui/Button";
import { ColumnContainer } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";
import { get2DigitTimeString } from "@evr/utils";

interface TimePickerProps {
  handleIncrease: () => void;
  handleDecrease: () => void;
  time: number;
}

export const TimePicker = ({ time, handleDecrease, handleIncrease }: TimePickerProps) => {
  return (
    <ColumnContainer>
      <Button padding="0.5rem 1rem" variant="text" onClick={handleIncrease}>
        <IconCaretUp size={25} />
      </Button>
      <Typography size={1.5} fontWeight="bold">
        {get2DigitTimeString(time)}
      </Typography>
      <Button padding="0.5rem 1rem" variant="text" onClick={handleDecrease}>
        <IconCaretDown size={25} />
      </Button>
    </ColumnContainer>
  );
};
