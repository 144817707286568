import { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useLazyGetSessionQuery, useLazyLoginSSOQuery } from "@evr/apis/accounts";
import { AxiosBaseQueryError } from "@evr/apis/axiosBaseQuery";
import { useLazyGetSettingsQuery } from "@evr/apis/orgSettings";
import { useLazyGetSubscriptionsQuery } from "@evr/apis/subscriptions";
import { LocalStorageKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser } from "@evr/store/slices/auth";
import { getCalculationResultAsync } from "@evr/store/slices/result/asyncActions";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Typography } from "@evr/ui/Typography";
import { localStorageHelper } from "@evr/utils";

export const LoadingApp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getData, setGetData] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");

  const currentUser = useAppSelector(selectCurrentUser);

  const [loginSSO] = useLazyLoginSSOQuery();
  const [getSession] = useLazyGetSessionQuery();
  const [getOrgSettings] = useLazyGetSettingsQuery();
  const [getSubscription] = useLazyGetSubscriptionsQuery();

  const { calculationId } = useParams();

  const goToSignInPage = useCallback(() => {
    localStorageHelper.removeItem(LocalStorageKeys.TOKEN);
    const ssoUrl = localStorageHelper.getItem(LocalStorageKeys.SSO_URL);
    return navigate(ssoUrl ? "/ssoError" : "/signin", { replace: true, state: { calculationId } });
  }, [calculationId, navigate]);

  //First step: Check whether there is a token or not. If so, tries to get session/user
  const restoreToken = useCallback(async () => {
    const token = localStorageHelper.getItem(LocalStorageKeys.TOKEN);
    if (!token) {
      return goToSignInPage();
    }
    const isSSO = localStorageHelper.getItem(LocalStorageKeys.SSO_URL);
    try {
      isSSO ? await loginSSO(token).unwrap() : await getSession().unwrap();
    } catch (error) {
      goToSignInPage();
    }
  }, [getSession, goToSignInPage, loginSSO]);

  //Second step: Tries to get the subscription. If users don't have any plan, redirect them to licensing app
  const handleSubscription = useCallback(async () => {
    try {
      const sub = await getSubscription().unwrap();
      if (sub.isNoPlan) {
        return setRedirectUrl(sub.planUrl);
      }
      return setGetData(true);
    } catch (error) {
      goToSignInPage();
    }
  }, [getSubscription, goToSignInPage]);

  //Third step: Tries to get the result if there is a parameters in the URL like [/result/5]
  //and getting other data (if they are necessary before loading the app)
  const loadData = useCallback(async () => {
    try {
      await getOrgSettings().unwrap();
      calculationId && (await dispatch(getCalculationResultAsync(parseInt(calculationId))).unwrap());

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const mode = urlParams.get("mode");
      if (mode === "live") {
        navigate("/livemode");
        return;
      }

      navigate("/", { replace: true });
    } catch (error) {
      const apiError = error as AxiosBaseQueryError;
      if (apiError.status === 403) {
        navigate("/unauthorized", { replace: true });
      } else {
        navigate("/errorLoadingData", { replace: true });
      }
    }
  }, [getOrgSettings, calculationId, dispatch, navigate]);

  useEffect(() => {
    currentUser ? handleSubscription() : restoreToken();
  }, [handleSubscription, restoreToken, currentUser]);

  useEffect(() => {
    if (!redirectUrl) {
      return;
    }
    const timeout = setTimeout(() => {
      global.window.open(redirectUrl, "_self")?.focus();
    }, 3000);
    return () => clearTimeout(timeout);
  }, [redirectUrl]);

  useEffect(() => {
    if (!getData) {
      return;
    }
    loadData();
  }, [getData, loadData]);

  return (
    <>
      <CircleLoading size={10} />
      <Typography fontWeight="bold" margin="8rem auto 0 auto">
        {getData && "Loading Data ..."}
        {redirectUrl && "You will be redirected to the licensing app."}
      </Typography>
    </>
  );
};
