import { useTheme } from "styled-components";

import { Button } from "@evr/ui/Button";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface PropsType {
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmDialog = ({ title, message, onClose, onConfirm }: PropsType) => {
  const theme = useTheme();

  return (
    <ColumnContainer
      rounded
      shadow={4}
      top="50%"
      left="50%"
      position="absolute"
      transform="translate3d(-50%, -50%, 0)"
      overflow="hidden"
      minWidth="300px"
      background={theme.colors.common.white}
      zIndex={theme.zIndex.modal}
    >
      <Flex padding="0.5rem 1rem" gradient width="100%" justify="space-between">
        <Typography as="h2" size={1.25} fontWeight={500} lineHeight={1.6} margin="0">
          {title}
        </Typography>
      </Flex>
      <Flex padding="0.5rem 1rem">{message}</Flex>
      <Flex width="100%" justify="flex-end" margin="0.5rem 0 0">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="text" onClick={onConfirm}>
          Confirm
        </Button>
      </Flex>
    </ColumnContainer>
  );
};
