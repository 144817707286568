import { useState } from "react";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { setPointToZoom, toggleShowingJourney } from "@evr/store/slices/result";
import { selectSettingState } from "@evr/store/slices/setting";
import { Journey, JourneyColor, RoutedJobLocations, Vehicle } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { getFuelIconAndRemainingBattery, getTripLength } from "@evr/utils";

import { ResultCardItem } from "./ResultCardComponents";

interface PropsType {
  onOpenDetails: () => void;
  maximize: boolean;
  journey: Journey;
  journeyColor: JourneyColor;
}

export const ResultItems = ({ onOpenDetails, maximize, journey, journeyColor }: PropsType) => {
  const dispatch = useAppDispatch();
  const [toggleJourney, setToggleJourney] = useState<boolean>(false);
  const { distanceUnit } = useAppSelector(selectSettingState);
  const vehicle = journey.driverVehiclePair.vehicle;
  const { remainingBatteryOrSymbol, fuelIcon } = getFuelIconAndRemainingBattery(
    journey.finalStateOfCharge,
    vehicle,
  );

  const handleToggleJourney = () => {
    setToggleJourney(toggleJourney => !toggleJourney);
    dispatch(toggleShowingJourney(journey.id));
  };

  const handleMapBoundries = () => dispatch(setPointToZoom(journey.locations[0].point));

  return (
    <RowContainer
      background={journeyColor.main}
      color={journeyColor.contrast}
      padding="0.5rem 0"
      height="70px"
      width="100%"
      justify="space-evenly"
    >
      <ResultCardItem hoverEffect>
        <IconButton
          icon="search-location"
          WH="25px"
          onClick={handleMapBoundries}
          title="Click to Zoom to the first parcel"
        />
      </ResultCardItem>

      {maximize && (
        <>
          <ResultCardItem border>
            <Icon className="fas fa-cube" />
            <Typography size={1}>
              {journey.locations.flatMap(a => a.addresses).flatMap(j => j.jobs).length || 0}
            </Typography>
          </ResultCardItem>

          <ResultCardItem>
            <span>
              <Icon className="fas fa-map-marker-alt" size={1} />
              <Icon className="fas fa-arrows-alt-h" size={1} margin="0 2px" />
              <Icon className="fas fa-map-marker-alt" size={1} />
            </span>
            <Typography size={1}>{getTripLength(journey.tripLength, distanceUnit)}</Typography>
          </ResultCardItem>

          <ResultCardItem border>
            <Icon className={`fas fa-${fuelIcon}`} />
            <Typography size={1}>{remainingBatteryOrSymbol}</Typography>
          </ResultCardItem>

          <ResultCardItem hoverEffect>
            <IconButton
              iconClass="far"
              icon={toggleJourney ? "eye-slash" : "eye"}
              WH="25px"
              title="Toggle journey"
              onClick={handleToggleJourney}
            />
            <IconButton icon="caret-down" WH="25px" title="Click to view Journey details" onClick={onOpenDetails} />
          </ResultCardItem>
        </>
      )}
    </RowContainer>
  );
};
