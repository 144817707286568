import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import markerGreen from "@evr/assets/markers/markerGreen.png";
import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerNames, MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";

interface ParcelDepotLayerProps {
  source: olVectorSource;
}

const ParcelDepotLayer = ({ source }: ParcelDepotLayerProps) => {
  const currentDepot = useAppSelector(selectCurrentDepot);

  if (!source.isEmpty()) {
    source.clear();
  }

  if (currentDepot !== null) {
    const convertedCenter = fromLonLat(currentDepot.point.coordinates);

    const markerFeature = new Feature({
      geometry: new Point(convertedCenter),
    });

    const pinStyle = new Style({
      image: new Icon({
        src: markerGreen,
        scale: 0.5,
        anchor: [0.5, 1],
      }),
    });

    markerFeature.setStyle(pinStyle);
    source.addFeature(markerFeature);
  }

  return <Vector name={MapLayerNames.PARCEL_DEPOT} zIndex={MapLayerZIndices.DEPOT} source={source} />;
};

export default ParcelDepotLayer;
