import { useEffect, useRef, useState } from "react";

import { AxiosError } from "axios";
import L, { LatLngLiteral } from "leaflet";

import { DepotStatusKeys, Dialogs, notificationsMsg, SnackbarKeys, WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { reverseGeo } from "@evr/services/depotAddressLookup";
import { selectDepotsStatus, setDepotStatus } from "@evr/store/slices/depot";
import { openDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { openWindow } from "@evr/store/slices/window";
import { theme } from "@evr/theme";
import { ExtendedDepot } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { RowContainer } from "@evr/ui/FlexBox";
import { LoadingBox } from "@evr/ui/LoadingBox";
import { errorHandling } from "@evr/utils";

interface PropsType {
  pos: LatLngLiteral;
  id: number;
  hidden?: boolean;
}
export const DepotActionButtons = ({ id, pos, hidden }: PropsType) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const depotStatus = useAppSelector(selectDepotsStatus);
  const divRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    L.DomEvent.disableClickPropagation(divRef.current);
  }, []);

  const handleActionBt = async () => {
    setLoading(true);
    try {
      const { data: reverseData } = await reverseGeo(pos);
      if (!reverseData) {
        setLoading(false);
        return dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: notificationsMsg.INVALID_DEPOT_LOCATION }));
      }
      const values: ExtendedDepot = {
        ...reverseData,
        name: "",
        id,
        address: reverseData.address,
        display_name: reverseData.displayName,
        point: { type: "Point", coordinates: [pos.lng, pos.lat] }, //point is (x,y) = (longitude, latitude)
      };

      if (depotStatus === DepotStatusKeys.ADD) {
        dispatch(openDialog({ type: Dialogs.ADD_DEPOT, data: { ...values, id: -1 } }));
      } else {
        dispatch(openDialog({ type: Dialogs.EDIT_DEPOT, data: values }));
      }
    } catch (error) {
      const { msg } = errorHandling(error as AxiosError);
      dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: msg }));
    }
    setLoading(false);
  };

  const handleCloseDepot = () => {
    dispatch(setDepotStatus(DepotStatusKeys.CLOSE));
    //re-open the depot window table only when editing
    if (depotStatus === DepotStatusKeys.EDIT) {
      dispatch(openWindow(WindowKeys.DEPOT));
    }
  };

  return (
    <RowContainer
      hidden={hidden}
      zIndex={theme.zIndex.drawer}
      ref={divRef}
      position="absolute"
      top="10px"
      right="10px"
      rounded
    >
      <LoadingBox height="100%" isLoading={loading}>
        <Button margin="0" color="errorLight" borderRadius="4px 0 0 4px" onClick={handleCloseDepot} disabled={loading}>
          Cancel
        </Button>
        <Button margin="0" borderRadius="0 4px 4px 0" onClick={handleActionBt} disabled={loading}>
          {`${depotStatus === DepotStatusKeys.ADD ? "Add" : "Edit"} Depot`}
        </Button>
      </LoadingBox>
    </RowContainer>
  );
};
