import { TimeRange } from "@evr/types";

export const get2DigitTimeString = (time: number) => (time <= 9 ? `0${time}` : `${time}`);

export const getTimeString = ({ hour, minutes }: { hour: number; minutes: number }) => {
  return `${get2DigitTimeString(hour)}:${get2DigitTimeString(minutes)}`;
};

export const convertTimeString2Number = (timeOrDate: string) => {
  var pattern = /^(\d{4}-\d{2}-\d{2}T)?(?<hour>\d{1,2}):(?<minuites>\d{2})/;
  var match = pattern.exec(timeOrDate);
  const hour = match?.groups?.hour;
  const minuites = match?.groups?.minuites;
  return { hour: Number(hour), minutes: Number(minuites) };
};

export const getTimeRangeNumber = ({ start, end }: TimeRange) => {
  const startTime = convertTimeString2Number(start);
  const endTime = convertTimeString2Number(end);
  return { startTime, endTime };
};

export const getTotalMinutes = (timeRange: TimeRange) => {
  const { startTime, endTime } = getTimeRangeNumber(timeRange);
  let hour = endTime.hour - startTime.hour;
  let minutes = endTime.minutes - startTime.minutes;
  if (minutes < 0) {
    hour -= 1;
  }
  return hour * 60 + Math.abs(minutes);
};

export const getTotalWorkingHours = (workingHours: TimeRange, lunchBreak: TimeRange | null) => {
  let workingMinutes = getTotalMinutes(workingHours);
  const lunchMinutes = lunchBreak ? getTotalMinutes(lunchBreak) : 0;
  if (workingMinutes > lunchMinutes) {
    workingMinutes -= lunchMinutes;
  }
  const hour = Math.floor(workingMinutes / 60);
  const minutes = workingMinutes % 60;
  return `${hour}:${get2DigitTimeString(Math.abs(minutes))}`;
};

export const isAfterTime = (start?: string, end?: string) => {
  if (!start || !end) {
    return true;
  }
  const { startTime, endTime } = getTimeRangeNumber({ start, end });
  if (endTime.hour === startTime.hour) {
    return endTime.minutes > startTime.minutes;
  }
  return endTime.hour > startTime.hour;
};
