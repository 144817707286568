import { ResultCardItem } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { setScheduledJourneyPointToZoom } from "@evr/store/slices/scheduled-journey-results";
import { selectSettingState } from "@evr/store/slices/setting";
import { JourneyColor, ScheduledJourney } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { getFuelIconAndRemainingBattery, getTripLength } from "@evr/utils";

interface PropsType {
  onOpenDetails: () => void;
  maximize: boolean;
  scheduledJourney: ScheduledJourney;
  journeyColor: JourneyColor;
}

export const ScheduledJourneyResultItems = ({ onOpenDetails, maximize, scheduledJourney, journeyColor }: PropsType) => {
  const dispatch = useAppDispatch();
  const { distanceUnit } = useAppSelector(selectSettingState);
  const { remainingBatteryOrSymbol, fuelIcon } = getFuelIconAndRemainingBattery(
    scheduledJourney.finalStateOfCharge,
    scheduledJourney.vehicle,
  );

  const handleMapBoundries = () => dispatch(setScheduledJourneyPointToZoom(scheduledJourney.locations[0].point));

  return (
    <RowContainer
      background={journeyColor.main}
      color={journeyColor.contrast}
      padding="0.5rem 0"
      height="70px"
      width="100%"
      justify="space-evenly"
    >
      <ResultCardItem hoverEffect>
        <IconButton icon="search-location" WH="25px" onClick={handleMapBoundries} title="Click to refocus on route" />
      </ResultCardItem>

      {maximize && (
        <>
          <ResultCardItem border>
            <Icon className="fas fa-cube" />
            <Typography size={1}>
              {scheduledJourney.status.toString() !== "Completed" &&
                (scheduledJourney.locations.flatMap(a => a.addresses).flatMap(j => j.jobs).length || 0)}
              {scheduledJourney.status.toString() === "Completed" &&
                (scheduledJourney.locations
                  .flatMap(a => a.addresses)
                  .flatMap(j => j.jobs)
                  .filter(x => x.status == "Completed").length ||
                  0)}
            </Typography>
          </ResultCardItem>

          <ResultCardItem>
            <span>
              <Icon className="fas fa-map-marker-alt" size={1} />
              <Icon className="fas fa-arrows-alt-h" size={1} margin="0 2px" />
              <Icon className="fas fa-map-marker-alt" size={1} />
            </span>
            <Typography size={1}>{getTripLength(scheduledJourney.tripLength, distanceUnit)}</Typography>
          </ResultCardItem>

          <ResultCardItem border>
            <Icon className={`fas fa-${fuelIcon}`} />
            <Typography size={1}>{remainingBatteryOrSymbol}</Typography>
          </ResultCardItem>

          <ResultCardItem hoverEffect>
            <IconButton icon="caret-down" WH="25px" title="Click to view Journey details" onClick={onOpenDetails} />
          </ResultCardItem>
        </>
      )}
    </RowContainer>
  );
};
