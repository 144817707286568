import { evrApi } from "@evr/apis";
import { ApiMethodKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { setOrgSettings } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { OrgSettings } from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { OrgSettingsEndpoint } from "../endpoints";

export const orgSettingsApi = evrApi
  .enhanceEndpoints({ addTagTypes: [CacheKeys.ORG_SETTINGS, CacheKeys.DRIVERS] })
  .injectEndpoints({
    endpoints: builder => ({
      getSettings: builder.query<OrgSettings, void>({
        query: () => ({ url: OrgSettingsEndpoint }),
        providesTags: [CacheKeys.ORG_SETTINGS],
        onQueryStarted(_, { dispatch, queryFulfilled }) {
          queryFulfilled.then(({ data }) => {
            dispatch(setOrgSettings(data));
          });
        },
      }),
      editSettings: builder.mutation<OrgSettings, OrgSettings>({
        query: data => ({ url: OrgSettingsEndpoint, method: ApiMethodKeys.POST, data }),
        invalidatesTags: [CacheKeys.ORG_SETTINGS, CacheKeys.DRIVERS],
        onQueryStarted(_, { dispatch, queryFulfilled }) {
          queryFulfilled.then(({ data }) => {
            dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.UPDATE_SETTINGS }));
            dispatch(setOrgSettings(data));
          });
        },
      }),
      updateVerizonImportDate: builder.mutation<OrgSettings, void>({
        query: _ => ({ url: OrgSettingsEndpoint + "/UpdateVerizonLastImport", method: ApiMethodKeys.POST }),
        invalidatesTags: [CacheKeys.ORG_SETTINGS],
        onQueryStarted(_, { dispatch, queryFulfilled }) {
          queryFulfilled.then(({ data }) => {
            dispatch(setOrgSettings(data));
          });
        },
      }),
    }),
  });

export const { useLazyGetSettingsQuery, useEditSettingsMutation, useUpdateVerizonImportDateMutation } = orgSettingsApi;
