import * as Yup from "yup";

import { validationMsg, DepotKeys } from "@evr/constant";

export const DepotValidationSchema = Yup.object({
  [DepotKeys.NAME]: Yup.string()
    .required(validationMsg.required)
    .min(3, validationMsg.minlength(3))
    .max(200, validationMsg.maxlength(200)),
});
