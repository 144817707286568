import { Dialogs, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import { selectDVMatchState } from "@evr/store/slices/dvMatch";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { TableAction } from "@evr/ui/Table";

interface DriverTableActionsProps {
  driverId: number;
}

export const DriverTableActions = ({ driverId: id }: DriverTableActionsProps) => {
  const dispatch = useAppDispatch();
  const { list: dvm } = useAppSelector(selectDVMatchState);

  const handleDeleteDriver = () => {
    if (dvm.find(dv => dv.driverId === id)) {
      return dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: notificationsMsg.DELETE_DRIVER_IN_DV_MATCH }));
    }
    dispatch(openDialog({ type: Dialogs.DELETE_DRIVER, id }));
  };

  const handleEditDriver = () => dispatch(openDialog({ type: Dialogs.EDIT_DRIVER, id }));

  return <TableAction id={id} onRemoveClick={handleDeleteDriver} onEditClick={handleEditDriver} />;
};
