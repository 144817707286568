import { useState } from "react";

import { FieldHookConfig, useField } from "formik";

import { TextInput, TextInputPropsType } from "@evr/ui/TextInput";

export const FormikPasswordInput = (props: FieldHookConfig<string> & TextInputPropsType) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  const err = Boolean(meta.touched && meta.error);
  return (
    <TextInput
      {...field}
      {...props}
      type={showPassword ? "text" : "password"}
      endIcon={showPassword ? "eye" : "eye-slash"}
      endIconTitle={showPassword ? "Hide password" : "Show password"}
      onClickEndIcon={handleTogglePassword}
      id={props.name}
      error={err}
      errorText={err ? meta.error : null}
    />
  );
};
