import { createSelector, createSlice } from "@reduxjs/toolkit";

import { SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";

import { resultInitialState } from "./initialState";
import { ResultReducers } from "./resultReducers";

export const ResultSlice = createSlice({
  name: SliceKeys.RESULT,
  initialState: resultInitialState,
  reducers: { ...ResultReducers },
});

export const {
  setResult,
  setPointToZoom,
  setMapBoundries,
  invalidateMapSize,
  toggleShowingJourney,
  setJourneyStartDate,
  setAccepted,
  setToggleUnrouteables,
  resetCalculationParams,
  clearCurrentResult,
} = ResultSlice.actions;

export const selectResultState = (state: RootState) => state.result;
export const selectResult = (state: RootState) => state.result;
export const selectResultIsReady = (state: RootState) => state.result.isReady;
export const selectCalculationSavings = (state: RootState) => state.result.savingsEnergyData;

export const selectResultRoutesStatus = createSelector(
  (state: RootState) => state.result,
  ({ bbox, showRoutes, pointToZoom }) => ({ bbox, showRoutes, pointToZoom }),
);
export const selectResultBBox = createSelector( 
  (state: RootState) => state.result.bbox,
  bbox => bbox,
);
export const selectInvalidateMapSize = createSelector(
  (state: RootState) => state.result.invalidateMapSize,
  invalidateMapSize => invalidateMapSize,
);
export const selectResultHeader = createSelector(
  (state: RootState) => state.result,
  ({ isReady, hasNonElectricVehicle, header }) => ({ isReady, hasNonElectricVehicle, header }),
);
export const selectCalculationId = createSelector(
  (state: RootState) => state.result.calculationId,
  id => id,
);
export const selectShowUnrouteables = createSelector(
  (state: RootState) => state.result.showUnrouteables,
  showUnrouteables => showUnrouteables,
);

export default ResultSlice.reducer;
