import styled from "styled-components";

import logo from "@evr/assets/EVRLogo.png";
import { Job, Parcel } from "@evr/types";
import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface DetailedJobViewProps {
  job: Job;
  routeBackToTable: () => void;
}

const NotesSection = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 1rem;
`;

const StyledLink = styled.a`
  cursor: pointer;
`;

const DetailedJobView = ({ job, routeBackToTable }: DetailedJobViewProps) => {
  return (
    <>
      {job.jobName && (
        <Flex padding="1rem 20% 0 20%" justify="center" direction="row">
          <Flex width="100%" justify="flex-start" direction="row">
            <Typography textAlign="left" width="50%" fontWeight={700}>
              Name:{" "}
            </Typography>
            <Typography textAlign="left" width="50%">
              {job.jobName}
            </Typography>
          </Flex>
        </Flex>
      )}
      {job.trackingId && (
        <Flex padding="0.3rem 20% 0 20%" justify="center" direction="row">
          <Flex width="100%" justify="flex-start" direction="row">
            <Typography textAlign="left" width="50%" fontWeight={700}>
              Tracking ID:{" "}
            </Typography>
            <Typography textAlign="left" width="50%">
              JL245089B
            </Typography>
          </Flex>
        </Flex>
      )}
      {((job as Parcel).photoKey || (job as Parcel).signatureKey) && (
      <Flex padding="0.3rem 20% 0 20%" justify="center" direction="row">
        <Flex width="100%" justify="center" direction="row">
          {(job as Parcel).photoKey && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: "45px",height: "160px" }}>
              <img src={(job as Parcel).photoURL ?? ""} alt="Image 1" style={{ width: "114px", height: "152px", marginBottom: "5px" }} />
            </div>
          )}
          {(job as Parcel).signatureKey && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "0px",height: "160px"}}>
              <img src={(job as Parcel).signatureURL ?? ""} alt="Image 2" style={{ width: "114px", height: "152px", marginBottom: "5px" }} />
              <Typography>Name: {(job as Parcel).signedName}</Typography>
            </div>
          )}
        </Flex>
      </Flex>
    )}
      {job.driverComments && (
        <>
          <Flex padding="0.3rem 20% 0 20%" justify="center" direction="row">
            <Flex width="100%" justify="flex-start" direction="row">
              <Typography textAlign="left" width="50%" fontWeight={700}>
                Notes:{" "}
              </Typography>
            </Flex>
          </Flex>
          <Flex padding="0.3rem 20% 0 20%" justify="center" direction="row">
            <NotesSection>{job.driverComments}</NotesSection>
          </Flex>
        </>
      )}
      <Flex margin="1rem 0 0 0" justify="flex-end">
        <StyledLink onClick={e => routeBackToTable()}> back to jobs table </StyledLink>
      </Flex>
    </>
  );
};

export default DetailedJobView;
