import { AxiosRequestConfig } from "axios";

import { CalculationEndpointApi } from "@evr/constant";

const getCalculationResult = (calculationId: number): AxiosRequestConfig => ({
  url: `${CalculationEndpointApi.BASE}/${calculationId}`,
});

const getCalculationSavings = (): AxiosRequestConfig => ({
  url: CalculationEndpointApi.GET_SAVINGS,
});

const getCalculationData = (
  calculationId: number,
  hasGpx: boolean = true,
  hasCsv: boolean = true,
): AxiosRequestConfig => ({
  url: `${CalculationEndpointApi.BASE}/${calculationId}/journeys?includeGpx=${hasGpx}&includeCsv=${hasCsv}`,
  responseType: "blob",
});

const getJourneyGpx = (calculationId: number, journeyId: number): AxiosRequestConfig => ({
  url: `${CalculationEndpointApi.BASE}/${calculationId}/journeys/${journeyId}/gpx`,
  responseType: "blob",
});

const getJourneyCsv = (calculationId: number, journeyId: number): AxiosRequestConfig => ({
  url: `${CalculationEndpointApi.BASE}/${calculationId}/journeys/${journeyId}/csv`,
  responseType: "blob",
});

const getCalculationUnrouteableCsv = (calculationId: number): AxiosRequestConfig => ({
  url: `${CalculationEndpointApi.BASE}/${calculationId}/UnrouteableParcels`,
  responseType: "blob",
});

export const calculationApi = {
  getCalculationResult,
  getCalculationSavings,
  getCalculationData,
  getJourneyGpx,
  getJourneyCsv,
  getCalculationUnrouteableCsv,
};
