import { Dialogs, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import { selectDVMatchState } from "@evr/store/slices/dvMatch";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { TableAction } from "@evr/ui/Table";

interface VehicleTableActionsProps {
  vehicleId: number;
}

export const VehicleTableActions = ({ vehicleId: id }: VehicleTableActionsProps) => {
  const dispatch = useAppDispatch();
  const { list: dvm } = useAppSelector(selectDVMatchState);

  const handleDeleteVehicle = () => {
    if (dvm.find(dv => dv.vehicleId ===id )) {
      return dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: notificationsMsg.DELETE_VEHICLE_IN_DV_MATCH }));
    }
    dispatch(openDialog({ type: Dialogs.DELETE_VEHICLE, id }));
  };
  const handleEditVehicle = () => dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE, id }));

  return <TableAction id={id} onRemoveClick={handleDeleteVehicle} onEditClick={handleEditVehicle} />;
};
