import { Job, JobType, JourneyColor } from "@evr/types";

export const getJobLocationColor = (jobs: Job[], inEditMode?: boolean): JourneyColor => {
  if (inEditMode) {
    return { main: "#808080", contrast: "#fff" };
  }
  const isOnlyPackageJob = jobs.every(({ jobType }) => jobType === JobType.PACKAGE);
  if (isOnlyPackageJob) {
    return { main: "#00f", contrast: "#fff" };
  }
  const isOnlyPickupJob = jobs.every(({ jobType }) => jobType === JobType.PICKUP);
  if (isOnlyPickupJob) {
    return { main: "#f00", contrast: "#fff" };
  }
  return { main: "#f0f", contrast: "#fff" };
};
