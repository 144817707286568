import { DownloadResultMenu } from "@evr/components/DownloadResultMenu";
import { CalculationEndpointApi } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { selectCalculationId } from "@evr/store/slices/result";
import { getJourneyCsvAsync, getJourneyGpxAsync } from "@evr/store/slices/result/asyncActions";
import { ColumnContainer } from "@evr/ui/FlexBox";
import { downloadFile } from "@evr/utils";

import { ResultCircleDownload } from "./ResultCardComponents";

interface PropsType {
  index: number;
  color: string;
  journeyId: number;
  legs: number;
}

export const ResultDownload = ({ color, index, journeyId, legs }: PropsType) => {
  const calculationId = useAppSelector(selectCalculationId);
  const downloadGpxApi = useApi(getJourneyGpxAsync);
  const downloadCsvApi = useApi(getJourneyCsvAsync);

  const loading = downloadGpxApi.loading || downloadCsvApi.loading;

  const handleDownloadCSV = async () => {
    const res = await downloadCsvApi.sendRequest(calculationId, journeyId);
    if (!res.apiErrors) {
      const journeyNo = journeyId - calculationId * 1000;
      downloadFile(res.response as string, `Calculation${calculationId}_Journey${journeyNo}.csv`, true);
    }
  };

  const handleDownloadGPX =  async () => {
    const res = await downloadGpxApi.sendRequest(calculationId, journeyId);
    if (!res.apiErrors) {
      const journeyNo = journeyId - calculationId * 1000;
      downloadFile(res.response as string, `Calculation${calculationId}_Journey${journeyNo}.gpx`, true);
    }
  };

  let handleOpenInSygicNav = undefined;
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1;
  if (isAndroid) {
    handleOpenInSygicNav = (legIndex: number) => {
      const ptvlink = encodeURIComponent(
        `${process.env.REACT_APP_BASE_URL_ABSOLUTE}${CalculationEndpointApi.BASE}/${calculationId}/journeys/${journeyId}/leg/${legIndex}/ptv.json`,
      );
      window.open(`com.sygic.aura://route_download|${ptvlink}|json`);
    };
  }

  return (
    <ColumnContainer padding="0.25rem 0" width="45px" justify="space-between">
      <ResultCircleDownload color={color}>{index + 1}</ResultCircleDownload>
      <DownloadResultMenu
        loading={loading}
        menuPosition={{ left: "0" }}
        handleDownloadCSV={handleDownloadCSV}
        handleDownloadGPX={handleDownloadGPX}
        handleOpenInSygicNav={handleOpenInSygicNav}
        legs={legs}
      />
    </ColumnContainer>
  );
};
