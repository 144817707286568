import { Driver } from "@evr/types";
import { ColumnContainer } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface LiveDriverDetailsProps {
  driver: Driver;
  hidden: boolean;
}

const LiveDriverDetails = ({ driver, hidden }: LiveDriverDetailsProps) => {
  return (
    <ColumnContainer padding="1rem" width="100%" align="flex-start" hidden={hidden}>
      <Typography textAlign="left" size={0.9}>
        <strong>Name:</strong> {driver.name}
      </Typography>
    </ColumnContainer>
  );
};

export default LiveDriverDetails;
