import { locationAddressItems } from "@evr/constant";
import { JobLocation, JobType, Location, Parcel, RoutedJobLocations, UnrouteableJobLocation } from "@evr/types";
import { Wrapper } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface PopupValueProps {
  title: string;
  value?: string | number;
  breakLine?: boolean;
}

const PopupValue = ({ title, value, breakLine }: PopupValueProps) => {
  return value ? (
    <>
      <Wrapper minWidth="95px" display="inline-block">
        <strong>{title}:</strong> {value}
      </Wrapper>
      {breakLine && <br />}
    </>
  ) : null;
};

export const locationPopup = (location: JobLocation | Location) => {
  const reason = (location as UnrouteableJobLocation).reason;
  const addressGrouping = (location as Location).addresses;
  const stateOfCharge = parseFloat(((location as RoutedJobLocations).stateOfCharge * 100).toFixed(2));
  const stateOfChargeAvailable = stateOfCharge == 0 || Number.isNaN(stateOfCharge) ? "" : String(stateOfCharge + "%");

  if (!addressGrouping) {
    let address: string[] = [];
    locationAddressItems.forEach(value => {
      const item = (location as unknown as Record<string, string>)[value];
      item && address.push(item);
    });
    const reason = (location as UnrouteableJobLocation).reason;

    return (
      <>
        <Typography as={"div"} textAlign="left" margin="0 0 0.5rem 0">
          <PopupValue title="Reason" value={reason} breakLine />
          <PopupValue title="Address" value={address.join(", ")} />
        </Typography>
        <hr />
        {(location as JobLocation).jobs.map((job, index) => {
          const { dimensions, quantity, customerName, jobType, trackingId } = job as Parcel;
          const isChargeJob = jobType === JobType.CHARGE;
          const taskValue = isChargeJob ? "Charge" : jobType === JobType.PICKUP ? "Collection" : "Delivery";
          return (
            //There is no unique key in "job", which is why the index is used as the key here
            <Typography key={index} as="div" margin="0 0 0.5rem 0" textAlign="left">
              <PopupValue title="Name" value={customerName} />
              <PopupValue title="Task" value={taskValue} breakLine />
              {!isChargeJob && (
                <>
                  <PopupValue title="Weight" value={dimensions.weight} />
                  <PopupValue title="Quantity" value={quantity} breakLine />
                </>
              )}
              <PopupValue title="Tracking ID" value={trackingId} />
            </Typography>
          );
        })}
      </>
    );
  }

  const addresses = addressGrouping.map((address, addressIndex) => {
    let addressLine: string[] = [address.houseNumber, address.streetName, address.city, address.postCode];
    const jobs = address.jobs.map((job, jobIndex) => {
      const { dimensions, quantity, customerName, jobType, trackingId } = job as Parcel;
      const isChargeJob = jobType === JobType.CHARGE;
      const taskValue = isChargeJob ? "Charge" : jobType === JobType.PICKUP ? "Collection" : "Delivery";

      return (
        //There is no unique key in "job", which is why the index is used as the key here
        <Typography key={jobIndex} as="div" margin="0 0 0.5rem 0" textAlign="left">
          <PopupValue title="Name" value={customerName} />
          <PopupValue title="Task" value={taskValue} breakLine />
          {!isChargeJob && (
            <>
              <PopupValue title="Weight" value={dimensions.weight} />
              <PopupValue title="Quantity" value={quantity} breakLine />
            </>
          )}
          <PopupValue title="Tracking ID" value={trackingId} />
          <PopupValue title="State of Charge" value={stateOfChargeAvailable || "NA"} />
        </Typography>
      );
    });

    return (
      <>
        {addressIndex !== 0 && <hr />}
        <div key={addressIndex}>
          <PopupValue title="Address" value={addressLine.join(", ")} />
          <div style={{ paddingLeft: "20px" }}>{jobs}</div>
        </div>
      </>
    );
  });

  return (
    <>
      {reason && (
        <>
          <Typography as={"div"} textAlign="left" margin="0 0 0.5rem 0">
            <PopupValue title="Reason" value={reason} breakLine />
          </Typography>
          <hr />
        </>
      )}
      <div style={{ maxHeight: "300px", overflowY: "auto" }}>{addresses}</div>
    </>
  );
};
