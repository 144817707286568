import styled, { css } from "styled-components";

import { theme } from "@evr/theme";
import { colorMixinFunc, ColorsType, contrastColorMixinFunc, hoverColorMixinFunc } from "@evr/theme/colors";
interface PropsType {
  borderRadius?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  fontSize?: string;
  padding?: string;
  margin?: string;
  variant?: "contained" | "outlined" | "text";
  color?: ColorsType;
  display?:string;
}

const getVariantStyles = ({ variant, color }: PropsType) => {
  switch (variant) {
    case "text":
      return css`
        background: transparent;
        color: ${theme.colors.common.black};
        &:hover {
          color: ${color ? colorMixinFunc(color) : theme.colors.primary.dark};
          background: #0000000d;
        }
        &:active,
        &:disabled {
          color: ${theme.colors.common.black};
          background: transparent;
        }
      `;
    case "outlined":
      return css`
        background: transparent;
        border: 1px solid ${color ? colorMixinFunc(color) : theme.colors.primary.main};
        color: ${color ? colorMixinFunc(color) : theme.colors.common.black};
        &:hover {
          background: ${color ? hoverColorMixinFunc(color) : theme.colors.primary.main};
          color: ${color ? contrastColorMixinFunc(color) : theme.colors.primary.contrastText};
        }
        &:active,
        &:disabled {
          background: transparent;
          color: ${color ? colorMixinFunc(color) : theme.colors.common.black};
        }
      `;
    default:
      return css`
        background: ${color ? colorMixinFunc(color) : theme.colors.gradient.main};
        color: ${color ? contrastColorMixinFunc(color) : theme.colors.gradient.contrastText};
        border: 1px solid ${color ? colorMixinFunc(color) : theme.colors.gradient.main};
        &:hover {
          background: ${color ? hoverColorMixinFunc(color) : theme.colors.gradient.dark};
        }
        &:active,
        &:disabled {
          background: ${color ? colorMixinFunc(color) : theme.colors.gradient.main};
        }
      `;
  }
};

export const Button = styled.button.attrs(props => ({
  type: props.type ? props.type : "button",
}))<PropsType>`
  display: ${props => props.display || "flex" };
  position: relative;
  font-family: "Roboto";
  align-items: center;
  justify-content: center;
  border: none;
  user-select: none;
  cursor: pointer;
  transition: all 0.3s;
  margin: ${props => props.margin || "0.2rem"};
  padding: ${props => props.padding || "0.5rem 1rem"};
  font-size: ${props => props.fontSize || "1.1rem"};
  border-radius: ${props => props.borderRadius || props.theme.borderRadius};
  ${props => props.fullHeight && "height: 100%;"}
  ${props => props.fullWidth && "width: 100%;"}
  &:disabled {
    opacity: ${props => props.theme.colors.action.disabledOpacity};
    cursor: default;
  }

  /* default value is contained and gradient*/
  ${getVariantStyles}
`;
