import { PayloadAction } from "@reduxjs/toolkit";
import { Point } from "geojson";

import { EngineType, FetchDataKeys, SavingsEnergyIntervalsKeys } from "@evr/constant";
import { ResultResponse, ResultState, SavingsEnergyParams, Vehicle } from "@evr/types";
import { filterSavingsBasedOnMonths, getThisJourneySavings } from "@evr/utils";

import { resultInitialState } from "./initialState";

export const ResultReducers = {
  setResult: (results: ResultState, action: PayloadAction<ResultResponse>) => {
    results.showRoutes = { ...resultInitialState.showRoutes };
    results.header = { ...resultInitialState.header };
    results.hasNonElectricVehicle = false;

    results.calculationId = action.payload.id;
    results.result = action.payload.results;

    results.currencySymbol = action.payload.currencySymbol;
    results.header.currency = action.payload.currencySymbol;

    results.result.journeys.forEach(journey => {
      const { id, cO2, nOx, costs, savings, tripLength } = journey;
      //show all journeys by default
      results.showRoutes[id] = true;
      // sum of each items to show a total values on header
      results.header.totalCO2 += cO2;
      results.header.totalNOx += nOx * 1000;
      results.header.totalCosts += costs;
      results.header.totalSavings += savings;
      results.header.totalTripLength += tripLength;
      //check if all vehicles are electric
      if (!results.hasNonElectricVehicle) {
        const vehicle: Vehicle = journey.driverVehiclePair.vehicle;
        results.hasNonElectricVehicle = vehicle.engineType !== EngineType.ELECTRIC;
      }
    });

    results.savingsEnergyData.data[SavingsEnergyIntervalsKeys.THIS_JOURNEY] = getThisJourneySavings(results.header);

    results.showUnrouteables =
      action.payload.results.unrouteableLocations.length > 0 && results.result.journeys.every(j => !j.route);

    results.isReady = true;
  },
  invalidateMapSize: (results: ResultState) => {
    results.invalidateMapSize = !results.invalidateMapSize;
  },
  setMapBoundries: (results: ResultState, action: PayloadAction<number>) => {
    results.bbox.id = action.payload;
    results.bbox.generate = Date.now();
  },
  setPointToZoom: (results: ResultState, action: PayloadAction<Point | null>) => {
    results.pointToZoom.point = action.payload;
    results.pointToZoom.generate = Date.now();
  },
  toggleShowingJourney: (results: ResultState, action: PayloadAction<number>) => {
    const journeyId = action.payload;
    results.showRoutes[journeyId] = !results.showRoutes[journeyId];
  },
  setJourneyStartDate: (results: ResultState, action: PayloadAction<{ journeyId: number; startDate: string }>) => {
    const journeyId = action.payload.journeyId;
    const startDate = action.payload.startDate;
    var journey = results.result?.journeys.find(j => j.id === journeyId);
    if (journey) {
      journey.startDate = startDate;
    }
  },
  setAccepted: (results: ResultState, action: PayloadAction<{ journeyId: number; acceptedJourneyHref: string }>) => {
    const journeyId = action.payload.journeyId;
    const acceptedJourneyHref = action.payload.acceptedJourneyHref;
    var journey = results.result?.journeys.find(j => j.id === journeyId);
    if (journey) {
      journey.acceptedJourneyHref = acceptedJourneyHref;
    }
  },
  setToggleUnrouteables: (results: ResultState) => {
    if (!results.showUnrouteables) {
      results.bbox.id = -1;
      results.bbox.generate = Date.now();
    }
    results.showUnrouteables = !results.showUnrouteables;
  },
  setSavingsEnergyData: (results: ResultState, action: PayloadAction<SavingsEnergyParams[]>) => {
    results.savingsEnergyData.data = {
      ...results.savingsEnergyData.data,
      [SavingsEnergyIntervalsKeys.ONE_MONTH]: filterSavingsBasedOnMonths(1, action.payload),
      [SavingsEnergyIntervalsKeys.THREE_MONTHS]: filterSavingsBasedOnMonths(3, action.payload),
      [SavingsEnergyIntervalsKeys.SIX_MONTHS]: filterSavingsBasedOnMonths(6, action.payload),
    };
    results.savingsEnergyData.loading = FetchDataKeys.LOADED;
  },
  resetCalculationParams: (results: ResultState) => {
    const { savingsEnergyData, ...rest } = resultInitialState;
    return { ...results, ...rest };
  },
  clearCurrentResult: (results: ResultState) => {
    const { savingsEnergyData, ...rest } = resultInitialState;
    return { ...results, ...rest };
  },
};
