import largeVSelectedEl from "@evr/assets/vehicles/electric_large_selected.png";
import mediumVSelectedEl from "@evr/assets/vehicles/electric_medium_selected.png";
import smallVSelectedEl from "@evr/assets/vehicles/electric_small_selected.png";
import largeVSelected from "@evr/assets/vehicles/large_selected.png";
import mediumVSelected from "@evr/assets/vehicles/medium_selected.png";
import smallVSelected from "@evr/assets/vehicles/small_selected.png";
import { EngineType, VehicleSize } from "@evr/constant";

export const getVehicleImage = (type: EngineType, size: VehicleSize) => {
  if (type === EngineType.ELECTRIC) {
    switch (size) {
      case VehicleSize.SMALL:
        return smallVSelectedEl;
      case VehicleSize.MEDIUM:
        return mediumVSelectedEl;
      case VehicleSize.LARGE:
        return largeVSelectedEl;
      default:
        return smallVSelectedEl;
    }
  } else {
    switch (size) {
      case VehicleSize.SMALL:
        return smallVSelected;
      case VehicleSize.MEDIUM:
        return mediumVSelected;
      case VehicleSize.LARGE:
        return largeVSelected;
      default:
        return smallVSelected;
    }
  }
};
