import { JourneyColor } from "@evr/types";

const driverMarkerSvg = (color: JourneyColor) => {
  const bothStyles = `fill: ${color.main};`;
  const cls1 = `${bothStyles} fill-rule: evenodd; stroke-width: 0px;`;
  const cls2 = `${bothStyles} fill: ${color.main}; stroke: ${color.main}; stroke-miterlimit: 10; stroke-width: 13px;`;
  const svg = `<svg height="55px" width="45px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 733.33">
    <path style="${cls2}" d="M485.06,352.37c-1.45-7.41-14.29-72.64-17.46-82.17-7.62-22.9-25.94-48.97-48.99-69.73-23.97-21.59-48.8-33.97-68.14-33.97h-188.06c-9.75,0-17.68,7.93-17.68,17.68v27.77h-13.02c-4.78,0-8.65,3.87-8.65,8.65s3.87,8.65,8.65,8.65h13.02v20.63h-23.3c-4.78,0-8.65,3.87-8.65,8.65s3.87,8.65,8.65,8.65h23.3v86.87c0,9.75,7.93,17.68,17.68,17.68h21.56c3.22,21.42,21.75,37.9,44.05,37.9s40.83-16.48,44.05-37.9h79.31c3.22,21.42,21.75,37.9,44.05,37.9s40.83-16.48,44.05-37.9h28.05c9.75,0,17.68-7.93,17.68-17.68,0-.56-.05-1.12-.16-1.66ZM450.2,272.88h-89.1v-87.54c31.57,8.48,75.32,50.47,89.1,87.54ZM228.02,392.31c-15.03,0-27.26-12.23-27.26-27.26s12.23-27.26,27.26-27.26,27.26,12.23,27.26,27.26c0,15.03-12.23,27.26-27.26,27.26ZM395.44,392.31c-15.03,0-27.26-12.23-27.26-27.26s12.23-27.26,27.26-27.26,27.26,12.23,27.26,27.26c0,15.03-12.23,27.26-27.26,27.26ZM467.11,354.42h-28.42c-4.78-19.45-22.36-33.91-43.26-33.91s-38.48,14.47-43.26,33.91h-80.9c-4.78-19.45-22.36-33.91-43.26-33.91s-38.48,14.47-43.26,33.91h-22.35c-.21,0-.38-.17-.38-.38v-86.87h105.97c4.78,0,8.65-3.87,8.65-8.65s-3.87-8.65-8.65-8.65h-105.97v-20.63h48.78c4.78,0,8.65-3.87,8.65-8.65s-3.87-8.65-8.65-8.65h-48.78v-27.77c0-.21.17-.38.38-.38h181.4v97.73c0,4.78,3.87,8.65,8.65,8.65h102.23c3.69,16.81,8.74,43.76,13.05,63.71.09.4-.16.53-.61.53Z"/>
    <path style="${cls1}" d="M66.67,283.33c0-113.54,100.33-216.67,233.33-216.67s233.33,103.12,233.33,216.67c0,71.5-43.74,151.35-101.31,225.37-45.5,58.5-96.11,109.06-132.02,144.44-35.91-35.38-86.52-85.95-132.02-144.44-57.57-74.02-101.31-153.87-101.31-225.37ZM300,0C133,0,0,130.21,0,283.33c0,95.17,56.26,190.31,115.35,266.3,51.14,65.75,108.63,122.28,144.56,157.61,6.19,6.09,11.75,11.56,16.52,16.33,6.25,6.25,14.73,9.76,23.57,9.76s17.32-3.51,23.57-9.76c4.77-4.77,10.33-10.23,16.52-16.32h0c35.93-35.34,93.41-91.86,144.55-157.62,59.1-75.98,115.35-171.13,115.35-266.3C600,130.21,467,0,300,0Z"/>
    </svg>`;

  return svg;
};

export default driverMarkerSvg;
