import { useState } from "react";

import { ResultCardBox } from "@evr/components/ResultSidebar/ResultCard/ResultCardComponents";
import { ScheduledResultDownload } from "@evr/components/ScheduledJourneySidebar/ScheduledJourneyResultCard/ScheduledResultDownload";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyColor } from "@evr/store/slices/scheduled-journey-results";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";

import { ScheduledJourneyResultItems } from "./ScheduledJourneyResultItems";
import { ScheduledResultDetails } from "./ScheduledResultDetails";

interface PropsType {
  scheduledJourney: ScheduledJourney;
}

export const ScheduledJourneyResultCard = ({ scheduledJourney }: PropsType) => {
  const maximize = useAppSelector(selectSidebarOpenStatus);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenDetails = () => setOpen(open => !open);
  const journeysColor = useAppSelector(selectScheduledJourneyColor);

  return (
    <RowContainer margin="0.5rem 0 1rem" align="flex-start">
      {maximize && <ScheduledResultDownload color={journeysColor.main} />}
      <ResultCardBox open={maximize}>
        <ScheduledJourneyResultItems
          onOpenDetails={handleOpenDetails}
          maximize={maximize}
          scheduledJourney={scheduledJourney}
          journeyColor={journeysColor}
        />
        {maximize && (
          <Collapsible open={open}>
            <ScheduledResultDetails scheduledJourney={scheduledJourney} />
          </Collapsible>
        )}
      </ResultCardBox>
    </RowContainer>
  );
};
