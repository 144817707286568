import { SavingsEnergyIntervalsKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectResultIsReady } from "@evr/store/slices/result";
import { Button } from "@evr/ui/Button";

import { SavingsEnergyButtonsContainer } from "./styles";

interface CustomButtonPropsType {
  disabled?: boolean;
  title: SavingsEnergyIntervalsKeys;
  time: SavingsEnergyIntervalsKeys;
  setTime: React.Dispatch<React.SetStateAction<SavingsEnergyIntervalsKeys>>;
}
type ButtonsContainerPropsType = Omit<CustomButtonPropsType, "title">;

const CustomButton = ({ disabled, title, time, setTime }: CustomButtonPropsType) => {
  const handleClick = () => setTime(title);
  return (
    <Button disabled={disabled} fullWidth variant={time === title ? "contained" : "outlined"} onClick={handleClick}>
      {title}
    </Button>
  );
};

export const SavingsCustomButtons = ({ time, setTime }: ButtonsContainerPropsType) => {
  const resultIsReady = useAppSelector(selectResultIsReady);
  return (
    <SavingsEnergyButtonsContainer>
      <CustomButton
        disabled={!resultIsReady}
        title={SavingsEnergyIntervalsKeys.THIS_JOURNEY}
        time={time}
        setTime={setTime}
      />
      <CustomButton title={SavingsEnergyIntervalsKeys.ONE_MONTH} time={time} setTime={setTime} />
      <CustomButton title={SavingsEnergyIntervalsKeys.THREE_MONTHS} time={time} setTime={setTime} />
      <CustomButton title={SavingsEnergyIntervalsKeys.SIX_MONTHS} time={time} setTime={setTime} />
    </SavingsEnergyButtonsContainer>
  );
};
