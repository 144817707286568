import { useFormikContext } from "formik";

import { DriverBehavior, driverBehaviorOptions, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { Select } from "@evr/ui/Select";
interface DriverBehaviorFieldProps {
  name: string;
  label: string;
  isAllowedToChange: boolean;
}

export const DriverBehaviorField = ({ name, label, isAllowedToChange }: DriverBehaviorFieldProps) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext();

  const selectedValue = (values as Record<string, DriverBehavior>)[name];

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const behavior = e.target.value;
    if (behavior !== DriverBehavior.NORMAL && !isAllowedToChange) {
      setFieldValue(name, DriverBehavior.NORMAL);
      return dispatch(pushSnackbar({ type: SnackbarKeys.WARNING, title: notificationsMsg.LICENSE_DRIVER_BEHAVIOUR }));
    }
    setFieldValue(name, behavior);
  };

  return <Select items={driverBehaviorOptions} label={label} value={selectedValue} onChange={handleOnChange} />;
};
