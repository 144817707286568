import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SliceKeys, tablePaginationInitialValue } from "@evr/constant";
import { RootState } from "@evr/store";
import { SearchColumn } from "@evr/types";
import { getTableSortColumn, queryBuilder } from "@evr/utils";

export const VehicleSlice = createSlice({
  name: SliceKeys.VEHICLES,
  initialState: { pagination: tablePaginationInitialValue },
  reducers: {
    setVehiclePageIndex: (state, action: PayloadAction<number>) => {
      state.pagination.pageIndex = action.payload;
    },
    setVehicleSearchColumn: (state, action: PayloadAction<SearchColumn>) => {
      state.pagination.pageIndex = tablePaginationInitialValue.pageIndex;
      state.pagination.search[action.payload.column] = action.payload;
    },
    clearVehicleSearchColumn: (state, action: PayloadAction<string>) => {
      const { [action.payload]: deleteItem, ...otherSearchItems } = state.pagination.search;
      state.pagination.search = otherSearchItems;
    },
    setVehicleSortColumn: (state, action: PayloadAction<string>) => {
      const { column, type } = state.pagination.sort;
      state.pagination.sort = getTableSortColumn(column, action.payload, type);
    },
  },
});

export const { setVehiclePageIndex, setVehicleSearchColumn, setVehicleSortColumn, clearVehicleSearchColumn } =
  VehicleSlice.actions;

export const selectVehiclesQuery = createSelector(
  (state: RootState) => state.vehicles.pagination,
  ({ pageSize, pageIndex, search, sort }) => queryBuilder(pageSize, pageIndex, search, sort),
);
export const selectVehiclesPagination = createSelector(
  (state: RootState) => state.vehicles.pagination,
  ({ totalPages, pageIndex, sort, search }) => ({ totalPages, pageIndex, sort, search }),
);
export default VehicleSlice.reducer;
