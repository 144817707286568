import OSMGreyscale from "./OSMGreyscale";
import OSMGreyscaleDark from "./OSMGreyscaleDark";
import VectorTile from "./VectorTile";

enum State {
  Unloaded = 0,
  Loading = 1,
  Ready = 2,
  Error = 3,
}

export { OSMGreyscale, OSMGreyscaleDark, VectorTile, State };
