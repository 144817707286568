import { useState } from "react";

import { ScheduledJourney } from "@evr/types";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

import { ResultCardTab } from "../ResultSidebar/ResultCard/ResultCardComponents";
import LiveDriverDetails from "./LiveDriverDetails";
import LiveJourneyDetails from "./LiveJourneyDetails";

interface LiveJourneyResultDetailsProps {
  journey: ScheduledJourney;
}

enum ResultDetailsTab {
  DRIVER = "driver",
  JOURNEY = "journey",
}

const LiveJourneyResultDetails = ({ journey }: LiveJourneyResultDetailsProps) => {
  const [detailsTab, setDetailsTab] = useState<ResultDetailsTab>(ResultDetailsTab.JOURNEY);

  return (
    <ColumnContainer width="100%" minHeight="145px" justify="flex-start">
      <Flex width="100%">
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.DRIVER}
          onClick={() => setDetailsTab(ResultDetailsTab.DRIVER)}
        >
          Driver
        </ResultCardTab>
        <ResultCardTab
          active={detailsTab === ResultDetailsTab.JOURNEY}
          onClick={() => setDetailsTab(ResultDetailsTab.JOURNEY)}
        >
          Journey
        </ResultCardTab>
      </Flex>

      <LiveJourneyDetails journey={journey} hidden={detailsTab !== ResultDetailsTab.JOURNEY} />
      <LiveDriverDetails driver={journey.driver} hidden={detailsTab !== ResultDetailsTab.DRIVER} />
    </ColumnContainer>
  );
};

export default LiveJourneyResultDetails;
