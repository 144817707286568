import { useEffect } from "react";

import { Form, Formik } from "formik";

import { Dialogs, DialogTitle, LocalStorageKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser, selectUserOrgSettings } from "@evr/store/slices/auth";
import { closeDialog, detachedDialog, openDialog } from "@evr/store/slices/dialog";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { localStorageHelper } from "@evr/utils";

export const VerizonNewUserDialog = () => {
  const dispatch = useAppDispatch();
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    var fromVerizon = currentUser?.organisation.fromVerizon;
    var firstImport = localStorageHelper.getItem(LocalStorageKeys.IS_FIRST_VERIZON_IMPORT, false);
    var alreadyImported = orgSettings?.verizonLastImportDate !== undefined;
    if (alreadyImported || !fromVerizon || !firstImport) {
      dispatch(detachedDialog());
    }
  }, [orgSettings, currentUser]);

  const handleSubmit = (values: any) => {
    localStorageHelper.removeItem(LocalStorageKeys.IS_FIRST_VERIZON_IMPORT);
    dispatch(openDialog({ type: Dialogs.VERIZON_LOGIN }));
  };

  const handleCancel = () => {
    localStorageHelper.removeItem(LocalStorageKeys.IS_FIRST_VERIZON_IMPORT);
    dispatch(closeDialog());
  };

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_NEW_USER} showClose={false} />
      <DialogBody>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          <Form>
            <Flex wrap="wrap" align="baseline" justify="flex-start">
              <GridItem>
                <p>Would you like to start a Verizon Data Import?</p>
              </GridItem>
            </Flex>
            <DialogActionButtons
              buttonTitle={"Yes"}
              variant="contained"
              cancelText="No"
              onCancelAction={handleCancel}
            />
          </Form>
        </Formik>
      </DialogBody>
    </>
  );
};
