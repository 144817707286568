import { useState } from "react";

import { useEditVehicleMutation } from "@evr/apis/vehicle";
import { vehicleInitialValues } from "@evr/constant";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserSubscription } from "@evr/store/slices/auth";
import { Vehicle } from "@evr/types";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";

import { chargeDictionary } from "./chargeDictionary";

interface SettingInitialStateOfChargeProps {
  vehicle: Vehicle;
}
export const SettingInitialStateOfCharge: React.FC<SettingInitialStateOfChargeProps> = ({ vehicle }) => {
  if (!(vehicle.id in chargeDictionary)) {
    chargeDictionary[vehicle.id] = 100;
  }
  const { planType } = useAppSelector(selectUserSubscription);
  const [initialStateOfCharge, setInitialStateOfCharge] = useState(chargeDictionary[vehicle.id]);
  const [editVehicle, { isLoading: editLoading }] = useEditVehicleMutation();

  const isFreePlan = planType === PlanKeys.STARTER;

  const modifyInitialStateOfCharge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      const sanitizedValue = inputValue.replace(/^0+/, "") || "0";
      const clampedValue = Math.min(100, Math.max(0, Number(sanitizedValue)));
      setInitialStateOfCharge(clampedValue);
      var id = vehicle.id;
      Object.assign(chargeDictionary, { [id]: clampedValue });
    }
  };

  return (
    <RowContainer width="40%" justify="flex-start">
      <img src="battery-status-full.png" alt="Battery" style={{ width: "20px", height: "20px", marginRight: "1px" }} />

      <Flex margin="0.2rem 0.2rem 0.2rem auto" title={isFreePlan ? "This item is not active on your plan" : ""}>
        <Input
          type="text"
          style={{ height: "25px", width: "40px", textAlign: "center", padding: "0" }}
          value={initialStateOfCharge.toString()}
          onChange={e => {
            modifyInitialStateOfCharge(e);
          }}
          inputMode="numeric"
          pattern="[0-9]*"
          min={0}
          max={100}
        />
        <span style={{ marginLeft: "4px", lineHeight: "20px" }}>%</span>
        <Wrapper position="relative">{editLoading && <CircleLoading size={4} />}</Wrapper>
      </Flex>
    </RowContainer>
  );
};
