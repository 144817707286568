import { useState, useEffect } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";
import { ToggleSwitch } from "@evr/ui/ToggleSwitch";
import { Typography } from "@evr/ui/Typography";

export const ToggleMonitorDrivers = () => {
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [monitorDriverInactivity, setMonitorDrivers] = useState(orgSettings.monitorDriverInactivity);
  const [activityTimeout, setActivityTimeout] = useState(orgSettings.activityTimeout);
  const [inputValue, setInputValue] = useState(orgSettings.activityTimeout.toString());

  const isGoldPlan = planType === PlanKeys.GOLD;

  const [editMonitorDrivers, { isLoading }] = useEditSettingsMutation();
  const [editActivityTimeout] = useEditSettingsMutation();

  useEffect(() => {
    setMonitorDrivers(orgSettings.monitorDriverInactivity);
  }, [orgSettings]);

  useEffect(() => {
    setActivityTimeout(orgSettings.activityTimeout);
    setInputValue(orgSettings.activityTimeout.toString());
  }, [orgSettings]);

  useEffect(() => {
    if (!isGoldPlan && monitorDriverInactivity) {
      onChange(false);
    }
  }, [isGoldPlan, monitorDriverInactivity, orgSettings, editMonitorDrivers]);

  const onChange = (status: boolean) => {
    editMonitorDrivers({ ...orgSettings, monitorDriverInactivity: status });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = Number(inputValue);
      const clampedValue = Math.min(240, Math.max(5, value));
      setActivityTimeout(clampedValue);
      editActivityTimeout({ ...orgSettings, activityTimeout: clampedValue });
    }
  };

  return (
    <RowContainer width="100%" justify="flex-start" style={{ alignItems: "right" }}>
      <Typography>Driver Inactivity Alert(mins)</Typography>
      <Flex
        margin="0.2rem 0.2rem 0.6rem 0.2rem"
        title={isGoldPlan ? "" : "This item is not active on your plan"}
        style={{ marginLeft: "auto", alignItems: "right" }}
      >
        {monitorDriverInactivity && (
          <Wrapper margin="0 0.2rem">
            <Input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              disabled={!isGoldPlan || isLoading}
              style={{ padding: "0.4rem", fontSize: "1rem", width: "80px" }} // Adjusted the width to be wider
            />
          </Wrapper>
        )}
        <ToggleSwitch
          onChange={onChange}
          loading={isLoading}
          disabled={!isGoldPlan}
          value={monitorDriverInactivity}
          checked={monitorDriverInactivity}
        ></ToggleSwitch>
        <Wrapper position="relative">{isLoading && <CircleLoading size={4} />}</Wrapper>
      </Flex>
    </RowContainer>
  );
};
