import { FormikConfig, FormikHelpers } from "formik";

import { FormikForm } from "@evr/components/Form";
import { AddressKeys, AuthKeys } from "@evr/constant";
import { SignUpType } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { GridItem, RowContainer } from "@evr/ui/FlexBox";

import { CompanyForm } from "../CompanyForm";
import { PersonalForm } from "../PersonalForm";
import { companyFormValidationSchema, personalFormValidationSchema, termsConditionsValidationSchema } from "../schema";
import { TermsConditionsForm } from "../TermsConditionsForm";
import { BackButtonContainer } from "./styles";

const forms = [
  { validation: personalFormValidationSchema, component: <PersonalForm /> },
  { validation: companyFormValidationSchema, component: <CompanyForm /> },
  { validation: termsConditionsValidationSchema, component: <TermsConditionsForm /> },
];

const initialValues = {
  [AuthKeys.FIRST_NAME]: "",
  [AuthKeys.LAST_NAME]: "",
  [AuthKeys.USER_NAME]: "",
  [AuthKeys.EMAIL]: "",
  [AuthKeys.PASSWORD]: "",
  [AuthKeys.CONFIRM_PASSWORD]: "",

  [AuthKeys.PHONE_NUMBER]: "",
  [AuthKeys.ORGANISATION_NAME]: "",
  [AuthKeys.BILLING_ADDRESS]: "",
  [AuthKeys.VAT_NUMBER]: "",
  [AuthKeys.BILLING_EMAIL]: "",

  [AuthKeys.TERMS_CONDITION]: false,
  [AuthKeys.ORG_ADDITIONAL]: {},
  [AuthKeys.ORGANISATION_ADDRESS]: {
    [AddressKeys.ID]: "",
    [AddressKeys.FIRST_LINE]: "",
    [AddressKeys.SECOND_LINE]: "",
    [AddressKeys.CITY]: "",
    [AddressKeys.STATE]: "",
    [AddressKeys.COUNTRY_ID]: "",
    [AddressKeys.COUNTRY]: {},
    [AddressKeys.POSTCODE]: "",
  },
};

interface PropsType extends Pick<FormikConfig<SignUpType>, "onSubmit"> {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  step: number;
}

export const SignUpForms = ({ onSubmit, setStep, step, loading }: PropsType) => {
  const isLastStep = () => step === forms.length - 1;
  const handleBack = () => setStep(s => s - 1);

  const handleSubmit = async (values: SignUpType, helpers: FormikHelpers<SignUpType>) => {
    if (isLastStep()) {
      await onSubmit(values, helpers);
    } else {
      setStep(s => s + 1);
    }
  };

  return (
    <FormikForm<SignUpType>
      initialValues={initialValues}
      validationSchema={forms[step].validation}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
    >
      {forms[step].component}
      <RowContainer position="relative">
        {step > 0 ? (
          <BackButtonContainer>
            <Button variant="text" onClick={handleBack}>
              Back
            </Button>
          </BackButtonContainer>
        ) : null}
        <GridItem sm={6} padding="0.5rem" margin="auto" display="flex">
          <Button disabled={loading} fontSize="1.3rem" type="submit" padding="0.5rem" fullWidth>
            {loading && <CircleLoading />}
            {isLastStep() ? "Sign Up" : "Next"}
          </Button>
        </GridItem>
      </RowContainer>
    </FormikForm>
  );
};
