import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { closeDialog } from "@evr/store/slices/dialog";
import { setTour } from "@evr/store/slices/setting";
import { Button } from "@evr/ui/Button";
import { RowContainer } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

export const SettingInformationCard = () => {
  const dispatch = useAppDispatch();

  const handleStartTour = () => {
    dispatch(setTour(true));
    dispatch(closeDialog());
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Information Cards</Typography>
      <Button variant="contained" margin="0.2rem 0.2rem 0.2rem auto" onClick={handleStartTour}>
        Start
      </Button>
    </RowContainer>
  );
};
