import { evrApi } from "@evr/apis";
import { ApiMethodKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { setUserHasCalculation } from "@evr/store/slices/auth";
import { setAccepted } from "@evr/store/slices/result";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { ApiPageResponse, Calculation, CalculationParams, AcceptCalculationParams, DriverJourney } from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { CalculationEndpoint } from "../endpoints";

export const calculationApi = evrApi.enhanceEndpoints({ addTagTypes: [CacheKeys.CALCULATIONS] }).injectEndpoints({
  endpoints: builder => ({
    calculations: builder.query<ApiPageResponse<Calculation>, string>({
      query: query => ({
        url: `${CalculationEndpoint}?${query}`,
      }),
      providesTags: [CacheKeys.CALCULATIONS],
    }),
    sendCalculation: builder.mutation<void, CalculationParams>({
      query: data => ({ url: CalculationEndpoint, method: ApiMethodKeys.POST, data }),
      invalidatesTags: [CacheKeys.CALCULATIONS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.CALCULATION_IN_PROGRESS }));
          dispatch(setUserHasCalculation(true));
        });
      },
    }),
    acceptJourney: builder.mutation<DriverJourney, AcceptCalculationParams>({
      query: data => ({
        url: `${CalculationEndpoint}/${data.calculationId}/journeys/${data.journeyId}/Accept`,
        method: ApiMethodKeys.POST,
        data: { startDate: data.startDate },
      }),
      invalidatesTags: [CacheKeys.CALCULATIONS],
      onQueryStarted(data, { dispatch, queryFulfilled }) {
        queryFulfilled.then(result => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.JOURNEY_SCHEDULED }));
          dispatch(setAccepted({ journeyId: data.journeyId, acceptedJourneyHref: result.data.href }));
        });
      },
    }),
    reRunCalculation: builder.mutation<void, number>({
      query: id => ({ url: `${CalculationEndpoint}/${id}/ReRun`, method: ApiMethodKeys.POST }),
      invalidatesTags: [CacheKeys.CALCULATIONS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.CALCULATION_IN_PROGRESS }));
          dispatch(setUserHasCalculation(true));
        });
      },
    }),
  }),
});

export const {
  useCalculationsQuery,
  useSendCalculationMutation,
  useAcceptJourneyMutation,
  useReRunCalculationMutation,
} = calculationApi;
