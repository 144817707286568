import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { DriverProgressSnapshot, JourneyColor } from "@evr/types";

import driverMarkerSvg from "../driverMarkerSvg";

interface DriverPositionLayerProps {
  source: olVectorSource;
  snapshot: DriverProgressSnapshot;
  journeyColor: JourneyColor;
}

const DriverPositionLayer = ({ source, snapshot, journeyColor }: DriverPositionLayerProps) => {
  if (!source.isEmpty()) {
    source.clear();
  }

  const feature = new Feature({
    geometry: new Point(fromLonLat(snapshot.point.coordinates)),
  });

  const svg = driverMarkerSvg(journeyColor);
  const style = new Style({
    image: new Icon({
      src: "data:image/svg+xml;utf8," + encodeURIComponent(svg),
      anchor: [0.5, 1],
    }),
  });

  feature.setStyle(style);

  source.addFeature(feature);

  return <Vector source={source} zIndex={MapLayerZIndices.DRIVER_POSITION}></Vector>;
};

export default DriverPositionLayer;
