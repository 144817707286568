import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { removeDVMatch, selectDriverMatched, selectDVMatchState } from "@evr/store/slices/dvMatch";
import { Driver } from "@evr/types";
import { RowContainer } from "@evr/ui/FlexBox";

import { DVMatchSelectDriver } from "./DVMatchSelectDriver";
import { DVMatchSelectVehicle } from "./DVMatchSelectVehicle";
import { DriverVehicleRowContainer, VehicleIndicator } from "./styles";
import { VehicleMatchTable } from "./VehicleMatchTable";

interface PropsType {
  driver: Driver;
}

export const DVMatchRow = ({ driver }: PropsType) => {
  const dispatch = useAppDispatch();
  const driverMatched = useAppSelector(selectDriverMatched(driver.id));
  const { activeDriverId, activeVehicleSize: vehicleSize } = useAppSelector(selectDVMatchState);

  const [driverSelect, setDriverSelect] = useState<boolean>(driverMatched ? true : false);

  const isActiveTable = activeDriverId === driver.id;

  // This useEffect reset the driverSelect state when driverMatched will be reset
  // to the default value by clicking on the EVR Icon
  useEffect(() => {
    setDriverSelect(!!driverMatched);
  }, [driverMatched]);

  const handleDriverSelect = () => {
    if (driverSelect) {
      dispatch(removeDVMatch(driver.id));
    }
    setDriverSelect(driverSelect => !driverSelect);
  };

  return (
    <DriverVehicleRowContainer>
      <RowContainer
        width="100%"
        justify="space-evenly"
        position="relative"
        padding="1rem 0.5rem"
        opacity={driverSelect ? 1 : 0.5}
      >
        <DVMatchSelectDriver driver={driver} isDriverActive={driverSelect} handleDriverSelected={handleDriverSelect} />
        <DVMatchSelectVehicle driverSelected={driverSelect} driverId={driver.id} />
        {isActiveTable && vehicleSize && <VehicleIndicator vehicleSize={vehicleSize} />}
      </RowContainer>
      {driverSelect && vehicleSize && isActiveTable && <VehicleMatchTable driverId={driver.id} />}
    </DriverVehicleRowContainer>
  );
};
