import styled from "styled-components";

import { RowContainer } from "@evr/ui/FlexBox";

export const DrawerBox = styled(RowContainer)`
  position: absolute;
  top: 50%;
  left: 0;
  transition: all 0.25s ease-in-out;
  z-index: ${props => props.theme.zIndex.drawer};
  transform: ${props => (props.open ? "translate(0px, -50%)" : "translate(-60px, -50%)")};
`;
export const Menu = styled.div`
  width: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 0 1rem 1rem 0;
  button {
    margin: 0.7rem 0;
  }
`;
