import { useEffect, useState } from "react";

interface PropsType {
  count: number;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SendEmailAgainCounter = ({ count, setDisable }: PropsType) => {
  const [counter, setCounter] = useState(count);

  useEffect(() => {
    if (counter <= 0) {
      setDisable(false);
      return;
    }
    const timeout = setTimeout(() => setCounter(counter - 1), 1000);
    return () => clearTimeout(timeout);
  }, [counter, setDisable]);

  const getMinutesSeconds = (time?: number) => {
    if (!time) {
      return "0";
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return <>{getMinutesSeconds(counter)}</>;
};
