import { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import CountryTypeahead from "@evr/components/CountryTypeahead/CountryTypeahead";
import FormikTypeahead from "@evr/components/CountryTypeahead/FormikTypeahead";
import { AutoCompleteOption } from "@evr/components/CountryTypeahead/Typeahead";
import { FormikTextInput } from "@evr/components/Form";
import { AddressKeys, AuthKeys } from "@evr/constant";
import { GetCurrencies } from "@evr/services/sso";
import { Country, Currency } from "@evr/types";
import { GridItem, Flex } from "@evr/ui/FlexBox";

import { AuthTitle } from "../AuthTitle";

export const CompanyForm = () => {
  const { setFieldValue } = useFormikContext();

  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [currencySearchTerm, setCurrencySearchTerm] = useState<string>("");

  useEffect(() => {
    GetCurrencies().then(res => {
      setCurrencies(res.data);
    });
  }, []);

  return (
    <>
      <AuthTitle>Please enter your company information</AuthTitle>
      <Flex wrap="wrap" align="baseline" justify="flex-start">
        <GridItem xs={12} sm={12}>
          <FormikTextInput placeholder="Organisation Name" name={AuthKeys.ORGANISATION_NAME} autoFocus />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <FormikTextInput
            placeholder="Address First Line"
            name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.FIRST_LINE}`}
          />
        </GridItem>
        <GridItem xs={12} sm={12}>
          <FormikTextInput
            placeholder="Address Second Line"
            name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.SECOND_LINE}`}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="City" name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.CITY}`} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="State" name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.STATE}`} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Post code" name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.POSTCODE}`} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CountryTypeahead
            handleChange={(country: Country) => {
              setFieldValue(`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.COUNTRY}`, country);
              setFieldValue(AuthKeys.PHONE_NUMBER, country.callingCode);
              setFieldValue(AuthKeys.ORGANISATION_TRANSACTION_CURRENCY_CODE, country.defaultCurrencyCode);
            }}
            clear={() => {
              setFieldValue(`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.COUNTRY}`, undefined);
              setFieldValue(AuthKeys.PHONE_NUMBER, "");
              setFieldValue(AuthKeys.ORGANISATION_TRANSACTION_CURRENCY_CODE, "");
            }}
            name={`${AuthKeys.ORGANISATION_ADDRESS}.${AddressKeys.COUNTRY_ID}`}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTypeahead
            items={
              (currencies
                ?.map(x => ({
                  key: x.code,
                  value: x.code,
                  label: `${x.symbol ?? ""} ${x.name} (${x.code})`,
                }))
                .filter(x =>
                  x.label.toLowerCase().includes(currencySearchTerm.toLowerCase()),
                ) as AutoCompleteOption[]) || []
            }
            label="Currency"
            onSearch={(term: string) => setCurrencySearchTerm(term)}
            clear={() => setCurrencySearchTerm("")}
            name={AuthKeys.ORGANISATION_TRANSACTION_CURRENCY_CODE}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Billing Email" name={AuthKeys.BILLING_EMAIL} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="Phone" name={AuthKeys.PHONE_NUMBER} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormikTextInput placeholder="VAT Number" name={AuthKeys.VAT_NUMBER} />
        </GridItem>
      </Flex>
    </>
  );
};
