import styled from "styled-components";

import { increaseWidthAnimation } from "@evr/theme/animations";

import { Wrapper } from "./FlexBox";

interface PropsType {
  delay?: number;
}
const IncreaseLine = styled.div<PropsType>`
  height: 5px;
  position: absolute;
  background: ${props => props.theme.colors.gradient.main};
  animation: ${increaseWidthAnimation} 3s ${props => props.delay && `${props.delay}s`} infinite;
`;
export const LinearLoading = () => {
  return (
    <Wrapper aria-label="linearLoading" position="relative" width="100%" height="5px" overflow="hidden">
      <Wrapper position="absolute" opacity={0.5} width="100%" height="5px" />
      <IncreaseLine />
      <IncreaseLine delay={1.5} />
    </Wrapper>
  );
};
