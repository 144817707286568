import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerNames, MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectResultState } from "@evr/store/slices/result";
import { selectJourneysColor } from "@evr/store/slices/setting";

import { resultLocationSvg } from "../ResultLocation/resultLocationSvg";

interface ResultUnroutablesLayerProps {
  unroutablesSource: olVectorSource;
}

const ResultUnroutablesLayer = ({ unroutablesSource }: ResultUnroutablesLayerProps) => {
  const resultState = useAppSelector(selectResultState);
  const unroutables = resultState.result?.unrouteableLocations ?? [];
  const journeysColor = useAppSelector(selectJourneysColor);

  if (resultState.showUnrouteables) {
    const unrouteableFeatures = unroutables.map((location, index) => {
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(location.point.coordinates)),
      });

      const svg = resultLocationSvg(index + 1, journeysColor.unroutables);
      const style = new Style({
        image: new Icon({
          src: "data:image/svg+xml;utf8," + encodeURIComponent(svg),
          scale: 0.22,
          anchor: [0.5, 1],
        }),
      });

      feature.setProperties({
        id: location.id,
      });

      feature.setStyle(style);
      return feature;
    });

    unroutablesSource.addFeatures(unrouteableFeatures ?? []);
  } else {
    if (!unroutablesSource.isEmpty()) {
      unroutablesSource.clear();
    }
  }

  return (
    <Vector
      name={MapLayerNames.RESULT_UNROUTABLES}
      source={unroutablesSource}
      zIndex={MapLayerZIndices.LOCATIONS}
    ></Vector>
  );
};

export default ResultUnroutablesLayer;
