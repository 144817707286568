import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import {
  selectCalculationId,
  selectResult,
  selectShowUnrouteables,
  setToggleUnrouteables,
} from "@evr/store/slices/result";
import { getCalculationUnrouteableCsvAsync } from "@evr/store/slices/result/asyncActions";
import { selectJourneysColor } from "@evr/store/slices/setting";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { Flex, RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";
import { downloadFile } from "@evr/utils";

export const NonRoutedDropOffs = () => {
  const dispatch = useAppDispatch();
  const resultState = useAppSelector(selectResult);
  const calculationId = useAppSelector(selectCalculationId);
  const isShowUnrouteables = useAppSelector(selectShowUnrouteables);
  const journeysColor = useAppSelector(selectJourneysColor);
  const maximize = useAppSelector(selectSidebarOpenStatus);

  const downloadApi = useApi(getCalculationUnrouteableCsvAsync);

  const handleToggleShowingNonRoutedDropOffs = () => dispatch(setToggleUnrouteables());

  const handleDownloadNonroutedDropOffs = async () => {
    const res = await downloadApi.sendRequest(calculationId);
    if (!res.apiErrors) {
      downloadFile(res.response as string, `Calculation${calculationId}_UnrouteableParcels.csv`, true);
    }
  };

  return (
    <RowContainer margin="0.5rem 0 1rem" align="flex-start">
      {maximize && (
        <Flex width="45px" padding="0.25rem 0" height="100%">
          <IconButton
            WH="25px"
            icon="cloud-download-alt"
            title="Download Non Routable Parcels"
            onClick={handleDownloadNonroutedDropOffs}
          />
        </Flex>
      )}

      <Flex
        rounded
        shadow={5}
        height="50px"
        overflow="hidden"
        padding="0.5rem 1rem"
        background={journeysColor.unroutables.main}
        color={journeysColor.unroutables.contrast}
        width={maximize ? "300px" : "50px"}
        justify={maximize ? "space-between" : "center"}
      >
        {maximize && (
          <Typography fontWeight={500}>
            Non Routable Parcels{` : ${resultState.result && resultState.result.unrouteableLocations.length}`}
          </Typography>
        )}

        <IconButton
          WH="25px"
          iconClass="far"
          icon={isShowUnrouteables ? "eye" : "eye-slash"}
          title="Toggle Non Routable Parcels Layout"
          color={journeysColor.unroutables.contrast}
          onClick={handleToggleShowingNonRoutedDropOffs}
        />
      </Flex>
    </RowContainer>
  );
};
