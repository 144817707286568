import { useState } from "react";

import { useSendCalculationMutation } from "@evr/apis/calculation";
import { chargeDictionary } from "@evr/components/DriverVehicleMatch/DVMatchSelectVehicle/chargeDictionary";
import { ParcelProcessedStatusKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";
import { selectDVMatchState, setDVMatchVehicleIdAndCharge } from "@evr/store/slices/dvMatch";
import { selectProcessedParcelStatus, selectValidJobLocations } from "@evr/store/slices/parcel";
import { theme } from "@evr/theme";
import { danceAnimation, rotateAnimation } from "@evr/theme/animations";
import { CalculationParams, JobLocation } from "@evr/types";
import { IconButton } from "@evr/ui/IconButton";

export const CalculationItem = () => {
  const [firstClick, setFirstClick] = useState<boolean>(true);
  const [sendCalculation, { isLoading }] = useSendCalculationMutation();
  const dispatch = useAppDispatch();
  const currentDepot = useAppSelector(selectCurrentDepot);
  const isDepotSelected = currentDepot !== null;
  dispatch(setDVMatchVehicleIdAndCharge(chargeDictionary));
  const { list: driverVehiclePairs } = useAppSelector(selectDVMatchState);
  const isDVsSelected = driverVehiclePairs.length !== 0;
  const parcelStatus = useAppSelector(selectProcessedParcelStatus);
  const jobLocationsObject = useAppSelector(selectValidJobLocations);
  const enableCalculation = isDepotSelected && isDVsSelected && parcelStatus === ParcelProcessedStatusKeys.DONE;
  const jobLocations = Object.values(jobLocationsObject).map(l => l as JobLocation);

  const handleCalculation = async () => {
    if (!currentDepot) {
      return;
    }
    const calculationParams: CalculationParams = { depotId: currentDepot.id, driverVehiclePairs, jobLocations };
    await sendCalculation(calculationParams);
    setFirstClick(false);
  };

  return (
    <IconButton
      className="calculation-tour"
      WH="40px"
      icon="calculator"
      title="Calculation"
      gradient
      borderRadius={isLoading ? "50%" : theme.borderRadius}
      disabled={!enableCalculation || isLoading || !jobLocations.length}
      onClick={handleCalculation}
      animation={isLoading ? rotateAnimation : danceAnimation}
      animationStyle={`${isLoading ? "1s" : "6s"} infinite`}
      enableAnimation={(enableCalculation && jobLocations.length && firstClick) || isLoading}
    />
  );
};
