import styled, { css } from "styled-components";

interface PropsType {
  primary?: boolean;
  error?: boolean;
  borderShadow?: boolean;
  width?: string | number;
  padding?: string;
  warning?: boolean;
}

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5rem;
  flex-grow: 1;
  width: 100%;
`;
export const Input = styled.input.attrs(props => ({
  type: props.type ? props.type : "text",
}))<PropsType>`
  display: block;
  width: ${props => props.width || "100%"};
  padding: ${props => props.padding || "0.375rem 0.75rem"};
  font-size: 1rem;
  line-height: 1.5;
  color: ${props => props.theme.colors.grey.dark};
  background-color: ${props => props.theme.colors.common.white};
  background-clip: padding-box;
  border: 1px solid ${props => props.theme.colors.action.border};
  border-radius: ${props => props.theme.borderRadius};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: ${props => props.theme.colors.grey.main};
  }
  &:focus {
    border-color: ${props => props.theme.colors.primary.main};
    outline: 0;
    ${props => props.borderShadow && "box-shadow: 0 0 0 0.2rem rgba(122, 212, 0, 0.25);"}
  }
  &:focus + div {
    border-color: ${props => props.theme.colors.primary.main};
  }
  ${props =>
    props.warning &&
    css`
      border-color: ${props.theme.colors.warning.main};
      & + div {
        border-color: ${props.theme.colors.warning.main};
      }
      &:focus {
        border-color: ${props.theme.colors.warning.main};
        ${props.borderShadow && "box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);"}
      }
      &:focus + div {
        border-color: ${props.theme.colors.warning.main};
      }
    `}
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.error.main};
      & + div {
        border-color: ${props.theme.colors.error.main};
      }
      &:focus {
        border-color: ${props.theme.colors.error.main};
        ${props.borderShadow && "box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);"}
      }
      &:focus + div {
        border-color: ${props.theme.colors.error.main};
      }
    `}
 
  ${props => props.disabled && `color: ${props.theme.colors.action.disabled};`}
`;
export const Label = styled.label<{ error?: boolean; warning?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  width: 100%;
  ${props => props.disabled && `color: ${props.theme.colors.action.disabled};`}
  ${props => props.warning && `color: ${props.theme.colors.warning.main};`}
  ${props => props.error && `color: ${props.theme.colors.error.main};`}
`;
export const EndLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  width: 45px;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.grey.default};
  background-color: ${props => props.theme.colors.grey.light};
  border-top: 1px solid ${props => props.theme.colors.action.border};
  border-right: 1px solid ${props => props.theme.colors.action.border};
  border-bottom: 1px solid ${props => props.theme.colors.action.border};
  border-top-right-radius: ${props => props.theme.borderRadius};
  border-bottom-right-radius: ${props => props.theme.borderRadius};
`;
export const ErrorBox = styled.div`
  margin-top: 0.1rem;
  flex: 1;
  color: ${props => props.theme.colors.error.main};
  font-size: 0.7rem;
`;
export const WarningBox = styled.div`
  margin-top: 0.1rem;
  flex: 1;
  color: ${props => props.theme.colors.warning.main};
  font-size: 0.7rem;
`;
