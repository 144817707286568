import { useEffect, useMemo, useState } from "react";

import { Form, Formik } from "formik";

import { FormikCheckbox } from "@evr/components/Form";
import { Dialogs, DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, openDialog } from "@evr/store/slices/dialog";
import {
  getTotalDrivers,
  selectVehicles,
  setImportPosition,
  setVehicleIndex,
  setVehiclesSelected,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { VerizonVehicleImport } from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { StyledTable, TD, TH, TR } from "@evr/ui/Table";

type SortField = "registration" | "make" | "model";
type SortOrder = "asc" | "desc";

export const VerizonVehicleSelectDialog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const reduxVehicles = useAppSelector(selectVehicles);
  const [localVehicles, setLocalVehicles] = useState<VerizonVehicleImport[]>(reduxVehicles);
  const [sortField, setSortField] = useState<SortField>("registration");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [hideImported, setHideImported] = useState<boolean>(true);

  const totalSelectedDrivers = useAppSelector(getTotalDrivers);

  useEffect(() => {
    setLocalVehicles(reduxVehicles);
  }, [reduxVehicles]);

  const handleSubmit = (values: any) => {
    var updatedVehicles = localVehicles.map(vehicle => {
      return { registration: vehicle.vehicle.registration, selected: vehicle.selected };
    });

    dispatch(setVehiclesSelected(updatedVehicles));

    if (localVehicles.filter(vehicle => vehicle.selected && !vehicle.exists).length > 0) {
      dispatch(setVehicleIndex(0));
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_IMPORT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE }));
      }, 200);
    } else {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DATA_IMPORT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_IMPORT }));
      }, 200);
    }
  };

  const handleCheckboxChange = (registration: string) => {
    setLocalVehicles(prevVehicles =>
      prevVehicles.map(vehicle =>
        vehicle.vehicle.registration === registration ? { ...vehicle, selected: !vehicle.selected } : vehicle,
      ),
    );
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const toggleSelectAll = () => {
    const allSelected = localVehicles.every(vehicle => vehicle.selected);
    setLocalVehicles(prevVehicles => prevVehicles.map(vehicle => ({ ...vehicle, selected: !allSelected })));
  };

  const sortedAndFilteredVehicles = useMemo(() => {
    return [...localVehicles]
      .sort((a, b) => {
        let comparison = 0;
        switch (sortField) {
          case "registration":
            comparison = (a.vehicle.registration || "").localeCompare(b.vehicle.registration || "");
            break;
          case "make":
            comparison = (a.vehicle.make || "").localeCompare(b.vehicle.make || "");
            break;
          case "model":
            comparison = (a.vehicle.model || "").localeCompare(b.vehicle.model || "");
            break;
        }
        return sortOrder === "asc" ? comparison : -comparison;
      })
      .filter(x => !hideImported || !x.exists);
  }, [localVehicles, sortField, sortOrder, hideImported]);

  const handleBack = () => {
    if (totalSelectedDrivers > 0) {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DRIVER_IMPORT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_CREATE }));
      }, 200);
    } else {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DRIVER_SELECT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_SELECT }));
      }, 200);
    }
  };

  const handleHideImported = (value: any) => {
    setHideImported(!hideImported);
  };

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_VEHICLE_SELECT} showClose={false} />
      <DialogBody>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Wrapper position="relative">
                <RowContainer height="50px" width="100%" padding="1rem 0 0 0" justify="flex-start">
                  <FormikCheckbox
                    checked={hideImported}
                    name="hidechk"
                    label="Hide already imported vehicles"
                    onChange={handleHideImported}
                  />
                </RowContainer>
              </Wrapper>
              <br />
              <StyledTable stickyHeader={false}>
                <thead>
                  <TR>
                    <TH stickyHeader={false} onClick={toggleSelectAll} style={{ cursor: "pointer" }}>
                      Selected
                    </TH>
                    <TH
                      stickyHeader={false}
                      onClick={() => handleSort("registration")}
                      style={{ cursor: "pointer", minWidth: 110 }}
                    >
                      Registration {sortField === "registration" && (sortOrder === "asc" ? "▲" : "▼")}
                    </TH>
                    <TH stickyHeader={false} onClick={() => handleSort("make")} style={{ cursor: "pointer" }}>
                      Make {sortField === "make" && (sortOrder === "asc" ? "▲" : "▼")}
                    </TH>
                    <TH stickyHeader={false} onClick={() => handleSort("model")} style={{ cursor: "pointer" }}>
                      Model {sortField === "model" && (sortOrder === "asc" ? "▲" : "▼")}
                    </TH>
                  </TR>
                </thead>
                <tbody>
                  {sortedAndFilteredVehicles.map(vehicle => (
                    <TR key={vehicle.vehicle.registration}>
                      <TD>
                        <FormikCheckbox
                          name="driver"
                          value={vehicle.vehicle.registration}
                          checked={vehicle.selected || vehicle.exists}
                          onChange={() => {
                            if (!vehicle.exists) {
                              handleCheckboxChange(vehicle.vehicle.registration);
                            }
                          }}
                          label=""
                          disabled={vehicle.exists}
                          title={vehicle.exists ? "Driver already exists" : undefined}
                        />
                      </TD>
                      <TD>{vehicle.vehicle.registration}</TD>
                      <TD>{vehicle.vehicle.make}</TD>
                      <TD>{vehicle.vehicle.model}</TD>
                    </TR>
                  ))}
                  {sortedAndFilteredVehicles.length === 0 && (
                    <TR>
                      <TD colSpan={4}>
                        <b>No vehicles to import</b>
                      </TD>
                    </TR>
                  )}
                </tbody>
              </StyledTable>

              <DialogActionButtons
                loading={loading}
                buttonTitle={"Continue"}
                variant="contained"
                cancelText="Back"
                onCancelAction={handleBack}
              />
            </Form>
          )}
        </Formik>
      </DialogBody>
    </>
  );
};
