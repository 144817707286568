import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosRequestConfig, AxiosError } from "axios";

import { SnackbarKeys } from "@evr/constant";
import { apiInstance } from "@evr/services/baseHttp";
import { logOutUser } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { apiErrors } from "@evr/types";
import { errorHandling } from "@evr/utils";

export interface AxiosBaseQueryError {
  status: number;
  data: string;
}

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, unknown> =>
  async (axiosRequest, { dispatch }) => {
    try {
      const result = await apiInstance.request(axiosRequest);
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError<apiErrors>;

      const { msg, unAuthorized } = errorHandling(err, axiosRequest.url);

      if (unAuthorized) {
        dispatch(logOutUser());
      }
      dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: msg }));

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        } as AxiosBaseQueryError,
      };
    }
  };
