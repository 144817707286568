import { useState } from "react";

import { useTheme } from "styled-components";

import { MinutesStep, Time, TimeRange } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";
import { convertTimeString2Number, getTimeString } from "@evr/utils";

import { TimePicker } from "../TimePicker";
import { TimePickerTab } from "../TimePickerTab";

enum TimeSection {
  START,
  END,
}

interface TimeRangeContainerProps {
  onClose: () => void;
  timeRange: TimeRange | null;
  minutesStep?: MinutesStep;
  clearable?: boolean;
  setTimeRange: (timeRange: TimeRange | null) => void;
}

export const TimeRangeContainer = ({
  onClose,
  timeRange,
  setTimeRange,
  clearable,
  minutesStep = 15,
}: TimeRangeContainerProps) => {
  const theme = useTheme();
  const [section, setSetction] = useState<TimeSection>(TimeSection.START);

  const [startTime, setStartTime] = useState<Time>(convertTimeString2Number(timeRange?.start || "0:0"));
  const [endTime, setEndTime] = useState<Time>(convertTimeString2Number(timeRange?.end || "0:0"));

  const [hour, setHour] = useState(startTime.hour);
  const [minutes, setMinutes] = useState(startTime.minutes);

  const maxMinutes = Math.floor(59 / minutesStep) * minutesStep;

  const handleSetHour = (hour: number) => {
    setHour(hour);
    if (section === TimeSection.START) {
      setStartTime(time => ({ ...time, hour: hour }));
    } else {
      setEndTime(time => ({ ...time, hour: hour }));
    }
  };
  const handleSetMinutes = (minutes: number) => {
    setMinutes(minutes);
    if (section === TimeSection.START) {
      setStartTime(time => ({ ...time, minutes: minutes }));
    } else {
      setEndTime(time => ({ ...time, minutes: minutes }));
    }
  };

  const handleIncreaseHour = () => handleSetHour(hour >= 23 ? 0 : hour + 1);
  const handleDecreaseHour = () => handleSetHour(hour <= 0 ? 23 : hour - 1);

  const handleIncreaseMinutes = () => handleSetMinutes(minutes >= maxMinutes ? 0 : minutes + minutesStep);
  const handleDecreaseMinutes = () => handleSetMinutes(minutes < 15 && minutes != 0 ? 0 : minutes == 0 ? maxMinutes :  minutes - minutesStep);

  const handleSetStartTime = () => {
    setHour(startTime.hour);
    setMinutes(startTime.minutes);
    setSetction(TimeSection.START);
  };
  const handleSetEndTime = () => {
    setHour(endTime.hour);
    setMinutes(endTime.minutes);
    setSetction(TimeSection.END);
  };

  const handleSetTimeRange = () => {
    const start = getTimeString(startTime);
    const end = getTimeString(endTime);
    setTimeRange({ start, end });
    onClose();
  };

  const handleClearTimeRange = () => {
    setTimeRange(null);
    onClose();
  };

  return (
    <ColumnContainer
      rounded
      shadow={4}
      top="50%"
      left="50%"
      position="absolute"
      transform="translate3d(-50%, -50%, 0)"
      overflow="hidden"
      minWidth="300px"
      background={theme.colors.common.white}
      zIndex={theme.zIndex.modal}
    >
      <Flex width="100%">
        <TimePickerTab active={section === TimeSection.START} onClick={handleSetStartTime}>
          <span>Start</span>
          <span>{getTimeString(startTime)}</span>
        </TimePickerTab>
        <TimePickerTab active={section === TimeSection.END} onClick={handleSetEndTime}>
          <span>End</span>
          <span>{getTimeString(endTime)}</span>
        </TimePickerTab>
      </Flex>
      <Flex width="100%">
        <TimePicker time={hour} handleDecrease={handleDecreaseHour} handleIncrease={handleIncreaseHour} />
        <Typography size={1.5} fontWeight="bold">
          :
        </Typography>
        <TimePicker time={minutes} handleDecrease={handleDecreaseMinutes} handleIncrease={handleIncreaseMinutes} />
      </Flex>
      <Flex width="100%" justify="flex-end" margin="0.5rem 0 0">
        {clearable && (
          <Button variant="text" onClick={handleClearTimeRange} margin="0 auto 0 0">
            Clear
          </Button>
        )}
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSetTimeRange}>
          Set
        </Button>
      </Flex>
    </ColumnContainer>
  );
};
