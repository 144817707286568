import { useEffect, useMemo } from "react";

import { useFormikContext, getIn } from "formik";

import { RowContainerProps } from "@evr/ui/FlexBox";

import Typeahead, { AutoCompleteOption } from "./Typeahead";

interface FormikTypeaheadProps {
  label: string;
  items: AutoCompleteOption[];
  value?: AutoCompleteOption;
  openUpward?: boolean;
  onSearch: (searchItem: string) => void;
  heightOffset?: number;
  maxHeight?: number;
  fieldProps?: RowContainerProps;
  error?: boolean;
  disabled?: boolean;
  name: string;
  clear: () => void;
  handleChange?: (value: string | number) => void;
}

const FormikTypeahead = (props: FormikTypeaheadProps) => {
  const {
    setFieldValue,
    setFieldTouched,
    getFieldMeta,
    errors,
    handleChange: formikChange,
    handleBlur: formikBlur,
    validateField,
    values,
  } = useFormikContext();

  const { value: currentValue, touched: fieldTouched } = getFieldMeta(props.name);
  const fieldErrors = getIn(errors as any, props.name);

  const value = useMemo(() => {
    return props.items.find(option => {
      return option.value === currentValue;
    });
  }, [currentValue]);

  useEffect(() => {
    validateField(props.name);
  }, [currentValue]);

  const onChange = (value: string | number) => {
    formikChange({ target: { name: props.name, value } });
    setFieldValue(props.name, value);
    if (props.handleChange) {
      props.handleChange(value);
    }
  };

  const onBlur = () => {
    setFieldTouched(props.name, true);
  };

  const handleClear = () => {
    props.clear();
    setFieldValue(props.name, undefined);
    formikChange({ target: { name: props.name, value: undefined } });
  };

  return (
    <Typeahead
      maxHeight={200}
      onClear={() => handleClear()}
      value={value}
      error={fieldTouched && fieldErrors}
      errorText={fieldErrors}
      onBlur={onBlur}
      onChange={onChange}
      {...props}
    ></Typeahead>
  );
};

export default FormikTypeahead;
