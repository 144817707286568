import styled from "styled-components";

import { Flex } from "@evr/ui/FlexBox";

interface ImageDropAreaProps {
  WH: string;
}
export const ImageDropArea = styled(Flex)<ImageDropAreaProps>`
  height: ${props => (props.WH ? props.WH : "150px")};
  width: ${props => (props.WH ? props.WH : "150px")};
  min-width: ${props => (props.WH ? props.WH : "150px")};
  border-radius: 50%;
  margin: ${props => (props.margin ? props.margin : "0 auto")};
  overflow: hidden;
  background-color: ${props => props.theme.colors.divider};
`;

interface ImagePreviewWrapperProps {
  url: string;
}
export const ImagePreviewWrapper = styled.div<ImagePreviewWrapperProps>`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.url});
`;
export const ImagePreviewOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.common.white};
  opacity: 0;
  &:hover {
    opacity: 0.7;
  }
`;
