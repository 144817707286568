import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings } from "@evr/store/slices/auth";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Select } from "@evr/ui/Select";

export const SettingProofOfDeliverySelect = () => {
  const [editSettings] = useEditSettingsMutation();
  const orgSettings = useAppSelector(selectUserOrgSettings);

  const handleProofOfDeliveryRequirementChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    var proofOfDeliveryRequirement = e.target.value;
    var id = 0;
    if (proofOfDeliveryRequirement === "Signature") {
      id = 1;
    } else if (proofOfDeliveryRequirement === "Photo") {
      id = 2;
    } else if (proofOfDeliveryRequirement === "Signature & Photo") {
      id = 3;
    } else {
      id = 0;
    }

    editSettings({ ...orgSettings, proofOfDeliveryRequirement: id });
  };

  const getProofOfDeliveryRequirement = (id: number) => {
    if (id === 1) {
      return "Signature";
    } else if (id === 2) {
      return "Photo";
    } else if (id === 3) {
      return "Signature & Photo";
    } else {
      return "None";
    }
  };

  return (
    <>
      <RowContainer width="100%" justify="flex-start">
        Proof of Delivery Requirement
        <Wrapper flexGrow={1} />
        <Select
          value={getProofOfDeliveryRequirement(orgSettings.proofOfDeliveryRequirement)}
          items={[
            { key: 0, value: "None" },
            { key: 1, value: "Signature" },
            { key: 2, value: "Photo" },
            { key: 3, value: "Signature & Photo" },
          ]}
          onChange={handleProofOfDeliveryRequirementChange}
        />
      </RowContainer>
    </>
  );
};
