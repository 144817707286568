import { useState } from "react";

import { useAcceptJourneyMutation } from "@evr/apis/calculation";
import { DateTimePicker } from "@evr/components/DateTimePicker";
import { ConfirmDialog } from "@evr/components/Dialogs/ConfirmDialog";
import { Portal } from "@evr/components/Portal";
import { notificationsMsg, SnackbarKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { setJourneyStartDate } from "@evr/store/slices/result";
import { selectJourneysColorArray } from "@evr/store/slices/setting";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { apiErrors, Journey } from "@evr/types";
import { Button } from "@evr/ui/Button";
import { Collapsible } from "@evr/ui/Collapsible";
import { RowContainer } from "@evr/ui/FlexBox";

import { ResultCardBox } from "./ResultCardComponents";
import { ResultDetails } from "./ResultDetails";
import { ResultDownload } from "./ResultDownload";
import { ResultItems } from "./ResultItems";

interface PropsType {
  index: number;
  calculationId: number;
  journey: Journey;
  currencySymbol: string;
}

export const ResultCard = ({ calculationId, journey, index, currencySymbol }: PropsType) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [startDate, setStartDate] = useState(new Date(journey.startDate || new Date()));

  const [acceptJourney, { isLoading: isAccepting }] = useAcceptJourneyMutation();

  const maximize = useAppSelector(selectSidebarOpenStatus);
  const journeysColor = useAppSelector(selectJourneysColorArray);
  const driver = journey.driverVehiclePair.driver;
  const vehicle = journey.driverVehiclePair.vehicle;
  const minStartDate = new Date();
  minStartDate.setHours(minStartDate.getHours(), 0, 0, 0);

  const handleOpenDetails = () => setOpen(open => !open);
  const handleStartDateChange = (value: Date) => {
    setStartDate(value);
    dispatch(setJourneyStartDate({ journeyId: journey.id, startDate: value.toJSON() }));
  };
  const handleToggleConfirm = () => setShowConfirm(!showConfirm);

  const localTimezoneIsoString = (d: Date) => {
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const date = d.getDate();
    const hour = d.getHours();
    const minutes = d.getMinutes();
    const timezoneSegment = d.toTimeString().split(" ")[1].slice(-5);
    const timezone = timezoneSegment.slice(0, 3) + ":" + timezoneSegment.slice(3);

    const result =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      date.toString().padStart(2, "0") +
      "T" +
      hour.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":00" +
      timezone;

    return result;
  };

  const handleSchedule = async () => {
    setShowConfirm(false);
    try {
      await acceptJourney({
        calculationId: calculationId,
        journeyId: journey.id,
        startDate: localTimezoneIsoString(startDate),
      }).unwrap();
    } catch (error) {
      const apiErr = error as { data: apiErrors };
      if (apiErr && apiErr.data.errors) {
        dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: notificationsMsg.DEFAULT_ERROR }));
      }
    }
  };

  return (
    <RowContainer margin="0.5rem 0 1rem" align="flex-start">
      {maximize && (
        <ResultDownload
          index={index}
          color={journeysColor[index % journeysColor.length].main}
          journeyId={journey.id}
          legs={journey.locations.length + 1}
        />
      )}
      <ResultCardBox open={maximize}>
        <ResultItems
          onOpenDetails={handleOpenDetails}
          maximize={maximize}
          journey={journey}
          journeyColor={journeysColor[index % journeysColor.length]}
        />
        {maximize && (
          <Collapsible open={open}>
            <ResultDetails journey={journey} vehicle={vehicle} driver={driver} currencySymbol={currencySymbol} />
          </Collapsible>
        )}
        {maximize && journey.acceptedJourneyHref && (
          <span style={{ padding: "1rem 0.5rem", fontSize: "1rem" }}>Journey already Scheduled</span>
        )}
        {maximize && !journey.acceptedJourneyHref && (
          <RowContainer style={{ alignItems: "start", paddingTop: "1rem" }}>
            <DateTimePicker
              title={`Schedule Journey for ${journey.driverVehiclePair.driver.name}`}
              label=""
              value={startDate}
              onChange={handleStartDateChange}
              minDateTime={minStartDate}
            />
            <Button
              type="submit"
              disabled={isAccepting}
              onClick={handleToggleConfirm}
              style={{ margin: "0 0.5rem 1rem 0" }}
            >
              Schedule
            </Button>
            {showConfirm && (
              <Portal withOverlay>
                <ConfirmDialog
                  title={`Schedule Journey for ${journey.driverVehiclePair.driver.name}`}
                  message={`Scheduled to start on ${startDate.toLocaleString("en-UK", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                  onClose={handleToggleConfirm}
                  onConfirm={handleSchedule}
                />
              </Portal>
            )}
          </RowContainer>
        )}
      </ResultCardBox>
    </RowContainer>
  );
};
