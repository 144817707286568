import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import { useClickAwayListener } from "@evr/hooks/useClickAwayListener";
import { Divider } from "@evr/ui/Divider";
import { Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";

import { AcContainer, AcInput, AcItem, AcOption, AcInnerContainer, AcSelectedItem } from "./styles";

type OptionType = { label: string; value: string; disabled?: string; reverse?: boolean };
interface PropsType<T> {
  placeHolder?: string;
  searchable?: boolean;
  clearable?: boolean;
  options: T[];
  optionDefs?: OptionType;
  defaultValue?: T | null;
  noBorder?: boolean;
  emptyOption?: string;
  icon?: string;
  disabled?: boolean;
  onChange?: (value: T) => void;
}
export const AutoComplete = <T,>({
  placeHolder,
  searchable = true,
  clearable = true,
  options,
  optionDefs = { label: "label", value: "value", disabled: "none", reverse: false },
  defaultValue,
  noBorder,
  emptyOption = "No option",
  icon,
  disabled,
  onChange,
}: PropsType<T>) => {
  const [selected, setSelected] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null!);
  const refInput = useRef<HTMLInputElement>(null!);
  const closeList = useCallback(() => {
    setOpen(false);
    setSearch("");
  }, []);
  useClickAwayListener(ref, closeList);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  useEffect(() => {
    setOpen(false);
    setSearch("");
  }, [options.length]);

  useEffect(() => {
    setSelected(defaultValue ? ((defaultValue as Record<string, unknown>)[optionDefs.label] as string) : "");
  }, [defaultValue, optionDefs.label]);

  const handleSelected = (item: T | null) => {
    setSelected(item ? ((item as Record<string, unknown>)[optionDefs.label] as string) : "");
    setOpen(false);
    setSearch("");
    if (onChange) {
      onChange(item as T);
    }
  };
  const handleOpen = () => {
    if (!disabled) {
      setOpen(open => !open);
      if (!open) {
        refInput.current.focus();
      }
    }
  };
  const filteredData = options.filter((el: T) =>
    ((el as Record<string, unknown>)[optionDefs.label] as string).toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <AcContainer ref={ref}>
      <AcInnerContainer open={open} onClick={handleOpen} noBorder={noBorder} disabled={disabled}>
        {icon && <IconButton icon={icon} WH="10px" color="primary" margin="0 0.5rem 0 0" disabled={disabled} />}
        <Wrapper position="relative" flex="1 1 0">
          {!search && <AcSelectedItem title={selected}>{selected || placeHolder || "select..."}</AcSelectedItem>}
          <AcInput readOnly={!searchable} ref={refInput} value={search} onChange={handleChange} />
        </Wrapper>
        {selected && clearable && <IconButton size={1} icon="times" WH="10px" onClick={() => handleSelected(null)} />}
        <Divider as="span" direction="v" height="20px" />
        <IconButton icon="sort-down" WH="10px" margin="0 0 0.5rem 0" disabled={disabled} />
      </AcInnerContainer>
      {open && (
        <AcOption>
          {filteredData.length === 0 ? (
            <AcItem disabled empty>
              {emptyOption}
            </AcItem>
          ) : (
            filteredData.map((el: T) => {
              const isDisable = optionDefs.disabled
                ? optionDefs.reverse
                  ? (!(el as Record<string, unknown>)[optionDefs.disabled] as boolean)
                  : ((el as Record<string, unknown>)[optionDefs.disabled] as boolean)
                : false;
              return (
                <AcItem
                  key={(el as Record<string, unknown>)[optionDefs.value] as string | number}
                  disabled={isDisable}
                  onClick={() => (isDisable ? null : handleSelected(el as T))}
                >
                  {(el as Record<string, unknown>)[optionDefs.label] as string}
                </AcItem>
              );
            })
          )}
        </AcOption>
      )}
    </AcContainer>
  );
};
