import { useState } from "react";

import { Form, Formik } from "formik";

import { FormikSelect, FormikTextInput, FormikTimeRange } from "@evr/components/Form";
import {
  DialogTitle,
  JobTaskOptions,
  ParcelOptionalItems,
  PlanKeys,
  ProofOfDeliveryRequirementOptions,
  SnackbarKeys,
} from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserSubscription } from "@evr/store/slices/auth";
import { closeDialog, selectDialog } from "@evr/store/slices/dialog";
import { addJobLocation, editJobLocation, selectValidJobLocations } from "@evr/store/slices/parcel";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import {
  EditJobLocationForm,
  JobLocation,
  JobTask,
  JobType,
  Parcel,
  ProofOfDeliveryRequirementOption,
} from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { Select } from "@evr/ui/Select";
import { Typography } from "@evr/ui/Typography";
import { createParcelFromItems } from "@evr/utils";

import { JobLocationValidationSchema } from "./schema";

export const JobLocationDialog = () => {
  const dispatch = useAppDispatch();
  const [selectedJob, setSelectedJob] = useState(0);
  const { data } = useAppSelector(selectDialog);
  const { description, isEditForm } = data as { description: string; isEditForm: boolean };

  const { planType } = useAppSelector(selectUserSubscription);

  const isSilverPlusPlan = planType === PlanKeys.SILVER || planType === PlanKeys.GOLD;

  const jobLocations = useAppSelector(selectValidJobLocations);
  const jobLocation = description ? (jobLocations[description as string] as JobLocation) : undefined;

  const handleSelectedJob = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedJob(Number(e.target.value));
  };

  const handleSubmit = ({ weight, ...values }: EditJobLocationForm) => {
    const openingTime = values.openingClosingTime?.start;
    const closingTime = values.openingClosingTime?.end;
    const newValues = {
      ...values,
      openingTime: openingTime,
      closingTime: closingTime,
      weight: weight,
      latitude: "",
      longitude: "",
    };

    const job = createParcelFromItems(newValues as Record<ParcelOptionalItems, string>);
    isEditForm
      ? dispatch(
          editJobLocation({
            key: description as string,
            openingTime,
            closingTime,
            job,
            jobIndex: selectedJob,
          }),
        )
      : dispatch(
          addJobLocation({
            key: description as string,
            openingTime,
            closingTime,
            job,
          }),
        );
    dispatch(
      pushSnackbar({ type: SnackbarKeys.SUCCESS, title: `Job ${isEditForm ? "updated" : "added"} successfully.` }),
    );
    dispatch(closeDialog());
  };

  if (!jobLocation) {
    return null;
  }

  const jobItems = jobLocation.jobs.map(job => job) as Parcel[];
  const jobSelectItems = jobItems.map((task, index) => {
    const value = `Job ${index + 1}${task?.customerName ? `, Name: ${task?.customerName}` : ""}`;
    return { key: index, value };
  });

  const job = jobItems[selectedJob];
  const task = job.jobType === JobType.PICKUP ? JobTask.COLLECTION : JobTask.DELIVERY;
  const jobInitialValues: EditJobLocationForm = isEditForm
    ? {
        openingClosingTime:
          jobLocation.openingTime && jobLocation.closingTime
            ? {
                start: jobLocation.openingTime,
                end: jobLocation.closingTime,
              }
            : {
                start: "09:00",
                end: "17:00",
              },
        task,
        quantity: job.quantity === undefined ? "" : String(job.quantity),
        duration: job.duration === undefined ? "" : String(job.duration),
        weight: job.dimensions.weight === undefined ? "" : String(job.dimensions.weight),
        height: job.dimensions.height === undefined ? "" : String(job.dimensions.height),
        width: job.dimensions.width === undefined ? "" : String(job.dimensions.width),
        depth: job.dimensions.depth === undefined ? "" : String(job.dimensions.depth),
        trackingId: job.trackingId || "",
        name: job.customerName || "",
        proofOfDeliveryRequirement:
          job.proofOfDeliveryRequirement === undefined
            ? ProofOfDeliveryRequirementOption.DEFAULT
            : job.proofOfDeliveryRequirement,
      }
    : {
        openingClosingTime: {
          start: "09:00",
          end: "17:00",
        },
        task: JobTask.DELIVERY,
        quantity: "1",
        duration: "",
        weight: "1",
        trackingId: "",
        height: "",
        width: "",
        depth: "",
        name: "",
        proofOfDeliveryRequirement: ProofOfDeliveryRequirementOption.DEFAULT,
      };

  return (
    <>
      <DialogHeader title={isEditForm ? DialogTitle.EDIT_JOB_LOCATION : DialogTitle.ADD_JOB_LOCATION} />
      <DialogBody>
        <Formik
          enableReinitialize
          initialValues={jobInitialValues}
          validationSchema={JobLocationValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Flex wrap="wrap" align="baseline" justify="flex-start">
              <GridItem xs={12}>
                <Typography as="div" textAlign="left" margin="0 0 1rem 0">
                  {description ? `Address: ${description}` : "Error finding the Parcel."}
                  <br />
                  {isEditForm && <small>Note: Only one item will be updated at a time.</small>}
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTimeRange name="openingClosingTime" label="Opening time - Closing Time" />
              </GridItem>
              {isEditForm && (
                <GridItem xs={12} display="flex" wrap="wrap" justify="space-between">
                  <Select
                    label="Jobs"
                    value={selectedJob}
                    items={jobSelectItems}
                    disabled={jobSelectItems.length <= 1}
                    onChange={handleSelectedJob}
                    isChangedByKey
                  />
                </GridItem>
              )}
              <GridItem xs={12} sm={6}>
                <FormikSelect label="Task" name={ParcelOptionalItems.Task} items={JobTaskOptions} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Weight" endLabel="KG" name={ParcelOptionalItems.WEIGHT} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Quantity" name={ParcelOptionalItems.QUANTITY} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput
                  label="Duration"
                  endLabel="Mins"
                  name={ParcelOptionalItems.DURATION}
                  disabled={!isSilverPlusPlan}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Name" name={ParcelOptionalItems.NAME} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Height" endLabel="CM" name={ParcelOptionalItems.HEIGHT} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Width" endLabel="CM" name={ParcelOptionalItems.WIDTH} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Depth" endLabel="CM" name={ParcelOptionalItems.DEPTH} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikTextInput label="Tracking ID" name={ParcelOptionalItems.TRACKING_ID} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormikSelect
                  label="Proof of Delivery Requirement"
                  name={ParcelOptionalItems.PROOF_OF_DELIVERY_REQUIREMENT}
                  items={ProofOfDeliveryRequirementOptions}
                />
              </GridItem>
            </Flex>
            <DialogActionButtons buttonTitle={isEditForm ? "Edit" : "Add"} />
          </Form>
        </Formik>
      </DialogBody>
    </>
  );
};
