import Joyride, { CallBackProps, STATUS } from "react-joyride";

import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectSettingState, setTour } from "@evr/store/slices/setting";
import { theme } from "@evr/theme";
import { Flex, Wrapper } from "@evr/ui/FlexBox";
import { Icon } from "@evr/ui/Icon";
import { Typography } from "@evr/ui/Typography";

const getTitleComponent = (text: string, icon: string) => (
  <Flex align="flex-start" justify="space-between" padding="0 10px">
    <Typography as="h1" size={1.1} color="info" textAlign="left">
      {text}
    </Typography>
    {icon === "driver-vehicle" ? (
      <>
        <Icon className={"fas fa-user"} size={1.3} color="primary" />
        <Typography as="span" size={1.1} color="primary" margin="0 0.25rem">
          /
        </Typography>
        <Icon className={"fas fa-car-alt"} size={1.5} color="primary" />
      </>
    ) : (
      <Icon className={`fas fa-${icon}`} size={icon === "car-alt" ? 1.5 : 1.3} color="primary" />
    )}
  </Flex>
);

const steps = [
  {
    target: ".depotSelect-tour",
    title: getTitleComponent("Start Here", "map-marker-alt"),
    content:
      "Choose your depot location.Here you can choose you depot location, this is the start and end of the journey.",
    hideCloseButton: true,
    disableBeacon: true,
  },
  {
    target: ".driverVehicelItem-tour",
    title: getTitleComponent("It looks like you don’t have any drivers created.", "user"),
    content: "We need to create a driver first.",
    hideCloseButton: true,
  },
  {
    target: ".driverVehicelItem-tour",
    title: getTitleComponent("We now need to create a vehicle list.", "car-alt"),
    content: "This will have the information of the engine type, along with weight and storage limitations.",
    hideCloseButton: true,
  },
  {
    target: ".driverVehicelItem-tour",
    title: getTitleComponent("We need to match up your drivers and vehicles.", "driver-vehicle"),
    content:
      "These will then be used for the route analysis. You need to select both the driver and their corresponding vehicle.",
    hideCloseButton: true,
  },
  {
    target: ".parcelItem-tour",
    title: getTitleComponent("Select parcel drop off locations.", "cube"),
    content:
      "Upload your parcels from a CSV file.These are the locations that will be used to calculate the optimal route for each driver.Ensure the CSV file has the address of each package.",
    hideCloseButton: true,
  },
  {
    target: ".parcelItem-tour",
    title: getTitleComponent("We need to know a bit of information; can you help us match it.", "cube"),
    content: "Please select the columns from the CSV files.",
    hideCloseButton: true,
  },
  {
    target: ".calculation-tour",
    title: getTitleComponent("Calculate.", "calculator"),
    content:
      "EVR will calculate the optimum route for each vehicle and displays the results. For more information on each route per vehicle view the result panel.",
    hideCloseButton: true,
  },
];

export const InformationCard = () => {
  const dispatch = useAppDispatch();
  const { startTour } = useAppSelector(selectSettingState);

  const handleTour = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      dispatch(setTour(false));
    }
  };

  return (
    <Wrapper position="absolute" left="0">
      <Joyride
        continuous={true}
        steps={steps}
        run={startTour}
        callback={handleTour}
        disableOverlayClose
        showSkipButton
        locale={{ skip: "Close", last: "Done" }}
        styles={{
          tooltipContainer: {
            textAlign: "left",
          },
          options: {
            zIndex: theme.zIndex.modal,
            primaryColor: theme.colors.primary.main,
          },
        }}
      />
    </Wrapper>
  );
};
