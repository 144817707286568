import { useEffect } from "react";

import { IconArrowBack, IconLogin } from "@tabler/icons";
import { useTheme } from "styled-components";

import { ErrorPage } from "@evr/components/ErrorPage";
import { LocalStorageKeys } from "@evr/constant";
import { Anchor, CustomLink } from "@evr/ui/CustomLink";
import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";
import { localStorageHelper } from "@evr/utils";

export const SsoError = () => {
  const { colors } = useTheme();
  const ssoURL = localStorageHelper.getItem(LocalStorageKeys.SSO_URL);

  useEffect(() => {
    return () => {
      localStorageHelper.removeItem(LocalStorageKeys.SSO_URL);
    };
  });

  return (
    <ErrorPage>
      <Typography size={2} margin="1rem 0 2rem 0" color="greyDark">
        Sorry, something went wrong!
      </Typography>
      <Flex wrap="wrap">
        <Anchor
          href={ssoURL}
          fontSize="1.3rem"
          margin="0.5rem 1rem"
          color={colors.info.dark}
          fontWeight="bold"
          target="_self"
        >
          Return to SSO&nbsp;
          <IconArrowBack />
        </Anchor>
        <CustomLink to="/signin" fontSize="1.3rem" margin="0.5rem 1rem" fontWeight="bold" replace>
          Direct Login&nbsp;
          <IconLogin />
        </CustomLink>
      </Flex>
    </ErrorPage>
  );
};
