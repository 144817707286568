import { useState } from "react";

import { unparse } from "papaparse";

import { ParcelProcessedStatusKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  removeParcelsData,
  selectProcessedParcelsNumber,
  addAdditionalParcels,
  selectInvalidParcels,
  selectProcessedParcelStatus,
  selectTotalProcessedParcels,
} from "@evr/store/slices/parcel";
import { Button } from "@evr/ui/Button";
import { ColumnContainer, RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { ProgressBar } from "@evr/ui/ProgressBar";
import { Typography } from "@evr/ui/Typography";
import { downloadFile } from "@evr/utils";

import { ParcelProcessing } from "./ParcelProcessing";

export const ParcelResultView = () => {
  const dispatch = useAppDispatch();
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const invalidParcels = useAppSelector(selectInvalidParcels);
  const status = useAppSelector(selectProcessedParcelStatus);
  const totalValidParcels = useAppSelector(selectTotalProcessedParcels);

  const { numberOfParcel, numberOfProcessedParcel } = useAppSelector(selectProcessedParcelsNumber);
  const parcelInProcessing = status === ParcelProcessedStatusKeys.IN_PROGRESS;
  const parcelDoneProcessing = status === ParcelProcessedStatusKeys.DONE;

  const handleDownloadUnsuccessfulParcel = () => {
    const csv = unparse(invalidParcels);
    downloadFile(csv, "Invalid_Parcels_Addresses.csv");
  };

  const handleUploadNewParcels = () => dispatch(removeParcelsData());
  const handleAddAdditionalParcels = () => dispatch(addAdditionalParcels());
  const handleCancelParcelProcessing = () => {
    setCancelProcessing(true);
    dispatch(addAdditionalParcels());
  };

  return (
    <ColumnContainer width="100%" padding="1.5rem" align="flex-start">
      {!cancelProcessing && <ParcelProcessing />}
      {parcelInProcessing && (
        <Typography fontWeight="bolder" padding="0 0 0.8rem">
          We are just working out the parcel locations for you.
        </Typography>
      )}
      <Typography fontWeight="bolder">Total Parcels: {totalValidParcels + invalidParcels.length}</Typography>
      <Typography fontWeight="bolder" padding="0 0 0.5rem">
        Unsuccessful Parcels: {invalidParcels.length}
      </Typography>
      <ColumnContainer direction="column" align="flex-start" justify="space-between" width="100%">
        <RowContainer>
          {invalidParcels.length !== 0 && !parcelInProcessing && (
            <>
              <Typography color="infoLight" fontWeight="bold">
                Download Invalid Parcels Report Here
              </Typography>
              <IconButton icon="download" color="info" onClick={handleDownloadUnsuccessfulParcel} />
            </>
          )}
        </RowContainer>
        <RowContainer width="100%" justify="flex-start">
          <Button
            fontSize="1rem"
            color={parcelInProcessing ? "info" : "error"}
            margin={parcelInProcessing ? "0 0 0 auto" : "0"}
            variant={parcelInProcessing ? "contained" : "outlined"}
            onClick={parcelInProcessing ? handleCancelParcelProcessing : handleUploadNewParcels}
          >
            {parcelInProcessing ? "Cancel" : "Delete all parcels"}
          </Button>
          {parcelDoneProcessing && (
            <Button fontSize="1rem" color="info" onClick={handleAddAdditionalParcels}>
              Add additional parcels
            </Button>
          )}
        </RowContainer>
      </ColumnContainer>
      {parcelInProcessing && <ProgressBar max={numberOfParcel} value={numberOfProcessedParcel} />}
    </ColumnContainer>
  );
};
