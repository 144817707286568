import ResultDepotInfoOverlay from "./JourneyDepotInfo";
import UnrouteableOverlay from "./Unroutable";

const ResultOverlays = () => {
  return (
    <>
      <ResultDepotInfoOverlay />
      <UnrouteableOverlay />
    </>
  );
};

export default ResultOverlays;
