import { useContext, useEffect } from "react";

import { Feature } from "ol";
import { inAndOut } from "ol/easing";
import { Point } from "ol/geom";
import olMap from "ol/Map";
import { fromLonLat, toLonLat } from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectEditLocationCoordinates, selectParcelMapState } from "@evr/store/slices/parcel";
import { JobLocation, Location } from "@evr/types";
import { getJobLocationColor } from "@evr/utils";

import { resultLocationSvg } from "../../ResultsLayer/ResultLocation/resultLocationSvg";

interface LocationLayerProps {
  jobLocationsArray: [string, JobLocation | Location][];
  source: olVectorSource;
}

const LocationsLayer = ({ jobLocationsArray, source }: LocationLayerProps) => {
  if (!source.isEmpty()) {
    source.clear();
  }

  const { editLocation, itemToPoint, jobLocation } = useAppSelector(selectParcelMapState);
  const editLocationCoordinates = useAppSelector(selectEditLocationCoordinates);
  const map = useContext(MapContext) as olMap;

  useEffect(() => {
    const result = jobLocationsArray.find(([_, location], index) => index === itemToPoint.index);
    if (result) {
      const resultLocation = result[1];
      map.getView().animate({
        center: fromLonLat(resultLocation.point.coordinates),
        duration: 500,
        easing: inAndOut,
      });
    }
  }, [itemToPoint]);

  useEffect(() => {
    if (!editLocationCoordinates || !jobLocation) {
      return;
    }

    const jobs = (jobLocation as Location).addresses?.flatMap(a => a.jobs) ?? (jobLocation as JobLocation)?.jobs;
    const locationColor = getJobLocationColor(jobs, false);
    var svg2 = resultLocationSvg(itemToPoint.index + 1, locationColor);
    const feature = new Feature({
      geometry: new Point(editLocationCoordinates),
    });

    const style = new Style({
      image: new Icon({
        src: "data:image/svg+xml;utf8," + encodeURIComponent(svg2),
        scale: 0.22,
        anchor: [0.5, 1],
      }),
    });
    feature.setStyle(style);
    feature.setProperties({
      index: itemToPoint.index,
    });
    feature.setId("editLocation");

    source.addFeature(feature);
  }, [editLocationCoordinates]);

  const features = jobLocationsArray.map(([description, location], index) => {
    const jobs = (location as JobLocation)?.jobs ?? (location as Location)?.addresses?.flatMap(a => a.jobs);
    const locationColor = getJobLocationColor(jobs, editLocation && index === itemToPoint.index);

    var svg2 = resultLocationSvg(index + 1, locationColor);

    const feature = new Feature({
      geometry: new Point(fromLonLat(location.point.coordinates)),
    });

    const style = new Style({
      image: new Icon({
        src: "data:image/svg+xml;utf8," + encodeURIComponent(svg2),
        scale: 0.22,
        anchor: [0.5, 1],
      }),
    });
    feature.setStyle(style);
    feature.setProperties({
      index: index,
    });

    return feature;
  });

  source.addFeatures(features);

  return <Vector name={MapLayerNames.PARCEL_LOCATIONS} zIndex={1} source={source} />;
};

export default LocationsLayer;
