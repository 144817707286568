import { useContext, useEffect, useMemo, useState } from "react";

import { extend } from "ol/extent";
import { Geometry } from "ol/geom";
import olMap from "ol/Map";
import olVectorSource from "ol/source/Vector";

import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot } from "@evr/store/slices/depot";
import { selectValidJobLocations } from "@evr/store/slices/parcel";

import ParcelDepotLayer from "./DepotLayer";
import LocationsLayer from "./LocationsLayer";

interface ParcelLayerProps {
  fitViewToExtent: (extent: number[]) => void;
}

const ParcelLayer = ({ fitViewToExtent }: ParcelLayerProps) => {
  const jobLocations = useAppSelector(selectValidJobLocations);
  const jobLocationsArray = Object.entries(jobLocations);
  const map = useContext(MapContext) as olMap;

  const locationsSource = useMemo(() => new olVectorSource(), []);
  const depotSource = useMemo(() => new olVectorSource(), []);
  const currentDepot = useAppSelector(selectCurrentDepot);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => fitLayersToView([locationsSource]), 500);
  }, [map, locationsSource, jobLocationsArray.length]);

  useEffect(() => {
    if (currentDepot && firstLoad) {
      setFirstLoad(false);
      return;
    }
    fitLayersToView([depotSource, locationsSource]);
  }, [currentDepot]);

  const fitLayersToView = (sourcesToFit: olVectorSource<Geometry>[]) => {
    if (sourcesToFit.every(source => source.isEmpty())) {
      return;
    }

    const combinedExtent = sourcesToFit
      .map(source => source.getExtent())
      .reduce((acc, extent) => extend(acc ?? [], extent ?? []));

    fitViewToExtent(combinedExtent);
  };

  return (
    <>
      <LocationsLayer source={locationsSource} jobLocationsArray={jobLocationsArray} />
      <ParcelDepotLayer source={depotSource} />
    </>
  );
};

export default ParcelLayer;
