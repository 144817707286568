import { ColumnContainer, Flex } from "../FlexBox";
import { Typography } from "../Typography";
import { StyledCheckBox } from "./styles";

interface PropsType {
  id?: string;
  label?: string;
  error?: boolean;
  errorText?: string | null;
}

export const Checkbox = ({ id, label, error, errorText, ...rest }: PropsType) => {
  return (
    <ColumnContainer align="flex-start" padding="0 0.5rem">
      <Flex>
        <StyledCheckBox id={id} error={error} {...rest} />
        <label htmlFor={id}>{label}</label>
      </Flex>
      {error && (
        <Typography color="error" margin="0.5rem 0 0 0.5rem" size={0.7}>
          {errorText}
        </Typography>
      )}
    </ColumnContainer>
  );
};
