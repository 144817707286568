import { JobTask, ProofOfDeliveryRequirementOption } from "@evr/types";

export enum ParcelProcessedStatusKeys {
  INITIAL = "initial",
  SELECT_FILE = "selectFile",
  IN_PROGRESS = "inProgress",
  DONE = "done",
}

export enum ParcelRequiredItems {
  CITY = "city",
  POSTCODE = "postcode",
  STREET_NAME = "streetName",
  HOUSE_NUMBER = "houseNumber",
}

export enum ParcelOptionalItems {
  WEIGHT = "weight",
  QUANTITY = "quantity",
  Task = "task",
  DURATION = "duration",
  OPENING_TIME = "openingTime",
  CLOSING_TIME = "closingTime",
  HEIGHT = "height",
  WIDTH = "width",
  DEPTH = "depth",
  NAME = "name",
  TRACKING_ID = "trackingId",
  PROOF_OF_DELIVERY_REQUIREMENT = "proofOfDeliveryRequirement",
  LATTITUDE = "latitude",
  LONGITUDE = "longitude",
}



export const ParcelHeaderItems: Record<string, string> = {
  "Street Name": ParcelRequiredItems.STREET_NAME,
  "House Number": ParcelRequiredItems.HOUSE_NUMBER,
  City: ParcelRequiredItems.CITY,
  Postcode: ParcelRequiredItems.POSTCODE,
  Weight: ParcelOptionalItems.WEIGHT,
  Task: ParcelOptionalItems.Task,
  Quantity: ParcelOptionalItems.QUANTITY,
  Duration: ParcelOptionalItems.DURATION,
  "Opening Time": ParcelOptionalItems.OPENING_TIME,
  "Closing Time": ParcelOptionalItems.CLOSING_TIME,
  Height: ParcelOptionalItems.HEIGHT,
  Width: ParcelOptionalItems.WIDTH,
  Depth: ParcelOptionalItems.DEPTH,
  Name: ParcelOptionalItems.NAME,
  "Tracking ID": ParcelOptionalItems.TRACKING_ID,
  "Proof of Delivery Requirement": ParcelOptionalItems.PROOF_OF_DELIVERY_REQUIREMENT,
  Latitude: ParcelOptionalItems.LATTITUDE,
  Longitude: ParcelOptionalItems.LONGITUDE,
  "": "",
};

export const locationAddressItems = [
  ParcelRequiredItems.POSTCODE,
  ParcelRequiredItems.HOUSE_NUMBER,
  ParcelRequiredItems.STREET_NAME,
  ParcelRequiredItems.CITY,
];

export const JobTaskOptions = Object.entries(JobTask).map(([key, value]) => ({ key, value }));

export const ProofOfDeliveryRequirementOptions = Object.entries(ProofOfDeliveryRequirementOption).map(([key, value]) => ({ key, value }));