import { useEffect, useState } from "react";

import { IconChevronLeft, IconChevronRight } from "@tabler/icons";
import { useTheme } from "styled-components";

import { Button } from "@evr/ui/Button";
import { ColumnContainer, Flex } from "@evr/ui/FlexBox";

import styles from "./DatePicker.module.css";

interface DatePickerProps {
  value: Date | null;
  onChange: (value: Date) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}

interface TableData {
  date: Date;
  label: string;
  style: any;
  onClick: (date: Date) => void;
}

export const DatePicker = ({ value, onChange: setValue, minDate = null, maxDate = null }: DatePickerProps) => {
  const theme = useTheme();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthStyle = {
    backgroundColor: theme.colors.common.white,
    cursor: "pointer",
  };
  const otherMonthStyle = {
    backgroundColor: theme.colors.common.white,
    color: theme.colors.grey.default,
    cursor: "pointer",
  };
  const todayStyle = {
    borderRadius: 15,
    backgroundColor: theme.colors.grey.main,
    color: theme.colors.common.white,
    cursor: "pointer",
  };
  const selectedStyle = {
    borderRadius: 15,
    backgroundColor: theme.colors.primary.main,
    color: theme.colors.common.white,
    fontWeight: "bold",
    cursor: "pointer",
  };
  const disabledStyle = {
    backgroundColor: theme.colors.common.white,
    color: theme.colors.grey.light,
    cursor: "not-allowed",
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(value);
  const [viewMonth, setViewMonth] = useState((selectedDate || new Date()).getMonth());
  const [viewYear, setViewYear] = useState((selectedDate || new Date()).getFullYear());
  const [tableData, setTableData] = useState<TableData[][]>([]);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  useEffect(() => {
    const weeks = [];

    // Ajust the 1st of the month back to a Monday
    const date = new Date(viewYear, viewMonth, 1);
    const startDayOfWeek = getDayOfWeek(date);
    date.setDate(date.getDate() + startDayOfWeek * -1);

    do {
      const week = [];

      do {
        const isDisabled = (minDate && date < minDate) || (maxDate && date > maxDate);
        week.push({
          date: new Date(date),
          label: date.getDate().toString(),
          onClick: (date: Date) => {
            if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
              return;
            }
            setSelectedDate(date);
            setValue(date);
          },
          style:
            selectedDate && date.toDateString() === selectedDate.toDateString()
              ? selectedStyle
              : date.toDateString() === new Date().toDateString()
              ? todayStyle
              : isDisabled
              ? disabledStyle
              : date.getMonth() !== viewMonth
              ? otherMonthStyle
              : currentMonthStyle,
        });

        date.setDate(date.getDate() + 1);
      } while (getDayOfWeek(date) !== 0);

      weeks.push(week);
    } while (date.getMonth() == viewMonth);

    setTableData(weeks);
  }, [selectedDate, viewMonth, viewYear, minDate, maxDate]);

  const handlePrevMonth = () => {
    let month = viewMonth - 1;
    let year = viewYear;
    if (month < 0) {
      month = 11;
      year--;
    }
    setViewMonth(month);
    setViewYear(year);
  };

  const handleNextMonth = () => {
    let month = viewMonth + 1;
    let year = viewYear;
    if (month > 11) {
      month = 0;
      year++;
    }
    setViewMonth(month);
    setViewYear(year);
  };

  const getDayOfWeek = (date: Date) => {
    var dayOfWeek = date.getDay() - 1;
    if (dayOfWeek < 0) {
      dayOfWeek = 6;
    }
    return dayOfWeek;
  };

  return (
    <ColumnContainer>
      <Flex width="100%">
        <Button padding="0.5rem 1rem" variant="text" onClick={handlePrevMonth}>
          <IconChevronLeft size={25} />
        </Button>
        {monthNames[viewMonth]} {viewYear}
        <Button padding="0.5rem 1rem" variant="text" onClick={handleNextMonth}>
          <IconChevronRight size={25} />
        </Button>
      </Flex>
      <div className={styles.datePicker}>
        <table>
          <thead>
            <tr>
              <th>M</th>
              <th>T</th>
              <th>W</th>
              <th>T</th>
              <th>F</th>
              <th>S</th>
              <th>S</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((week, i) => (
              <tr key={i}>
                {week.map((day, j) => (
                  <td key={j} style={day.style} onClick={() => day.onClick(day.date)}>
                    {day.label}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ColumnContainer>
  );
};
