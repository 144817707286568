import { useContext, useEffect, useState } from "react";

import { Coordinate } from "ol/coordinate";
import Feature, { FeatureLike } from "ol/Feature";
import { Point } from "ol/geom";
import olMap from "ol/Map";
import { fromLonLat } from "ol/proj";

import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { ActiveLayerTypes, DepotStatusKeys, MapLayerNames } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot, selectDepotsStatus } from "@evr/store/slices/depot";
import { selectActiveLayer } from "@evr/store/slices/map";

import DepotCommandOverlay from "./Command/DepotCommandOverlay";
import DepotInfoOverlay from "./Info/DepotInfoOverlay";

const DepotOverlays = () => {
  const depotStatus = useAppSelector(selectDepotsStatus);
  const currentDepot = useAppSelector(selectCurrentDepot);
  const [popupCoordinate, setPopUpCoordinate] = useState<Coordinate>();
  const activeLayer = useAppSelector(selectActiveLayer);

  const onDepotLayerClick = (features: FeatureLike[]) => {
    if (features.length === 0) {
      setPopUpCoordinate(undefined);
      return;
    }

    const depotFeature = features[0] as Feature;
    const point = depotFeature.getGeometry() as Point;
    setPopUpCoordinate(point.getCoordinates());
  };

  const map = useContext(MapContext) as olMap;

  useEffect(() => {
    if (depotStatus === DepotStatusKeys.ADD && !currentDepot) {
      setPopUpCoordinate(map.getView().getCenter());
      return;
    }

    if (depotStatus === DepotStatusKeys.CLOSE && !currentDepot) {
      setPopUpCoordinate(undefined);
      return;
    }

    if ((depotStatus === DepotStatusKeys.CLOSE || depotStatus === DepotStatusKeys.EDIT) && currentDepot) {
      setPopUpCoordinate(undefined);
      return;
    }
  }, [currentDepot, depotStatus, map]);

  useEffect(() => {
    if (activeLayer !== ActiveLayerTypes.DEPOTS) {
      setPopUpCoordinate(undefined);
    }
  }, [activeLayer]);

  return (
    <>
      <DepotCommandOverlay
        depotStatusKey={depotStatus}
        coordinate={
          depotStatus === DepotStatusKeys.ADD || depotStatus === DepotStatusKeys.EDIT ? popupCoordinate : undefined
        }
      />
      <DepotInfoOverlay
        depot={currentDepot}
        coordinate={depotStatus === DepotStatusKeys.CLOSE && currentDepot ? popupCoordinate : undefined}
      />
      <MapClick layerNames={[MapLayerNames.DEPOT]} onClick={onDepotLayerClick} />
    </>
  );
};

export default DepotOverlays;
