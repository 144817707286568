import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";
import { ScheduledJourney } from "@evr/types";
import { Typography } from "@evr/ui/Typography";

import LiveJourneyResultCard from "./LiveJourneyResultCard";

interface AllLiveJourneysSidebarProps {
  journeys?: ScheduledJourney[];
}

const AllLiveJourneysSidebar = ({ journeys }: AllLiveJourneysSidebarProps) => {
  const open = useAppSelector(selectSidebarOpenStatus);
  return (
    <>
      {(!journeys || !journeys?.length) && open && <Typography>There are no current live journeys</Typography>}
      {journeys?.map((journey, index) => {
        if (journey.latestDriverProgress) {
          return <LiveJourneyResultCard index={index} journey={journey} key={index} />;
        }
      })}
    </>
  );
};

export default AllLiveJourneysSidebar;
