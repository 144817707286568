export enum MapLayerZIndices {
  ACTUAL_DRIVER_ROUTE = 1,
  ORIGINAL_CALCULATION = 100,
  RECALCULATION_ROUTES = 250,
  LOCATIONS = 500,
  LOCATION_TIMES = 600,
  RECALCULATION_LOCATIONS = 750,
  RECALCULATION_LOCATION_TIMES = 800,
  DEPOT = 1000,
  DRIVER_POSITION = 1500,
}
