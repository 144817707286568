import { AxiosError } from "axios";

import { AccountsEndpoint } from "@evr/apis/endpoints";
import { notificationsMsg } from "@evr/constant";
import { apiErrors } from "@evr/types";

type errorResponse = { msg: string; unAuthorized: boolean };

export const errorHandling = (error: AxiosError<apiErrors>, url?: string): errorResponse => {
  const response = { msg: "", unAuthorized: false };
  if (error.response) {
    if (error.response.status === 401) {
      response.msg =
        url === AccountsEndpoint.Login ? notificationsMsg.WRONG_CREDENTIALS : notificationsMsg.EXPIRE_SESSION;
      response.unAuthorized = url !== AccountsEndpoint.Session;
      return response;
    }
    const bodyMsg = typeof error.response.data === "string" ? error.response.data : "";
    response.msg = error.response.data.title || bodyMsg || notificationsMsg.DEFAULT_ERROR;
  } else {
    response.msg = error.message;
  }
  return response;
};

export const transformErrorFromApi = (err: Record<string, string[]>): Record<string, string> => {
  let transformErrors: Record<string, string> = {};
  Object.entries(err).forEach(([key, value]) => {
    const fieldName = key.charAt(0).toLowerCase() + key.slice(1);
    transformErrors[fieldName] = value[0];
  });
  return transformErrors;
};
