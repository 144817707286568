export interface ActionButtonsProps {
  children?: React.ReactNode;
}

/**
 * Component to wrap a collection of overlays components
 * Does not actually render anything, but instead allows the controls to be renders after the map container
 * @component
 * @param {React.ReactNode} children - the children components to render
 */
const ActionButtons = ({ children }: ActionButtonsProps) => {
  return <>{children}</>;
};

export default ActionButtons;
