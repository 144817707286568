import { IconX } from "@tabler/icons";

import { Flex } from "@evr/ui/FlexBox";
import { Typography } from "@evr/ui/Typography";

interface TableSearchChipProps {
  title: string;
  onCloseClick?: () => void;
}

export const TableSearchChip = ({ title, onCloseClick }: TableSearchChipProps) => {
  return (
    <Flex height="2rem" bgColor="greyDefault" padding="0 0.5rem" margin="0.1rem" borderRadius="1rem">
      <Typography title={title} size={0.8} padding="0 0.5rem" overflowEllipsis>
        {title}
      </Typography>
      <Flex borderRadius="50%" width="20px" height="20px" background="#625f5f" onClick={onCloseClick}>
        <IconX size={15} cursor="pointer" />
      </Flex>
    </Flex>
  );
};
