import { IconArrowBack } from "@tabler/icons";

import { ParcelProcessedStatusKeys, WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectProcessedParcelStatus, selectIsAppendingParcels, setParcelStatus } from "@evr/store/slices/parcel";
import { openWindow, selectWindowType } from "@evr/store/slices/window";
import { RowContainer } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { StyledIconButton } from "@evr/ui/IconButton/styles";

import { ParcelActionButtons } from "./ParcelActionButtons";
import { ParcelHeaderTitle } from "./ParcelHeaderTitle";
import { ParcelResultView } from "./ParcelResultView";
import { ParcelSelectFile } from "./ParcelSelectFile";
import { ParcelTable } from "./ParcelTable";
import { ParcelContainer } from "./styles";

export const Parcel = () => {
  const dispatch = useAppDispatch();

  const isHidden = useAppSelector(selectWindowType) !== WindowKeys.PARCEL;
  const status = useAppSelector(selectProcessedParcelStatus);
  const isAppendingParcels = useAppSelector(selectIsAppendingParcels);

  const showParcelResult =
    status === ParcelProcessedStatusKeys.IN_PROGRESS || status === ParcelProcessedStatusKeys.DONE;
  const showParcelSelectFile =
    status === ParcelProcessedStatusKeys.INITIAL || status === ParcelProcessedStatusKeys.SELECT_FILE;
  const showParcelTable = status === ParcelProcessedStatusKeys.SELECT_FILE;

  const handleCloseWindow = () => dispatch(openWindow(WindowKeys.CLOSE));
  const handleBackToResultView = () => dispatch(setParcelStatus(ParcelProcessedStatusKeys.DONE));

  return (
    <ParcelContainer hidden={isHidden}>
      <RowContainer position="absolute" right="0">
        {isAppendingParcels && showParcelSelectFile && (
          <StyledIconButton title="Back to result view" hoverColor="infoDark" onClick={handleBackToResultView}>
            <IconArrowBack />
          </StyledIconButton>
        )}
        <IconButton icon="times" title="Close" onClick={handleCloseWindow} />
      </RowContainer>
      {showParcelSelectFile && <ParcelSelectFile />}
      {showParcelTable && (
        <>
          <ParcelHeaderTitle />
          <ParcelTable />
          <ParcelActionButtons />
        </>
      )}
      {showParcelResult && <ParcelResultView />}
    </ParcelContainer>
  );
};
