import { useEffect } from "react";

import Feature from "ol/Feature";
import { LineString } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Stroke, Style } from "ol/style";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyShowRecalculationRoutes } from "@evr/store/slices/scheduled-journey-results";
import { JourneyColor, Recalculation } from "@evr/types";

interface RecalculationRouteLayerProps {
  recalculationRouteSource: olVectorSource;
  recalculation: Recalculation;
  recalculationIndex: number;
  journeyColor: JourneyColor;
}

const RecalculationRouteLayer = ({
  recalculationRouteSource,
  recalculation,
  recalculationIndex,
  journeyColor,
}: RecalculationRouteLayerProps) => {
  const showRecalc = useAppSelector(selectScheduledJourneyShowRecalculationRoutes);

  useEffect(() => {
    if (!showRecalc[recalculationIndex]) {
      recalculationRouteSource.clear();
    }
  }, [showRecalc]);

  if (showRecalc[recalculationIndex]) {
    const feature = new Feature({
      geometry: new LineString(recalculation.route.coordinates.map(coordinate => olProj.fromLonLat(coordinate))),
    });

    const style = new Style({
      stroke: new Stroke({
        color: journeyColor.main,
        width: 3,
      }),
    });

    feature.setStyle(style);
    recalculationRouteSource.addFeature(feature);
  }

  return (
    <Vector
      source={recalculationRouteSource}
      zIndex={MapLayerZIndices.RECALCULATION_ROUTES + recalculationIndex}
    ></Vector>
  );
};

export default RecalculationRouteLayer;
