import { BatteryBufferType } from "@evr/types";

export const config = {
  baseHubURL: process.env.REACT_APP_HUB_BASE_URL || "",
  basemapURL: process.env.REACT_APP_BASEMAP_URL,
  basemapSupportDeskURL: process.env.REACT_APP_BASEMAP_SUPPORT_DESK_URL,

  locationPerRequest: 10,
  batteryBuffer: {
    percent: {
      default: 20,
      min: 0,
      max: 50,
    },
    decimal: {
      default: 0.8,
      min: 1,
      max: 0.5,
    },
    distance: {
      default: 0
    },
    type: {
      default: BatteryBufferType.PERCENTAGE
    },   
  },
};
