import { memo, useContext, useEffect, useState } from "react";

import olLayer from "ol/layer/Layer";
import olVectorLayer from "ol/layer/Vector";
import olMap from "ol/Map";
import olVectorSource from "ol/source/Vector";

import MapContext from "../Map/MapContext";

export interface VectorProps {
  name?: string;
  source: olVectorSource;
  visible?: boolean;
  zIndex: number;
  declutter?: boolean;
}

export const Vector = ({ source, visible = true, zIndex, name, declutter }: VectorProps) => {
  const map = useContext(MapContext) as olMap;
  const [layer, setLayer] = useState<olLayer | null>(null);
  useEffect(() => {
    if (!map) {
      return () => {};
    }

    const layer = new olVectorLayer({
      source,
      zIndex: zIndex,
      declutter,
    });

    if (name) {
      layer.set("name", name);
    }

    layer.setVisible(visible);
    map.addLayer(layer);

    setLayer(layer);

    return () => {
      if (!map) {
        return;
      }
      map.removeLayer(layer);
    };
  }, [map, source]);

  useEffect(() => {
    if (!layer) {
      return;
    }
    layer.setVisible(visible);
  }, [visible]);

  useEffect(() => {
    if (!layer || !zIndex) {
      return;
    }
    layer.setZIndex(zIndex);
  }, [zIndex]);

  useEffect(() => {
    if (!layer || !name) {
      return;
    }
    layer.set("name", source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return null;
};
