import * as Yup from "yup";

import { config } from "@evr/config";
import { validationMsg, DriverKeys } from "@evr/constant";
import { isAfterTime } from "@evr/utils";

const timeRangeObject = (name: string) =>
  Yup.object({
    start: Yup.string(),
    end: Yup.string().test(`${name}_test`, validationMsg.endAfterStartTime, function (value) {
      const { start } = this.parent;
      return isAfterTime(start, value as string);
    }),
  });

export const driverValidationSchema = Yup.object({
  [DriverKeys.CREATE_SSO_ACCOUNT]: Yup.boolean(),
  [DriverKeys.FIRST_NAME]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
  [DriverKeys.LAST_NAME]: Yup.string().required(validationMsg.required).max(100, validationMsg.maxlength(100)),
  [DriverKeys.EMAIL]: Yup.string()
    .nullable()
    .email(validationMsg.email)
    .max(100, validationMsg.maxlength(100))
    .when(DriverKeys.CREATE_SSO_ACCOUNT, {
      is: true,
      then: Yup.string().required(validationMsg.required),
    }),
  [DriverKeys.WORKING_HOURS]: timeRangeObject(DriverKeys.WORKING_HOURS),
  [DriverKeys.LUNCH_BREAK]: timeRangeObject(DriverKeys.LUNCH_BREAK).nullable(),
  [DriverKeys.BATTERY_BUFFER]: Yup.number()
    .nullable()
    .min(config.batteryBuffer.percent.min, validationMsg.minNumber(config.batteryBuffer.percent.min))
    .max(config.batteryBuffer.percent.max, validationMsg.maxNumber(config.batteryBuffer.percent.max)),
});
