import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectParcelHeader } from "@evr/store/slices/parcel";
import { Divider } from "@evr/ui/Divider";
import { Typography } from "@evr/ui/Typography";

export const ParcelHeaderTitle = () => {
  const {
    present: { title },
  } = useAppSelector(selectParcelHeader);

  const getHeaderTitle = (item: string) => {
    switch (item) {
      case "Task":
        return "Task (collection or delivery)";
      case "Duration":
        return "Duration (minutes)";
      default:
        return item;
    }
  };

  return (
    <>
      <Divider direction="h" margin="0 0 0.5rem" />
      <Typography fontWeight={500} padding="0.25rem 0.5rem">
        {title ? (
          <>
            Select the column name that has the Parcel <Typography color="info">{getHeaderTitle(title)}</Typography>{" "}
            details
          </>
        ) : (
          "Please double check the fields have been correctly matched before proceeding"
        )}
      </Typography>
      <Divider direction="h" margin="1rem 0 0.5rem" />
    </>
  );
};
