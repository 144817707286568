import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Icon, Style } from "ol/style";

import markerGreen from "@evr/assets/markers/markerGreen.png";
import ResultDepotInfoOverlay from "@evr/components/OlMap/Overlays/Result/JourneyDepotInfo";
import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerNames, MapLayerZIndices } from "@evr/constant";
import { Depot } from "@evr/types";

interface ResultDepotLayerProps {
  depotSource: olVectorSource;
  depot: Depot;
  name: MapLayerNames;
}

const ResultsDepotLayer = ({ depotSource, depot, name }: ResultDepotLayerProps) => {
  if (!depotSource.isEmpty()) {
    depotSource.clear();
  }
  const convertedCenter = olProj.fromLonLat(depot.point.coordinates);

  const markerFeature = new Feature({
    geometry: new Point(convertedCenter),
  });

  const pinStyle = new Style({
    image: new Icon({
      src: markerGreen,
      scale: 0.5,
      anchor: [0.5, 1],
    }),
  });

  markerFeature.setStyle(pinStyle);
  depotSource.addFeature(markerFeature);

  return (
    <>
      <Vector name={name} source={depotSource} zIndex={MapLayerZIndices.DEPOT} />
    </>
  );
};

export default ResultsDepotLayer;
