import { useEffect, useState } from "react";

import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

import { config } from "@evr/config";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser } from "@evr/store/slices/auth";
import { addNewCalculation } from "@evr/store/slices/calculation";
import { Calculation, UpdateJourneyProgressRequest } from "@evr/types";

import { CacheKeys } from "./apis/cacheKeys";
import { calculationApi } from "./apis/calculation";
import { ActiveLayerTypes, JourneyEvent } from "./constant";
import { useApi } from "./hooks/useApi";
import { selectAllLiveJourneys, updateDriverLocation } from "./store/slices/all-live-journeys-result";
import { getAllLiveJourneyAsync } from "./store/slices/all-live-journeys-result/asyncActions";
import { selectActiveLayer } from "./store/slices/map";
import { updateScheduledJourneyLocation } from "./store/slices/scheduled-journey-results";
import { getScheduledJourneyAsync } from "./store/slices/scheduled-journey-results/asyncActions";

export const SignalRConnection = () => {
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const user = useAppSelector(selectCurrentUser);
  const scheduledJourneyApi = useApi(getScheduledJourneyAsync);
  const allLiveJourneysApi = useApi(getAllLiveJourneyAsync);
  const allLiveJourneys = useAppSelector(selectAllLiveJourneys);
  const activeLayer = useAppSelector(selectActiveLayer);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(config.baseHubURL, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (!connection) {
      return;
    }

    connection
      .start()
      .then(() => {
        connection.send("SendUserInfo", user?.email);
      })
      .catch(e => console.error("SignalR Connection failed: ", e));
            
    connection.off("CalculationResultReady");
    connection.on("CalculationResultReady", (calculation: Calculation) => {
      dispatch(calculationApi.util.invalidateTags([CacheKeys.CALCULATIONS]));
      dispatch(addNewCalculation(calculation));
    });

    connection.onreconnected(() => {
      connection.send("SendUserInfo", user?.email);
    });

    return () => {
      connection.stop().catch(console.error);
    };
  }, [connection, dispatch, user?.email, activeLayer]);

  useEffect(() => {
    if (!connection) {
      return;
    }
    connection.off("NotifyJourneyEvent");
    connection.on("NotifyJourneyEvent", async (journeyId: number, email: string, journeyEvent: JourneyEvent) => {
      if (activeLayer !== ActiveLayerTypes.ALL_JOURNEYS && activeLayer !== ActiveLayerTypes.SCHEDULED_JOURNEY) {
        return;
      }

      await scheduledJourneyApi.sendRequest(journeyId);
      await allLiveJourneysApi.sendRequest();
    });
  }, [connection, dispatch, activeLayer]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    connection.off("UpdateJourneyProgress");
    connection.on("UpdateJourneyProgress", async (request: UpdateJourneyProgressRequest) => {
      if (activeLayer !== ActiveLayerTypes.ALL_JOURNEYS && activeLayer !== ActiveLayerTypes.SCHEDULED_JOURNEY) {
        return;
      }

      if (request.remainingBatteryCapacity == null && allLiveJourneys?.resources.some(x => x.id === request.journeyId)) {
        dispatch(updateDriverLocation(request));
        dispatch(updateScheduledJourneyLocation(request));
      } else {
        await scheduledJourneyApi.sendRequest(request.journeyId);
        await allLiveJourneysApi.sendRequest();
      }
    });
  }, [allLiveJourneys, connection, dispatch, activeLayer]);

  return null;
};
