import { PayloadAction } from "@reduxjs/toolkit";

import { ChargeDictionary } from "@evr/components/DriverVehicleMatch/DVMatchSelectVehicle/chargeDictionary";
import { DriverVehicleMatchIds, DriverVehicleMatchState, DVMatchVehicleSizeAction } from "@evr/types";

export const DVMatchReducer = {
  addDVMatch: (dvm: DriverVehicleMatchState, action: PayloadAction<DriverVehicleMatchIds>) => {
    const { driverId, vehicleId, initialStateOfCharge } = action.payload;
    const found = dvm.list.find(dv => dv.driverId === driverId);
    if (!found) {
      dvm.list.push({ driverId, vehicleId, initialStateOfCharge });
    } else {
      dvm.list = dvm.list.map(dv => {
        if (dv.driverId === driverId) {
          return { driverId, vehicleId, initialStateOfCharge };
        }
        return dv;
      });
    }
    dvm.activeVehicleSize = null;
  },
  removeDVMatch: (dvm: DriverVehicleMatchState, action: PayloadAction<number>) => {
    dvm.list = dvm.list.filter(dv => dv.driverId !== action.payload);
    if (dvm.activeDriverId === action.payload) {
      dvm.activeVehicleSize = null;
      dvm.activeDriverId = null;
    }
  },
  setDVMatchActiveDriverId: (dvm: DriverVehicleMatchState, action: PayloadAction<number | null>) => {
    dvm.activeDriverId = action.payload;
  },

  setDVMatchVehicleIdAndCharge: (dvm: DriverVehicleMatchState, action: PayloadAction<ChargeDictionary>) => {
    for (var dv of dvm.list)
    { 
      dv.initialStateOfCharge = (action.payload[dv.vehicleId] / 100) ?? 1.0;
    }
  },

  setDVMatchActiveVehicleSize: (dvm: DriverVehicleMatchState, action: PayloadAction<DVMatchVehicleSizeAction>) => {
    const { size, driverId } = action.payload;
    if (dvm.activeDriverId === driverId) {
      dvm.activeVehicleSize = size === dvm.activeVehicleSize ? null : size;
    } else {
      dvm.activeVehicleSize = size;
    }
    dvm.activeDriverId = driverId;
  },
};
