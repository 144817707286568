import styled from "styled-components";

interface PropsType {
  error?: boolean;
}

export const StyledCheckBox = styled.input.attrs({ type: "checkbox" })<PropsType>`
  position: relative;
  appearance: none;
  width: 1.3em;
  height: 1.3em;
  margin-right: 0.5rem;
  background: ${props => props.theme.colors.common.white};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.colors.action.border};
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  ${props =>
    props.checked &&
    `
    &::after {
        display: block;
        top: 0.1em;
        left: 0.35em;
        width: 35%;
        height: 70%;
        border: solid ${props.theme.colors.common.white};
        border-width: 0 0.2em 0.2em 0;
        transform:rotate(45deg);
    }
  `}
  ${props =>
    !props.disabled &&
    props.checked &&
    `
    background: ${props.theme.colors.primary.main};
    border-color: ${props.theme.colors.primary.main};
  `}
  ${props =>
    props.disabled &&
    props.checked &&
    `
    background: ${props.theme.colors.grey.main}};
    border-color: ${props.theme.colors.grey.main};
  `}
  &::disabled {
    cursor: not-allowed;
  }
  & + label {
    ${props => props.disabled && `color: ${props.theme.colors.action.disabled};`}
    ${props => props.error && `color: ${props.theme.colors.error.main};`}
  }
`;
