import styled from "styled-components";

export const SignUpContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    padding: 1rem 0;
    justify-content: flex-start;
  }
`;
