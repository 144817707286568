import { ActiveLayerTypes } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { setActiveLayer } from "@evr/store/slices/map";
import { backParcelItem, nextParcelItem, preProcessParcels, selectParcelActions } from "@evr/store/slices/parcel";
import { clearScheduledJourneyResult } from "@evr/store/slices/scheduled-journey-results";
import { Button } from "@evr/ui/Button";
import { Flex, RowContainer } from "@evr/ui/FlexBox";

export const ParcelActionButtons = () => {
  const dispatch = useAppDispatch();
  const { future, past, enableNextBt } = useAppSelector(selectParcelActions);

  const enableSubmit = future.length === 0;
  const backDisable = past.length === 0;
  const enableSkip = past.length >= 4;

  const handleSubmit = () => {
    dispatch(clearScheduledJourneyResult());
    dispatch(setActiveLayer(ActiveLayerTypes.PARCEL));
    dispatch(preProcessParcels());
  };

  const handleNext = () => dispatch(nextParcelItem({ skip: false }));
  const handleSkip = () => dispatch(nextParcelItem({ skip: true }));
  const handleBack = () => dispatch(backParcelItem());

  return (
    <Flex width="100%" padding="0.5rem" shadow={8}>
      <RowContainer width="100%" position="relative" justify="space-between" height="35px">
        <Flex height="100%">
          <Button
            color="primary"
            disabled={!enableSubmit && !enableNextBt}
            onClick={enableSubmit ? handleSubmit : handleNext}
          >
            {enableSubmit ? "Submit" : "Next"}
          </Button>
          <Button variant="text" disabled={backDisable} onClick={handleBack}>
            Back
          </Button>
        </Flex>
        {enableSkip && !enableSubmit && (
          <Button color="info" onClick={handleSkip}>
            Skip
          </Button>
        )}
      </RowContainer>
    </Flex>
  );
};
