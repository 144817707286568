import { useDepotsQuery } from "@evr/apis/depot";
import { ActiveLayerTypes, DepotKeys, DepotStatusKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentDepot, selectDepotsStatus, setCurrentDepot, setDepotStatus } from "@evr/store/slices/depot";
import { selectActiveLayer, setActiveLayer } from "@evr/store/slices/map";
import { Depot } from "@evr/types";
import { AutoComplete } from "@evr/ui/AutoComplete";
import { Button } from "@evr/ui/Button";
import { NavItem } from "@evr/ui/NavItem";
import { maxPageSizeQuery } from "@evr/utils";

const depotDefs = { label: DepotKeys.NAME, value: DepotKeys.ID };

export const DepotSelect = () => {
  const dispatch = useAppDispatch();
  const { data } = useDepotsQuery(maxPageSizeQuery);
  const depotStatus = useAppSelector(selectDepotsStatus);
  const currentDepot = useAppSelector(selectCurrentDepot);
  const disabled = depotStatus !== DepotStatusKeys.CLOSE;
  const activeLayer = useAppSelector(selectActiveLayer);

  const handleAddDepot = () => {
    if (activeLayer !== ActiveLayerTypes.PARCEL) {
      dispatch(setActiveLayer(ActiveLayerTypes.DEPOTS));
    }
    dispatch(setDepotStatus(DepotStatusKeys.ADD));
  };

  const handleDepotChange = (depot: Depot) => {
    if (activeLayer !== ActiveLayerTypes.PARCEL) {
      dispatch(setActiveLayer(ActiveLayerTypes.DEPOTS));
    }
    dispatch(setCurrentDepot(depot));
  };

  return (
    <NavItem className="depotSelect-tour">
      <AutoComplete
        optionDefs={depotDefs}
        options={data?.resources || []}
        defaultValue={currentDepot}
        clearable={false}
        onChange={handleDepotChange}
        placeHolder="Select Depot ..."
        emptyOption="No Depot."
        icon="map-marker-alt"
        disabled={disabled}
        noBorder
      />
      <Button margin="0" fullHeight borderRadius="0 4px 4px 0" disabled={disabled} onClick={handleAddDepot}>
        <i className={"fas fa-plus"} />
      </Button>
    </NavItem>
  );
};
