import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { SidebarState, SidebarTab } from "@evr/types";

import { setResult } from "../result";

const initialState: SidebarState = { open: false, tab: SidebarTab.PARCEL };

export const SidebarSlice = createSlice({
  name: SliceKeys.SIDEBAR,
  initialState,
  reducers: {
    toggleSidebar: sidebar => {
      sidebar.open = !sidebar.open;
    },
    setSidebarTab: (sidebar, action: PayloadAction<SidebarTab>) => {
      sidebar.tab = action.payload;
    },
    openSidebar: siderbar => {
      siderbar.open = true;
    },
    closeSidebar: siderbar => {
      siderbar.open = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(setResult, sidebar => {
      sidebar.tab = SidebarTab.RESULT;
    });
  },
});

export const { toggleSidebar, setSidebarTab, openSidebar, closeSidebar } = SidebarSlice.actions;
export const selectSidebarState = (state: RootState) => state.sidebar;
export const selectSidebarOpenStatus = createSelector(
  (state: RootState) => state.sidebar.open,
  open => open,
);
export const selectSidebarTab = createSelector(
  (state: RootState) => state.sidebar.tab,
  tab => tab,
);
export const selectShowMapLayer = createSelector(
  (state: RootState) => state.sidebar.tab,
  (state: RootState) => state.result.isReady,
  (state: RootState) => state.parcel.processedState.jobLocations.valids,
  (state: RootState) => state.scheduledJourneyResult,
  (sidebarTab, resultIsReady, validJobLocaaion, scheduledJourney) => {
    const hiddenResultLayer = Object.keys(validJobLocaaion).length > 0 && sidebarTab === SidebarTab.PARCEL;
    const hiddenJoblocationLayer = resultIsReady && sidebarTab === SidebarTab.RESULT;

    const showResultLayer = resultIsReady && !hiddenResultLayer && !scheduledJourney.selectedJourney;
    const showJobLocationLayer = Object.keys(validJobLocaaion).length > 0 && !hiddenJoblocationLayer;
    const showScheduledJourneyLayer = scheduledJourney !== null;
    return { showResultLayer, showJobLocationLayer, showScheduledJourneyLayer };
  },
);

export default SidebarSlice.reducer;
