import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";

import { Dialogs, LocalStorageKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { logInUser } from "@evr/store/slices/auth";
import { clearCurrentResult, setResult } from "@evr/store/slices/result";
import { setTour } from "@evr/store/slices/setting";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { LoginResponse, ResultResponse } from "@evr/types";
import { localStorageHelper } from "@evr/utils";

import { setCurrentDepot } from "../slices/depot";
import { openDialog } from "../slices/dialog";
import { addParcelCSV } from "../slices/parcel";

export const miscellaneous: Middleware =
  (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) => next => (action: PayloadAction) => {
    switch (action.type) {
      case setResult.type:
        const calc = action.payload as unknown as ResultResponse;
        if (calc.results.journeys.length === 0) {
          return store.dispatch(
            pushSnackbar({ type: SnackbarKeys.ERROR, title: notificationsMsg.CALCULATION_IS_EMPTY }),
          );
        }
        return next(action);
      case logInUser.type:
        const { user } = action.payload as unknown as LoginResponse;

        if (user.isFirstLogin) {
          localStorageHelper.storeItem(LocalStorageKeys.IS_FIRST_LOGIN, true);
          localStorageHelper.storeItem(LocalStorageKeys.IS_FIRST_VERIZON_IMPORT, true);
          store.dispatch(setTour(true));

          // This will be shown on a first login as we don't have access to the
          // current user or org settings yet. The dialog will be closed before
          // showing if it's the case that the user is not with verizon
          // or an import has already happened
          store.dispatch(openDialog({type: Dialogs.VERIZON_NEW_USER}));
        }
        return next(action);
      case setCurrentDepot.type:
      case addParcelCSV.type:
        if (store.getState().result.isReady) {
          store.dispatch(clearCurrentResult());
        }
        return next(action);
      default:
        return next(action);
    }
  };
