import { StepLineContainer, StepLineDivider, StepLineItemContainer, StepLineNumber, StepLineText } from "./styles";
interface PropsType {
  step: number;
}
export const StepLine = ({ step }: PropsType) => {
  return (
    <StepLineContainer>
      <StepLineDivider />
      <StepLineItemContainer>
        <StepLineNumber active={step === 0}>1</StepLineNumber>
        <StepLineText>Personal Details</StepLineText>
      </StepLineItemContainer>
      <StepLineItemContainer>
        <StepLineNumber active={step === 1}>2</StepLineNumber>
        <StepLineText>Company Details</StepLineText>
      </StepLineItemContainer>
      <StepLineItemContainer>
        <StepLineNumber active={step === 2}>3</StepLineNumber>
        <StepLineText>{`Accept T&C's`}</StepLineText>
      </StepLineItemContainer>
    </StepLineContainer>
  );
};
