import { useEffect, useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { ToggleSwitch } from "@evr/ui/ToggleSwitch";
import { Typography } from "@evr/ui/Typography";

export const ToggleEvPrioritisation = () => {
  const { planType } = useAppSelector(selectUserSubscription);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [prioritiseEVs, setPrioritiseEVs] = useState(orgSettings.prioritiseEVs);

  useEffect(() => {
    setPrioritiseEVs(orgSettings.prioritiseEVs);
  }, [orgSettings]);

  const isFreePlan = planType === PlanKeys.STARTER;

  const [editPrioritiseEVs, { isLoading }] = useEditSettingsMutation();

  const onChange = (status: boolean) => {
    editPrioritiseEVs({ ...orgSettings, prioritiseEVs: status });
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Prioritise Electric Vehicles</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto" title={isFreePlan ? "This item is not active on your plan" : ""}>
        <ToggleSwitch
          onChange={onChange}
          loading={isLoading}
          disabled={isFreePlan || orgSettings.spreadWork}
          value={prioritiseEVs}
          checked={prioritiseEVs}
        ></ToggleSwitch>
        <Wrapper position="relative">{isLoading && <CircleLoading size={4} />}</Wrapper>
      </Flex>
    </RowContainer>
  );
};
