import styled from "styled-components";

import { colorMixinFunc, ColorsType, contrastColorMixinFunc } from "@evr/theme/colors";

export interface WrapperProps {
  position?: "static" | "relative" | "absolute" | "fixed" | "sticky" | "inherit";
  display?: string;

  flex?: string;
  flexGrow?: number;
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  align?: "initial" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch" | "space-between" | "space-around";
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  alignSelf?: "flex-start" | "flex-end" | "center";
  padding?: string;
  margin?: string;
  marginBottom?:string;

  width?: string;
  maxWidth?: string;
  minWidth?: string;

  height?: string;
  maxHeight?: string;
  minHeight?: string;
  background?: string;
  overflow?: "visible" | "hidden" | "scroll" | "auto" | "inherit";
  border?: string;

  bgColor?: ColorsType;
  opacity?: number;
  shadow?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  rounded?: boolean;
  borderRadius?: string;
  transition?: string;
  transform?: string;

  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  inset?: string;

  open?: boolean;
  zIndex?: number;

  textAlign?: "center" | "end" | "start";
  color?: ColorsType | string;
  bgImage?: string;
  bgPosition?: "bottom" | "center" | "top" | "initial" | "left" | "right";
  bgSize?: "cover" | "contain" | "initial" | "auto";
  hidden?: boolean;
  gradient?: boolean;
  cursor?: string;
  gap?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${props => props.position && `position: ${props.position}`};
  ${props => props.display && `display: ${props.display}`};

  ${props => props.flex && `flex: ${props.flex}`};
  ${props => props.wrap && `flex-wrap: ${props.wrap}`};
  ${props => props.direction && `flex-direction: ${props.direction}`};
  align-items: ${props => props.align || "center"};
  justify-content: ${props => props.justify || "center"};
  ${props => props.alignSelf && `align-self: ${props.alignSelf}`};

  ${props => props.padding && `padding: ${props.padding}`};
  ${props => props.margin && `margin: ${props.margin}`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};

  ${props => props.background && `background: ${props.background}`};

  ${props => props.width && `width: ${props.width}`};
  ${props => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${props => props.minWidth && `min-width: ${props.minWidth}`};

  ${props => props.height && `height: ${props.height}`};
  ${props => props.maxHeight && `max-height: ${props.maxHeight}`};
  ${props => props.minHeight && `min-height: ${props.minHeight}`};

  ${props => props.overflow && `overflow: ${props.overflow}`};
  ${props => props.border && `border: ${props.border}`};

  ${props => props.flexGrow && `flex-grow: ${props.flexGrow}`};
  ${props => props.opacity && `opacity: ${props.opacity}`};
  ${props => props.shadow && `box-shadow: ${props.theme.shadows[props.shadow]}`};
  ${props => props.rounded && `border-radius: ${props.theme.borderRadius}`};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${props => props.transition && `transition: ${props.transition}`};
  ${props => props.transform && `transform: ${props.transform}`};

  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.inset && `inset: ${props.inset}`};

  ${props => props.zIndex && `z-index: ${props.zIndex}`};

  ${props => props.textAlign && `text-align: ${props.textAlign}`};
  ${props => props.color && `color: ${colorMixinFunc(props.color)}`};
  ${props =>
    props.bgColor &&
    `background-color: ${colorMixinFunc(props.bgColor)}; color:${contrastColorMixinFunc(props.bgColor)};`}
  ${props =>
    props.gradient && `background: ${props.theme.colors.gradient.main}; color:${props.theme.colors.common.white};`}

  ${props => props.bgImage && `background-image: url("${props.bgImage}")`};
  ${props => props.bgPosition && `background-position: ${props.bgPosition}`};
  ${props => props.bgSize && `background-size: ${props.bgSize}`};
  ${props => props.cursor && `cursor: ${props.cursor}`};
`;

export type FlexProps = Omit<WrapperProps, "display">;

export const Flex = styled(Wrapper)<FlexProps>`
  display: ${props => (props.hidden ? "none" : "flex")};
`;

export type RowContainerProps = Omit<FlexProps, "direction">;

export const RowContainer = styled(Flex)`
  display: ${props => (props.hidden ? "none" : "flex")};
  flex-direction: row;
`;

export type ColumnContainerProps = Omit<FlexProps, "direction">;

export const ColumnContainer = styled(Flex)`
  display: ${props => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  gap: ${props => props.gap || "0"};
`;

interface GridProps extends WrapperProps {
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | number;
}
export const GridItem = styled(Wrapper)<GridProps>`
  flex-grow: 0;
  max-width: ${props => (props.xs ? `${100 / (12 / props.xs)}%` : "100%")};
  flex-basis: ${props => (props.xs ? `${100 / (12 / props.xs)}%` : "100%")};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    max-width: ${props => (props.sm ? `${100 / (12 / props.sm)}%` : "100%")};
    flex-basis: ${props => (props.sm ? `${100 / (12 / props.sm)}%` : "100%")};
  }
  ${props =>
    props.lg &&
    `
  @media (min-width: ${props.theme.breakpoints.md}) {
    max-width: ${props.lg ? `${100 / (12 / props.lg)}%` : "100%"};
    flex-basis: ${props.lg ? `${100 / (12 / props.lg)}%` : "100%"};
  }
  `}
`;
