import { Link } from "react-router-dom";
import styled from "styled-components";

import { Typography } from "@evr/ui/Typography";

const Circle = styled.div`
  min-height: 225px;
  width: 225px;
  border-radius: 50%;
  background: linear-gradient(145deg, #2bb3d5, #7de355);
  margin: 1rem;
  color: ${props => props.theme.colors.common.white};
  padding: 1rem 0 0;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    min-height: 200px;
    width: 200px;
  }
`;
const CustomLink = styled(Link)`
  background-color: #0b4599;
  color: ${props => props.theme.colors.common.white};
  text-decoration: none;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  font-size: 1.1rem;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
`;
const CustomP = styled.p`
  margin-bottom: 2rem;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 1.5rem;
  }
`;
export const CircleCreateAccount = () => {
  return (
    <Circle>
      <Typography as="h2" margin="1.3rem 0">
        New User?
      </Typography>
      <CustomP>Sign up for an EVR account</CustomP>
      <CustomLink to="/signup">Create account</CustomLink>
    </Circle>
  );
};
