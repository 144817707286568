import { TableNameKeys } from "@evr/constant";
import { ColorsType } from "@evr/theme/colors";
import { SelectDataType } from "@evr/ui/Select";

export enum SearchFieldKeys {
  TEXT = "text",
  SELECT = "select",
}

export enum SearchParameterKeys {
  CONTAIN = "cn",
  EQUAL = "eq",
}

export enum SortKeys {
  ASC = "ASC",
  DESC = "DESC",
  NONE = "NONE",
}

export type SearchType = { type: SearchFieldKeys.TEXT } | { type: SearchFieldKeys.SELECT; options: SelectDataType[] };

export type TableType = TableNameKeys;

export interface TableHeaderType {
  headerName: string;
  field: string;
  color?: ColorsType;
  clickable?: boolean;
}

export interface SearchColumn {
  column: string;
  value: string;
  title: string;
  parameter?: SearchParameterKeys;
}

export type SearchObject = Record<string, SearchColumn>;

export interface SortColumn {
  column: string;
  type: SortKeys;
}

export interface TableColumn<T = unknown> {
  name: string;
  field: T;
  actionField?: string;
  sortable?: boolean;
  search?: SearchType;
}

export interface TablePagination {
  pageSize: number;
  totalItems: number;
  totalPages: number;
  pageIndex: number;
  sort: SortColumn;
  search: SearchObject;
}
