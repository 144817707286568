import styled from "styled-components";

import { ColumnContainer } from "@evr/ui/FlexBox";

export const TableWindowContainer = styled(ColumnContainer)`
  justify-content: flex-start;
  margin: 8rem 1rem 1rem;
  width: 1000px;
  min-width: 500px;
  min-height: 400px;
  max-height: 70vh;
  background-color: #fff;
  overflow: hidden;
  resize: both;
  box-shadow: ${props => props.theme.shadows[6]};
  border-radius: ${props => props.theme.borderRadius};
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-width: 95%;
    resize: none;
  }
`;
