import { useVehiclesQuery } from "@evr/apis/vehicle";
import { TableNameKeys, VehicleKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { addDVMatch, selectDVMatchState } from "@evr/store/slices/dvMatch";
import { Vehicle } from "@evr/types";
import { StyledTable, TD, TH, TR, TableVehicleImage, TableWrapper } from "@evr/ui/Table";
import { Typography } from "@evr/ui/Typography";
import { maxPageSizeQuery } from "@evr/utils";

import { chargeDictionary } from "../DVMatchSelectVehicle/chargeDictionary";
import { vehicleMatchTableColumns } from "./vehicleMatchTableColumns";

interface PropsType {
  driverId: number;
}

export const VehicleMatchTable = ({ driverId }: PropsType) => {
  const dispatch = useAppDispatch();

  const { data: vehicles } = useVehiclesQuery(maxPageSizeQuery);
  const { list: dvmList, activeVehicleSize } = useAppSelector(selectDVMatchState);

  const dvmVehicleId = dvmList.map(dv => dv.vehicleId);
  const handleOnClickRow = (vehicle: Vehicle) => {
    const vehicleId = vehicle.id;
    let initialStateOfCharge = chargeDictionary[vehicleId] ?? 100;
    dispatch(addDVMatch({ driverId, vehicleId, initialStateOfCharge }));
  };
  const vehiclesList = vehicles?.resources.filter(
    v => v.vehicleSize === activeVehicleSize && !dvmList.find(dv => dv.vehicleId === v.id),
  );

  return (
    <TableWrapper>
      <StyledTable tableType={TableNameKeys.DRIVER_VEHICLE_MATCH}>
        <thead>
          <tr>
            {vehicleMatchTableColumns.map(col => (
              <TH key={col.field} textAlign="center" tableType={TableNameKeys.DRIVER_VEHICLE_MATCH}>
                {col.name}
              </TH>
            ))}
          </tr>
        </thead>
        <tbody>
          {vehiclesList &&
            vehiclesList.map(vehicle => (
              <TR key={vehicle.id} clickable onClick={() => handleOnClickRow(vehicle)}>
                {vehicleMatchTableColumns.map(col => {
                  if (col.field === VehicleKeys.ICON) {
                    return <TableVehicleImage key={col.field} size={vehicle.vehicleSize} type={vehicle.engineType} />;
                  }
                  return (
                    <TD key={col.field} textAlign="center">
                      {(vehicle as unknown as Record<string, string>)[col.field] || "_"}
                    </TD>
                  );
                })}
              </TR>
            ))}
        </tbody>
      </StyledTable>
      {(!vehiclesList || vehiclesList.length === 0) && (
        <Typography as="div" margin="0.75rem 0">
          Vehicle not available
        </Typography>
      )}
    </TableWrapper>
  );
};
