import { useEffect, useState } from "react";

import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectSnackbar } from "@evr/store/slices/snackbar";
import { IconButton } from "@evr/ui/IconButton";

import { SnackBox } from "./styles";

export const Snanckbar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const snack = useAppSelector(selectSnackbar);

  useEffect(() => {
    if (!snack.title) {
      return setOpen(false);
    }
    setOpen(true);
    const timer = setTimeout(() => {
      setOpen(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [snack]);

  const handleCloseSnackbar = () => setOpen(false);

  return (
    <SnackBox open={open} type={snack.type}>
      <div>{snack.title}</div>
      <IconButton icon="times" onClick={handleCloseSnackbar} tabIndex={-1} />
    </SnackBox>
  );
};
