import React from "react";

import olVectorSource from "ol/source/Vector";

import { MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import {
  selectScheduledJourneyColor,
  selectScheduledJourneyShowRecalculationLocations,
  selectScheduledJourneyShowRecalculationRoutes,
} from "@evr/store/slices/scheduled-journey-results";
import { selectJourneysColorArray } from "@evr/store/slices/setting";
import { Recalculation, ScheduledJourney } from "@evr/types";

import ScheduledJourneyLocationsLayer from "../ScheduledJourneyLocationsLayer";
import ScheduledJourneyLocationTimesLayer from "../ScheduledJourneyLocationTimesLayer";

interface RecalculationLocationsProps {
  recalculationLocationSources: olVectorSource[];
  recalculationLocationTimesSources: olVectorSource[];
  scheduledJourney: ScheduledJourney;
  layerNames: string[];
}

const RecalculationLocations = ({
  recalculationLocationSources,
  recalculationLocationTimesSources,
  scheduledJourney,
  layerNames,
}: RecalculationLocationsProps) => {
  const showRoutes = useAppSelector(selectScheduledJourneyShowRecalculationRoutes);
  const scheduledJourneysColor = useAppSelector(selectScheduledJourneyColor);
  const allJourneysColors = useAppSelector(selectJourneysColorArray);
  const recalcColors = allJourneysColors.filter(
    (x: any) => x.main !== scheduledJourneysColor.main || x.contrast !== scheduledJourneysColor.contrast,
  );
  const recalculations = scheduledJourney.recalculations as Recalculation[];

  const showLocations = useAppSelector(selectScheduledJourneyShowRecalculationLocations);

  const calculateRecalcStartTime = () => {
    const allVisitedLocations = scheduledJourney.locations.filter(
      l => l.addresses.flatMap(x => x.jobs).filter(x => x.status === "Completed").length !== 0,
    );
    const passedTimeOfLegs = allVisitedLocations
      .flatMap(l => l.addresses.flatMap(a => a.jobs).flatMap(j => (j.duration ? Math.round(j.duration as number) : 0)))
      .reduce((partialSum, a) => partialSum + a, 0);
    const legTimes = allVisitedLocations.reduce(
      (accumulator, location) => accumulator + Math.round((location.legTime as number) / 60),
      0,
    );
    const totalTimePassed = passedTimeOfLegs + legTimes;
    const startTime = new Date(scheduledJourney.startTime ?? scheduledJourney.driver.workingHours.start);
    startTime.setMinutes(startTime.getMinutes() + totalTimePassed);
    return startTime;
  };

  return (
    <>
      {recalculations.map((recalculation, index) => (
        <React.Fragment key={index}>
          <ScheduledJourneyLocationsLayer
            locations={recalculation.locations}
            source={recalculationLocationSources[index]}
            startDate={calculateRecalcStartTime()}
            journeyColor={recalcColors[index % recalcColors.length]}
            zIndex={MapLayerZIndices.RECALCULATION_LOCATIONS}
            isVisible={showLocations[index]}
            layerName={layerNames[index]}
          />
          <ScheduledJourneyLocationTimesLayer
            source={recalculationLocationTimesSources[index]}
            locations={recalculation.locations}
            startDate={calculateRecalcStartTime()}
            zIndex={MapLayerZIndices.RECALCULATION_LOCATION_TIMES + index}
            isVisible={showLocations[index]}
            layerName={layerNames[index]}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default RecalculationLocations;
