import { ScheduledJourneyStatus, WindowKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import {
  selectScheduledJourneyPagination,
  setScheduledJourneyPagination,
  setScheduledJourneyStatus,
} from "@evr/store/slices/scheduled-journeys";
import { closeWindow, openWindow, selectWindowType } from "@evr/store/slices/window";
import { Flex } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

interface HeaderItemProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const HeaderItem = ({ title, selected, onClick }: HeaderItemProps) => (
  <Typography size={1.15} fontWeight={selected ? 700 : "lighter"} onClick={onClick} cursorPointer margin="0 1rem 0 0">
    {title}
  </Typography>
);

export const ScheduledJourneysWindowHeader = () => {
  const dispatch = useAppDispatch();
  const windowType = useAppSelector(selectWindowType);
  const pagination = useAppSelector(selectScheduledJourneyPagination);

  const handleOpenScheduledWindow = () => {
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.SCHEDULED));
    dispatch(openWindow(WindowKeys.JOURNEY_SCHEDULED));
  };

  const handleOpenCompletedWindow = () => {
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.COMPLETED));
    dispatch(openWindow(WindowKeys.JOURNEY_COMPLETED));
  };

  const handleOpenInProgressWindow = () => {
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.INPROGRESS));
    dispatch(openWindow(WindowKeys.JOURNEY_INPROGRESS));
  };

  const handleOpenFailedWindow = () => {
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.CANCELLED));
    dispatch(openWindow(WindowKeys.JOURNEY_FAILED));
  };

  const handleCloseWindow = () => {
    dispatch(closeWindow());
    dispatch(setScheduledJourneyStatus(ScheduledJourneyStatus.SCHEDULED));
  };

  return (
    <Flex padding="0.5rem 1rem" width="100%" gradient justify="space-between">
      <Flex width="100%" justify="flex-start">
        <HeaderItem
          title="Scheduled"
          selected={windowType === WindowKeys.JOURNEY_SCHEDULED}
          onClick={handleOpenScheduledWindow}
        />
        <HeaderItem
          title="In Progress"
          selected={windowType === WindowKeys.JOURNEY_INPROGRESS}
          onClick={handleOpenInProgressWindow}
        />
        <HeaderItem
          title="Completed"
          selected={windowType === WindowKeys.JOURNEY_COMPLETED}
          onClick={handleOpenCompletedWindow}
        />
        <HeaderItem
          title="Failed"
          selected={windowType === WindowKeys.JOURNEY_FAILED}
          onClick={handleOpenFailedWindow}
        />
      </Flex>
      <IconButton icon="times" title="Close" color="white" size={1.5} onClick={handleCloseWindow} />
    </Flex>
  );
};
