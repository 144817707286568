import styled from "styled-components";
interface PropsType {
  width?: string;
  height?: string;
  borderRadius?: string;
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  cursorPointer?: boolean;
}
export const Img = styled.img.attrs(props => ({
  alt: props.alt ? props.alt : "",
}))<PropsType>`
  ${props => props.position && `position: ${props.position}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  ${props => props.left && `left: ${props.left}`};
  ${props => props.cursorPointer && `cursor: pointer`};
  ${props => props.borderRadius && `border-radius: ${props.borderRadius}`};

  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "100%")};
`;
