import { useEffect, useMemo, useState } from "react";

import { Form, Formik } from "formik";

import { FormikCheckbox } from "@evr/components/Form";
import { Dialogs, DialogTitle } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, openDialog } from "@evr/store/slices/dialog";
import {
  cancelImport,
  selectDrivers,
  setDriverIndex,
  setDriversSelected,
  setImportPosition,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { VerizonDriverImport } from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { StyledTable, TD, TH, TR } from "@evr/ui/Table";

type SortField = "email" | "name";
type SortOrder = "asc" | "desc";

export const VerizonDriverSelectDialog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const reduxDrivers = useAppSelector(selectDrivers);
  const [localDrivers, setLocalDrivers] = useState<VerizonDriverImport[]>(reduxDrivers);
  const [sortField, setSortField] = useState<SortField>("email");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [hideImported, setHideImported] = useState<boolean>(true);
  useEffect(() => {
    setLocalDrivers(reduxDrivers);
  }, [reduxDrivers]);

  const handleSubmit = (values: any) => {
    var updatedDrivers = localDrivers.map(driver => ({ email: driver.driver.email, selected: driver.selected }));
    dispatch(setDriversSelected(updatedDrivers));

    if (localDrivers.filter(driver => driver.selected && !driver.exists).length === 0) {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_SELECT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_VEHICLE_SELECT }));
      }, 200);
    } else {
      dispatch(setDriverIndex(0));
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.DRIVER_IMPORT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_DRIVER_CREATE }));
      }, 500);
    }
  };

  const handleCheckboxChange = (email: string) => {
    setLocalDrivers(prevDrivers =>
      prevDrivers.map(driver => (driver.driver.email === email ? { ...driver, selected: !driver.selected } : driver)),
    );
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const sortedAndFilteredDrivers = useMemo(() => {
    return [...localDrivers]
      .sort((a, b) => {
        let comparison = 0;
        switch (sortField) {
          case "email":
            comparison = a.driver.email.localeCompare(b.driver.email);
            break;
          case "name":
            const aName = `${a.driver.firstName} ${a.driver.lastName}`;
            const bName = `${b.driver.firstName} ${b.driver.lastName}`;
            comparison = aName.localeCompare(bName);
            break;
        }
        return sortOrder === "asc" ? comparison : -comparison;
      })
      .filter(x => !hideImported || !x.exists);
  }, [localDrivers, sortField, sortOrder, hideImported]);

  const handleCancel = () => {
    dispatch(cancelImport());
    dispatch(closeDialog());
  };

  const handleHideImported = (value: any) => {
    setHideImported(!hideImported);
  };

  const toggleSelectAll = () => {
    // check if all the items are already selected
    const allSelected = localDrivers.every(driver => driver.selected || driver.exists);
    setLocalDrivers(prevDrivers =>
      prevDrivers.map(driver => (driver.exists ? driver : { ...driver, selected: !allSelected })),
    );
  };

  return (
    <>
      <DialogHeader title={DialogTitle.VERIZON_DRIVER_SELECT} showClose={false} />
      <DialogBody>
        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Wrapper position="relative" style={{ marginTop: 0 }}>
                <RowContainer width="100%" padding="1rem 0 0 0" justify="flex-start">
                  <FormikCheckbox
                    checked={hideImported}
                    name="hidechk"
                    label="Hide already imported drivers"
                    onChange={handleHideImported}
                  />
                </RowContainer>
              </Wrapper>
              <br />
              <StyledTable stickyHeader={false}>
                <thead>
                  <TR>
                    <TH stickyHeader={false} onClick={toggleSelectAll} style={{ cursor: "pointer" }}>
                      Selected
                    </TH>
                    <TH stickyHeader={false} onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                      Email {sortField === "email" && (sortOrder === "asc" ? "▲" : "▼")}
                    </TH>
                    <TH stickyHeader={false} onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
                      Name {sortField === "name" && (sortOrder === "asc" ? "▲" : "▼")}
                    </TH>
                  </TR>
                </thead>
                <tbody>
                  {sortedAndFilteredDrivers.map(driver => (
                    <TR key={driver.driver.email}>
                      <TD>
                        <FormikCheckbox
                          name="driver"
                          value={driver.driver.email}
                          checked={driver.selected || driver.exists}
                          onChange={() => {
                            if (!driver.exists) {
                              handleCheckboxChange(driver.driver.email);
                            }
                          }}
                          label=""
                          disabled={driver.exists}
                          title={driver.exists ? "Driver already exists" : undefined}
                        />
                      </TD>
                      <TD>{driver.driver.email}</TD>
                      <TD>
                        {driver.driver.firstName} {driver.driver.lastName}
                      </TD>
                    </TR>
                  ))}
                  {sortedAndFilteredDrivers.length === 0 && (
                    <TR>
                      <TD colSpan={3}>
                        <b>No drivers to import</b>
                      </TD>
                    </TR>
                  )}
                </tbody>
              </StyledTable>
              <DialogActionButtons
                loading={loading}
                buttonTitle={"Continue"}
                variant="contained"
                onCancelAction={handleCancel}
              />
            </Form>
          )}
        </Formik>
      </DialogBody>
    </>
  );
};
