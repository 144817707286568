import { ApiMethodKeys, CommonKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { closeDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { DateRangeResourceListResult, ScheduledJourney } from "@evr/types";

import { evrApi } from "..";
import { CacheKeys } from "../cacheKeys";
import { ScheduledJourneysEndpoint } from "../endpoints";

export const scheduledJourneysApi = evrApi
  .enhanceEndpoints({ addTagTypes: [CacheKeys.SCHEDULED_JOURNEYS] })
  .injectEndpoints({
    endpoints: builder => ({
      scheduledJourneys: builder.query<DateRangeResourceListResult<ScheduledJourney>, string>({
        query: query => ({ url: `${ScheduledJourneysEndpoint}?${query}` }),
        providesTags: [CacheKeys.SCHEDULED_JOURNEYS],
      }),
      cancelScheduledJourney: builder.mutation<void, number>({
        query: id => ({ url: `${ScheduledJourneysEndpoint}/cancel/${id}`, method: ApiMethodKeys.PATCH }),
        invalidatesTags: [CacheKeys.SCHEDULED_JOURNEYS],
        onQueryStarted(_, { dispatch, queryFulfilled }) {
          queryFulfilled.then(() => {
            dispatch(
              pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.DELETE_ITEM(CommonKeys.JOURNEY) }),
            );
            dispatch(closeDialog());
          });
        },
      }),
    }),
  });

export const { useScheduledJourneysQuery, useCancelScheduledJourneyMutation } = scheduledJourneysApi;
