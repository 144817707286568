import { useEffect, useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { SnackbarKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings } from "@evr/store/slices/auth";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { Button } from "@evr/ui/Button";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Input } from "@evr/ui/TextInput/styles";

export const SettingFuelPrices = () => {
  const { petrolCostOverride, dieselCostOverride, electricityCostOverride } = useAppSelector(selectUserOrgSettings);
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [editOrgSettings, { isLoading }] = useEditSettingsMutation();
  const [petrolLoading, setPetrolLoading] = useState(false);
  const [dieselLoading, setDieselLoading] = useState(false);
  const [electricityLoading, setElectricityLoading] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isLoading) {
      setPetrolLoading(false);
      setDieselLoading(false);
      setElectricityLoading(false);
    }
  }, [isLoading]);

  const handlePetrolPriceSave = (price: number | undefined) => {
    if (price != undefined && price < 0) {
      dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: "Petrol price cannot be below 0" }));
      return;
    }
    setPetrolLoading(true);
    editOrgSettings({ ...orgSettings, petrolCostOverride: price });
  };

  const handleDieselPriceSave = (price: number | undefined) => {
    if (price != undefined && price < 0) {
      dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: "Diesel price cannot be below 0" }));
      return;
    }
    setDieselLoading(true);
    editOrgSettings({ ...orgSettings, dieselCostOverride: price });
  };

  const handleElectricityPriceSave = (price: number | undefined) => {
    if (price != undefined && price < 0) {
      dispatch(pushSnackbar({ type: SnackbarKeys.ERROR, title: "Electricity price cannot be below 0" }));
      return;
    }
    setElectricityLoading(true);
    editOrgSettings({ ...orgSettings, electricityCostOverride: price });
  };

  return (
    <>
      <SettingFuelPrice
        prompt="Petrol Price (£) (L)"
        defaultFuelPrice={petrolCostOverride}
        onSave={handlePetrolPriceSave}
        isLoading={petrolLoading}
      />
      <SettingFuelPrice
        prompt="Diesel Price (£) (L)"
        defaultFuelPrice={dieselCostOverride}
        onSave={handleDieselPriceSave}
        isLoading={dieselLoading}
      />
      <SettingFuelPrice
        prompt="Electricity Price (£) (kWh)"
        defaultFuelPrice={electricityCostOverride}
        onSave={handleElectricityPriceSave}
        isLoading={electricityLoading}
      />
    </>
  );
};

interface SettingFuelPriceProps {
  defaultFuelPrice: number | undefined;
  onSave: (fuelPrice: number | undefined) => void;
  prompt: string;
  isLoading: boolean;
}
const SettingFuelPrice = ({ defaultFuelPrice, isLoading, onSave, prompt }: SettingFuelPriceProps) => {
  const [fuelPrice, setFuelPrice] = useState<number | undefined>(
    defaultFuelPrice !== undefined ? defaultFuelPrice : NaN,
  );

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const setValue = e.target.valueAsNumber;
    if (isNaN(setValue)) {
      setFuelPrice(NaN);
    } else {
      setFuelPrice(e.target.valueAsNumber);
    }
  };

  return (
    <>
      <RowContainer width="100%" justify="flex-start">
        {prompt}
        <Wrapper flexGrow={1} />
        <Input
          type="number"
          width="80px"
          padding="0.35rem 0.4rem"
          value={fuelPrice}
          min="0"
          onChange={handlePriceChange}
        />
        <Wrapper position="relative">
          {isLoading && <CircleLoading size={4} />}
          <Button
            disabled={fuelPrice === defaultFuelPrice || (Number.isNaN(fuelPrice) && defaultFuelPrice == undefined)}
            variant="contained"
            onClick={() => {
              onSave(fuelPrice);
            }}
          >
            Set
          </Button>
        </Wrapper>
        <Wrapper position="relative">
          {isLoading && <CircleLoading size={4} />}
          <Button
            disabled={Number.isNaN(fuelPrice)}
            variant="contained"
            onClick={() => {
              onSave(undefined);
              setFuelPrice(NaN);
            }}
          >
            Clear
          </Button>
        </Wrapper>
      </RowContainer>
    </>
  );
};
