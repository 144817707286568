import { useCallback, useEffect, useRef, useState } from "react";

import { useClickAwayListener } from "@evr/hooks/useClickAwayListener";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";
import { StyledIconButtonProps } from "@evr/ui/IconButton/styles";
import { MenuContainer, MenuItem } from "@evr/ui/Menu";

interface DownloadResultMenuProps extends StyledIconButtonProps {
  loading?: boolean;
  handleDownloadCSV?: () => void;
  handleDownloadGPX?: () => void;
  handleOpenInSygicNav?: (legIndex: number) => void;
  legs?: number;
  handleDownloadBoth?: () => void;
  onClose?: () => void;
  menuPosition?: { right?: string; left?: string; top?: string; bottom?: string };
}

export const DownloadResultMenu = ({
  loading,
  handleDownloadCSV,
  handleDownloadGPX,
  handleOpenInSygicNav,
  legs,
  handleDownloadBoth,
  onClose,
  menuPosition,
  ...IconButtonProps
}: DownloadResultMenuProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [doneFetching, setDoneFetching] = useState(false);

  const downloadMenuContainerRef = useRef<HTMLDivElement>(null!);
  const closeMenu = useCallback(() => setOpenMenu(false), []);
  useClickAwayListener(downloadMenuContainerRef, closeMenu);

  useEffect(() => {
    if (!loading && doneFetching) {
      closeMenu();
    }
    if (loading) {
      return setDoneFetching(true);
    }
  }, [loading, doneFetching, closeMenu]);

  const handleToggleOpenMenu = () => setOpenMenu(state => !state);

  const handleOnClickCsv = () => handleDownloadCSV && handleDownloadCSV();
  const handleOnClickGpx = () => handleDownloadGPX && handleDownloadGPX();
  const handleOnClickOpenInSygicNav = (legIndex: number) => handleOpenInSygicNav && handleOpenInSygicNav(legIndex);
  const handleOnClickBoth = () => handleDownloadBoth && handleDownloadBoth();

  const sygicLinks =
    legs && handleOpenInSygicNav
      ? [...Array(legs)].map((x, i) => (
          <MenuItem key={i} onClick={() => handleOnClickOpenInSygicNav(i)}>
            Nagivate Leg {i} in Sygic Nav
          </MenuItem>
        ))
      : null;
  return (
    <Wrapper position="relative" ref={downloadMenuContainerRef}>
      <IconButton icon="cloud-download-alt" WH="25px" onClick={handleToggleOpenMenu} {...IconButtonProps} />
      {openMenu && (
        <MenuContainer top="115%" {...menuPosition} onBlur={closeMenu}>
          {loading && <CircleLoading size={4} blurBackground />}
          {handleDownloadCSV && <MenuItem onClick={handleOnClickCsv}>Download CSV</MenuItem>}
          {handleDownloadGPX && <MenuItem onClick={handleOnClickGpx}>Download GPX</MenuItem>}
          {sygicLinks}
          {handleDownloadBoth && <MenuItem onClick={handleOnClickBoth}>Download Both</MenuItem>}
        </MenuContainer>
      )}
    </Wrapper>
  );
};
