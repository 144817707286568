export const notificationsMsg = {
  DEFAULT_ERROR: "Something went wrong. Please try again.",
  EXPIRE_SESSION: "Your Session is expired, please login again.",
  WRONG_CREDENTIALS: "Your credentials are incorrect.",

  LOGIN: "You logged in successfully, Wait to load Data please.",
  SIGNUP: "Your account created successfully. login Please.",
  FORGOT_PASSWORD: "The reset password link has been sent to your email. please check your email.",
  RESET_PASSWORD: "Your Password has been changed successfully.",
  RESET_PASSWORD_NO_TOKEN: "You didn't provide correct data. Please check your email for correct link address.",

  UPLOAD_PARCEL: "Your file has been uploaded successfully",
  CANCEL_PARCEL_PROCESSING: "Parcels processing canceled successfully",

  INVALID_DEPOT_LOCATION: "Your selected point for Depot is not valid! Change it please.",
  CALCULATION_IN_PROGRESS:
    "Calculation in progress, please check your email to be notified once the calculation has completed",
  CALCULATION_IS_EMPTY: "Somthing went wrong with the calculation.",

  JOURNEY_SCHEDULED: "Your journey has been scheduled and assigned to the driver",

  LICENSE_DRIVER_BEHAVIOUR: "You can not change this option in a Starter plan.",
  LICENSE_DRIVER_VEHICLE_MATCH: (planName: string, number: number) =>
    `For a ${planName} plan, a maximum of ${number} drivers and vehicles can be selected.`,
  LICENSE_DEPOT: (planName: string, number: number) =>
    `For a ${planName} plan, a maximum of ${number} ${number > 1 ? "depots" : "depot"} can be added.`,
  LICENSE_PARCEL: (planName: string, number: number) =>
    `For a ${planName} plan, a maximum of ${number} Parcels file can be selected. Please upload a file that does not exceed this limit.`,
  LICENSE_REMAINING_PARCEL:
    "The number of parcels is more than the amount left for processing. Check the My-Plan dialog.",
  LICENSE_SELECTING_COLUMN_PARCEL: "This item is not available on your plan, Please upgrade your plan.",

  DELETE_DRIVER_IN_DV_MATCH:
    "This Driver is selected in the Match Driver Vehicle input. Unselect the driver before deleting.",
  DELETE_VEHICLE_IN_DV_MATCH:
    "This Vehcile is selected in the Match Driver Vehicle input. Unselect the Vehicle before deleting.",
  DISABLE_DRIVER_IN_DV_MATCH:
    "This Driver is selected in the Match Driver Vehicle input. Unselect the driver before disabling.",
  DISABLE_VEHICLE_IN_DV_MATCH:
    "This Vehcile is selected in the Match Driver Vehicle input. Unselect the Vehicle before disasbling.",

  UPDATE_SETTINGS: "Settings updated successfully",

  ADD_ITEM: (itemName: string) => `${itemName} added successfully`,
  EDIT_ITEM: (itemName: string) => `${itemName} edited successfully`,
  DELETE_ITEM: (itemName: string) => `${itemName} removed successfully`,
};
