import { Navigate } from "react-router-dom";

import { Dialog } from "@evr/components/Dialogs";
import { Header } from "@evr/components/Header";
import { LeftSidebar } from "@evr/components/LeftSidebar";
import OlMap from "@evr/components/OlMap/Map";
import { RightSidebar } from "@evr/components/RightSidebar";
import { ScheduledJourneysWindow } from "@evr/components/ScheduledJourneysWindow";
import { TablesWindow } from "@evr/components/TablesWindow";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { SignalRConnection } from "@evr/SignalRConnection";
import { selectCurrentUser } from "@evr/store/slices/auth";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";

export const Home = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  if (!currentUser && window.location.href.toLowerCase().includes("livemode")) {
    return <Navigate to="/loadingApp?mode=live" replace />;
  }

  if (!currentUser && !window.location.href.toLowerCase().includes("livemode")) {
    return <Navigate to="/loadingApp" replace />;
  }
  return (
    <>
      <Header />
      <RowContainer flexGrow={1} overflow="hidden" position="relative">
        <Wrapper flexGrow={1} height="100%">
          <OlMap />
        </Wrapper>
        <RightSidebar />
        <LeftSidebar />
      </RowContainer>
      <TablesWindow />
      <ScheduledJourneysWindow />
      <Dialog />
      <SignalRConnection />
    </>
  );
};
