import logo from "@evr/assets/EVRLogo.png";
import { ResultHeader } from "@evr/components/ResultHeader";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { resetCalculationParams } from "@evr/store/slices/result";
import { theme } from "@evr/theme";
import { RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { Img } from "@evr/ui/Img";

import { NotificationIcon } from "./NotificationIcon";
import { SecondHeader } from "./SecondHeader";
import { UserHeaderMenu } from "./UserHeaderMenu";

export const Header = () => {
  const dispatch = useAppDispatch();
  const handleResetCalculationParams = () => dispatch(resetCalculationParams());
  return (
    <Wrapper as="header" position="relative" zIndex={theme.zIndex.header}>
      <RowContainer gradient justify="space-between" height="50px" padding="5px 15px">
        <Img src={logo} title="Reset Calculation parameters" cursorPointer onClick={handleResetCalculationParams} />
        <RowContainer>
          <UserHeaderMenu />
          <NotificationIcon />
        </RowContainer>
      </RowContainer>
      <SecondHeader />
      <ResultHeader />
    </Wrapper>
  );
};
