import { DriverVehicleMatch } from "@evr/components/DriverVehicleMatch";
import { Parcel } from "@evr/components/Parcel";
import { RowContainer } from "@evr/ui/FlexBox";

import { CalculationItem } from "./CalculationItem";
import { DepotSelect } from "./DepotSelect";
import { DisablingHeader } from "./DisablingHeader";
import { DriverVehicleItem } from "./DriverVehicleItem";
import { InformationCard } from "./InformationCard";
import { ParcelItem } from "./ParcelItem";

export const SecondHeader = () => {
  return (
    <RowContainer justify="space-around" wrap="wrap" padding="10px" position="relative" bgColor="white" zIndex={1}>
      <DisablingHeader />
      <InformationCard />
      <DepotSelect />
      <DriverVehicleItem />
      <ParcelItem />
      <CalculationItem />
      {/* <SavingsEnergyItem /> */}
      <DriverVehicleMatch />
      <Parcel />
    </RowContainer>
  );
};
