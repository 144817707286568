import { IconArrowDown } from "@tabler/icons";

import { SortColumn, SortKeys, TableColumn } from "@evr/types";
import { Flex, FlexProps } from "@evr/ui/FlexBox";

interface TableSortableHeaderProps extends FlexProps {
  sort: SortColumn;
  column: TableColumn<string>;
  onChange: (field: string) => void;
}

export const TableSortableHeader = ({ column, sort, onChange, ...flexProps }: TableSortableHeaderProps) => {
  const field = column.actionField || column.field;
  const isSorted = sort.column === field && sort.type !== SortKeys.NONE;
  const isSortedDesc = sort.type === SortKeys.DESC;

  const handleChangeSortColumn = () => {
    column.sortable && onChange(field);
  };

  return (
    <Flex
      minHeight="20px"
      padding="0.5rem 0.4rem"
      justify="flex-start"
      cursor={column.sortable ? "pointer" : "default"}
      onClick={handleChangeSortColumn}
      {...flexProps}
    >
      {column.name}
      {column.sortable && (
        <Flex transition="transform 0.3s" transform={`rotate(${isSortedDesc ? "180deg" : "0deg"})`}>
          <IconArrowDown size={20} visibility={isSorted ? "default" : "hidden"} />
        </Flex>
      )}
    </Flex>
  );
};
