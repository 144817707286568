import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalculationStatusKeys, SliceKeys, tablePaginationInitialValue } from "@evr/constant";
import { RootState } from "@evr/store";
import { Calculation, CalculationState, SearchColumn } from "@evr/types";
import { queryBuilder } from "@evr/utils";

const initialState: CalculationState = {
  newCalculationIds: [],
  notificationBadge: { number: 0, status: CalculationStatusKeys.COMPLETED },
  pagination: tablePaginationInitialValue,
};

export const CalculationSlice = createSlice({
  name: SliceKeys.CALCULATION,
  initialState,
  reducers: {
    addNewCalculation: (state, action: PayloadAction<Calculation>) => {
      const { id, status } = action.payload;
      state.newCalculationIds.push(id);
      state.notificationBadge.number++;
      state.notificationBadge.status = status;
    },
    clearNewCalculationIds: state => {
      state.newCalculationIds = [];
    },
    resetNotificationBadgeNumber: state => {
      state.notificationBadge.number = 0;
    },
    setCalculationPageIndex: (state, action: PayloadAction<number>) => {
      state.pagination.pageIndex = action.payload;
    },
    setCalculationSearchValue: (state, action: PayloadAction<SearchColumn>) => {
      state.pagination.pageIndex = tablePaginationInitialValue.pageIndex;
      state.pagination.search[action.payload.column] = action.payload;
    },
    clearCalculationSearchValue: (state, action: PayloadAction<string>) => {
      const { [action.payload]: deleteItem, ...otherSearchItems } = state.pagination.search;
      state.pagination.search = otherSearchItems;
    },
  },
});

export const {
  addNewCalculation,
  clearNewCalculationIds,
  setCalculationPageIndex,
  setCalculationSearchValue,
  clearCalculationSearchValue,
  resetNotificationBadgeNumber,
} = CalculationSlice.actions;

export const selectNotificationBadge = createSelector(
  (state: RootState) => state.calcualtion.notificationBadge,
  notificationBadge => notificationBadge,
);
export const selectNewCalculationIds = createSelector(
  (state: RootState) => state.calcualtion.newCalculationIds,
  newCalculationIds => newCalculationIds,
);
export const selectCalculationsQuery = createSelector(
  (state: RootState) => state.calcualtion.pagination,
  ({ pageSize, pageIndex, search, sort }) => queryBuilder(pageSize, pageIndex, search, sort),
);
export const selectCalculationPagination = createSelector(
  (state: RootState) => state.calcualtion.pagination,
  ({ totalPages, pageIndex, sort, search }) => ({ totalPages, pageIndex, sort, search }),
);
export default CalculationSlice.reducer;
