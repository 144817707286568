import { Vehicle } from "@evr/types";

export enum VehicleKeys {
  ID = "id",
  LICENCE = "licence",
  MAKE = "make",
  MODEL = "model",
  ENGINE_TYPE = "engineType",
  PLUG_SET = "plugSet",
  STORAGE_SIZE = "storageSize",
  MAX_PAYLOAD_WEIGHT = "maxPayloadWeight",
  SIZE = "vehicleSize",
  BATTERY_CAPACITY = "batteryCapacity",
  LISTED_RANGE = "listedRange",
  CREATED_DATE = "createdDate",
  ICON = "icon",
}

export enum VehicleSize {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
}

export const vehicleSizeOptions = Object.entries(VehicleSize).map(([key, value]) => ({ key, value }));

export enum EngineType {
  ELECTRIC = "Electric",
  DIESEL = "Diesel",
  PETROL = "Petrol",
  HYBRID = "Hybrid",
}
export const engineTypeOptions = Object.entries(EngineType).map(([key, value]) => ({ key, value }));


export enum PlugSet {
  StandardHousehold = "StandardHousehold",
  IEC62196Type1 = "IEC62196Type1",
  IEC62196Type1CCS = "IEC62196Type1CCS",
  IEC62196Type2Outlet = "IEC62196Type2Outlet",
  IEC62196Type2CableAttached = "IEC62196Type2CableAttached",
  IEC62196Type2CCS = "IEC62196Type2CCS",
  IEC62196Type3 = "IEC62196Type3",
  Chademo = "Chademo",
  GBT20234Part2 = "GBT20234Part2",
  GBT20234Part3 = "GBT20234Part3",
  IEC60309AC3PhaseRed = "IEC60309AC3PhaseRed",
  IEC60309AC1PhaseBlue = "IEC60309AC1PhaseBlue",
  IEC60309DCWhite = "IEC60309DCWhite",
  Tesla = "Tesla"
}
export const plugSetOptions = Object.entries(PlugSet).map(([key, value]) => ({ key, value }));


export const vehicleTypeSymbol = {
  [EngineType.ELECTRIC]: "E",
  [EngineType.DIESEL]: "D",
  [EngineType.HYBRID]: "H",
  [EngineType.PETROL]: "P",
};

export const vehicleInitialValues: Vehicle = {
  [VehicleKeys.ID]: -1,
  [VehicleKeys.SIZE]: VehicleSize.SMALL,
  [VehicleKeys.LICENCE]: "",
  [VehicleKeys.ENGINE_TYPE]: EngineType.ELECTRIC,
  [VehicleKeys.PLUG_SET]: PlugSet.StandardHousehold,
  [VehicleKeys.MODEL]: "",
  [VehicleKeys.MAKE]: "",
  [VehicleKeys.LISTED_RANGE]: 0,
  [VehicleKeys.BATTERY_CAPACITY]: 0,
  [VehicleKeys.MAX_PAYLOAD_WEIGHT]: 0,
  [VehicleKeys.STORAGE_SIZE]: 0,
};

// These are the warning bounds for a vehicle which exceeds the below expected KW per mile limits
export interface VehicleKwBounds {
  [VehicleSize.SMALL]: number;
  [VehicleSize.MEDIUM]: number;
  [VehicleSize.LARGE]: number;
}

export const vehicleLowerKwBounds: VehicleKwBounds = {
  [VehicleSize.SMALL]: 2.7,
  [VehicleSize.MEDIUM]: 2,
  [VehicleSize.LARGE]: 1.60,
};

export const vehicleUpperKwBounds: VehicleKwBounds = {
  [VehicleSize.SMALL]: 4.17,
  [VehicleSize.MEDIUM]: 2.97,
  [VehicleSize.LARGE]: 2.40,
};
