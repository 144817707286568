import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, QueryKeys } from "@evr/constant";
import { SearchObject, SearchParameterKeys, SortColumn, SortKeys, TimeRange } from "@evr/types";

const getPascalCase = (key: string) => key.charAt(0).toUpperCase() + key.slice(1);

export const queryBuilder = (pageSize: number, pageIndex: number, searchObject?: SearchObject, sort?: SortColumn, timeRange?:TimeRange) => {  
  return getDefaultSearchParams(pageSize,pageIndex,searchObject,sort,timeRange).toString();
};

export const getDefaultSearchParams = (pageSize: number, pageIndex: number, searchObject?: SearchObject, sort?: SortColumn, timeRange?:TimeRange) => {
  const query = new URLSearchParams();
  query.set(QueryKeys.PAGE_SIZE, String(pageSize));
  query.set(QueryKeys.PAGE_INDEX, String(pageIndex));

  if (searchObject) {
    const filterQuery = Object.values(searchObject)
      .map(
        search => `${getPascalCase(search.column)} ${search.parameter || SearchParameterKeys.CONTAIN} ${search?.value}`,
      )
      .join(" _and ");
    filterQuery.length && query.set(QueryKeys.FILTER, filterQuery);
  }
  if (sort && sort.type !== SortKeys.NONE) {
    query.set(QueryKeys.ORDER_BY, `${getPascalCase(sort.column)}${sort.type === SortKeys.DESC ? " desc" : ""}`);
  }
  if(timeRange && timeRange.start && timeRange.end) {
    query.set(QueryKeys.START_DATE, timeRange.start);
    query.set(QueryKeys.END_DATE, timeRange.end);
  }
  
  return query;
};

export const maxPageSizeQuery = queryBuilder(MAX_PAGE_SIZE, DEFAULT_PAGE_INDEX);
