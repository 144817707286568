import { evrApi } from "@evr/apis";
import { ApiMethodKeys, CommonKeys, notificationsMsg, SnackbarKeys } from "@evr/constant";
import { closeDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { ApiPageResponse, Driver } from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { DriverEndpoint } from "../endpoints";

export const driverApi = evrApi.enhanceEndpoints({ addTagTypes: [CacheKeys.DRIVERS] }).injectEndpoints({
  endpoints: builder => ({
    drivers: builder.query<ApiPageResponse<Driver>, string>({
      query: query => ({ url: `${DriverEndpoint}?${query}` }),
      providesTags: [CacheKeys.DRIVERS],
    }),
    addDriver: builder.mutation<Driver, Partial<Driver> & { skipNotification?: boolean }>({
      query: arg => {
        const { skipNotification, ...data } = arg;
        return {
          url: DriverEndpoint,
          method: ApiMethodKeys.POST,
          data,
        };
      },
      invalidatesTags: [CacheKeys.DRIVERS],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { skipNotification = false } = arg;
        await queryFulfilled;
        if (!skipNotification) {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.ADD_ITEM(CommonKeys.DRIVER) }));
          dispatch(closeDialog());
        }
      },
    }),
    editDriver: builder.mutation<Driver, Partial<Driver>>({
      query: data => ({ url: DriverEndpoint, method: ApiMethodKeys.PATCH, data }),
      invalidatesTags: [CacheKeys.DRIVERS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.EDIT_ITEM(CommonKeys.DRIVER) }));
          dispatch(closeDialog());
        });
      },
    }),
    deleteDriver: builder.mutation<void, number>({
      query: id => ({ url: `${DriverEndpoint}/${id}`, method: ApiMethodKeys.DELETE }),
      invalidatesTags: [CacheKeys.DRIVERS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.DELETE_ITEM(CommonKeys.DRIVER) }),
          );
          dispatch(closeDialog());
        });
      },
    }),
  }),
});

export const { useDriversQuery, useAddDriverMutation, useEditDriverMutation, useDeleteDriverMutation } = driverApi;
