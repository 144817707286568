import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import { BaseLayout } from "@evr/components/BaseLayout";
import { ForgotPassword, ResetPassword, SignIn, SignUp } from "@evr/pages/Auth";
import { SignUpVerizon } from "@evr/pages/Auth/SignUpVerizon";
import { ErrorLoadingData } from "@evr/pages/ErrorLoadingData";
import { Home } from "@evr/pages/Home";
import { LoadingApp } from "@evr/pages/LoadingApp";
import { PrivacyPolicy } from "@evr/pages/PrivacyPolicy/PrivacyPolicy";
import { Sso } from "@evr/pages/Sso";
import { SsoError } from "@evr/pages/SsoError";
import { Unauthorized } from "@evr/pages/Unauthorized";

const MainRoutes: RouteObject = {
  path: "/",
  element: <BaseLayout />,
  children: [
    { index: true, element: <Home /> },
    { path: "/liveMode", element: <Home /> },
    { path: "/result/:calculationId", element: <LoadingApp /> },
    { path: "/loadingApp", element: <LoadingApp /> },
    { path: "/signin", element: <SignIn /> },
    { path: "/signin/:userEmail", element: <SignIn /> },
    { path: "/signup", element: <SignUp /> },
    { path: "/signup-verizon", element: <SignUpVerizon />},
    { path: "/forgotpass", element: <ForgotPassword /> },
    { path: "/resetpass", element: <ResetPassword /> },
    { path: "/unauthorized", element: <Unauthorized /> },
    { path: "/errorLoadingData", element: <ErrorLoadingData /> },
    { path: "/sso", element: <Sso /> },
    { path: "/ssoError", element: <SsoError /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "*", element: <Navigate to="/signin" replace /> },
  ],
};

export const Routes = () => {
  return useRoutes([MainRoutes]);
};
