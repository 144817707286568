import styled, { css } from "styled-components";

export const TableSearchContainer = styled.form`
  width: 60%;
  max-width: 450px;
  padding: 0 0.2rem;
  display: flex;
  position: relative;
  @media (max-width: ${p => p.theme.breakpoints.sm}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const inputCss = css`
  width: 100%;
  padding: 0.4rem 80px 0.4rem 0.4rem;
  color: ${p => p.theme.colors.grey.dark};
  transition: width 0.5s ease;
  opacity: 0.6;
  ::placeholder {
    font-size: 1rem;
    font-family: "Roboto";
    color: ${p => p.theme.colors.grey.main};
  }
  :focus {
    opacity: 1;
  }
`;
