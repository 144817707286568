import { createSelector, createSlice } from "@reduxjs/toolkit";

import { InitialPlan, OrgSettingsInitialValues, SliceKeys } from "@evr/constant";
import { RootState } from "@evr/store";
import { AuthState } from "@evr/types";

import { AuthReducer } from "./authReducer";

const initialState: AuthState = { currentUser: null, subscription: InitialPlan, orgSettings: OrgSettingsInitialValues };

export const AuthSlice = createSlice({
  name: SliceKeys.AUTH,
  initialState,
  reducers: { ...AuthReducer },
});

export const {
  logInUser,
  logOutUser,
  logInSSOUser,
  setProfilePhoto,
  setUserHasCalculation,
  setSubscription,
  getUserFromSession,
  setOrgSettings,
} = AuthSlice.actions;

export const selectCurrentUser = createSelector(
  (state: RootState) => state.auth.currentUser,
  currentUser => currentUser,
);
export const selectUserSubscription = createSelector(
  (state: RootState) => state.auth.subscription,
  subscription => subscription,
);
export const selectUserOrgSettings = createSelector(
  (state: RootState) => state.auth.orgSettings,
  orgSettings => orgSettings,
);
export default AuthSlice.reducer;
