import { useGetUserPhotoQuery } from "@evr/apis/accounts";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectCurrentUser } from "@evr/store/slices/auth";
import { CircleImageIcon } from "@evr/ui/CircleImageIcon";
import { IconButton } from "@evr/ui/IconButton";
import { Typography } from "@evr/ui/Typography";

export const UserPhotoAndEmail = () => {
  const user = useAppSelector(selectCurrentUser);

  const { data: userPhoto } = useGetUserPhotoQuery(undefined, { skip: !user || !user.hasPhoto });

  return (
    <>
      <CircleImageIcon
        url={userPhoto}
        WH="35px"
        cursorPointer
        icon="user-circle"
        iconProps={{ color: "white", size: 1.9 }}
      />
      <Typography margin="0 0 0 0.5rem" size={1.1} cursorPointer>
        {user?.email}
      </Typography>
      <IconButton icon="sort-down" WH="25px" color="white" margin="0 1rem 0.3rem 0" />
    </>
  );
};
