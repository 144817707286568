import styled from "styled-components";

export const AcContainer = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  position: relative;
`;
interface InnerContainerProps {
  primary?: boolean;
  open?: boolean;
  borderShadow?: boolean;
  width?: string;
  noBorder?: boolean;
  disabled?: boolean;
}
export const AcInnerContainer = styled.div<InnerContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: ${props => props.theme.colors.common.white};
  background-clip: padding-box;
  ${props => !props.noBorder && `border: 1px solid ${props.theme.colors.action.border};`}
  border-radius: ${props => props.theme.borderRadius};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${props =>
    props.open &&
    !props.noBorder &&
    `
    border-color: ${props.theme.colors.primary.main};
  `}
  ${props => props.disabled && `color: ${props.theme.colors.action.disabled};`}
`;

export const AcSelectedItem = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
  width: 100%;
  height: 100%;
`;

export const AcInput = styled.input`
  display: block;
  font-size: 1rem;
  width: 100%;
  line-height: 1.5;
  color: #495057;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ::placeholder {
    color: ${props => props.theme.colors.grey.default};
  }
  border: none;
  outline: 0;
`;

export const AcOption = styled.div`
  position: absolute;
  width: 100%;
  top: 110%;
  max-height: 170px;
  overflow-y: auto;
  background-color: ${props => props.theme.colors.common.white};
  z-index: ${props => props.theme.zIndex.menu};
  box-shadow: ${props => props.theme.shadows[4]};
  border-radius: ${props => `${props.theme.borderRadius}`};
`;
interface ItemProps {
  disabled?: boolean;
  empty?: boolean;
}
export const AcItem = styled.span<ItemProps>`
  position: relative;
  display: block;
  padding: 10px;
  font-size: 1rem;
  color: ${props => props.theme.colors.common.black};
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid ${props => props.theme.colors.divider};
  &:last-child {
    border-bottom: none;
  }
  ${props =>
    props.empty &&
    `
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  `}
  ${props =>
    props.disabled
      ? `
  color: ${props.theme.colors.action.disabled}; 
  cursor: default;
  `
      : `
  &:hover {
    background: ${props.theme.colors.gradient.main};
    color: ${props.theme.colors.gradient.contrastText};
  }
  `}
`;
