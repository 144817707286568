import { useEffect } from "react";

import Feature from "ol/Feature";
import { Point } from "ol/geom";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";

import { Vector } from "@evr/components/ReactOL/Layers";
import { MapLayerZIndices } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectScheduledJourneyColor } from "@evr/store/slices/scheduled-journey-results";
import { DriverProgressSnapshot } from "@evr/types";

interface ActualJourneyLayerProps {
  source: olVectorSource;
  driverProgressSnapshots: DriverProgressSnapshot[];
  isVisible: boolean;
}

const ActualJourneyLayer = ({ source, driverProgressSnapshots, isVisible }: ActualJourneyLayerProps) => {
  const journeyColor = useAppSelector(selectScheduledJourneyColor);

  useEffect(() => {
    if (!isVisible) {
      source.clear();
      return;
    }
    return () => {
      source.clear();
    };
  }, [isVisible, source]);

  if (isVisible) {
    const features = driverProgressSnapshots.map((snapshot, index) => {
      // convert the point in the snapshot and create a point feature
      const feature = new Feature({
        geometry: new Point(olProj.fromLonLat(snapshot.point.coordinates)),
      });

      const style = new Style({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({ color: journeyColor.main }),
          stroke: new Stroke({ color: journeyColor.main, width: 1 }),
        }),
      });
      feature.setStyle(style);
      return feature;
    });

    source.addFeatures(features);
  }

  return <Vector source={source} zIndex={MapLayerZIndices.ACTUAL_DRIVER_ROUTE}></Vector>;
};

export default ActualJourneyLayer;
